export class PeriodicidadRMdl {

    activo: boolean;
    actualizado: Date;
    actualizadoPor: number;
    creado: Date;
    creadoPor: number;
    descripcion: string;
    nombre: string;
    periodicidadId: number;


    constructor(){
        this.activo = true;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.creado = null;
        this.creadoPor = null;
        this.descripcion = null;
        this.nombre = null;
        this.periodicidadId = null;
    }

}