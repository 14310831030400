import { Component, OnInit, Input } from '@angular/core';
import { OpcionesDesagregadoMdl } from '../../models/opcionesDesagregado.model';
import { FormGroup, FormArray, FormBuilder, FormControl } from '@angular/forms';

@Component({
  selector: 'app-historico-control',
  templateUrl: './historico-control.component.html',
  styles: []
})
export class HistoricoControlComponent implements OnInit {

  @Input() public ttlCtrls : Number
  @Input() public optDesagregados : OpcionesDesagregadoMdl[]

  public listOptsDesagregados: FormArray
  public formGroup: FormGroup
  public optDesagregadosGroup: FormGroup

  constructor(private _fb: FormBuilder){}

  ngOnInit(){
    this.formGroup = this._fb.group({
      optsDesagregados: this._fb.array([this.addOptsDesagregados( )])
    })

    this.listOptsDesagregados = this.formGroup.get('optsDesagregados') as FormArray;
  }

  get formArr() {
    let arryas =  this.formGroup.get('optsDesagregados') as FormArray;
    return arryas
  }

  addOptsDesagregados():FormGroup{
    this.optDesagregadosGroup = new FormGroup({})
    let temp=this.optDesagregados;
    
    temp.map(ctr => {
      this.optDesagregadosGroup.addControl(ctr.nombre, new FormControl(''))
    })
    
    return this.optDesagregadosGroup
  }

  addDesagregado() {
    // let temp=this.addOptsDesagregados();
    this.listOptsDesagregados.push(this.addOptsDesagregados())
  }

  removeDesagregado(index) {
    this.listOptsDesagregados.removeAt(index);
  }

  saveDesagregado(){
    console.log(this.formGroup.value);
  }
}
