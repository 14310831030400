import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { UnidadMedidaRMdl } from '../../models/UniMedidaReplica.model';
import { URL_API } from '../../globals';

@Injectable()
export class UniMedidaService {

  constructor(private http: HttpClient) { }

  getUniMedidas(){
    return this.http.get(`${URL_API}/unidadmedida`)
      .pipe(map(this.ListaUnidad))
      
  }

  eliminarUnidad(id:number, unidad: UnidadMedidaRMdl){
    return this.http.delete(`${URL_API}/unidadmedida/${id}`)
  }

  crearUnidad(unidad: UnidadMedidaRMdl){
    return this.http.post(`${URL_API}/unidadmedida`, unidad)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return unidad
    }))
  }

  getUnidad(id:number){
    return this.http.get<any>(`${URL_API}/unidadmedida/${id}`)
  }

  actualizarUnidad(unidad: UnidadMedidaRMdl){
    const unidadTemp = {
      ...unidad
    }
    delete unidadTemp.unidadmedidaId
    return this.http.put(`${URL_API}/unidadmedida/${unidad.unidadmedidaId}`, 
      unidad)
  }

  private ListaUnidad(unidadObj: Object){
    const unidades: UnidadMedidaRMdl[] = []
    if(unidadObj === null){ return [] }

    Object.keys(unidadObj).forEach(key => {
      const uni: UnidadMedidaRMdl = unidadObj[key]
      unidades.push(uni)
    })

    return unidades
  }

}