import { EjepvdMdl } from "./ejepvd.model";

export class BloqueMdl {
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion:string;
    bloqueId: number;
    ejepvd_id: EjepvdMdl;

    constructor(){
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.descripcion = null;
        this.bloqueId = null;
        this.nombre = null;

      
    }
}