export class EjecutoraMdl {

    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion: string;
    ejecutoraId: number;

    constructor(){
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.descripcion = null;
        this.ejecutoraId = null;
        this.nombre = null;

      
    }
}