import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { ObjetivoBloqueMdl } from '../../models/objetivoBloque.model';
import { URL_API } from '../../globals';
import { BloqueMdl } from '../../models/bloque.model';

@Injectable()
export class ObjetivosService {

  constructor(private http: HttpClient) { }

  getObjetivos(){
    return this.http.get(`${URL_API}/objetivobloque`)
      .pipe(map(this.crearLista))
  }

  getBloques(){
    return this.http.get(`${URL_API}/bloque`)
      .pipe(map(this.listaBloques))
  }

  eliminarObjetivo(id:number, objetivo: ObjetivoBloqueMdl){
    return this.http.delete(`${URL_API}/objetivobloque/${id}`)
  }

  crearObjetivo(objetivo: ObjetivoBloqueMdl){
    return this.http.post(`${URL_API}/objetivobloque`, objetivo)
    .pipe(map((resp:any)=>{
      //objetivo.objetivobloqueId = resp.objetivobloqueId
      console.info('Guardado: ', resp)
      return objetivo
    }))
  }

  getObjetivo(id:number){
    return this.http.get<any>(`${URL_API}/objetivobloque/${id}`)
  }

  actualizarObjetivo(objetivo: ObjetivoBloqueMdl){
    
    const objetivoTemp = {
      ...objetivo
    }
    delete objetivoTemp.objetivobloqueId
    return this.http.put(`${URL_API}/objetivobloque/${objetivo.objetivobloqueId}`, 
      objetivo)
  }

  private crearLista(objetivoObj: Object){
    const objetivos: ObjetivoBloqueMdl[] = []
    if(objetivoObj === null){ return [] }

    Object.keys(objetivoObj).forEach(key => {
      const objetivo: ObjetivoBloqueMdl = objetivoObj[key]
      objetivos.push(objetivo)
    })

    return objetivos
  }

  private listaBloques(objsBloques: Object){
    const bloques: BloqueMdl[] = []
    if(objsBloques === null){return []}
    Object.keys(objsBloques).forEach(key => {
      const bloque: BloqueMdl = objsBloques[key]
      bloques.push(bloque)
    })

    return bloques
  }
}
