/*import { Component, OnInit } from '@angular/core';
import { UsuarioMdl } from '../../../models/Usuario';
import { UsuarioService } from '../../../services/shared/usuario.service';
import Swal from 'sweetalert2'
import { DependenciasService } from '../../../services/service.index';
import { DependenciaMdl } from '../../../models/dependencia.model';

@Component({
  selector: 'app-usuario-listado',
  templateUrl: './usuario-listado.component.html',
  styleUrls: ['./usuario-listado.component.css']
})
export class UsuarioListadoComponent implements OnInit {
  usuarios: UsuarioMdl[] = []
  dependencias: DependenciaMdl[] = [];
  p: number = 1
  constructor(private service: UsuarioService, private serviceDep: DependenciasService) { }

  ngOnInit() {
    this.serviceDep.getDependencias() //se optienen las dependencias para el 
      .subscribe((res => {        
        this.dependencias = res;       
      }))

    /*this.service.getUsers()
      .subscribe(resp => {
        this.usuarios = resp;
      })
  }

  borrarUsuario(usuario: UsuarioMdl, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará ${usuario.nombreUsuario}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {
        this.usuarios = this.usuarios.filter(m => m.usuarioId !== usuario.usuarioId)
        usuario.activo = false;
        console.log(usuario)
        /*this.service.updateUser(
          usuario.usuarioId,
          usuario
        ).subscribe()
      }
    })
  }

  getDependenciaNombre(id: any) { 
    const dp = this.dependencias.find(dp => dp.dependenciaId == id.dependenciaId);
    if (dp)
      return dp.nombre
    else
      return ''

  }

}*/

import { Component, OnInit } from '@angular/core';
import { UsuarioService, SidebarService } from '../../../services/service.index';
import { UsuarioMdl } from '../../../models/Usuario';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario-listado.component.html',
  styleUrls: ['./usuario-listado.component.css']
})
export class UsuarioListadoComponent implements OnInit {
  usuarios: UsuarioMdl[] = []
  p: number = 1
  data: any[] = [];

  cols: any[] = [
    { field: "nombre", header: "Nombre" },
    { field: "apellidoPaterno", header: "Primer Apellido" },
    { field: "apellidoMaterno", header: "Segundo Apellido" },
    { field: "usuario", header: "Usuario" },
    { field: "Acciones", header: "Acciones" },
  ];

  constructor(private service: UsuarioService,
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    this.service.getUsuarios()
      .subscribe(resp => {
        this.data = resp;

        this.usuarios = resp
        this.usuarios = this.usuarios.filter( b => b.activo == true)
      })
  }

  borrarUsuario(usuario: UsuarioMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${usuario.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.usuarios = this.usuarios.filter( u => u.usuarioId !== usuario.usuarioId);
        this.data = this.usuarios.filter( u => u.usuarioId !== usuario.usuarioId);

        usuario.activo = false;
        console.log(usuario)
        this.service.eliminarUsuario( usuario.usuarioId ).subscribe()
      }
    })
  }
  
}

