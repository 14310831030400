import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { iUsuarioPerfilMdl } from '../../models/iUsuarioPerfil';
import { URL_API } from '../../globals';
import { UsuarioMdl } from '../../models/Usuario';
import { PerfilMdl } from '../../models/Perfil';

@Injectable()
export class IntUsuPerService {

  constructor(private http: HttpClient) { }

  getIntUsuPers(){
    return this.http.get(`${URL_API}/iusuarioperfil/`)
      .pipe(map(this.ListaIntermedia))
  }

  getUsuario(){
    return this.http.get(`${URL_API}/usuario`)
      .pipe(map(this.listaUsuario))
  }

  getPerfil(){
    return this.http.get(`${URL_API}/perfil`)
      .pipe(map(this.listaPerfil))
  }

  eliminarIntUsuPer(id:number, intermedia: iUsuarioPerfilMdl){
    return this.http.delete(`${URL_API}/iusuarioperfil/${id}`)
  }

  crearIntUsuPer(intermedia: iUsuarioPerfilMdl){
    return this.http.put(`${URL_API}/iusuarioperfil/persistencia`, intermedia)
    .pipe(map((resp:any)=>{
     // dependencia.dependenciaId = resp.dependenciaId
      //console.info('Guardado: ', resp)
      return intermedia
    }))
  }

  getIntUsuPer(id:number){
    return this.http.get<any>(`${URL_API}/iusuarioperfil/${id}`)
  }

  getPerfilesUserid(id:number){
    return this.http.get<any[]>(`${URL_API}/iusuarioperfil/list/${id}`)
  }

  actualizarIntUsuPer(intermedia: iUsuarioPerfilMdl){
    const intermediaTemp = {
      ...intermedia
    }
    delete intermediaTemp.iusuarioPerfilId
    return this.http.put(`${URL_API}/iusuarioperfil/persistencia`, 
      intermedia)
  }

  private ListaIntermedia(IntObj: Object){
    const intermedias: iUsuarioPerfilMdl[] = []
    if(IntObj === null){ return [] }

    Object.keys(IntObj).forEach(key => {
      const intermedia: iUsuarioPerfilMdl = IntObj[key]
      intermedias.push(intermedia)
    })

    return intermedias
  }

  private listaUsuario(Usobjs: Object){
    const usuarios: UsuarioMdl[] = []
    if(Usobjs === null){return []}
   
    Object.keys(Usobjs).forEach(key => {
      const usuario: UsuarioMdl = Usobjs[key]
      usuarios.push(usuario)
    })

    return usuarios
  }

  private listaPerfil(PerObj: Object){
    const perfils:PerfilMdl [] = []
    if(PerObj === null){ return [] }

    Object.keys(PerObj).forEach(key => {
      const perfil: PerfilMdl = PerObj[key]
      perfils.push(perfil)
    })

    return perfils
  }
}