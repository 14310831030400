import { AfterViewInit, Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { ImagenInversionService, InversionService, SidebarService } from '../../../services/service.index';
import { ImgProyInvMdl } from '../../../models/ImgProy.model';
import Swal from 'sweetalert2'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

export interface PagingConfig {
  currentPage: number;
  itemsPerPage: number;
  totalItems: number;
}

@Component({
  selector: 'app-imagenes',
  templateUrl: './listImagen.component.html',
  styleUrls: ['./listImagen.component.css']
})
export class ListadoImagenComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, {static: false})
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings   = {};

  imagenes: ImgProyInvMdl[] = []

  filtro: any = {
    vin: "",
  };
  data: any[] = [];
  p:number = 1

  currentPage:number  = 1;
  itemsPerPage: number = 10;
  totalItems: number = 0;
  tableSize: number[] = [10, 50, 100, 200];

  pagingConfig: PagingConfig = {} as PagingConfig;

  constructor(private service: ImagenInversionService,
    private inversionService:InversionService,
  private sideBarService: SidebarService,private router:Router) {

    this.pagingConfig = {
      itemsPerPage: this.itemsPerPage,
      currentPage: this.currentPage,
      totalItems: this.totalItems
    }
   }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "loadingRecords": "Cargando Datos...",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
    },
      dom: 'Blfrtip',
      processing: true,
        // serverSide: true,
        // paging: true,
        // pagingType: 'full_numbers',
        destroy:true,
        scrollX: true,
        responsive: true,
        orderCellsTop: true,
        pageLength: 100,
        "lengthMenu": [ [100, 500, 1000, -1], [100, 500, 1000, "All"] ],
        // stateSave: true,
      ajax: (dataTablesParameters: any, callback) => {
    this.service.getImagen()
    .subscribe(resp => {

        this.imagenes = resp
        this.imagenes = this.imagenes.filter( b => b.activo == true && b.proyectoinversionId.activo==true)
        this.data = this.imagenes;
        this.data.forEach(element => {
          try {
            element.nombre = element.proyectoinversionId.nombre
          } catch {
            element.nombre = "";
          }

        });

        callback({
          recordsTotal: resp.length,
          recordsFiltered: resp.length,
          data: this.imagenes
        });
      })
    },
    columns: [
      {title:'Id',data:'imagenesProyectoinversionId'},
      { title: 'Nombre Imagen', data: 'nombreArchivoOriginal' },
      { title: 'Proyecto de Inversión', data: 'proyectoinversionId.nombre' },
      { title: 'Acciones', data: 'imagenesProyectoinversionId' },
    ],
    rowCallback: (row: Node, data: ImgProyInvMdl, index: number) => {
      const self = this;
      $('#editar', row).off('click');
      $('#editar', row).on('click', () => {
        this.router.navigate(["/ComImg/" + data.imagenesProyectoinversionId])
      });
      $('#borrar', row).off('click');
      $('#borrar', row).on('click', () => {
        self.borrarImagen(data, index);
      });
      return row;
    },
    createdRow: (row, data: ImgProyInvMdl, dataIndex) => {
      if(this.sideBarService.BuscarPermiso('/webresources/imagenesproyectoinversion/{id}', 'Administrador', 'put')==true){
        $('td', row).eq(3).html("" +
        "<button id='editar' type='button' class='btn btn-success btn-default btn-sm'>" +
        "<i class='fa fa-edit icon-white'></i>" +
        "</button>")
      }
      if(this.sideBarService.BuscarPermiso('/webresources/imagenesproyectoinversion/{id}', 'Administrador', 'delete')==true){
        $('td', row).eq(3).append("" +
        "<button id='borrar' type='button' class='btn btn-danger btn-default btn-sm'>" +
        "<i class='fa fa-trash icon-white'></i>" +
        "</button>")
      }
    }
    }

    }

    ngAfterViewInit(): void {
      this.dtTrigger.next();

      // this.dtTrigger.subscribe(() => {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input').on('keyup change', function () {
            if (dtInstance.column(this['id']).search() !== this['value']) {
              dtInstance
                .columns(this['id'])
                .search(this['value'])
                .draw();
            }
          });
        });
      });
      // });

    }

    rerender(): void {

      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next()
        // this.dtTrigger.next(0);
        // console.log(this.datatableElement)
      });
    }

    ngOnDestroy(): void {
      // Do not forget to unsubscribe the event
      this.dtTrigger.unsubscribe();
    }

    // getImagenes(){
    //   this.service.getImagenPagination(this.currentPage,this.itemsPerPage*100)
    //   .subscribe(res=>{
    //     console.log(res)
    //     // this.imagenes=res
    //     this.imagenes = this.imagenes.filter( b => b.activo == true && b.proyectoinversionId.activo==true)
    //     this.pagingConfig.totalItems = this.imagenes.length
    //   })

    // }

    // onTableDataChange(event:any){
    //   this.pagingConfig.currentPage  = event;
    //   console.log(event)
    //   // this.getImagenes();
    // }

    // onTableSizeChange(event:any): void {
    //   this.pagingConfig.itemsPerPage = event.target.value;
    //   this.pagingConfig.currentPage = 1;
    //   console.log(event)
    //   // this.getImagenes();
    // }

  borrarImagen(imgen: ImgProyInvMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${imgen.nombreArchivoOriginal}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){

        this.imagenes = this.imagenes.filter( img => img.imagenesProyectoinversionId !== imgen.imagenesProyectoinversionId)
        imgen.activo = false;
        console.log(imgen)
        this.service.eliminarImagen(
          imgen.imagenesProyectoinversionId,

          ).subscribe()
      }
    })
  }



}
