import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {PickListModule} from 'primeng/picklist';  
import {DropdownModule} from 'primeng/dropdown';
import {ChipsModule} from 'primeng/chips';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {ButtonModule} from 'primeng/button';
import {TableModule} from 'primeng/table';
import {OrderListModule} from 'primeng/orderlist';
import {TreeModule} from 'primeng/tree';
import {ListboxModule} from 'primeng/listbox';
import {TooltipModule} from 'primeng/tooltip';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    PickListModule,
    DropdownModule,
    ChipsModule,
    OverlayPanelModule,
    ButtonModule,
    TableModule,
    OrderListModule,
    TreeModule,
    ListboxModule,
    TooltipModule
   
   

  ],
  exports: [
    BrowserAnimationsModule,
    PickListModule,
    DropdownModule,
    ChipsModule,
    OverlayPanelModule,
    ButtonModule,
    TableModule,
    OrderListModule,
    TreeModule,
    ListboxModule,
    TooltipModule,
    

  ],
  providers: [
  ]
})
export class PrimeNgModule { }
