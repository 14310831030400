import { EstadoMdl } from './estado.model';


export class RegionMdl {
    creado: Date
    creadoPor: number
    actualizado: Date
    actualizadoPor: number
    activo: boolean
    claveregion:number
    nombre: string
    abreviatura:string
    latitud:any
    longitud:any
    latDecimal:any
    longDecimal:any
    altitud:any
    mapa:any
    regionId: number
    estadoId?: EstadoMdl
    

    constructor(){
        this.activo = true;
        this.creado=new Date();
        this.creadoPor=null;
        this.actualizado=new Date();
        this.actualizadoPor=null;
        this.claveregion=null;
        this.nombre=null;
        this.abreviatura=" ";
        this.latitud=" ";
        this.longitud=" ";
        this.latDecimal=" ";
        this.longDecimal=" ";
        this.altitud=null;
        this.mapa=null;
        this.estadoId=null
    }
}