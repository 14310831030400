import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { MetaA2030Mdl } from '../../models/metaa2030.model';
import { URL_API } from '../../globals';
import { OdsMdl } from '../../models/ods.model';

@Injectable()
export class MetaA2030sService {

  constructor(private http: HttpClient) { }

  getMetas(){
    return this.http.get(`${URL_API}/metaa2030`)
      .pipe(map(this.crearLista))
  }

  getOdsS(){
    return this.http.get(`${URL_API}/ods`)
      .pipe(map(this.listaOds))
  }

  eliminarMeta(id:number, meta: MetaA2030Mdl){
    return this.http.delete(`${URL_API}/metaa2030/${id}`)
  }

  crearMeta(meta: MetaA2030Mdl){
    return this.http.post(`${URL_API}/metaa2030`, meta)
    .pipe(map((resp:any)=>{
     // meta.metaa2030Id = resp.metaa2030Id
      //console.info('Guardado: ', resp)
      return meta
    }))
  }

  getMeta(id:number){
    return this.http.get<any>(`${URL_API}/metaa2030/${id}`)
  }

  actualizarMeta(meta: MetaA2030Mdl){
    const metaTemp = {
      ...meta
    }
    delete metaTemp.metaa2030Id
    return this.http.put(`${URL_API}/metaa2030/${meta.metaa2030Id}`, 
      meta)
  }

  private crearLista(metaObj: Object){
    const metas: MetaA2030Mdl[] = []
    if(metaObj === null){ return [] }

    Object.keys(metaObj).forEach(key => {
      const meta: MetaA2030Mdl = metaObj[key]
      metas.push(meta)
    })

    return metas
  }

  private listaOds(objsOds: Object){
    const OdsS: OdsMdl[] = []
    if(objsOds === null){return []}
    Object.keys(objsOds).forEach(key => {
      const ods: OdsMdl = objsOds[key]
      OdsS.push(ods)
    })

    return OdsS
  }
}
