import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { URL_API } from '../../globals';
import { ImgProyInvMdl } from '../../models/ImgProy.model';
import { ProyectoInversionMdl } from '../../models/proyectoinv.model';
import { RegionMdl } from '../../models/region.model';
import { MunicipioMdl } from '../../models/municipio.model';
import { LocalidadMdl } from '../../models/localidad.model';
import { Observable } from 'rxjs';

@Injectable()
export class ImagenInversionService {

  constructor(private http: HttpClient) { }

  getImagen() {
    return this.http.get(`${URL_API}/imagenesproyectoinversion`)
      .pipe(map(this.ListaImagen))
  }

  getImagenPagination(page:number,to:number){
    return this.http.get(`${URL_API}/imagenesproyectoinversion/${page}/${to}`)
    .pipe(map(this.ListaImagen))
  }
  

  getImagenes(id:number){
    return this.http.get(`${URL_API}/imagenesproyectoinversion/list/${id}`)
    .pipe(map(this.ListaImagen))
  }

 

  getInversion(idInv: number) {
    return this.http.get(`${URL_API}/proyectoinversion/list/${idInv}`)
      .pipe(map(this.ListaInversion))
  }

  getProyInversion(idInv: number) {
    return this.http.get(`${URL_API}/proyectoinversion/${idInv}`)
  }
  


  getRegion() {
    return this.http.get(`${URL_API}/region`)
      .pipe(map(this.ListaRegion))
  }

  getMunicipio(idMun: number) {
    return this.http.get(`${URL_API}/municipio/list/${idMun}`)
      .pipe(map(this.ListaMunicipio))
  }

  getLocalidad(idLoc: number) {
    return this.http.get(`${URL_API}/localidad/list/${idLoc}`)
      .pipe(map(this.ListaLocalidad))
  }

  eliminarImagen(id:number){
    return this.http.delete(`${URL_API}/imagenesproyectoinversion/${id}`,
    )
  }


  crearImagen(imagen: ImgProyInvMdl) {
    return this.http.post(`${URL_API}/imagenesproyectoinversion`, imagen)
      .pipe(map((resp: any) => {
        // invers.proyectoinversionId = resp.proyectoinversionId
        console.info('Guardado: ', resp)
        return imagen
      }))
  }

  AgregarImagenProyecto(formulario: FormData,id:number) {

       return this.http.post<any>(`${URL_API}/imagenesproyectoinversion/${id}`, formulario);
       
  }

  getImg(id: number) {
    return this.http.get(`${URL_API}/imagenesproyectoinversion/${id}`)
  }

  actualizarImagen(imagen: ImgProyInvMdl) {
    const imagenTemp = {
      ...imagen
    }
    delete imagenTemp.imagenesProyectoinversionId
    return this.http.put(`${URL_API}/imagenesproyectoinversion/${imagen.imagenesProyectoinversionId}`,
      imagen)
  }

  private ListaImagen(ImgObj: Object) {
    const imagens: ImgProyInvMdl[] = []
    if (ImgObj === null) { return [] }
    Object.keys(ImgObj).forEach(key => {
      const imagen: ImgProyInvMdl = ImgObj[key]
      imagens.push(imagen)
    })

    return imagens
  }

  private ListaInversion(InvObj: Object) {
    const inversiones: ProyectoInversionMdl[] = []
    if (InvObj === null) { return [] }
    Object.keys(InvObj).forEach(key => {
      const inversion: ProyectoInversionMdl = InvObj[key]
      inversiones.push(inversion)
    })

    return inversiones;
  }

  private ListaRegion(RegObj: Object) {
    const regiones: RegionMdl[] = []
    if (RegObj === null) { return [] }
    Object.keys(RegObj).forEach(key => {
      const region: RegionMdl = RegObj[key]
      regiones.push(region)
    })

    return regiones;
  }

  private ListaMunicipio(MunObj: Object) {
    const municipios: MunicipioMdl[] = []
    // console.log('resul: ', MunObj)
    if (MunObj === null) { return [] }

    Object.keys(MunObj).forEach(key => {
      const municipio: MunicipioMdl = MunObj[key]
      municipios.push(municipio)
    })

    return municipios;
  }


  private ListaLocalidad(LocObj: Object) {
    const localidades: LocalidadMdl[] = []
    // console.log('resul: ', LocObj)
    if (LocObj === null) { return [] }

    Object.keys(LocObj).forEach(key => {
      const localidad: LocalidadMdl = LocObj[key]
      localidades.push(localidad)
    })

    return localidades;
  }

  
}

