import { Component, OnInit } from '@angular/core';
import { AuthenticationService, SidebarService } from '../../services/service.index';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: []
})
export class HeaderComponent implements OnInit {
  bandera: boolean = false;
  user:any=[];
  constructor
    (private authenticationService: AuthenticationService,
      private sidebarService: SidebarService,
      ) { }

  ngOnInit() {

    this.user=this.sidebarService.getCurrentUser();
  }
  
  logOut() { //2019/9/9 Enoc: llamma el servicio de cierre de sesion    
    this.authenticationService.logout();
  }
  
  openMenu() {
    if (this.bandera) this.bandera = false
    else this.bandera = true;
  }

  ChangePerfil(perfil :any) {
    sessionStorage.setItem('perfil', perfil)
    
    this.sidebarService.ActualizarMenu();
    this.bandera = false;
  }
}
