import { Component, OnInit, getDebugNode } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { URL_API } from '../../../globals';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ImagenInversionService, SidebarService } from '../../../services/service.index';

import Swal from 'sweetalert2'
import { Observable } from 'rxjs';

import { ImgProyInvMdl } from '../../../models/ImgProy.model';
import { ProyectoInversionMdl } from '../../../models/proyectoinv.model';
import { RegionMdl } from '../../../models/region.model';
import { MunicipioMdl } from '../../../models/municipio.model';
import { LocalidadMdl } from '../../../models/localidad.model';
import { headersToString } from 'selenium-webdriver/http';
import { element } from 'protractor';




@Component({
    selector: 'app-imagen',
    templateUrl: './imgProyInv.component.html',
    styles: ['./imgProyInv.component.ts']
})


export class ImagenInversionComponent implements OnInit {

    urls = [];
    selectedFile: File = null;

    imagen: ImgProyInvMdl = new ImgProyInvMdl();

    formImagen: FormData = new FormData();
    labelImage: string = 'Selecciona una Imagen';

    inv: ProyectoInversionMdl = new ProyectoInversionMdl()
    invs: ProyectoInversionMdl[] = []
    invslctd: number = 0;

    reg: RegionMdl = new RegionMdl()
    regs: RegionMdl[] = []
    regslctd: number = 0;

    mun: MunicipioMdl = new MunicipioMdl()
    muns: MunicipioMdl[] = []
    munslctd: number = 0;

    loc: LocalidadMdl = new LocalidadMdl()
    locs: LocalidadMdl[] = []
    locslctd: number

    get f() { return this.imagenGroupForm.controls; }
    isSave: boolean = true

    //*****************VALIDAR*******************************************
    createFormGroup() {
        return new FormGroup({
            fechaFoto: new FormControl('', Validators.required),
            notaFoto: new FormControl('', Validators.required),

        });
    }
    //*****************VALIDAR*******************************************
    imagenGroupForm: FormGroup;
    imagenGroupForm2: FormGroup;

    constructor(private service: ImagenInversionService,
        private route: ActivatedRoute,
        private _formbuilder: FormBuilder,
        private http: HttpClient,
        private sideBarService: SidebarService) {

        this.imagenGroupForm = _formbuilder.group(this.imagen);
        // this.imagenGroupForm = _formbuilder.group(this.imagen);

        this.imagenGroupForm2 = _formbuilder.group({
            profile: ['']




        });

        //*****************VALIDAR*******************************************
        // this.imagenGroupForm = this.createFormGroup();
        //*****************VALIDAR*******************************************
    }


    ngOnInit() {



        this.service.getRegion()
            .subscribe(res => {
                this.regs = res
            })

        //Obtengo el parametro de la ruta get
        const id = this.route.snapshot.paramMap.get('id')

        this.service.getImagenes(Number(id)).subscribe(resp=>{
             Object.keys(resp).forEach(key => {

    })
        })

        if (id !== 'new') {
            this.isSave = false


            //Editar
            this.service.getProyInversion(Number(id)).subscribe((res: ProyectoInversionMdl) => {
                this.inv = res


                this.regslctd = this.inv.localidadId.municipioId.regionId.regionId;
                this.reg = this.regs.find(r => r.regionId === this.regslctd)
                this.mun = this.muns.find(m => m.municipioId === this.munslctd)
                this.loc = this.locs.find(l => l.localidadId === this.locslctd)

                this.service.getMunicipio(this.regslctd)
                    .subscribe(res => {
                        this.muns = res
                        this.munslctd = this.inv.localidadId.municipioId.municipioId;


                        this.service.getLocalidad(this.munslctd)
                            .subscribe(res => {
                                this.locs = res


                                this.locslctd = this.inv.localidadId.localidadId;
                                this.loc = this.locs.find(l => l.localidadId == this.locslctd)
                                //     this.inv.localidadId = this.inv.localidadId.localidadId;



                                this.service.getInversion(this.locslctd)
                                    .subscribe(res => {

                                        this.invs = res


                                        this.invslctd = this.inv.proyectoinversionId;

                                    })
                            })
                    })

            })
        }

    }




    guardarImagen() {

        const fd = new FormData();
        //var fd2: any = new FormData();




        var fdimage = this.imagenGroupForm2.get('profile').value;
        /*var fdfoto:Date =this.imagenGroupForm.get('fechaFoto').value;
        var fdnota =this.imagenGroupForm.get('notaFoto').value;
        fdfoto.toString();
        var options = { content: fd };*/

        let index = 1;

        this.urls.forEach(element => {

            fd.append('file_' + index, element.archivo);
            index++;

        })
        /* fd.append("fechaFoto",fdfoto.toString());
         fd.append("notaFoto",fdnota);*/



        //  fd2.append('file', this.imagenGroupForm2.get('profile').value);
        /* fd2.append("fechaFoto",this.imagenGroupForm.get("fechaFoto").value);
          fd2.append("notaFoto",this.imagenGroupForm.get("notaFoto").value);*/

        if (this.imagenGroupForm.invalid) {
            console.log('Form no valido')
            return
        }


        let peticion: Observable<any>
        Swal.fire({
            title: 'Espere',
            text: 'Guardando información',
            type: 'info',
            allowOutsideClick: false
        })

        this.imagen = this.imagenGroupForm.value;
        this.imagen.activo = true;
        this.imagen.creadoPor = 1;
        this.imagen.actualizado = new Date();
        this.imagen.actualizadoPor = 48;
        this.imagen.fechaFoto = new Date(this.imagen.fechaFoto);


        Swal.showLoading()

        if (this.imagen.proyectoinversionId) {
            this.imagen.creado = this.imagen.creado
            peticion = this.service.actualizarImagen(this.imagen)
        }

        else {
            this.imagen.creado = new Date()
            peticion = this.service.AgregarImagenProyecto(fd, this.inv.proyectoinversionId);

        }


        peticion.subscribe(resp => {
            console.log(resp)
            Swal.fire({
                title: this.inv.nombre,
                text: 'Realizado correctamente',
                type: 'success',
            })
        },
            error => {


                if (error) {

                }

                Swal.fire({
                    title: this.inv.nombre,
                    text: 'error',
                    type: 'error',
                })
            },


        )
    }

    //*****************VALIDAR*******************************************
    /*  get localidadId() { return this.inversionGroupForm.get('localidadId'); }
      get fondofinanciamientoId() { return this.inversionGroupForm.get('fondofinanciamientoId'); }
      get estatusproyectoId() { return this.inversionGroupForm.get('estatusproyectoId'); }
      get ejecutoraId() { return this.inversionGroupForm.get('ejecutoraId'); }
      get nombre() { return this.inversionGroupForm.get('nombre'); }
      get monto() { return this.inversionGroupForm.get('monto'); }
      get beneficiario() { return this.inversionGroupForm.get('beneficiario'); }
      get numeroProyecto() { return this.inversionGroupForm.get('numeroProyecto'); }*/

    //*****************VALIDAR*******************************************


    onSelectMun(idMun: number) {
        this.service.getMunicipio(idMun)
            .subscribe(res => {
                this.muns = res
            })
    }

    onSelectLoc(idLoc: number) {

        this.service.getLocalidad(idLoc)
            .subscribe(res => {
                this.locs = res
            })
    }

    onSelectInv(idInv: number) {
        this.service.getInversion(idInv)
            .subscribe(res => {
                this.invs = res
            })
    }

    onFileSelected(event) {
        this.selectedFile = <File>event.target.files[0];

        this.imagenGroupForm2.get('profile').setValue(this.selectedFile);
        this.labelImage = this.selectedFile.name;
        // var archivo: any = {};

        // archivo.nombre = this.selectedFile.name;
        // archivo.tipo = this.selectedFile.type;
        // archivo.tamaño = this.selectedFile.size;
        // archivo.file = this.selectedFile;
    }

    onSelectFile(event) {
        if (event.target.files && event.target.files[0]) {
            var filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                var temp: any = [];
                temp.archivo = event.target.files[i];
                this.agregarImagen(temp)
            }
        }
    }


    agregarImagen(imagen) {
        var reader = new FileReader();
        reader.readAsDataURL(imagen.archivo);
        reader.onload = (event: any) => {
            imagen.imagen = event.target.result
            this.urls.push(imagen);
        }
    }

    eliminar(url: number) {
        this.urls.splice(url, 1);
    }


}

