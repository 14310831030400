import { Component, OnInit,OnDestroy } from '@angular/core';
import {  PeriodicidadRMdl } from '../../../models/periodicidadReplica.model';
import { PeriodicidadRService, SidebarService } from '../../../services/service.index';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';
@Component({
  selector: 'app-listado-periodicidad',
  templateUrl: './listado-periodicidad.component.html',
  styles: []
})
export class ListadoPeriodicidadComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

 periodicidades: PeriodicidadRMdl[] = []
 p: number = 1
  constructor(private service: PeriodicidadRService,
    private  sideBarService: SidebarService
    ) { }

  ngOnInit() {
        //----
this.dtOptions = {
  dom: 'Bfrtip',
};
    this.service.getPeriodicidades()
    .subscribe(data => {
      
      this.periodicidades = data
      this.periodicidades = this.periodicidades.filter( b => b.activo == true)
      this.dtTrigger.next();
    })
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  
  borrarPeriodicidad(per: PeriodicidadRMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${per.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.periodicidades = this.periodicidades.filter
        ( e => e.periodicidadId !== per.periodicidadId)
        per.activo = false;
        console.log(per)
        this.service.eliminarPeriodicidad(
          per.periodicidadId,
          per
          ).subscribe()
      }
    })
  }

}
