/*import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { OdsMdl } from '../../models/ods.model';

@Injectable()
export class OdsService {

  constructor(private http: HttpClient) { }

  getOdsS(){
    return this.http.get(`${URL_API}/ods`)
      .pipe(map(this.ListaOds))
  }

  eliminarOds(id:number, ods: OdsMdl){
    return this.http.put(`${URL_API}/ods/${id}`, 
     ods)
  }

  crearOds(ods: OdsMdl){
    return this.http.post(`${URL_API}/ods`, ods)
    .pipe(map((resp:any)=>{
     //ods.odsId = resp.odsId
      console.info('Guardado: ', resp)
      return ods
    }))
  }

  getOds(id:number){
    return this.http.get(`${URL_API}/ods/${id}`)
  }

  actualizarOds(ods: OdsMdl){
    const odsTemp = {
      ...ods
    }
    delete odsTemp.odsId
    return this.http.put(`${URL_API}/ods/${ods.odsId}`, 
      ods)
  }

  private ListaOds(OdsObj: Object){
    const odsS: OdsMdl[] = []
    if(OdsObj === null){ return [] }

    Object.keys(OdsObj).forEach(key => {
      const ods: OdsMdl = OdsObj[key]
      odsS.push(ods)
    })

    return odsS
  }
}*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { OdsMdl } from '../../models/ods.model';
import { URL_API } from '../../globals';

@Injectable()
export class OdsService {

  constructor(private http: HttpClient) { }

  getOdsS(){
    return this.http.get(`${URL_API}/ods`)
      .pipe(map(this.crearLista))
      
  }

  eliminarOds(id:number, ods: OdsMdl){
    return this.http.delete(`${URL_API}/ods/${id}`)
  }

  crearOds(ods: OdsMdl){
    return this.http.post(`${URL_API}/ods`, ods)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return ods
    }))
  }

  getOds(id:number){
    return this.http.get<any>(`${URL_API}/ods/${id}`)
  }

  actualizarOds(ods: OdsMdl){
    const odsTemp = {
      ...ods
    }
    delete odsTemp.odsId
    return this.http.put(`${URL_API}/ods/${ods.odsId}`, 
      ods)
  }

  private crearLista(OdsObj: Object){
    const odsS: OdsMdl[] = []
    if(OdsObj === null){ return [] }

    Object.keys(OdsObj).forEach(key => {
      const ods: OdsMdl = OdsObj[key]
      odsS.push(ods)
    })

    return odsS
  }

}
