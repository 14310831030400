import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { IndicadorA2030RMdl } from '../../models/IndicadorA2030Replica.model';
import { URL_API } from '../../globals';
import { Meta2030RMdl } from '../../models/MetaA2030Replica.model';

@Injectable()
export class IndA2030Service {

  constructor(private http: HttpClient) { }

  getIndicadoresA2030(){
    return this.http.get(`${URL_API}/indicadora2030`)
      .pipe(map(this.ListaIndA2030))
  }

  getMetasA2030(){
    return this.http.get(`${URL_API}/metaa2030`)
      .pipe(map(this.ListaMetA2030))
  }

  eliminarIndicadorA2030(id:number, indicador: IndicadorA2030RMdl){
    return this.http.delete(`${URL_API}/indicadora2030/${id}`)
  }

  crearIndicadorA2030(indicador: IndicadorA2030RMdl){
    return this.http.post(`${URL_API}/indicadora2030`, indicador)
    .pipe(map((resp:any)=>{
     // dependencia.dependenciaId = resp.dependenciaId
      //console.info('Guardado: ', resp)
      return indicador
    }))
  }

  getIndA2030(id:number){
    return this.http.get<any>(`${URL_API}/indicadora2030/${id}`)
  }

  actualizarIndicadorA2030(indicador: IndicadorA2030RMdl){
    const indicadorTemp = {
      ...indicador
    }
    delete indicadorTemp.indicadora2030Id
    return this.http.put(`${URL_API}/indicadora2030/${indicador.indicadora2030Id}`, 
      indicador)
  }

  private ListaIndA2030(indicadorObj: Object){
    const indicadoresA2030: IndicadorA2030RMdl[] = []
    if(indicadorObj === null){ return [] }

    Object.keys(indicadorObj).forEach(key => {
      const indsA2030: IndicadorA2030RMdl = indicadorObj[key]
      indicadoresA2030.push(indsA2030)
    })

    return indicadoresA2030
  }

  private ListaMetA2030(metA2030objs: Object){
    const MetsA2030: Meta2030RMdl[] = []
    if(metA2030objs === null){return []}
   
    Object.keys(metA2030objs).forEach(key => {
      const met: Meta2030RMdl = metA2030objs[key]
      MetsA2030.push(met)
    })

    return MetsA2030
  }
}
