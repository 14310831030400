import { BloqueMdl } from './bloque.model';

export class BloquePorcentajeMdl {

    bloquePorcentajeId: number;
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    porcentaje:number;
    bloqueId: any;

    constructor(){
        this.bloquePorcentajeId = null;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.activo = true;
        this.nombre = null;       
        this.porcentaje = null;
        this.bloqueId = null;
    }
}