import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { IndA2030Service, SidebarService } from '../../../services/service.index';

//modelos
import { IndicadorA2030RMdl } from '../../../models/IndicadorA2030Replica.model';
import { Meta2030RMdl } from '../../../models/MetaA2030Replica.model';

@Component({
  selector: 'app-macro',
  templateUrl: './IndA2030.component.html',
  styleUrls: ['./IndA2030.component.css']
})
export class IndA2030Component implements OnInit {

  indicador: IndicadorA2030RMdl = new IndicadorA2030RMdl()
  
 
  meta: Meta2030RMdl = new Meta2030RMdl()
  metas: Meta2030RMdl[] = []
  metaslctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private indicadorService: IndA2030Service, 
    private  sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.indicador);
    }

  ngOnInit() {

    this.indicadorService.getMetasA2030()
      .subscribe(res => {
        
        this.metas = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.indicadorService.getIndA2030(Number(id))
        .subscribe((resp:IndicadorA2030RMdl) => {
        
          this.indicador = resp

          this.metaslctd = resp.metaa2030Id.metaa2030Id;
          this.meta = this.metas.find(m => m.metaa2030Id == this.metaslctd)
          this.indicador.metaa2030Id= this.indicador.metaa2030Id.metaa2030Id;
          this.formGroup = this._formbuilder.group(this.indicador);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarIndA2030(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.indicador = this.formGroup.value;
    this.indicador.activo = true;
    this.indicador.creadoPor =1;
    this.indicador.actualizado = new Date();
    this.indicador.actualizadoPor = 1;
    this.indicador.metaa2030Id = Number(this.indicador.metaa2030Id);

    
  
    Swal.showLoading()

    if(this.indicador.indicadora2030Id){
      this.indicador.creado = this.indicador.creado
      peticion = this.indicadorService.actualizarIndicadorA2030(this.indicador)

      console.log(this.indicador)
    }

    else {
      this.indicador.creado = new Date()
      console.log(this.indicador)
      peticion = this.indicadorService.crearIndicadorA2030(this.indicador)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.indicador.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.indicador.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  onMetaSlctd(event:any) {
    this.meta = this.metas.find( me =>  me.metaa2030Id === event  )
  }
}

