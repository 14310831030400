import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { PerioricidadMdl } from '../../models/perioricidad.model';

@Injectable()
export class PerioricidadService {

  constructor(private http: HttpClient) { }

  getPerioricidades(){
      return this.http.get(`${URL_API}/periodicidad`)
      .pipe(map(this.crearLista))
  }
 
  eliminarPerioricidad(id:number, perioricidad: PerioricidadMdl){
    return this.http.put(`${URL_API}/periodicidad/${id}`, 
    perioricidad)
  }

  crearPerioricidad(perioricidad: PerioricidadMdl){
    
    return this.http.post(`${URL_API}/periodicidad`, perioricidad)
    .pipe(map((resp:any)=>{
      perioricidad.perioricidad_id = resp.perioricidad_id
      return perioricidad
    }))
  }

  getPerioricidad(id:number){
    return this.http.get(`${URL_API}/periodicidad/${id}`)
  }

  actualizarPerioricidad(perioricidad: PerioricidadMdl){
    const perioricidadTemp = {
      perioricidad
    }
    delete perioricidadTemp.perioricidad.perioricidad_id
    return this.http.put(`${URL_API}/periodicidad/${perioricidad.perioricidad_id}`, 
    perioricidad)
  }

  private crearLista(perioricidadObj: Object){
    const perioricidades: PerioricidadMdl[] = []
    if(perioricidadObj === null){ return [] }

    Object.keys(perioricidadObj).forEach(key => {
      const perioricidad: PerioricidadMdl = perioricidadObj[key]
      perioricidades.push(perioricidad)
    })

    return perioricidades
  }

}