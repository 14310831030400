import { Component, OnInit,OnDestroy } from '@angular/core';
import {  BloquesPorcentajeService, SidebarService } from '../../../services/service.index';
import {BloquePorcentajeMdl  } from '../../../models/bloqueporcentaje.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

BloquesPorcentajeService

@Component({
  selector: 'app-bloquePorcentajes',
  templateUrl: './listado-bloquePorcentaje.component.html',
  styleUrls: ['./listado-bloquePorcentaje.component.css']
})
export class ListadoBloquePorcentajeComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  bloquesPorcentaje: BloquePorcentajeMdl[] = []
  p: number = 1
  constructor(private service: BloquesPorcentajeService,
    private sideBarService: SidebarService) { }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
    },
      dom: 'Bfrtip',
    };
    this.service.getBloques()
      .subscribe(resp => {
        this.bloquesPorcentaje = resp
        this.bloquesPorcentaje = this.bloquesPorcentaje.filter( b => b.activo == true)
        this.dtTrigger.next();
      })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  borrarObjetivo(objetivo: BloquePorcentajeMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${objetivo.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.bloquesPorcentaje = this.bloquesPorcentaje.filter( o => o.bloquePorcentajeId !== objetivo.bloquePorcentajeId)
        objetivo.activo = false;
        this.service.eliminarBloque(
          objetivo.bloquePorcentajeId,
          objetivo
          ).subscribe()
      }
    })
  }
  
}
