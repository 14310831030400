import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../../models/MenuItem';
import { MenuService } from '../../../services/shared/menu.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-menu-item-listado',
  templateUrl: './menu-item-listado.component.html',
  styleUrls: ['./menu-item-listado.component.css']
})
export class MenuItemListadoComponent implements OnInit {
  menuitems: MenuItem[] = []
  Save: boolean = true;
  p: number = 1
  constructor(private service: MenuService) { }

  ngOnInit() {
    this.service.getMenuItem()
      .subscribe(resp => {
        this.menuitems = resp;
      })
  }

  borrarMenuItem(menuitem: MenuItem, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${menuitem.nombre}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {
        // this.dependencias.splice(i, 1)
        this.menuitems = this.menuitems.filter(mi => mi.menuitemId !== menuitem.menuitemId)
        menuitem.activo = false;
        console.log(menuitem)
        this.service.updateMenuItem(
          menuitem.menuitemId,
          menuitem
        ).subscribe()
      }
    })
  }

  validaNombrePadre(menuitem: MenuItem) {
    if (menuitem.menuPadreId) return menuitem.menuPadreId.nombre
    else return ''
  }

}
