import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { BloquesPorcentajeService, BloquesService, SidebarService } from '../../../services/service.index';

//modelos
import {  BloquePorcentajeMdl} from '../../../models/bloqueporcentaje.model';
import {  BloqueMdl} from '../../../models/bloque.model';


@Component({
  selector: 'app-bloquePorcentaje',
  templateUrl: './bloquePorcentaje.component.html',
  styleUrls: ['./bloquePorcentaje.component.css']
})
export class BloquePorcentajeComponent implements OnInit {

  bloquePorcentaje: BloquePorcentajeMdl = new BloquePorcentajeMdl();

  bloque: BloqueMdl = new BloqueMdl();
  bloques: BloqueMdl[] = [];

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private bloqueService: BloquesService, 
    private bloquePorcentajeService: BloquesPorcentajeService,
    private sideBarService: SidebarService

    ) { 

      this.formGroup = this._formbuilder.group(this.bloquePorcentaje);
    }

  ngOnInit() {

    this.bloqueService.getBloques()
      .subscribe(res => {
        
        this.bloques = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.bloquePorcentajeService.getBloque(Number(id))
        .subscribe((resp:BloquePorcentajeMdl) => {
        
          this.bloquePorcentaje = resp;
          this.bloquePorcentaje.bloqueId=resp.bloqueId.bloqueId;
          this.formGroup = this._formbuilder.group(this.bloquePorcentaje);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarObjetivo(){
    

      if(this.formGroup.invalid){
      //Aquí va la validación del form
      
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    
    this.bloquePorcentaje = this.formGroup.value;
    this.bloquePorcentaje.activo = true;
    this.bloquePorcentaje.creadoPor =1;
    this.bloquePorcentaje.actualizado = new Date();
    this.bloquePorcentaje.actualizadoPor = 1;  
  
    Swal.showLoading()

    if(this.bloquePorcentaje.bloquePorcentajeId){
      this.bloquePorcentaje.creado = this.bloquePorcentaje.creado
      peticion = this.bloquePorcentajeService.actualizarBloque(this.bloquePorcentaje)

      console.log(this.bloquePorcentaje)
    }

    else {
      this.bloquePorcentaje.creado = new Date()
      console.log(this.bloquePorcentaje)
      peticion = this.bloquePorcentajeService.crearBloque(this.bloquePorcentaje)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.bloquePorcentaje.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.bloquePorcentaje.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  onComiteSlctd(event:any) {
    this.bloque = this.bloques.find( b =>  b.bloqueId === event  )
  }
}

