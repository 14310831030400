import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MenuSideNav } from '../../../app/models/menuSideNav.model';
import { element } from 'protractor';
import { MenuPerfil } from '../../../app/models/Perfil';
import * as jwt_decode from "jwt-decode";

@Injectable()
export class SidebarService {

  menu: any[] = [];
  menuPermisos: any = [];
  menuPerfiles: any = [];
  private permisos = [];

  constructor(private http: HttpClient) {
    this.menuPermisos.Administrador = [];
    this.menuPermisos.usuario = []
  }

  ActualizarMenu() {

    var user: any = JSON.parse(sessionStorage.getItem('currentUser'));
    const tokk = jwt_decode(user.token)
    let temp = JSON.parse(tokk.privilege_model.privileges);

    try {
      this.permisos = temp[0].perfiles;

    } catch{
      console.log("Error al leer los permisos del token")
    }


    let perfil = sessionStorage.getItem('perfil')

    this.menu = [];

    let menuPrincipal: MenuSideNav = { activo: true, titulo: 'Principal', icono: 'fa fa-edit', url: '/dashboard', submenu: [] };
    this.menu.push(menuPrincipal);

    // console.log(this.menu)

    // Menu catalogos  //////////
    let menuCatalogos: MenuSideNav = { activo: true, titulo: 'Catálogos', icono: 'fa fa-edit', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/dependencia", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Dependencias', url: '/dependencias', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/fuente", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Fuentes', url: '/fuentes', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/desglosados", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Desglosados', url: '/desglosados', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/video", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Videos', url: '/videos', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/fondofinanciamiento", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Fondos', url: '/fondos', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/periodicidad", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Periodicidad', url: '/periodicidades', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/unidadmedida", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Unidad de Medida', url: '/UniMed', icono: 'fa fa-edit' })

      if (this.BuscarPermiso("/webresources/region", perfil, "get"))
      menuCatalogos.submenu.push({ titulo: 'Listado Región', url: '/region', icono: 'fa fa-edit' })  
    if (menuCatalogos.submenu.length > 0)
      this.menu.push(menuCatalogos);


  
    // Menu catalogos //////////


    // Menu ods //////////
    let menuODS: MenuSideNav = { activo: true, titulo: 'ODS', icono: 'fa fa-edit', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/ods", perfil, "get"))
      menuODS.submenu.push({ titulo: 'Listado', url: '/odsS', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/ods", perfil, "post"))
      menuODS.submenu.push({ titulo: 'Agregar', url: '/ods/new', icono: 'fa fa-plus-square' });
    if (menuODS.submenu.length > 0)
      this.menu.push(menuODS);
    // Menu ods //////////

    // Menu Meta //////////
    let menuMeta: MenuSideNav = { activo: true, titulo: 'Metas', icono: 'fa fa-edit', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/metaa2030", perfil, "get"))
      menuMeta.submenu.push({ titulo: 'Listado', url: '/metas', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/metaa2030", perfil, "post"))
      menuMeta.submenu.push({ titulo: 'Agregar', url: '/meta/new', icono: 'fa fa-plus-square' });
    if (menuMeta.submenu.length > 0)
      this.menu.push(menuMeta);
    // Menu Meta //////////

    // Menu Macro //////////
    let menuMacro: MenuSideNav = { activo: true, titulo: 'Macro', icono: 'fa fa-edit', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/macroobjetivo", perfil, "get"))
      menuMacro.submenu.push({ titulo: 'Listado', url: '/macros', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/macroobjetivo", perfil, "post"))
      menuMacro.submenu.push({ titulo: 'Agregar', url: '/macro/new', icono: 'fa fa-plus-square' });
    if (menuMacro.submenu.length > 0)
      this.menu.push(menuMacro);
    // Menu Macro //////////

    // Menu Indicador A2030 //////////
    let menuIndicadorA2030: MenuSideNav = { activo: true, titulo: 'Indicador A2030', icono: 'fa fa-edit', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/indicadora2030", perfil, "get"))
      menuIndicadorA2030.submenu.push({ titulo: 'Listado', url: '/IndA2030', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/indicadora2030", perfil, "post"))
      menuIndicadorA2030.submenu.push({ titulo: 'Agregar', url: '/IndA2030/new', icono: 'fa fa-plus-square' });
    if (menuIndicadorA2030.submenu.length > 0)
      this.menu.push(menuIndicadorA2030);
    // Menu Indicador A2030 //////////

    // Menu Eje PND //////////
    let menuEjePND: MenuSideNav = { activo: true, titulo: 'Eje PND', icono: 'fa fa-edit', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/ejepnd", perfil, "get"))
      menuEjePND.submenu.push({ titulo: 'Listado', url: '/EjePnd', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/ejepnd", perfil, "post"))
      menuEjePND.submenu.push({ titulo: 'Agregar', url: '/EjePnd/new', icono: 'fa fa-plus-square' });
    if (menuEjePND.submenu.length > 0)
      this.menu.push(menuEjePND);
    // Menu Eje PND //////////

    // Menu Bloques //////////
    let menuBloques: MenuSideNav = { activo: true, titulo: 'Bloques', icono: 'fa fa-share', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/bloque", perfil, "get"))
      menuBloques.submenu.push({ titulo: 'Listado', url: '/bloques', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/bloque", perfil, "post"))
      menuBloques.submenu.push({ titulo: 'Agregar', url: '/bloque/new', icono: 'fa fa-plus-square' });
    if (this.BuscarPermiso("/webresources/bloqueporcentaje", perfil, "get"))
      menuBloques.submenu.push({ titulo: 'Listado porcentaje', url: '/bloquesporcentaje', icono: 'fas fa-list-ul' });
    if (this.BuscarPermiso("/webresources/bloqueporcentaje", perfil, "post"))
      menuBloques.submenu.push({ titulo: 'Agregar porcentaje', url: '/bloqueporcentaje/new', icono: 'fas fa-plus-square' });

    if (this.BuscarPermiso("/webresources/ibloquedependencia", perfil, "post"))
    menuBloques.submenu.push({ titulo: 'Agregar Bloque-Dependencia', url: '/IbloDep/new', icono: 'fa fa-plus-square' });
    if (this.BuscarPermiso("/webresources/ibloquedependencia", perfil, "get"))
    menuBloques.submenu.push({ titulo: 'Listado Bloque-Dependencia', url: '/IbloDep', icono: 'fa fa-list-ul' });


    if (menuBloques.submenu.length > 0)
      this.menu.push(menuBloques);
    // Menu Bloques //////////

    // Menu Objetivo //////////
    let menuObjetivo: MenuSideNav = { activo: true, titulo: 'Objetivo', icono: 'fa fa-table', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/objetivobloque", perfil, "get"))
      menuObjetivo.submenu.push({ titulo: 'Listado', url: '/objetivos', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/objetivobloque", perfil, "post"))
      menuObjetivo.submenu.push({ titulo: 'Agregar', url: '/objetivo/new', icono: 'fa fa-plus-square' });
    if (menuObjetivo.submenu.length > 0)
      this.menu.push(menuObjetivo);
    // Menu Objetivo //////////

    // Menu Estrategia Pvd //////////
    let menuEstrategiaPvd: MenuSideNav = { activo: true, titulo: 'Estrategia Pvd', icono: 'fa fa-envelope', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/estrategiapvd", perfil, "get"))
      menuEstrategiaPvd.submenu.push({ titulo: 'Listado', url: '/estrategias', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/estrategiapvd", perfil, "post"))
      menuEstrategiaPvd.submenu.push({ titulo: 'Agregar', url: '/estrategia/new', icono: 'fa fa-plus-square' });
    if (menuEstrategiaPvd.submenu.length > 0)
      this.menu.push(menuEstrategiaPvd);
    // Menu Estrategia Pvd //////////

    // Menu Línea Acción //////////
    let menuLineaAccion: MenuSideNav = { activo: true, titulo: 'Línea de Acción', icono: 'fa fa-edit', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/lineaaccionpvd", perfil, "get"))
      menuLineaAccion.submenu.push({ titulo: 'Listado', url: '/lineas', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/lineaaccionpvd", perfil, "post"))
      menuLineaAccion.submenu.push({ titulo: 'Agregar', url: '/linea/new', icono: 'fa fa-plus-square' });
    if (menuLineaAccion.submenu.length > 0)
      this.menu.push(menuLineaAccion);
    // Menu Línea Acción //////////

    // Menu IndicadoresPvd //////////
    let menuIndicadoresPvd: MenuSideNav = { activo: true, titulo: 'Indicadores PVD', icono: 'fa fa-book', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/indicadorpvd", perfil, "get"))
      menuIndicadoresPvd.submenu.push({ titulo: 'Listado', url: '/indicadores', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/indicadorpvd", perfil, "post"))
      menuIndicadoresPvd.submenu.push({ titulo: 'Agregar', url: '/indicador/new', icono: 'fa fa-plus-square' });
    if (menuIndicadoresPvd.submenu.length > 0)
      this.menu.push(menuIndicadoresPvd);
    // Menu IndicadoresPvd //////////

    // Menu Desagregados //////////
    let menuDesagregados: MenuSideNav = { activo: true, titulo: 'Desagregados', icono: 'fa fa-check', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/desagregado", perfil, "get"))
      menuDesagregados.submenu.push({ titulo: 'Listado', url: '/desagregados', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/desagregado", perfil, "post"))
      menuDesagregados.submenu.push({ titulo: 'Agregar', url: '/desagregado/new', icono: 'fa fa-plus-square' });
    if (menuDesagregados.submenu.length > 0)
      this.menu.push(menuDesagregados);
    // Menu Desagregados //////////

    // Menu Fichas //////////
    let menuFichas: MenuSideNav = { activo: true, titulo: 'Fichas', icono: 'fa fa-check', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/ficha", perfil, "get"))
      menuFichas.submenu.push({ titulo: 'Listado', url: '/fichas', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/ficha/persistencia", perfil, "post"))
      menuFichas.submenu.push({ titulo: 'Agregar', url: '/fichatecnica', icono: 'fa fa-plus-square' });
    if (menuFichas.submenu.length > 0)
      this.menu.push(menuFichas);
    // Menu Fichas //////////

    // Menu Proyecto Inversión //////////
    let menuProyectoInversion: MenuSideNav = { activo: true, titulo: 'Proyecto Inversión', icono: 'fa fa-calendar', url: '#', submenu: [] };
    if (this.BuscarPermiso("/webresources/proyectoinversion", perfil, "get"))
      menuProyectoInversion.submenu.push({ titulo: 'Listado', url: '/inversions', icono: 'fa fa-list-ul' });
    if (this.BuscarPermiso("/webresources/proyectoinversion", perfil, "post"))
      menuProyectoInversion.submenu.push({ titulo: 'Agregar', url: '/inversion/new', icono: 'fa fa-plus-square' });
    if (this.BuscarPermiso("/webresources/imagenesproyectoinversion", perfil, "get"))
      menuProyectoInversion.submenu.push({ titulo: 'Listado Imagenes', url: '/imgInv', icono: 'fa fa-list-ul' });
    if (menuProyectoInversion.submenu.length > 0)
      this.menu.push(menuProyectoInversion);
    // Menu Proyecto Inversión //////////

    // Menu Administración //////////
    let menuAdministracion: MenuSideNav = { activo: true, titulo: 'Administración', icono: 'fa fa-th', url: '#', submenu: [] };

    if (this.BuscarPermiso("/webresources/usuario", perfil, "get"))
      menuAdministracion.submenu.push({ titulo: 'Listado de usuarios', url: '/usuarioListado', icono: 'fas fa-list-ul' });
      if (this.BuscarPermiso("/webresources/usuario", perfil, "post"))
        menuAdministracion.submenu.push({ titulo: 'Agregar usuario ', url: '/usuario/new', icono: 'fas fa-plus-square' });
   
        if (this.BuscarPermiso("/webresources/usuario", perfil, "post"))
      menuAdministracion.submenu.push({ titulo: 'Agregar Usuario-Perfil ', url: '/intermedia/new', icono: 'fas fa-plus-square' });
    
      if (this.BuscarPermiso("/webresources/usuario", perfil, "get"))
      menuAdministracion.submenu.push({ titulo: 'Listado Usuario-Perfil ', url: '/intermedia', icono: 'fas fa-list-ul' });
   
    //menuAdministracion.submenu.push({ titulo: 'Agregar usuario ', url: '/usuario/new', icono: 'fas fa-plus-square' });


   /*  if (this.BuscarPermiso("/webresources/menuitem", perfil, "post"))
      //    menuAdministracion.submenu.push({ titulo: 'Agregar Menú ítem', url: '/menuItem/new', icono: 'fas fa-plus-square' });
      if (this.BuscarPermiso("/webresources/menuitem", perfil, "get"))
        //    menuAdministracion.submenu.push({ titulo: 'Listado menú ítem', url: '/menuItemListado', icono: 'fas fa-list-ul' });
        if (this.BuscarPermiso("/webresources/menu", perfil, "post"))
          //     menuAdministracion.submenu.push({ titulo: 'Menú', url: '/menu/new', icono: 'fas fa-plus-square' });
          if (this.BuscarPermiso("/webresources/menu", perfil, "get"))
            //     menuAdministracion.submenu.push({ titulo: 'Listado menú', url: '/menuListado', icono: 'fas fa-list-ul' });
            if (this.BuscarPermiso("/webresources/derechoitem", perfil, "post"))
              //     menuAdministracion.submenu.push({ titulo: 'Derecho ítem', url: '/derechoItem/new', icono: 'fas fa-plus-square' });
              if (this.BuscarPermiso("/webresources/derechoitem", perfil, "get"))
                //      menuAdministracion.submenu.push({ titulo: 'Listado derecho ítem', url: '/derechoItemListado', icono: 'fas fa-list-ul' });
                if (this.BuscarPermiso("/webresources/derecho", perfil, "post"))
                  //      menuAdministracion.submenu.push({ titulo: 'Derecho', url: '/derecho/new', icono: 'fas fa-plus-square' });
                  if (this.BuscarPermiso("/webresources/derecho", perfil, "get"))
                    //      menuAdministracion.submenu.push({ titulo: 'Listado derecho', url: '/derechoListado', icono: 'fas fa-list-ul' });
                    if (this.BuscarPermiso("/webresources/perfil", perfil, "post"))
                      menuAdministracion.submenu.push({ titulo: 'Perfil', url: '/perfil/new', icono: 'fas fa-plus-square' });
 */    if (this.BuscarPermiso("/webresources/perfil", perfil, "get"))
      menuAdministracion.submenu.push({ titulo: 'Listado perfil', url: '/perfilListado', icono: 'fas fa-list-ul' });
    //    menuAdministracion.submenu.push({ titulo: 'derechoAsignacion', url: '/derechoAsignacion', icono: 'fas fa-list-ul' });

    if (menuAdministracion.submenu.length > 0)
      this.menu.push(menuAdministracion);
    // Menu Administración //////////

    // No Visibles //////////
    let menuNovisibles: MenuSideNav = { activo: false, titulo: 'No visibles', icono: 'fa fa-fa-th', url: '#', submenu: [] };
    menuNovisibles.submenu.push({ titulo: 'Perfil', url: '/perfil/new', icono: 'fas fa-plus-square' });

    if (this.BuscarPermiso("/webresources/dependencia/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Dependencias', url: '/dependencia/:id', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/dependencia", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nuevo Dependencias', url: '/dependencia/new', icono: 'fa fa-edit' });

      


    if (this.BuscarPermiso("/webresources/fuente/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Fuentes', url: '/fuente/:id', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/fuente", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nuevo Fuentes', url: '/fuente/new', icono: 'fa fa-edit' });

    if (this.BuscarPermiso("/webresources/desglosados/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Desglosados', url: '/desglosado/:id', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/desglosados", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nuevo Desglosados', url: '/desglosado/new', icono: 'fa fa-edit' });

    if (this.BuscarPermiso("/webresources/video/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Videos', url: '/video/:id', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/video", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nuevo Videos', url: '/video/new', icono: 'fa fa-edit' });

    if (this.BuscarPermiso("/webresources/fondofinanciamiento/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Fondos', url: '/fondo/:id', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/fondofinanciamiento", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nuevo Fondos', url: '/fondo/new', icono: 'fa fa-edit' });

    if (this.BuscarPermiso("/webresources/periodicidad/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Periodicidad', url: '/periodicidad/:id', icono: 'fa fa-edit' });
    if (this.BuscarPermiso("/webresources/periodicidad", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nuevo Periodicidad', url: '/periodicidad/new', icono: 'fa fa-edit' });

    if (this.BuscarPermiso("/webresources/unidadmedida/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Unidad de Medida', url: '/UniMed/:id', icono: 'fa fa-edit' })
    if (this.BuscarPermiso("/webresources/unidadmedida", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nuevo Unidad de Medida', url: '/UniMed/new', icono: 'fa fa-edit' })

      //--------region
      if (this.BuscarPermiso("/webresources/region/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Región', url: '/region/:id', icono: 'fa fa-edit' })
    if (this.BuscarPermiso("/webresources/region", perfil, "post"))
      menuNovisibles.submenu.push({ titulo: 'nueva región', url: '/region/new', icono: 'fa fa-edit' })


    //----

    if (this.BuscarPermiso("/webresources/ods/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar ods', url: '/ods/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/ejepnd/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar eje pnd', url: '/EjePnd/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/metaa2030/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar meta', url: '/meta/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/macroobjetivo/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar macroobjetivo', url: '/macro/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/indicadora2030/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar indicadora2030', url: '/IndA2030/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/ejepnd/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar ejepnd', url: '/EjePnd/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/bloque/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar bloque', url: '/bloque/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/bloqueporcentaje/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar porcentaje', url: '/bloqueporcentaje/:id', icono: 'fas fa-plus-square' });

    //if (this.BuscarPermiso("/webresources/ibloquedependencia/{id}", perfil, "put"))
    menuNovisibles.submenu.push({ titulo: 'editar bloque-dependencia', url: '/IbloDep/:id', icono: 'fa fa-plus-square' });


    if (this.BuscarPermiso("/webresources/objetivobloque/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar objetivobloque', url: '/objetivo/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/estrategiapvd/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar estrategiapvd', url: '/estrategia/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/lineaaccionpvd/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar lineaaccionpvd', url: '/linea/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/indicadorpvd/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar indicadorpvd', url: '/indicador/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/desagregado/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar desagregado', url: '/desagregado/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/ficha/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar ficha', url: '/fichatecnica/:id', icono: 'fa fa-plus-square' });

    if (this.BuscarPermiso("/webresources/proyectoinversion/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar proyectoinversion', url: '/inversion/:id', icono: 'fa fa-plus-square' });

    //   if (this.BuscarPermiso("/webresources/imagenesproyectoinversion/{id}", perfil, "put"))
    menuNovisibles.submenu.push({ titulo: 'editar Imagenes', url: '/imgInv/:id', icono: 'fa fa-list-ul' });

    // if (this.BuscarPermiso("/webresources/imagenesproyectoinversion", perfil, "post"))
    menuNovisibles.submenu.push({ titulo: 'agregar Imagenes', url: '/imgInv/new', icono: 'fa fa-list-ul' });

    if (this.BuscarPermiso("/webresources/imagenesproyectoinversion/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar complemento Imagenes', url: '/ComImg/:id', icono: 'fa fa-list-ul' });

    if (this.BuscarPermiso("/webresources/usuario/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar usuario ', url: '/usuario/:id', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/menuitem/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Menú ítem', url: '/menuItem/:id', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/menu/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Menú', url: '/menu/:id', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/derechoitem/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Derecho ítem', url: '/derechoItem/:id', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/derecho/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Derecho', url: '/derecho/:id', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/perfil/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar Perfil', url: '/perfil/:id', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/iusuarioperfil/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar usuario asignacion', url: '/usuarioAsignacion/:id/:nombre', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/imenumenuitem/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar menu asignacion', url: '/menuAsigacion/:id/:nombre', icono: 'fas fa-plus-square' });
    if (this.BuscarPermiso("/webresources/iderechoderechoitem/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'editar derecho asignacion', url: '/derechoAsignacion/:id/:nombre', icono: 'fas fa-plus-square' });

    if (this.BuscarPermiso("/webresources/usuario/{id}", perfil, "put"))
      menuNovisibles.submenu.push({ titulo: 'Editar Usuario-Perfil ', url: '/intermedia/:id', icono: 'fas fa-plus-square' });

    this.menu.push(menuNovisibles);
    // No Visibles //////////
  }

  getPerfil() {
    let perfil22 = sessionStorage.getItem('perfil')
    return Number.parseInt(perfil22);    
  }


  BuscarPermiso(url: string, perfil2: string, metodo: string) {

    let perfil22 = sessionStorage.getItem('perfil')

    try {

      var lista: any[] = this.permisos;

      this.menuPerfiles = []

      var permisos: any[];

      let perfilPermisos;

      try {
        perfilPermisos = lista[Number.parseInt(perfil22)];
      } catch{

      }

      lista.forEach(element => {
        this.menuPerfiles.push(element.perfil_nombre);
        // console.log(element)
      })

      if (perfilPermisos) {
        permisos = perfilPermisos.derechos_perfil;
        var permiso = permisos.find(x => x.metodo == metodo && x.ruta == url)
      }
      else {
        perfilPermisos = lista[0];
        permisos = perfilPermisos.derechos_perfil;
        var permiso = permisos.find(x => x.metodo == metodo && x.ruta == url)
      }

      if (permiso) {
        return true;
      }
      else {
        return false;
      }

    }
    catch{
      return false;

    }
  }


  BuscarPermisos(url) {
    
    let urlBool = false;
    if (url == "/") {
      urlBool = true;

    }
    this.menu.forEach(element => {
      element.submenu.forEach(subMenus => {
        if (subMenus.url == url) {

          urlBool = true;
        }
      });
    });
    return urlBool;
  }

  getCurrentUser() {
    let Usuario: any = [];

    try {
      var user: any = JSON.parse(sessionStorage.getItem('currentUser'));
      const tokk = jwt_decode(user.token)
      let temp = JSON.parse(tokk.privilege_model.privileges);
      console.log(temp);

      Usuario.usuario_id = temp[0].usuario_id;
      Usuario.usuario = temp[0].usuario;
      Usuario.nombre_usuario = temp[0].nombre;
      Usuario.apellido_paterno = temp[0].apellido_paterno;
      Usuario.apellido_materno = temp[0].apellido_materno;
      Usuario.dependencia_id = temp[0].dependencia_id;
      return Usuario;
    } catch (error) {
      console.log("Error al leer el usuario actual");

      return Usuario;

    }

  }

  isAdmin() {
    let Usuario: any = [];

    try {
      var user: any = JSON.parse(sessionStorage.getItem('currentUser'));
      const tokk = jwt_decode(user.token)
      let temp = JSON.parse(tokk.privilege_model.privileges);
      console.log(temp);
      let userTemp= temp[0]
        //debugger
      let admin=false;
      userTemp.perfiles.forEach(element => {
        if(element.perfil_nombre=="Administrador"){
          admin= true;
        }

      });
          return admin;

    } catch (error) {

      return false;

    }

  }


}
