import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { PeriodicidadRService, SidebarService } from '../../../services/service.index';

//modelos
import { PeriodicidadRMdl } from '../../../models/periodicidadReplica.model';


@Component({
  selector: 'app-periodicidad',
  templateUrl: './periodicidad.component.html',
  styles: []
})
export class PeriodicidadComponent implements OnInit {
  periodicidad: PeriodicidadRMdl = new PeriodicidadRMdl();
  periodicidades: PeriodicidadRMdl[] = [];

  formGroup: FormGroup;

  get f() { return this.formGroup.controls; }
  isSave: boolean = true


  constructor(private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private periodicidadService: PeriodicidadRService,
    private  sideBarService: SidebarService

    ) {
    this.formGroup = this._formbuilder.group(this.periodicidad);
    
   }

  ngOnInit() {
    this.isSave = true
    
        //obtengo el parametro en la ruta GET
        const id = this.route.snapshot.paramMap.get('id')
        if (id !== 'new') {
          this.isSave = false
          //Editar
          this.periodicidadService.getUPeriodicidad(Number(id))
          .subscribe(resp => {
            
            console.log(resp);
            this.periodicidad = resp;
            console.log(this.periodicidad);
            this.formGroup = this._formbuilder.group(this.periodicidad);
          })
        }
  }


  guardarPeriodicidad() {
    
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    this.periodicidad = this.formGroup.value;
   
    Swal.showLoading()

    if (this.periodicidad.periodicidadId ){
      this.periodicidad.creado = new Date();
      this.periodicidad.actualizado = new Date();
      this.periodicidad.actualizadoPor = 1;

      peticion = this.periodicidadService.actualizarPeriodicidad(this.periodicidad)
      console.log(this.periodicidad)
    }
    else {
      this.periodicidad.activo = true;
      this.periodicidad.creadoPor = 1;
      this.periodicidad.creado =new Date();
      this.periodicidad.actualizado =new Date();
      this.periodicidad.actualizadoPor =1;
      peticion = this.periodicidadService.crearPeriodicidad(this.periodicidad)
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.periodicidad.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.periodicidad.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }
    
    )
  }

}
