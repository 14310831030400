import { Component, OnInit,OnDestroy } from '@angular/core';
import { BloquesService, SidebarService } from '../../../services/service.index';
import { BloqueMdl } from '../../../models/bloque.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bloques',
  templateUrl: './listado-bloque.component.html',
  styleUrls: ['./listado-bloque.component.css']
})
export class ListadoBloqueComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  bloques: BloqueMdl[] = [];
  p: number = 1;
  constructor(private service: BloquesService,
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
    },
    dom: 'Blfrtip',
    };
    this.service.getBloques()
      .subscribe(resp => {
        
        this.bloques = resp
        this.bloques = this.bloques.filter( b => b.activo == true)
        this.dtTrigger.next();
      })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  
  borrarBloque(bloque: BloqueMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${bloque.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.bloques = this.bloques.filter( b => b.bloqueId !== bloque.bloqueId)
        bloque.activo = false;
        console.log(bloque)
        this.service.eliminarBloque( bloque.bloqueId ).subscribe()
      }
    })
  }
  
}
