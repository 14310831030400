import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { DependenciasService, SidebarService } from '../../../services/service.index';

//modelos
import { DependenciaMdl } from '../../../models/dependencia.model';
import { ComiteMdl } from '../../../models/comite.model';

@Component({
  selector: 'app-dependencia',
  templateUrl: './dependencia.component.html',
  styleUrls: ['./dependencia.component.css']
})
export class DependenciaComponent implements OnInit {

  dependencia: DependenciaMdl = new DependenciaMdl()


  comite: ComiteMdl = new ComiteMdl()
  comites: ComiteMdl[] = []
  comiteslctd: number = 0;

  formGroup: FormGroup;

  get f() { return this.formGroup.controls; }
  isSave: boolean = true

  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private dependenciaService: DependenciasService,
    private sideBarService: SidebarService
  ) {

    this.formGroup = this._formbuilder.group(this.dependencia);
  }

  ngOnInit() {

    this.dependenciaService.getComites()
      .subscribe(res => {
        this.comites = res
      })

    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.dependenciaService.getDependencia(Number(id))
        .subscribe((resp: DependenciaMdl) => {

          this.dependencia = resp

          this.comiteslctd = resp.comiteId.comiteId;
          this.comite = this.comites.find(c => c.comiteId == this.comiteslctd)
          this.dependencia.comiteId = this.dependencia.comiteId.comiteId;
          this.formGroup = this._formbuilder.group(this.dependencia);

        })
    }
  }

  //guardarDependencia(form: NgForm){
  guardarDependencia() {

    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    this.dependencia = this.formGroup.value;
    this.dependencia.activo = true;
    this.dependencia.creadoPor = 1;
    this.dependencia.actualizado = new Date();
    this.dependencia.actualizadoPor = 1;
    this.dependencia.comiteId = Number(this.dependencia.comiteId);



    Swal.showLoading()

    if (this.dependencia.dependenciaId) {
      this.dependencia.creado = this.dependencia.creado
      peticion = this.dependenciaService.actualizarDependencia(this.dependencia)

      console.log(this.dependencia)
    }

    else {
      this.dependencia.creado = new Date()
      console.log(this.dependencia)
      peticion = this.dependenciaService.crearDependencia(this.dependencia)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.dependencia.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    },

      error => {
        if (error) {

        }

        Swal.fire({
          title: this.dependencia.nombre,
          text: 'error',
          type: 'error',
        })
      },

    )


  }

  onComiteSlctd(event: any) {
    this.comite = this.comites.find(c => c.comiteId === event)
  }
}
