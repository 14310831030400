import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { EjePndRService, SidebarService } from '../../../services/service.index';

//modelos
import { EjepndMdl } from '../../../models/ejePndReplica.model';

@Component({
  selector: 'app-eje',
  templateUrl: './ejepnd.component.html',
  styleUrls: ['./ejepnd.component.css']
})
export class EjePndRComponent implements OnInit {

  eje: EjepndMdl = new EjepndMdl();
  ejes: EjepndMdl[] = [];

  formGroup: FormGroup;

  get f() { return this.formGroup.controls; }
  isSave: boolean = true
  
  
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private ejeService: EjePndRService, 
    private  sideBarService: SidebarService
    ) {
    this.formGroup = this._formbuilder.group(this.eje);
    
  }

  ngOnInit() {

    
     
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.ejeService.getEje(Number(id))
      .subscribe(resp => {
        
        console.log(resp);
        this.eje = resp;
        console.log(this.eje);
        this.formGroup = this._formbuilder.group(this.eje);
      })
    }
  

  }

  guardarEjePnd() {
    
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    this.eje = this.formGroup.value;
   
    Swal.showLoading()

    if (this.eje.ejepndId) {
      this.eje.creado = new Date();
      this.eje.actualizado = new Date();
      this.eje.actualizadoPor = 1;

      peticion = this.ejeService.actualizarEje(this.eje)
      console.log(this.eje)
    }
    else {
      this.eje.activo = true;
      this.eje.creadoPor = 1;
      this.eje.creado =new Date();
      this.eje.actualizado =new Date();
      this.eje.actualizadoPor =1;
      peticion = this.ejeService.crearEjePnd(this.eje)
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.eje.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.eje.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }
    
    )
  }

}

