import { EjepndMdl } from './ejepnd.model';


export class MacrobjetivoMdl {
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number
    activo: boolean;
    nombre: string;
    descripcion:string;
    macroobjetivoId: number;
    ejepndId: any;

    constructor(){
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.nombre = null;
        this.descripcion = null;
        this.macroobjetivoId = null;
        this.ejepndId = null;

    }
}