import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IntUsuPerService } from '../../../services/service.index';

import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { iUsuarioPerfilMdl } from '../../../models/iUsuarioPerfil';
import { UsuarioMdl } from '../../../models/Usuario';
import { PerfilMdl } from '../../../models/Perfil';



@Component({
  selector: 'app-inversion',
  templateUrl: './usuario-asignacion.component.html',
  styles: ['./usuario-asignacion.component.ts']
})


export class IntermediaComponent implements OnInit {


  intermedias: iUsuarioPerfilMdl = new iUsuarioPerfilMdl()

  usu: UsuarioMdl = new UsuarioMdl()
  usus: UsuarioMdl[] = []
  ususlctd: number = 0;

  per: PerfilMdl = new PerfilMdl()
  pers: PerfilMdl[] = []
  perslctd: number = 0;


  dropdownPerfilSettings: any = {}
  selectedPerfil: PerfilMdl[] = [];
  PerfilRelacionadolist: PerfilMdl[] = []



  get f() { return this.intermediaGroupForm.controls; }
  isSave: boolean = true

  //*****************VALIDAR*******************************************
  createFormGroup() {
    return new FormGroup({
      iusuarioPerfilId: new FormControl('', Validators.required),
      usuarioId: new FormControl('', Validators.required),
      perfilId: new FormControl('', Validators.required)

    });
  }
  //*****************VALIDAR*******************************************



  intermediaGroupForm: FormGroup;
  interGroup: FormGroup;


  constructor(private service: IntUsuPerService,
    private route: ActivatedRoute,
    private _formbuilder: FormBuilder) {
    this.intermediaGroupForm = _formbuilder.group(this.intermedias);
    this.interGroup = new FormGroup({
      perfilId: new FormControl('', Validators.required)

    });

    //*****************VALIDAR*******************************************
    // this.inversionGroupForm = this.createFormGroup();
    //*****************VALIDAR*******************************************
  }


  ngOnInit() {

    this.service.getUsuario()
      .subscribe(res => {
        this.usus = res
      })


    this.dropdownPerfilSettings = {
      singleSelection: false,
      idField: 'perfilId',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }


    //Obtengo el parametro de la ruta get
    const id = this.route.snapshot.paramMap.get('id')

    this.service.getPerfil()
      .subscribe(res => {
        this.pers = res



        if (id !== 'new') {
          this.isSave = false

          //Editar
          this.service.getIntUsuPer(Number(id)).subscribe((res: iUsuarioPerfilMdl) => {

            this.intermedias = res;
            
            this.service.getPerfilesUserid(res.usuarioId.usuarioId).subscribe(resPer => {

              let IntTemp: string = res.iusuarioPerfilId.toString();
              let listaPerfiles: iUsuarioPerfilMdl[] = resPer;

              

              this.ususlctd = res.usuarioId.usuarioId
              this.usu = this.usus.find(u => u.usuarioId === this.ususlctd)
              this.intermedias.usuarioId = this.intermedias.usuarioId.usuarioId;


              //   let tempOds = odsidsTemp.split(",");
              let tempOds = JSON.parse(IntTemp);
              
              this.pers.forEach(perfil => {

                listaPerfiles.forEach(perfilid => {

                  if (perfilid.perfilId.perfilId == perfil.perfilId) {
                    this.selectedPerfil.push(perfil);

                  }

                });




              });




              this.intermedias.perfilId = this.selectedPerfil;
              this.intermediaGroupForm = this._formbuilder.group(this.intermedias);



            })



          })
        }

      })
  }



  guardarIntermedia() {

    if (this.intermediaGroupForm.invalid) {
      console.log('Form no valido')

      return
    }



    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })


    let perfilId = this.intermedias.perfilId;
    this.intermedias = this.intermediaGroupForm.value;

    this.intermedias.perfilId = perfilId;

    this.intermedias.activo = true;
    this.intermedias.creadoPor = 1;
    this.intermedias.actualizado = new Date();
    this.intermedias.actualizadoPor = 1;


    let IntPerUsuTem: any = this.intermedias;

    let Perfiles: Number[] = Array();

    this.intermedias.perfilId.forEach(perfil => {
      Perfiles.push(perfil.perfilId)
    })

    



    // proyectoinvTem.odsId="["+odsIds.toString()+"]";
    //  proyectoinvTem.bloqueId="["+bloqueIds.toString()+"]";

    IntPerUsuTem.perfilId = JSON.stringify(Perfiles);
    


    Swal.showLoading()

    if (this.intermedias.iusuarioPerfilId) {
      this.intermedias.creado = this.intermedias.creado
      peticion = this.service.actualizarIntUsuPer(IntPerUsuTem)

      console.log(this.intermedias)
    }

    else {
      this.intermedias.creado = new Date()
      console.log(this.intermedias)
      peticion = this.service.crearIntUsuPer(IntPerUsuTem)

    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.intermedias.usuarioId,
        text: 'Realizado correctamente',
        type: 'success',
      })
    },
      error => {

        if (error) {

        }

        Swal.fire({
          title: this.intermedias.usuarioId,
          text: 'error',
          type: 'error',
        })
      },


    )
  }

  //*****************VALIDAR*******************************************
  get iusuarioPerfilId() { return this.intermediaGroupForm.get('iusuarioPerfilId'); }
  get perfilId() { return this.intermediaGroupForm.get('perfilId'); }
  get usuarioId() { return this.intermediaGroupForm.get('usuarioId'); }
  //*****************VALIDAR*******************************************




  onUsuarioSlctd(event: any) {
    this.usu = this.usus.find(u => u.usuarioId === event)
  }


  onPerfilSelect(e: any) {
    // const idbloque = this.inversionGroupForm.controls.bloqueId.value.map(b => b.bloqueId);


  }

  guardarInversion() {
    console.log('Guardar')
  }
}