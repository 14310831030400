import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { BloquesService, SidebarService } from '../../../services/service.index';

//modelos
import { BloqueMdl } from '../../../models/bloque.model';

@Component({
  selector: 'app-bloque',
  templateUrl: './bloque.component.html',
  styleUrls: ['./bloque.component.css']
})
export class BloqueComponent implements OnInit {

  bloque: BloqueMdl = new BloqueMdl();
  bloques: BloqueMdl[] = [];

  formGroup: FormGroup;

  get f() { return this.formGroup.controls; }
  isSave: boolean = true
  
  
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private bloqueService: BloquesService,
    private sideBarService: SidebarService

    ) {
    this.formGroup = this._formbuilder.group(this.bloque);
    
  }

  ngOnInit() {   
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.bloqueService.getBloque(Number(id))
      .subscribe(resp => {
        
        console.log(resp);
        this.bloque = resp;
        console.log(this.bloque);
        this.formGroup = this._formbuilder.group(this.bloque);
      })
    }
  

  }

  guardarBloque() {
    
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    this.bloque = this.formGroup.value;
   
    Swal.showLoading()

    if (this.bloque.bloqueId) {
      this.bloque.creado = new Date();
      this.bloque.actualizado = new Date();
      this.bloque.actualizadoPor = 1;

      peticion = this.bloqueService.actualizarBloque(this.bloque)
      console.log(this.bloque)
    }
    else {
      this.bloque.activo = true;
      this.bloque.creadoPor = 1;
      this.bloque.creado =new Date();
      this.bloque.actualizado =new Date();
      this.bloque.actualizadoPor =1;
      peticion = this.bloqueService.crearBloque(this.bloque)
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.bloque.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.bloque.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }
    
    )
  }

}

