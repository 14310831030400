import { Component, OnInit } from '@angular/core';
import { EjepndMdl } from '../../../models/ejepnd.model';
import { IndicadorService, A2030Service, FichaTecnicaService, PerioricidadService, SidebarService } from '../../../services/service.index';
import { FormGroup, FormArray, FormBuilder, FormControl , Validators } from '@angular/forms';
import { MacrobjetivoMdl } from '../../../models/macrobjetivo.model';
import { BloqueMdl } from '../../../models/bloque.model';
import { ObjetivoBloqueMdl } from '../../../models/objetivoBloque.model';
import { EstrategiaPvdMdl } from '../../../models/estrategiaPvd.model';
import { LineaMdl } from '../../../models/lineaAccion.model';
import { Meta2030Mdl } from '../../../models/meta2030.model';
import { OdsMdl } from '../../../models/ods.model';
import { IndicadorPvdMdl } from '../../../models/indicadorPvd.model'
import { IndicadorA2030Mdl } from '../../../models/indicadorA2030.model'
import { DesagregadoMdl } from '../../../models/desagregado.model'
import { ChangeDetectionStrategy } from '@angular/core';
import { FichaItemMdl, FichaMdl, FichaPesistenciaMdl } from '../../../models/FichaItem.model';
import { ActivatedRoute } from '@angular/router';
import { ComiteMdl } from '../../../models/comite.model';
import { EjepvdMdl } from '../../../models/ejepvd.model';
import { RespuestaMdl } from '../../../models/respuestaDesagregado.model';
import { Observable } from 'rxjs';
import Swal from 'sweetalert2'
import { PerioricidadMdl } from '../../../models/perioricidad.model';

@Component({
  selector: 'app-fichatecnicaEdicion',
  templateUrl: './fichatecnicaEdicion.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FichaTecnicaEdicionComponent implements OnInit {  
  
  ejesPnd: EjepndMdl[] = [];
  ejesPvd: EjepvdMdl[] = []
  macroObjs: MacrobjetivoMdl[] = []
  bloquesIndicador: BloqueMdl[] = []
  objetivosBloque: ObjetivoBloqueMdl[] = []
  estrategiasPvd: EstrategiaPvdMdl[] = []
  lineasAccion: LineaMdl[] = []
  metasa2030: Meta2030Mdl[] = []
  indicadora2030: IndicadorPvdMdl[] = []
  indicador2030Slctd: IndicadorPvdMdl
  odslist: OdsMdl[] = []
  odsRelacionadolist: OdsMdl[] = []
  indicadorA2030list: IndicadorA2030Mdl[] = []
  desagregadosList: DesagregadoMdl[] = []
  comitesList: ComiteMdl[] = []

  dropdownMetaSettings: any = {}
  dropdownODSettings: any = {}
  dropdownIndicadorSettings: any = {}


  indicadorGroupForm: FormGroup;
  historicosList: FormArray;

  historico: any = {};
  historicos: any[] = [];

  bloque: BloqueMdl = new BloqueMdl();
  meta: MacrobjetivoMdl = new MacrobjetivoMdl();

  listaDesagregados: FichaItemMdl[] = [];
  listaHistoricos: any[] = [];

  isSave: boolean = true;
  TipoFormulario: any = "";

  fichaMdl: FichaMdl = new FichaMdl();
  fichaEdicion: FichaPesistenciaMdl = new FichaPesistenciaMdl();
  periodicidad_lista: PerioricidadMdl[] = [];

  get fichaForm() { return this.indicadorGroupForm.controls; }

  constructor(private service: IndicadorService,
    private a2030Service: A2030Service,
    private fichaService: FichaTecnicaService,
    private _fb: FormBuilder,
    private route: ActivatedRoute,
    private periodicidadService: PerioricidadService,
    private  sideBarService: SidebarService
  ) 
  {
     this.indicadorGroupForm = this._fb.group(
       this.fichaEdicion          
       );
  }
  
  click : boolean = true;
  
  isDisabled : boolean;

/*    onButtonClick(){
     this.click = !this.click;           
     this.indicadorGroupForm.controls.ejepnd_id.enable();
     this.indicadorGroupForm.controls.macroobjetivo_id.enable();
     this.indicadorGroupForm.controls.ejepvd_id.enable();
     this.indicadorGroupForm.controls.bloque_id.enable();
     this.indicadorGroupForm.controls.comite_id.enable();
     this.indicadorGroupForm.controls.objetivobloque_id.enable();
     this.indicadorGroupForm.controls.estrategiapvd_id.enable();
     this.indicadorGroupForm.controls.lineaaccionpvd_id.enable();
     this.indicadorGroupForm.controls.observaciones.enable();
     this.indicadorGroupForm.controls.indicadorpvd_id.enable();
     
     this.isDisabled = false;
   } */
 
  ngOnInit() { 

  /*   this.indicadorGroupForm.controls.ejepnd_id.disable();
    this.indicadorGroupForm.controls.macroobjetivo_id.disable();
    this.indicadorGroupForm.controls.ejepvd_id.disable();
    this.indicadorGroupForm.controls.bloque_id.disable();
    this.indicadorGroupForm.controls.comite_id.disable();
    this.indicadorGroupForm.controls.objetivobloque_id.disable();
    this.indicadorGroupForm.controls.estrategiapvd_id.disable();
    this.indicadorGroupForm.controls.lineaaccionpvd_id.disable();
    this.indicadorGroupForm.controls.observaciones.disable();
    this.indicadorGroupForm.controls.indicadorpvd_id.disable(); */
    
    
    this.isDisabled = false;
 

    this.historico.Valor = "";
    this.historico.Observacion={};
    this.historico.Observacion.observacion="";
    this.historico.Valor_opcional={};
    this.historico.Valor_opcional.valor_opcional="";
   

    const id = this.route.snapshot.paramMap.get('id');
    this.TipoFormulario = id;

    this.periodicidadService.getPerioricidades()
    .subscribe(res => {
      this.periodicidad_lista = res
    })

    this.service.getEjespvdList()
      .subscribe(res => {
        this.ejesPvd = res

        this.fichaService.getcomites()
          .subscribe(res => {
            this.comitesList = res

            this.service.getEjespndList()
              .subscribe(res => {
                this.ejesPnd = res

                this.service.getBloquesIndicadorList()
                  .subscribe(res => {
                    this.bloquesIndicador = res

                    this.a2030Service.getODSs()
                      .subscribe(res => {
                        this.odslist = res  

                        this.fichaService.getIndicadoresFicha()
                          .subscribe(res => {
                            this.indicadora2030 = res

                            if (id !== 'new') {
                              this.isSave = false
                              //Editar
                              this.fichaService.getFicha(id)
                                .subscribe(resp => {
                                  this.fichaMdl = resp;
                                  this.fichaEdicion.ficha_id = resp.fichaId;
                                  
                                  this.fichaMdl.datosDesagregados = JSON.parse(resp.datosDesagregados);
                                  this.fichaMdl.odsIdRelacionados = JSON.parse(resp.odsIdRelacionados);
                                  this.fichaMdl.indicadora2030Id = JSON.parse(resp.indicadora2030Id);
                                  this.fichaMdl.metaa2030Id = JSON.parse(resp.metaa2030Id);
                                  this.fichaMdl.odsId = JSON.parse(resp.odsId);
                                  this.fichaMdl.historicos = JSON.parse(resp.historicos);
                                  this.odslist.forEach(ods => {
                                    this.fichaMdl.odsId.forEach(ids => {
                                      if (ods.odsId.toString() == ids) {
                                        this.fichaEdicion.ods_id.push(ods);
                                      }
                                    })
                                  })
                                  this.indicadorGroupForm.controls.ods_id.setValue(this.fichaEdicion.ods_id);                                  
                                  this.onODSSelect("actualiza");

                                  this.indicadorGroupForm.controls.ejepnd_id.setValue(this.fichaMdl.ejepndId.ejepndId);

                                  this.service.getMocaroObj(this.fichaMdl.ejepndId.ejepndId)
                                    .subscribe(res => {
                                      this.macroObjs = res
                                      this.indicadorGroupForm.controls.macroobjetivo_id.setValue(this.fichaMdl.macroobjetivoId.macroobjetivoId);
                                    })

                                  this.indicadorGroupForm.controls.ejepvd_id.setValue(this.fichaMdl.ejepvdId.ejepvdId);

                                  this.indicadorGroupForm.controls.bloque_id.setValue(this.fichaMdl.bloqueId.bloqueId);
                                  this.service.getObjetivoBloquesIndicadorList(this.fichaMdl.bloqueId.bloqueId)
                                    .subscribe(res => {
                                      this.objetivosBloque = res;
                                      this.indicadorGroupForm.controls.objetivobloque_id.setValue(this.fichaMdl.objetivobloqueId.objetivobloqueId);
                                      this.onSelectObjBloque(this.fichaMdl.objetivobloqueId.objetivobloqueId);

                                      this.service.getEstrategiaPvdList(this.fichaMdl.objetivobloqueId.objetivobloqueId)
                                        .subscribe(res => {
                                          this.estrategiasPvd = res
                                          this.indicadorGroupForm.controls.estrategiapvd_id.setValue(this.fichaMdl.estrategiapvdId.estrategiapvdId);
                                          this.onSelectEstrategiaBloque(this.fichaMdl.estrategiapvdId.estrategiapvdId);

                                          this.service.getLineaAccionList(this.fichaMdl.estrategiapvdId.estrategiapvdId)
                                            .subscribe(res => {
                                              this.lineasAccion = res
                                              this.indicadorGroupForm.controls.lineaaccionpvd_id.setValue(this.fichaMdl.lineaaccionpvdId.lineaaccionpvdId);
                                            })
                                        })
                                    })

                                  this.indicadorGroupForm.controls.ejepnd_id.setValue(this.fichaMdl.ejepndId.ejepndId);
                                  this.indicadorGroupForm.controls.comite_id.setValue(this.fichaMdl.comiteId.comiteId);
                                  this.indicadorGroupForm.controls.indicadorpvd_id.setValue(this.fichaMdl.indicadorpvdId.indicadorpvdId);
                                  this.indicadorGroupForm.controls.observaciones.setValue(this.fichaMdl.observaciones);

                                  this.onSelectIndicador(this.fichaMdl.indicadorpvdId.indicadorpvdId);

                                  this.AsignarOdsRelacionados(1);
                                  this.AgregarHistoricos(1);
                                  // this.formGroup = this._formbuilder.group(this.desglosado);
                                })
                            }
                          })
                      })

                  })
              })

          })
      })

    

    this.indicador2030Slctd = new IndicadorPvdMdl()
    this.desagregadosList = []

    this.dropdownMetaSettings = {
      singleSelection: false,
      idField: 'metaa2030_id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    this.dropdownODSettings = {
      singleSelection: false,
      idField: 'odsId',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true,    
    }

    this.dropdownIndicadorSettings = {
      singleSelection: false,
      idField: 'indicadora2030_id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    
  }//ngOnInit

  ActualizaArreglo(arreglo, indice) {
    this.listaDesagregados[indice].lista = arreglo;
  }

  ActualizaHistoricos(arreglo, indice) {
    this.historicos = arreglo;
  }

  onSelectEje(idEje: number) {
    this.service.getMocaroObj(idEje)
      .subscribe(res => {
        this.macroObjs = res
      })
  }

  onSelectBloque(idBloque: number) {
    this.service.getObjetivoBloquesIndicadorList(idBloque)
      .subscribe(res => {
        this.objetivosBloque = res
      })
  }

  onSelectObjBloque(idObjBlock: number) {
    this.service.getEstrategiaPvdList(idObjBlock)
      .subscribe(res => {
        this.estrategiasPvd = res
      })
  }

  onSelectEstrategiaBloque(idEstrategia: number) {
    this.service.getLineaAccionList(idEstrategia)
      .subscribe(res => {
        this.lineasAccion = res
      })
  }

  AsignarOdsRelacionados(e: any) { 
      this.odslist.forEach(indicador => {            
        this.fichaMdl.odsIdRelacionados.forEach(ids => {
          if (indicador.odsId.toString() == ids) {
            this.fichaEdicion.ods_id_relacionados.push(indicador);            
          }
        })
      })
      this.indicadorGroupForm.controls.ods_id_relacionados.setValue(this.fichaEdicion.ods_id_relacionados);         
  }

  onMetaSelect(e: any) {
    const idsMetas = this.indicadorGroupForm.controls.metaa2030_id.value.map(meta => meta.metaa2030_id)
    this.fichaService.getIndicadoresOds(idsMetas).subscribe(res => {
      this.indicadorA2030list = res

      if (e == "actualiza") {
        this.indicadorA2030list.forEach(indicador => {
          this.fichaMdl.indicadora2030Id.forEach(ids => {
            if (indicador.indicadora2030_id.toString() == ids) {
              this.fichaEdicion.indicadora2030_id.push(indicador);
            }
          })
        })
        this.indicadorGroupForm.controls.indicadora2030_id.setValue(this.fichaEdicion.indicadora2030_id);
      }
    })
  }

  onSelectIndicador(e) {
    this.fichaService.getIndicadorDetail(e)
      .subscribe(res => {
       
        this.indicador2030Slctd = res;
        this.desagregadosList = this.indicador2030Slctd.desagregados.filter(i => i.i_indicadorpvd_desagregado_activo == true)
       
        this.listaDesagregados = [];
        
        this.desagregadosList.forEach(element => {     
          let tempDesagregado = new FichaItemMdl();  
          let tempCampos: any = {};
          let datosDesagregadosTemp = Array();
         
          tempDesagregado.desagregado = element.opciones_desagregados;
          element.opciones_desagregados.forEach(campo => {
           
            if (campo.activo == true) {
             
              tempCampos[campo.nombre] = {};
              tempCampos[campo.nombre].dato = "";
              tempCampos[campo.nombre].ficha_id = this.fichaMdl.fichaId;
              tempCampos[campo.nombre].lineabase = "";
              tempCampos[campo.nombre].anio = "";
              tempCampos[campo.nombre].desagregado_id = campo.desagregado_id;
              tempCampos[campo.nombre].indicadorpvd_id = e;
              tempCampos[campo.nombre].opcion_desagregado_id = campo.opcion_desagregado_id;
              tempCampos[campo.nombre].periodicidad_id = 1;

            }
          })
         
          tempDesagregado.desagregado = element;
          tempDesagregado.OpcionesDesagregado = tempCampos;
          
          if (this.fichaMdl.datosDesagregados) {

            this.fichaMdl.datosDesagregados.forEach(elementDesagregados => { 
              if (tempDesagregado.desagregado.desagregado_id == elementDesagregados.desagregado_id) {
                datosDesagregadosTemp.push(elementDesagregados);
              }
            })
          }
          tempDesagregado.listaDatosDesagregado = datosDesagregadosTemp;        
          datosDesagregadosTemp=[];
          //debugger
          if(tempDesagregado.desagregado.activo){
            this.listaDesagregados.push(tempDesagregado);           
            console.log(this.listaDesagregados)
          }
          
        })
      })
  }

  AgregarHistoricos(e) {
 
        this.listaDesagregados = [];

        this.fichaMdl.historicos.forEach(element => {

          let tempDesagregado = new FichaItemMdl();
          let tempCampos: any = {};
          let datosDesagregadosTemp = Array();
         

          Object.keys(element).forEach(key => {
            tempCampos[key] = {};
            tempCampos[key].dato = element[key];
            tempCampos[key].ficha_id = "";
            tempCampos[key].lineabase = "";
            tempCampos[key].anio = "";
            tempCampos[key].desagregado_id = "";
            tempCampos[key].indicadorpvd_id = "";
            tempCampos[key].opcion_desagregado_id = "";
            tempCampos[key].periodicidad_id = "";
            tempCampos[key].observacion = "";
            tempCampos[key].valor_opcional = "";

          })
                  
          tempDesagregado.desagregado = element;
          tempDesagregado.OpcionesDesagregado = tempCampos;

          tempDesagregado.listaDatosDesagregado = datosDesagregadosTemp;        
          datosDesagregadosTemp=[];
          this.listaHistoricos.push(tempDesagregado);
        })

  }

  onODSDeseelct(e: any) {
    let odslistNew: Meta2030Mdl[] = Array()
    if (this.indicadorGroupForm.controls.metaa2030_id.value) {
      this.indicadorGroupForm.controls.metaa2030_id.value.map(meta => {
        const meta2030 = this.metasa2030.find(m => m.metaa2030_id === meta.metaa2030_id)
        if (meta2030.ods_id !== e.odsId) {
          odslistNew.push(meta2030)
        }
      })
      this.indicadorGroupForm.controls.metaa2030_id.setValue(odslistNew)
    }
  }

  onMetaDeseelct(e: any) {
    let indicadorListNew: IndicadorA2030Mdl[] = Array()
    if (this.indicadorGroupForm.controls.indicadora2030_id.value) {
      this.indicadorGroupForm.controls.indicadora2030_id.value.map(indi => {
        const indicador = this.indicadorA2030list.find(m => m.indicadora2030_id === indi.indicadora2030_id)
        if (indicador.metaa2030_id !== e.metaa2030_id) {
          indicadorListNew.push(indicador)
        }
      })
      this.indicadorGroupForm.controls.indicadora2030_id.setValue(indicadorListNew)
    }
  }

  onODSSelect(e: any) {
    const idsOds = this.indicadorGroupForm.controls.ods_id.value.map(ods => ods.odsId);
    this.indicadorGroupForm.controls.ods_id_relacionados.setValue([]);
    this.odsRelacionadolist = this.odslist.filter((item: OdsMdl) => {
      if (!idsOds.includes(item.odsId))
        return item
    })

    this.fichaService.getMetasA2030(idsOds).subscribe(res => {
      // console.log('respuesta: ', res) 
      this.metasa2030 = res;

      
      if (e == "actualiza") {
        this.metasa2030.forEach(metas => {
          this.fichaMdl.metaa2030Id.forEach(ids => {
            if (metas.metaa2030_id.toString() == ids) {
              this.fichaEdicion.metaa2030_id.push(metas);
            }
          })
        })
        this.indicadorGroupForm.controls.metaa2030_id.setValue(this.fichaEdicion.metaa2030_id);
        this.onMetaSelect("actualiza");
      }
    })
  }


  parseToSave(listaDesagregados: RespuestaMdl[]) {  

    let fichaEnvio: FichaPesistenciaMdl = new FichaPesistenciaMdl();

    let odsIds = Array();
    let metasIds = Array();
    let indicadoreid2030 = Array();
    let odsIdsRel = Array();
    let historicosList = Array()

    this.fichaEdicion.ods_id.forEach(ods => {
      odsIds.push(ods.odsId.toString())
    })

    this.fichaEdicion.metaa2030_id.forEach(metas => {
      metasIds.push(metas.metaa2030_id.toString())
    })

    this.fichaEdicion.indicadora2030_id.forEach(ind2030 => {
      indicadoreid2030.push(ind2030.indicadora2030_id.toString())
    })

    this.fichaEdicion.ods_id_relacionados.forEach(odsRel => {
      odsIdsRel.push(odsRel.odsId.toString())
    })

    let index = 1;
    this.historicos.forEach(element=>{ 
      let hist:any={};
      hist.año=element.Año.dato;
      hist.valor=element.Valor.dato;          
      hist.observacion=element.Observacion.dato;
      hist.valor_opcional=element.Valor_opcional.dato;
      hist.index=index;
      index ++
      historicosList.push(hist);    
     
    })

    // console.info('Historicos: ', historicosList)
    
    fichaEnvio.ods_id = odsIds;
    fichaEnvio.metaa2030_id = metasIds;
    fichaEnvio.indicadora2030_id = indicadoreid2030;
    fichaEnvio.ods_id_relacionados = odsIdsRel;
    fichaEnvio.datos_desagregados = listaDesagregados;
    // fichaEnvio.datos_desagregados = listaDesagregados;
    fichaEnvio.historicos = historicosList;

    fichaEnvio.bloque_id = Number(this.fichaEdicion.bloque_id);
    fichaEnvio.comite_id = Number(this.fichaEdicion.comite_id);
    fichaEnvio.ejepnd_id = Number(this.fichaEdicion.ejepnd_id);
    fichaEnvio.ejepvd_id = Number(this.fichaEdicion.ejepvd_id);
    fichaEnvio.estrategiapvd_id = Number(this.fichaEdicion.estrategiapvd_id);
    fichaEnvio.ficha_id = Number( this.fichaEdicion.ficha_id);
    fichaEnvio.indicadorpvd_id = Number(this.fichaEdicion.indicadorpvd_id);
    fichaEnvio.lineaaccionpvd_id =Number( this.fichaEdicion.lineaaccionpvd_id);
    fichaEnvio.macroobjetivo_id = Number(this.fichaEdicion.macroobjetivo_id);
    fichaEnvio.objetivobloque_id =Number( this.fichaEdicion.objetivobloque_id);
    fichaEnvio.observaciones = this.fichaEdicion.observaciones;

    //  console.log(fichaEnvio)
    return fichaEnvio
  }

  onSubmitFicha() {
    let desagregados = []
    this.listaDesagregados.forEach(desagregado => {
      if (desagregado.lista) {
        desagregado.lista.forEach(element => {
          Object.keys(element).forEach(key => {
            if (key != "Año") {
              desagregados.push(element[key]);
            }
          })
        })
      }
    });
    let fichaToSave = this.parseToSave(desagregados);

    this.guardarActualizarFicha(fichaToSave);
  }

  guardarActualizarFicha(fichaToSave) {
  
    if (this.indicadorGroupForm.invalid) {

      Swal.fire({
        title: 'Error en la informacion',
        text: 'Por favor verifique los datos ingresados',
        type: 'info',
        allowOutsideClick: false
      })

      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    Swal.showLoading()

    peticion = this.fichaService.actualizarFicha(fichaToSave);

    peticion.subscribe(resp => {
      if (resp.exitoso) {
        Swal.fire({
          title: fichaToSave.fichaId,
          text: 'Realizado correctamente',
          type: 'success',
        })

      }
      else {
        Swal.fire({
          title: fichaToSave.fichaId,
          text: 'Error en la peticion',
          type: 'error',
        })

      }

    }, error => {
      if (error.exitoso) {
        Swal.fire({
          title: fichaToSave.fichaId,
          text: 'Realizado correctamente',
          type: 'success',
        })

      }
      else {
        Swal.fire({
          title: fichaToSave.fichaId,
          text: 'Error en la peticion',
          type: 'error',
        })

      }

    }

    )
  }


}
function onlySelf(onlySelf: any, arg1: boolean): { [s: string]: unknown; } | ArrayLike<unknown> {
  throw new Error('Function not implemented.');
}

