import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { RespuestaMdl } from '../../../models/respuestaDesagregado.model';
import { PerioricidadMdl } from '../../../models/perioricidad.model';
import { format } from 'path';


@Component({
  selector: 'app-listaDinamicaEdit',
  templateUrl: './ListaDinamicaEdit.component.html',
  styleUrls: ['./ListaDinamicaEdit.component.css'],
})

export class ListaDinamicaEditComponent implements OnInit {

  @Input('ObjetoBase') base: any = {};
  @Input('nombre') nombre: string;
  @Input('TipoDato') padre: string;
  @Input('listaDatosDesagregado') datosDesagregado: RespuestaMdl[] = [];
  @Input('listaHistoricos') datoshistoricos: any[] = [];
  @Input('listaPeriodicidad') periodicidades: PerioricidadMdl[] = [];
  @Output() public listaDesagregado = new EventEmitter<any>();


  
  
  listaObjetos: any[] = [];
  arreglo: any = {};
  arregloOrdenado: any = {};
  showPeriodicity = false;

  elementonuevoForma : FormGroup;

  constructor(
    private _fb: FormBuilder,
  ) {

  }
isDisabled:boolean;
  ngOnInit() {   
    this.base.Año = {};  
    this.base.Año.dato = "";
    this.base.Año.ficha_id = "";
    this.base.Año.lineabase = "";
    this.base.Año.anio = "";
    this.base.Año.opcion_desagregado_id = "";
    this.base.Año.periodicidad_id = '0';
    this.base.Año.observacion = "";
    this.base.Año.valor_opcional = "";

    // console.log(this.base)

    if (this.periodicidades.length > 0) {
      this.showPeriodicity = true
    }
    const groupBy = key => array =>
      array.reduce(
        (objectsByKeyValue, obj) => ({
          ...objectsByKeyValue,
          [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
        }),
        {}
      );
     

    const groupByAño = groupBy('anio');

    if (this.datosDesagregado.length > 0) {
      this.arregloOrdenado = groupByAño(this.datosDesagregado);

      Object.keys(this.arregloOrdenado).forEach(key => {

        var elementonuevo = this.AddElementoNuevo();

        this.arregloOrdenado[key].forEach(element => {

          Object.keys(elementonuevo.base).forEach(keyBase => {

            if (elementonuevo.base[keyBase].opcion_desagregado_id == element.opcion_desagregado_id) {

              if (keyBase == "Año") {
                element.dato = element.anio;

                try {
                  if (element.periodicidad_id) {
                    element.periodicidad_id = element.periodicidad_id;
                  }
                  else {
                    element.periodicidad_id = '0';
                  }
                }
                catch{
                  element.periodicidad_id = '0';
                }

              }
              elementonuevo.base[keyBase] = this.AddObjetoNuevo(element);
              elementonuevo.base["Año"] = this.AddObjetoNuevo(element);
            }
          })
        })

       elementonuevo.forma = this._fb.group( elementonuevo.base);       
        this.listaObjetos.push(elementonuevo);
       
      })

      this.EmitirArreglo();
    }//if datosDesagregado
//-----------------------------------------------------------------------------------------

    if (this.datoshistoricos.length > 0) {
      this.datoshistoricos.forEach(element => {      
        var elementonuevo = this.AddElementoNuevo();
        elementonuevo.base = {};
    
        elementonuevo.base.Año = {};
        elementonuevo.base.Valor = {};       
        elementonuevo.base.Observacion = {};
        elementonuevo.base.Valor_opcional = {};
     
    
        elementonuevo.base.Año.dato = element.año
        elementonuevo.base.Valor.dato = element.valor;      
        elementonuevo.base.Observacion.dato = element.observacion ;
        elementonuevo.base.Valor_opcional.dato = element.valor_opcional;
    
       elementonuevo.forma = this._fb.group(elementonuevo.base );       
     
        this.listaObjetos.push(elementonuevo);
       
      })
    
      this.EmitirArreglo();
 
    } // if datoshistoricos

  //-----------------------------------------------------------------------------------------  
  // console.log(this.listaObjetos)
  }//ngOnInit

  EmitirArreglo() {

    let listatemporal: any[] = []

    this.listaObjetos.forEach(element => {

      Object.keys(element.base).forEach(key => {
        element.base[key].anio = element.base.Año.dato;
        element.base[key].periodicidad_id = element.base.Año.periodicidad_id;
      })
      listatemporal.push(element.base);
    })
    this.listaDesagregado.emit(listatemporal);
  }


  EventoEmiter(item, campo, event) {
    //console.log('En el eventoemitter ', event, '->',campo, ' -> ',item)
    this.listaObjetos[campo].base[item.key].dato = event;
    this.EmitirArreglo();
  }

  EventoEmiterP(item, campo, event) {
    this.listaObjetos[campo].base[item.key].periodicidad_id = Number.parseInt(event);
    this.EmitirArreglo();
  }

  DeleteForma(index) {
    this.listaObjetos.splice(index, 1);

    this.EmitirArreglo();

  }

  AddCampo() {
    let basetemp: any = {};
    let baseobjeto: any = {};

    Object.keys(this.base).forEach(key => {
      baseobjeto[key] = {};
     

      Object.keys(this.base[key]).forEach(key2 => {
        baseobjeto[key][key2] = this.base[key][key2];
      })
    })

    if (this.datoshistoricos.length > 0) {
      baseobjeto.Valor = {};
      baseobjeto.Valor.dato = "";
    }

    basetemp.base = baseobjeto;
    basetemp.forma = this._fb.group(baseobjeto);
    this.listaObjetos.push(basetemp);
  }

  AddElementoNuevo() { 
    let basetemp: any = {};
    let baseobjeto: any = {};

    Object.keys(this.base).forEach(key => {
      baseobjeto[key] = {};

      Object.keys(this.base[key]).forEach(key2 => {
        baseobjeto[key][key2] = this.base[key][key2];
      })
    })

    basetemp.base = baseobjeto;    
    basetemp.forma = this._fb.control(baseobjeto);   
   
    return basetemp;
  }

  AddObjetoNuevo(objeto) {
    let baseobjeto: any = {};

    Object.keys(objeto).forEach(key => {
      baseobjeto[key] = objeto[key];
    })

    return baseobjeto;
  }

}
