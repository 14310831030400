export class DesglosadosMdl {
  creado: Date;
  creadoPor: number;
  actualizado: Date;
  actualizadoPor: number;
  activo: boolean;
  nombre: string;
  desglosadosId: number;


  constructor() {
    this.activo = true;
    this.creado = null;
    this.creadoPor = null;
    this.actualizado = null;
    this.actualizadoPor = null;
    this.nombre = null;
    this.desglosadosId = null;
  }

}
