import { Component, OnInit } from '@angular/core';
import { DerechoService } from '../../../services/shared/derecho.service';
import { TreeNode } from 'primeng/api';
import { element } from 'protractor';
import { SidebarService } from '../../../../app/services/service.index';

@Component({
  selector: 'app-derecho-asignacion',
  templateUrl: './derecho-asignacion.component.html',
  styleUrls: ['./derecho-asignacion.component.scss']
})
export class DerechoAsignacionComponent implements OnInit {

  constructor(private service: DerechoService,
    private sidebarService: SidebarService,
    ) { }
  listaDerecho: TreeNode[] = [];
  lista: any = [];
  lista2: any[] = [];
  selection: any = [];
  selection2: any = [];

  ngOnInit() {
    this.service.getDerechos()
      .subscribe(resp => {
        this.lista = resp[3];
        
         this.sidebarService.menuPermisos.Administrador= this.lista;

        var nodo: any = [];
        let endPoint: any = [];
        let endPointNombre = "";

        Object.keys(this.lista).forEach(key => {

          var temp: any = [];
          let nombre: string = key.toString();
          let ruta: string[] = nombre.split("/");
          let Nombreruta: string = nombre.toString().replace('/' + ruta[1] + '/' + ruta[2], "");

          if (endPointNombre == ruta[2]) {

            let listaNodos: any[] = this.NodosHijos(this.lista[key], endPoint.label, key.toString().replace('/' + ruta[1] + '/' + ruta[2], ""));

            // let listaNodos: any[] = this.NodosHijos(this.lista[key], endPoint.label, key);

            listaNodos.forEach(element => {
              let nodoNuevo: any = [];
              nodoNuevo.label = element.label;
              nodoNuevo.data = element.data;
              nodoNuevo.expandedIcon = "fa fa-edit";
              nodoNuevo.collapsedIcon = "fa fa-edit";
              nodoNuevo.data = key.toString();
              nodoNuevo.children = [];
              endPoint.children.push(nodoNuevo);
            });

          }

          else {

            endPointNombre = ruta[2];

            if (endPoint.label != null) {
              this.lista2.push(endPoint);
            }

            let endPointTemp: any = []
            endPointTemp.label = endPointNombre;
            endPointTemp.data = endPointNombre;
            endPointTemp.expandedIcon = "fa fa-folder-open";
            endPointTemp.collapsedIcon = "fa fa-folder";
            endPointTemp.data = endPointNombre;
            endPointTemp.children = [];
            endPoint = endPointTemp;

            let listaNodos: any[] = this.NodosHijos(this.lista[key], endPoint.label, key.toString().replace('/' + ruta[1] + '/' + ruta[2], ""));

            listaNodos.forEach(element => {

              let nodoNuevo: any = [];
              nodoNuevo.label = element.label;
              nodoNuevo.data = element.data;
              nodoNuevo.expandedIcon = "fa fa-edit";
              nodoNuevo.collapsedIcon = "fa fa-edit";
              nodoNuevo.data = key.toString();
              nodoNuevo.children = [];
              endPoint.children.push(nodoNuevo);

            });


          }
        });
        this.listaDerecho = this.lista2;
      });
  }


  NodosHijos(nodo: any, nombreEndPoint, rutaCompleta) {

    let listaNodos: any[] = [];
    Object.keys(nodo).forEach(key2 => {
      let Metodo: any = []
      Metodo.label = this.equivalenciaDatos(rutaCompleta, key2, nombreEndPoint);
      Metodo.data = rutaCompleta;
      Metodo.expandedIcon = "fa fa-edit";
      Metodo.collapsedIcon = "fa fa-edit";
      Metodo.children = [];
      listaNodos.push(Metodo);
    });

    return listaNodos;
  }


  equivalenciaDatos(nombre, metodo, nombreEndPoint) {

    if (nombre == "" && metodo == "get") {
      return "Listar"
    }
    else if (nombre == "/count" && metodo == "get") {
      return "Contar"
    }
    else if (nombre == "/{from}/{to}" && metodo == "get") {
      return "Buscar por rango"
    }
    else if (nombre == "/{id}" && metodo == "get") {
      return "Ver registro individualmente"
    }
    else if (nombre == "/{id}" && metodo == "delete") {
      return "Eliminar registro"
    }
    else if (nombre == "" && metodo == "post") {
      return "Crear nuevo registro"
    }
    else if (nombre == "/{id}" && metodo == "put") {
      return "Editar registro"
    }
    else {

      let MetodoNombre="";

      if(metodo == "post"){
        MetodoNombre="(metodo post) "
      }
      else if(metodo == "get"){
        MetodoNombre="(metodo get) "
      }
      else if(metodo == "delete"){
        MetodoNombre="(metodo delete) "
      }
      else if(metodo == "put"){
        MetodoNombre="(metodo put) "
      }
      else{
        MetodoNombre=metodo
      }

      return nombre + " " + MetodoNombre
    }

  }

  derechoChange() {
    this.selection2 = [];
    this.selection.forEach(element => {
      if (element.collapsedIcon == "fa fa-edit") {
        let Metodo: any = []
        Metodo.label = element.data;
        Metodo.data = element.data;
        Metodo.expandedIcon = "fa fa-edit";
        Metodo.collapsedIcon = "fa fa-edit";
        Metodo.children = [];
        this.selection2.push(Metodo);
      }
    });
  }

}
