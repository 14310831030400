export class EstatusProyectoMdl {
    creado: number
    creadoPor: number
    actualizado: number
    actualizadoPor: number
    activo: number
    nombre: string
    estatusproyectoId: number
}




