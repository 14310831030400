import { Component, OnInit } from '@angular/core';
import { SentidoService } from '../../../services/service.index';
import { SentidoMdl } from '../../../models/sentido.model';

@Component({
  selector: 'app-listado-sentido',
  templateUrl: './listado-sentido.component.html',
  styles: []
})
export class ListadoSentidoComponent implements OnInit {
  sentidos: SentidoMdl[] = []
  constructor(private service: SentidoService) { }

  ngOnInit() {
    this.service.getSentidos()
      .subscribe(resp => {
        this.sentidos = resp
        console.log(this.sentidos)
      })
  }

}
