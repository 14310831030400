import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms'
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { Menu } from '../../../models/Menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  menu: Menu = new Menu();
 

  isSave: boolean = true
  constructor(private service: MenuService,
    private route: ActivatedRoute) { }

  ngOnInit() {
        //obtengo el parametro en la ruta GET    
        const id = this.route.snapshot.paramMap.get('id')    
        if (id !== 'new') {    
          this.isSave = false
          //Editar
          this.service.getMenuId(Number(id))
            .subscribe((res: Menu) => {
              this.menu = res          
            })
        }
  }

  guardaMenu(form: NgForm) {
    if (form.invalid) {
      //Aquí va la validación del form
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    this.menu.activo = true
    this.menu.creadoPor = 1
    this.menu.actualizado = Date.now()    
    Swal.showLoading()
    if (this.menu.menuId) {
      this.menu.creado = this.menu.creado
      this.menu.actualizado = Date.now();
      this.menu.actualizadoPor = 1; 
      peticion = this.service.updateMenu(this.menu.menuId, this.menu)
      console.log(this.menu)
    }
    else {
      this.menu.creado = Date.now()
      //this.dependencia.actualizado = Date.now()
      console.log(this.menu)
      peticion = this.service.crearMenu(this.menu)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.menu.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    })
  }
}
