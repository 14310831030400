import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {URL_API} from '../../globals';
import {ProyectoInversionMdl} from '../../models/proyectoinv.model';
import {RegionMdl} from '../../models/region.model';
import {MunicipioMdl} from '../../models/municipio.model';
import {LocalidadMdl} from '../../models/localidad.model';
import {FondoFinanciamientoMdl} from '../../models/fondofinanciamiento.model';
import {EjecutoraMdl} from '../../models/ejecutora.model';
import {EstatusProyectoMdl} from '../../models/estatusproyecto.model';
import {OdsMdl} from '../../models/ods.model';
import {BloqueMdl} from '../../models/bloque.model';
import { Observable } from 'rxjs';

@Injectable()
export class InversionService {

  constructor(private http:HttpClient) { }

  getInversion(){
    return this.http.get(`${URL_API}/proyectoinversion`)
    .pipe(map(this.getListaInversion))
  }
  
  
  getRegion(){
    return this.http.get(`${URL_API}/region`)
    .pipe(map(this.getListaRegion))
  }

  getMunicipio(idMun:number){
    return this.http.get(`${URL_API}/municipio/list/${idMun}`)
    .pipe(map(this.getListaMunicipio))
  }

  getLocalidad(idLoc:number){
    return this.http.get(`${URL_API}/localidad/list/${idLoc}`)
    .pipe(map(this.getListaLocalidad))
  }

  getFondo(){
    return this.http.get(`${URL_API}/fondofinanciamiento`)
    .pipe(map(this.getListaFondo))
  }

  getEjecutora(){
    return this.http.get(`${URL_API}/ejecutora`)
    .pipe(map(this.getListaEjecutora))
  }

  getEstatus(){
    return this.http.get(`${URL_API}/estatusproyecto`)
    .pipe(map(this.getListaEstatus))
  }

  getOds(){
    return this.http.get(`${URL_API}/ods`)
    .pipe(map(this.getListaOds))
  }

  getBloque(){
    return this.http.get(`${URL_API}/bloque`)
    .pipe(map(this.getListaBloque))
  }

  eliminarInversion(id:number, invers:ProyectoInversionMdl){
    return this.http.delete(`${URL_API}/proyectoinversion/${id}`)
  }

  crearInversion(invers:ProyectoInversionMdl){
    return this.http.post(`${URL_API}/proyectoinversion`,invers)
    .pipe(map((resp:any) =>{
     // invers.proyectoinversionId = resp.proyectoinversionId
      console.info('Guardado: ', resp)
      return invers
    }))
  }

  getInv(id:number){
    
    return this.http.get(`${URL_API}/proyectoinversion/${id}`)
  }

  actualizarInversion(invers:ProyectoInversionMdl){
    const inversionTemp = {
      ...invers
    }
    delete inversionTemp.proyectoinversionId
    return this.http.put(`${URL_API}/proyectoinversion/${invers.proyectoinversionId}`,
    invers)
    }

    imprimirReporte(file, Id: number ): Observable<any> {
      var body = { filename: file };
      return this.http.post(`${URL_API}/proyectoinversion/reporte/${Id}`,body, {
        responseType: "blob",
        headers: new HttpHeaders().append("Content-Type", "application/json")
        }); 
    }

  
  private getListaInversion(InvObj: Object) {
    const inversiones: ProyectoInversionMdl[] = []
    if(InvObj === null){ return [] }
    Object.keys(InvObj).forEach(key => {
      const inversion: ProyectoInversionMdl = InvObj[key]
      inversiones.push(inversion)
    })

    return inversiones;
  }

  private getListaRegion(RegObj: Object) {
    const regiones: RegionMdl[] = []
    if(RegObj === null){ return [] }
    Object.keys(RegObj).forEach(key => {
      const region: RegionMdl = RegObj[key]
      regiones.push(region)
    })

    return regiones;
  }

  private getListaMunicipio(MunObj: Object){
    const municipios: MunicipioMdl[] = []
    console.log('resul: ', MunObj)
    if(MunObj === null){ return [] }

    Object.keys(MunObj).forEach(key => {
      const municipio: MunicipioMdl = MunObj[key]
      municipios.push(municipio)
    })

    return municipios;
  }


  private getListaLocalidad(LocObj: Object){
    const localidades: LocalidadMdl[] = []
    console.log('resul: ', LocObj)
    if(LocObj === null){ return [] }

    Object.keys(LocObj).forEach(key => {
      const localidad: LocalidadMdl = LocObj[key]
      localidades.push(localidad)
    })

    return localidades;
  }

  private getListaFondo(FonObj: Object) {
    const fondos: FondoFinanciamientoMdl[] = []
    if(FonObj === null){ return [] }
    Object.keys(FonObj).forEach(key => {
      const fondo: FondoFinanciamientoMdl = FonObj[key]
      fondos.push(fondo)
    })

    return fondos;
  }

  private getListaEjecutora(EjObj: Object) {
    const ejecutoras: EjecutoraMdl[] = []
    if(EjObj === null){ return [] }
    Object.keys(EjObj).forEach(key => {
      const ejecutora: EjecutoraMdl = EjObj[key]
      ejecutoras.push(ejecutora)
    })

    return ejecutoras;
  }

  private getListaEstatus(EstObj: Object) {
    const estatus: EstatusProyectoMdl[] = []
    if(EstObj === null){ return [] }
    Object.keys(EstObj).forEach(key => {
      const est: EstatusProyectoMdl = EstObj[key]
      estatus.push(est)
    })

    return estatus;
  }

  private getListaOds (OdsObj: Object) {
    const OdSS: OdsMdl[] = []
    if(OdsObj === null){ return [] }
    Object.keys(OdsObj).forEach(key => {
      const ods: OdsMdl = OdsObj[key]
      OdSS.push(ods)
    })

    return OdSS;
  }

  private getListaBloque (BloObj: Object) {
    const Bloques: BloqueMdl[] = []
    if(BloObj === null){ return [] }
    Object.keys(BloObj).forEach(key => {
      const bloque: BloqueMdl = BloObj[key]
      Bloques.push(bloque)
    })

    return Bloques;
  }
  


}

