import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { RegionMdl } from '../../models/region.model';
import { URL_API } from '../../globals';

@Injectable()
export class RegionService {

  constructor(private http: HttpClient) { }

  getRegion(){
    return this.http.get(`${URL_API}/region`)
      .pipe(map(this.ListaRegiones))   
  }

  getReg(id:number){
    return this.http.get<any>(`${URL_API}/region/${id}`)
  }

  actualizarRegion(region: RegionMdl){
      const regionTemp = {
      ...region
    }
    delete regionTemp.regionId
    
    return this.http.put(`${URL_API}/region/${region.regionId}`, 
    region)
  }

    crearRegion(region: RegionMdl){
    return this.http.post(`${URL_API}/region`, region)
    .pipe(map((resp:any)=>{      
      return region
    }))
  }

  eliminarRegion(id:number, unidad: RegionMdl){
    return this.http.delete(`${URL_API}/region/${id}`)
  }

  // crearUnidad(unidad: RegionMdl){
  //   return this.http.post(`${URL_API}/unidadmedida`, unidad)
  //   .pipe(map((resp:any)=>{
  //     //bloque.bloqueId = resp.bloqueId
  //     return unidad
  //   }))
  // }

  // getUnidad(id:number){
  //   return this.http.get<any>(`${URL_API}/unidadmedida/${id}`)
  // }

  // actualizarUnidad(unidad: RegionMdl){
  //   const unidadTemp = {
  //     ...unidad
  //   }
  //   delete unidadTemp.unidadmedidaId
  //   return this.http.put(`${URL_API}/unidadmedida/${unidad.unidadmedidaId}`, 
  //     unidad)
  // }

  private ListaRegiones(regionObj: Object){
    const regiones: RegionMdl[] = []
    if(regionObj === null){ return [] }

    Object.keys(regionObj).forEach(key => {
      const reg: RegionMdl = regionObj[key]
      regiones.push(reg)
    })

    return regiones
  }

}