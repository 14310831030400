import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { BloqueMdl } from '../../models/bloque.model';
import { URL_API } from '../../globals';

@Injectable()
export class BloquesService {

  constructor(private http: HttpClient) { }

  getBloques(){
    return this.http.get(`${URL_API}/bloque`)
      .pipe(map(this.crearLista))
      
  }

  getBloquesSinFiltro(){
    return this.http.get(`${URL_API}/bloque/sinFiltro`)
      .pipe(map(this.crearLista))
      
  }

  eliminarBloque(id:number){
    return this.http.delete(`${URL_API}/bloque/${id}`)
  }

  crearBloque(bloque: BloqueMdl){
    return this.http.post(`${URL_API}/bloque`, bloque)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return bloque
    }))
  }

  getBloque(id:number){
    return this.http.get<any>(`${URL_API}/bloque/${id}`)
  }

  actualizarBloque(bloque: BloqueMdl){
    const bloqueTemp = {
      ...bloque
    }
    delete bloqueTemp.bloqueId
    return this.http.put(`${URL_API}/bloque/${bloque.bloqueId}`, 
      bloque)
  }

  private crearLista(bloqueObj: Object){
    const bloques: BloqueMdl[] = []
    if(bloqueObj === null){ return [] }

    Object.keys(bloqueObj).forEach(key => {
      const bloque: BloqueMdl = bloqueObj[key]
      bloques.push(bloque)
    })

    return bloques
  }

}