export class FuenteMdl {
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    descripcion: string;
    fuenteId: number;

    constructor(){
      this.activo = true;
      this.creado=null;
      this.creadoPor=null;
      this.actualizado=null;
      this.actualizadoPor=null;
      this.descripcion=null;
      this.fuenteId=null;
  }

}
