import { Meta2030Mdl } from './meta2030.model';
import { OdsMdl } from './ods.model';
import { BloqueMdl } from './bloque.model';

export class ProyectoInversionMdl {
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    numeroProyecto:number;
    nombre: string;
    descripcion:string;
    monto:number;
    beneficiario:number;
    montoEjercido:number;
    avanceFinanciero:string;
    ramo:string;
    direccion:string;
    
    proyectoinversionId:number;
    
    bloqueId : BloqueMdl[]=[];
    ejecutoraId : any;
    fondofinanciamientoId: any;
    localidadId : any;
    //municipioId:any
    estatusproyectoId: any;
    //regionId:any
    odsId : OdsMdl[]=[];
    fechaInicio : Date;
    fechaTermino: Date;
    fechaCorte : Date;
    rezagoId:any;

    //ejercicio fiscal
    afiscal:number;
   

    constructor(){
        
    this.creado= null;
    this.creadoPor = null;
    this.actualizado = null;
    this.actualizadoPor = null;
    this.activo = true;
    this.numeroProyecto = null;
    this.nombre = "";
    this.descripcion = null;
    this.monto = null;
    this.beneficiario = null;
    this.montoEjercido = null;
    this.avanceFinanciero = null;
    this.ramo = null;
    this.direccion = null;
    this.proyectoinversionId = null;
 
    this.bloqueId = [];
    this.ejecutoraId = '';
    this.fondofinanciamientoId = '';
    this.localidadId = '';
   // this.municipioId = '';
    this.estatusproyectoId = '';
  //  this.regionId = '';
    this.odsId = [];
    this.fechaInicio = null;
    this.fechaTermino = null;
    this.fechaCorte = null;
    this.rezagoId = null;

    //ejercicio fiscal
    this.afiscal = null;
   
    }
}


