import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { EjepndMdl } from '../../models/ejePndReplica.model';
import { URL_API } from '../../globals';

@Injectable()
export class EjePndRService {

  constructor(private http: HttpClient) { }

  getEjePnds(){
    return this.http.get(`${URL_API}/ejepnd`)
      .pipe(map(this.ListaEje))
      
  }

  eliminarEjePnd(id:number, eje: EjepndMdl){
    return this.http.delete(`${URL_API}/ejepnd/${id}`)
  }

  crearEjePnd(eje: EjepndMdl){
    return this.http.post(`${URL_API}/ejepnd`, eje)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return eje
    }))
  }

  getEje(id:number){
    return this.http.get<any>(`${URL_API}/ejepnd/${id}`)
  }

  actualizarEje(eje: EjepndMdl){
    const ejeTemp = {
      ...eje
    }
    delete ejeTemp.ejepndId
    return this.http.put(`${URL_API}/ejepnd/${eje.ejepndId}`, 
      eje)
  }

  private ListaEje(ejeObj: Object){
    const ejes: EjepndMdl[] = []
    if(ejeObj === null){ return [] }

    Object.keys(ejeObj).forEach(key => {
      const eje: EjepndMdl = ejeObj[key]
      ejes.push(eje)
    })

    return ejes
  }

}