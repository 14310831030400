/*import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { UsuarioMdl } from '../../models/Usuario';
import { iUsuarioPerfil } from '../../models/iUsuarioPerfil';



@Injectable()
export class UsuarioService {

    constructor(private http: HttpClient) { }

    getUsers() {
        return this.http.get(`${URL_API}/usuario`).pipe(map(this.crearLista));
    }

    getUser(id: number) {
        return this.http.get(`${URL_API}/usuario/${id}`)
    }

    updateUser(id: number, usuario: UsuarioMdl) {
        return this.http.put(`${URL_API}/usuario/${id}`, usuario)
    }

    crearUser(usuario: UsuarioMdl) {
        return this.http.post(`${URL_API}/usuario`, usuario)
    }

    getIUsuarioPerfil() {
        return this.http.get(`${URL_API}/iusuarioperfil/`).pipe(map(this.crearListaIUP))
    }

    saveIUsuarioPerfiles(iusuarioperfiles: iUsuarioPerfil[]) {
        return this.http.put(`${URL_API}/iusuarioperfil/`, iusuarioperfiles)
    }

    private crearListaIUP(iusuarioperfilObj: Object) {
        const iusuarioperfiles: iUsuarioPerfil[] = []
        if (iusuarioperfilObj === null) { return [] }

        Object.keys(iusuarioperfilObj).forEach(key => {
            const iusuarioperfil: iUsuarioPerfil = iusuarioperfilObj[key]
            iusuarioperfiles.push(iusuarioperfil)
        })

        return iusuarioperfiles
    }


    private crearLista(userObj: Object) {
        const usuarios: UsuarioMdl[] = []
        if (userObj === null) { return [] }

        Object.keys(userObj).forEach(key => {
            const usuario: UsuarioMdl = userObj[key]
            usuarios.push(usuario)
        })

        return usuarios
    }
}*/


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { UsuarioMdl } from '../../models/Usuario';
import { DependenciaMdl } from '../../models/dependencia.model';

import { URL_API } from '../../globals';

@Injectable()
export class UsuarioService {

  constructor(private http: HttpClient) { }

  getUsuarios(){
    return this.http.get(`${URL_API}/usuario`)
      .pipe(map(this.ListaUsuario))
      
  }

  getDependencia(){
    return this.http.get(`${URL_API}/dependencia`)
      .pipe(map(this.ListaDependencia))
      
  }

  eliminarUsuario(id:number){
    return this.http.delete(`${URL_API}/usuario/${id}`)
  }

  crearUsuario(usuario: UsuarioMdl){
    return this.http.post(`${URL_API}/usuario`, usuario)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return usuario
    }))
  }

  getUsuario(id:number){
    return this.http.get<any>(`${URL_API}/usuario/${id}`)
  }

  actualizarUsuario(usuario: UsuarioMdl){
    const usuarioTemp = {
      ...usuario
    }
    delete usuarioTemp.usuarioId
    return this.http.put(`${URL_API}/usuario/updatePassword/${usuario.usuarioId}`, 
      usuario)
  }

  private ListaUsuario(usuarioObj: Object){
    const usuarios: UsuarioMdl[] = []
    if(usuarioObj === null){ return [] }

    Object.keys(usuarioObj).forEach(key => {
      const usuario: UsuarioMdl = usuarioObj[key]
      usuarios.push(usuario)
    })

    return usuarios
  }

  private ListaDependencia(dependenciaObj: Object){
    const dependencias: DependenciaMdl[] = []
    if(dependenciaObj === null){ return [] }

    Object.keys(dependenciaObj).forEach(key => {
      const dependencia: DependenciaMdl = dependenciaObj[key]
      dependencias.push(dependencia)
    })

    return dependencias
  }

}
