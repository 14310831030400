
import { Component, OnInit } from '@angular/core';
import { VideoService } from '../../../services/service.index';
import { VideoMdl } from '../../../models/video.model';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-videos',
  templateUrl: './listado-video.component.html',
  styleUrls: ['./listado-video.component.css']
})
export class ListadoVideoComponent implements OnInit {
  videos: VideoMdl[] = []
  p: number = 1
  constructor(private service: VideoService) { }

  ngOnInit() {
    this.service.getVideos()
      .subscribe(resp => {
        this.videos = resp
        this.videos = this.videos.filter( b => b.activo == true)
      })
  }

  borrarVideo(video: VideoMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${video.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.videos = this.videos.filter( v => v.videoId !== video.videoId)
        video.activo = false;
        console.log(video)
        this.service.eliminarVideo(
          video.videoId,
          video
          ).subscribe()
      }
    })
  }
  
}
