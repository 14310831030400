import { Component, OnInit,OnDestroy } from '@angular/core';
import { ObjetivosService, SidebarService } from '../../../services/service.index';
import { ObjetivoBloqueMdl } from '../../../models/objetivoBloque.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-objetivos',
  templateUrl: './listado-objetivo.component.html',
  styleUrls: ['./listado-objetivo.component.css']
})
export class ListadoObjetivoComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  objetivos: ObjetivoBloqueMdl[] = []
  p: number = 1
  constructor(private service: ObjetivosService,
     private  sideBarService: SidebarService) { }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
    },
      dom: 'Blfrtip',
    };
    this.service.getObjetivos()
      .subscribe(resp => {
        this.objetivos = resp
        this.objetivos = this.objetivos.filter( b => b.activo == true)
        this.dtTrigger.next();
      })
  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  
  borrarObjetivo(objetivo: ObjetivoBloqueMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${objetivo.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.objetivos = this.objetivos.filter( o => o.objetivobloqueId !== objetivo.objetivobloqueId)
        objetivo.activo = false;
        console.log(objetivo)
        this.service.eliminarObjetivo(
          objetivo.objetivobloqueId,
          objetivo
          ).subscribe()
      }
    })
  }
  
}
