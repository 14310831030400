import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { RespuestaMdl } from '../../../models/respuestaDesagregado.model';
import { PerioricidadMdl } from '../../../models/perioricidad.model';

@Component({
  selector: 'app-listaDinamica',
  templateUrl: './ListaDinamica.component.html',
  styleUrls: ['./ListaDinamica.component.css'],
})

export class listaDinamicaComponent implements OnInit {

  @Input('ObjetoBase') base: any = {};
  @Input('nombre') nombre: string;
  @Input('TipoDato') padre: string;
  @Input('listaDatosDesagregado') datosDesagregado: RespuestaMdl[] = [];
  @Input('listaPeriodicidad') periodicidades: PerioricidadMdl[] = [];
  @Output() public listaDesagregado = new EventEmitter<any>();

  listaObjetos: any[] = [];
  arreglo: any = {};
  arregloOrdenado: any = {};
  showPeriodicity = false

  constructor(
    private _fb: FormBuilder,
  ) {
    console.log('Tipo de Dato: ', this.padre)
  }

  ngOnInit() { 
     
    this.base.Año = {};
    this.base.Año.dato = "";
    this.base.Año.ficha_id = "";
    this.base.Año.lineabase = "";
    this.base.Año.anio = "";
    this.base.Año.opcionDesagregadoId = "";
    this.base.Año.periodicidad_id = '0';
    this.base.Año.observacion = "";

    if(this.periodicidades.length > 0 ){
      this.showPeriodicity = true
    }

    const groupBy = key => array =>
      array.reduce(
        (objectsByKeyValue, obj) => ({
          ...objectsByKeyValue,
          [obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj)
        }),
        {}
      );

    const groupByAño = groupBy('anio');

    if (this.datosDesagregado.length > 0) {
        
      this.arregloOrdenado = groupByAño(this.datosDesagregado);

      Object.keys(this.arregloOrdenado).forEach(key => {

        var elementonuevo = this.AddElementoNuevo();

        this.arregloOrdenado[key].forEach(element => {

          Object.keys(elementonuevo.base).forEach(keyBase => {

            if (elementonuevo.base[keyBase].opcionDesagregadoId == element.opcionDesagregadoId) {
              elementonuevo.base[keyBase] = element;
              elementonuevo.base["Año"] = element;                                                                   
            }
          })
        })

        elementonuevo.forma = this._fb.group(elementonuevo.base);
      
        this.listaObjetos.push(elementonuevo);
      })

      this.EmitirArreglo();
    }
  }

  EmitirArreglo() {
  
    let listatemporal: any[] = []

    this.listaObjetos.forEach(element => { 

      Object.keys(element.base).forEach(key => {
        element.base[key].anio = element.base.Año.dato;
        element.base[key].periodicidad_id = element.base.Año.periodicidad_id;
      })

      listatemporal.push(element.base);
    })
    this.listaDesagregado.emit(listatemporal);
   
  }


  EventoEmiter(item, campo, event) {
    //console.log('En el eventoemitter ', event, '->',campo, ' -> ',item)
    this.listaObjetos[campo].base[item.key].dato = event;
    this.EmitirArreglo();
    // console.log(this.EmitirArreglo())
  }

  EventoEmiterP(item, campo, event) {

    this.listaObjetos[campo].base[item.key].periodicidad_id = Number.parseInt(event);
    this.EmitirArreglo();
  }

  DeleteForma(index) {
    this.listaObjetos.splice(index, 1);
    this.EmitirArreglo();
  }

  AddCampo() { 
    let basetemp: any = {};
    let baseobjeto: any = {};
      
    Object.keys(this.base).forEach(key => {
      baseobjeto[key] = {};
     
        
      Object.keys(this.base[key]).forEach(key2 => {
        baseobjeto[key][key2] = this.base[key][key2];
        baseobjeto[key].ficha_id =null;
      
      })
    })

    // 
    basetemp.base = baseobjeto;
    basetemp.forma = this._fb.group(baseobjeto);
    this.listaObjetos.push(basetemp);
    
  }

  AddElementoNuevo() {
    
    let basetemp: any = {};
    let baseobjeto: any = {};

    Object.keys(this.base).forEach(key => {
      baseobjeto[key] = {};

      Object.keys(this.base[key]).forEach(key2 => {
        baseobjeto[key][key2] = this.base[key][key2];
      })
    })

    basetemp.base = baseobjeto;
    basetemp.forma = this._fb.group(baseobjeto);
   
    return basetemp;
  }

}
