import { BloqueMdl } from './bloque.model';

export class ObjetivoBloqueMdl {
    
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion : string;
    objetivobloqueId: number;
    bloqueId: any

    constructor(){
        
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.objetivobloqueId = null;
        this.nombre = null;
        this.descripcion = null;
        this.bloqueId = null;
       
    }
}