import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { PerfilMdl } from '../../models/Perfil';
import { IPerfilBloqueMdl } from '../../models/iPerfilBloque';

@Injectable()
export class PerfilService {

    constructor(private http: HttpClient) { }
    getPerfil() {
        return this.http.get(`${URL_API}/perfil`).pipe(map(this.crearLista));
    }

    getPerfilId(id: number) {
        return this.http.get<any>(`${URL_API}/perfil/${id}`)
    }

    getPerfilBloque(id: number) {
        return this.http.get<IPerfilBloqueMdl[]>(`${URL_API}/iperfilbloque/perfil/${id}`)
    }

    crearPerfil(perfil: PerfilMdl) {
        return this.http.post(`${URL_API}/perfil`, perfil)
    }

    updatePerfil(id: number, perfil: PerfilMdl) {
        return this.http.put(`${URL_API}/perfil/${id}`, perfil)
    }

    updatePerfilBloque(perfil: IPerfilBloqueMdl[]) {
        return this.http.post(`${URL_API}/iperfilbloque/perfil`, perfil)
    }

    getPerfilesAsignados(id: string) {
        return this.http.get(`${URL_API}/perfil/asignados/${id}`).pipe(map(this.crearLista));
    }

    private crearLista(perfilObj: Object) {
        const perfiles: PerfilMdl[] = []
        if (perfilObj === null) { return [] }

        Object.keys(perfilObj).forEach(key => {
            const perfil: PerfilMdl = perfilObj[key]
            perfiles.push(perfil)
        })

        return perfiles
    }

}
