import { Component, OnInit,OnDestroy,AfterViewInit,ViewChild } from '@angular/core';
import { MetaA2030sService, SidebarService } from '../../../services/service.index';
import { MetaA2030Mdl } from '../../../models/metaa2030.model';
import Swal from 'sweetalert2'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-metas',
  templateUrl: './listado-meta.component.html',
  styleUrls: ['./listado-meta.component.css']
})
export class ListadoMetaComponent implements OnDestroy,OnInit,AfterViewInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  metas: MetaA2030Mdl[] = []
  p: number = 1
  constructor(private service: MetaA2030sService,
    private  sideBarService: SidebarService,private router: Router) { }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
    },
    dom: 'Blfrtip',
    processing: true,
    scrollX: true,
    responsive: true,
    orderCellsTop: true,
    stateSave: true,
    ajax: (dataTablesParameters: any, callback) => {
      this.service.getMetas()
      .subscribe(resp => { 
        this.metas = resp
        this.metas = this.metas.filter( b => b.activo == true)
        // this.dtTrigger.next();     
      callback({
        recordsTotal: resp.length,
        recordsFiltered: resp.length,
        data: resp
      });
    });
    },
    columns: [
      { title: 'Id', data: 'metaa2030Id' },
      { title: 'Nombre Meta', data: 'nombre' },
      { title: 'ODS', data: 'odsId.nombre' },     
      { title: 'Acciones', data: 'metaa2030Id' }
    ],
    rowCallback: (row: Node, data: MetaA2030Mdl, index: number) => {
      const self = this;
      $('#editar', row).off('click');
      $('#editar', row).on('click', () => {
        this.router.navigate(["/meta/" + data.metaa2030Id])
      });

      $('#borrar', row).off('click');
      $('#borrar', row).on('click', () => {
        self.borrarMeta(data, index);

      });
      return row;
    },
    createdRow: (row, data: MetaA2030Mdl, dataIndex) => {  

      if(this.sideBarService.BuscarPermiso('/webresources/metaa2030/{id}', 'Administrador', 'put')==true ){
        $('td', row).eq(3).html(""+
        "<button id='editar' type='button' class='btn btn-success btn-default btn-sm'>" +
          "<i class='fa fa-edit icon-white'></i></button>"
        )        
      }

      if(this.sideBarService.BuscarPermiso('/webresources/metaa2030/{id}', 'Administrador', 'delete')==true ){
        $('td', row).eq(3).append(""+
        "<button id='borrar' type='button' class='btn btn-danger btn-default btn-sm'>" +
        "<i class='fa fa-trash icon-white'></i></button>" 
        )        
      }
    }
    };    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    // this.dtTrigger.subscribe(() => {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input').on('keyup change', function () {
          if (dtInstance.column(this['id']).search() !== this['value']) {
            dtInstance
              .column(this['id'])
              .search(this['value'])
              .draw();
          }

        });
      });
    });
    // });

  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next()   
    });
  }
  borrarMeta(meta: MetaA2030Mdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${meta.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.metas = this.metas.filter( met => met.metaa2030Id !== meta.metaa2030Id)
        meta.activo = false;
        // console.log(meta)
        this.service.eliminarMeta(
          meta.metaa2030Id,
          meta
          ).subscribe()
          this.rerender();
      }
    })
  }
  
}
