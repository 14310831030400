
export class IPerfilBloqueMdl{

    creado:Date;
    creadoPor:number;
    actualizado:Date;
    actualizadoPor:number;
    activo:boolean;
    bloqueId:any;
    perfilId:any;
    iperfilBloqueId:any

        constructor(){
            this.creado = new Date();
            this.creadoPor = null;
            this.actualizado =  new Date();
            this.actualizadoPor = null;
            this.activo = true;
            this.bloqueId = null;
            this.perfilId = null;
            this.iperfilBloqueId = null;
        }
}

