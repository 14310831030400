//import { Usuario } from './Usuario';

export class Menu {
    constructor() {
        this.menuId;
        this.creado = 0; 
        this.creadoPor;
        this.actualizado = 0; 
        this.actualizadoPor;
        this.activo = false;       
        this.nombre= '';
        this.descripcion = '';       
    }
    menuId: number;
    creado: number;
    creadoPor: any;
    actualizado: number;
    actualizadoPor: any;
    activo: boolean;   
    nombre: string;
    descripcion: string;    
}