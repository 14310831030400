export class IndicadorPvdDesagregadoMdl {
    activo:boolean
    actualizado:Date;
    actualizadoPor: number;
    creado: Date;
    creadoPor: number;
    indicadorpvdId:any;
    desagregadoId:any;
    iindicadorpvdDesagregadoId:any;
 
    constructor(){
        this.activo=true;
        this.actualizado=new Date();
        this.creado=new Date();
        this.actualizadoPor=null;
        this.creado=null;
        this.creadoPor=null;
     
        this.indicadorpvdId=null;
        this.desagregadoId=null;
        this.iindicadorpvdDesagregadoId=null;
    }
    
}