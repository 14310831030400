import { Component, OnInit } from '@angular/core';
import { MenuService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms'

import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { MenuItem } from '../../../models/MenuItem';

@Component({
  selector: 'app-menu-item',
  templateUrl: './menu-item.component.html',
  styleUrls: ['./menu-item.component.css']
})
export class MenuItemComponent implements OnInit {
  menuitem: MenuItem = new MenuItem();
  menuitemRoots: MenuItem[] = []
  root: MenuItem
  menuitemlctd: number

  isSave: boolean = true

  constructor(private service: MenuService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    //obtengo el parametro en la ruta GET    
    const id = this.route.snapshot.paramMap.get('id')
    this.service.getMenuItemRoot()
      .subscribe((res => {
        if (id !== 'new') {
          this.menuitemRoots = res.filter(e => e.menuitemId.toString() != id);
        } else {
          this.menuitemRoots = res
          this.menuitemlctd = 1
        }
      }))


    if (id !== 'new') {

      this.isSave = false
      //Editar
      this.service.getMenuitemId(Number(id))
        .subscribe((res: MenuItem) => {
          this.menuitem = res
          if (res.menuPadreId) {
            this.menuitemlctd = res.menuPadreId.menuitemId
            this.root = this.menuitemRoots.find(c => c.menuitemId === this.menuitemlctd)
          }
        })
    }
  }

  guardaMenuItem(form: NgForm) {
    if (form.invalid) {
      //Aquí va la validación del form
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    this.menuitem.activo = true
    this.menuitem.creadoPor = 1
    this.menuitem.actualizado = Date.now()
    this.onRootlctd(this.menuitemlctd)
    this.menuitem.menuPadreId = this.root
    Swal.showLoading()
    if (this.menuitem.menuitemId) {
      this.menuitem.creado = this.menuitem.creado
      this.menuitem.actualizado = Date.now();
      this.menuitem.actualizadoPor = 1; 
      peticion = this.service.updateMenuItem(this.menuitem.menuitemId, this.menuitem)
      console.log(this.menuitem)
    }
    else {
      this.menuitem.creado = Date.now()
      //this.dependencia.actualizado = Date.now()
      console.log(this.menuitem)
      peticion = this.service.crearMenuItem(this.menuitem)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.menuitem.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    })
  }

  onRootlctd(event: any) {      
    this.root = this.menuitemRoots.find(c => c.menuitemId === event)
  }

}
