import { Component, OnInit } from '@angular/core';
import { DerechoItem } from '../../../models/DerechoItem';
import { DerechoService } from '../../../services/shared/derecho.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-derecho-item-listado',
  templateUrl: './derecho-item-listado.component.html',
  styleUrls: ['./derecho-item-listado.component.css']
})
export class DerechoItemListadoComponent implements OnInit {
  derechosItem: DerechoItem[] = []
  p: number = 1
  constructor(private service: DerechoService) { }

  ngOnInit() {
    
  }

  borrarDerechoItem(derechoItem: DerechoItem, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${derechoItem.nombre}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {       
        this.derechosItem = this.derechosItem.filter(d => d.derechoitemId !== derechoItem.derechoitemId)
        derechoItem.activo = false;
        console.log(derechoItem)        
        this.service.actualizarDerechoItem(
          derechoItem.derechoitemId,
          derechoItem
        ).subscribe()
      }
    })
  }
}
