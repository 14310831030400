/*import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { EjecutoraMdl } from '../../models/ejecutora.model';
import { URL_API } from '../../globals';

@Injectable()
export class EjecutorasService {

  constructor(private http: HttpClient) { }

  getEjecutoras(){
    

    return this.http.get(`${URL_API}/ejecutora`)
      .pipe(map(this.crearLista))
      
  }

  eliminarEjecutora(id:number, ejecutora: EjecutoraMdl){
    return this.http.put(`${URL_API}/ejecutora/${id}`, 
    ejecutora)
  }

  crearEjecutora(ejecutora: EjecutoraMdl){
    
    return this.http.post(`${URL_API}/ejecutora`, ejecutora)
    .pipe(map((resp:any)=>{
     // ejecutora.ejecutoraId = resp.ejecutoraId
      return ejecutora
    }))
  }

  getEjecutora(id:number){
    return this.http.get(`${URL_API}/ejecutora/${id}`)
  }

  actualizarEjecutora(ejecutora: EjecutoraMdl){
    const ejecutoraTemp = {
      ...ejecutora
    }
    delete ejecutoraTemp.ejecutoraId
    return this.http.put(`${URL_API}/ejecutora/${ejecutora.ejecutoraId}`, 
    ejecutora)
  }

  private crearLista(ejecutoraObj: Object){
    const ejecutoras: EjecutoraMdl[] = []
    if(ejecutoraObj === null){ return [] }

    Object.keys(ejecutoraObj).forEach(key => {
      const ejecutora: EjecutoraMdl = ejecutoraObj[key]
      ejecutoras.push(ejecutora)
    })

    return ejecutoras
  }

}*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { EjecutoraMdl } from '../../models/ejecutora.model';
import { URL_API } from '../../globals';

@Injectable()
export class EjecutorasService {

  constructor(private http: HttpClient) { }

  getEjecutoras(){
    return this.http.get(`${URL_API}/ejecutora`)
      .pipe(map(this.crearLista))
      
  }

  eliminarEjecutora(id:number, ejecutora: EjecutoraMdl){
    return this.http.put(`${URL_API}/ejecutora/${id}`, 
     ejecutora)
  }

  crearEjecutora(ejecutora: EjecutoraMdl){
    return this.http.post(`${URL_API}/ejecutora`, ejecutora)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return ejecutora
    }))
  }

  getEjecutora(id:number){
    return this.http.get<any>(`${URL_API}/ejecutora/${id}`)
  }

  actualizarEjecutora(ejecutora: EjecutoraMdl){
    const ejecutoraTemp = {
      ...ejecutora
    }
    delete ejecutoraTemp.ejecutoraId
    return this.http.put(`${URL_API}/ejecutora/${ejecutora.ejecutoraId}`, 
      ejecutora)
  }

  private crearLista(ejeObj: Object){
    const ejecutoras: EjecutoraMdl[] = []
    if(ejeObj === null){ return [] }

    Object.keys(ejeObj).forEach(key => {
      const ejecutora: EjecutoraMdl = ejeObj[key]
      ejecutoras.push(ejecutora)
    })

    return ejecutoras
  }

}