export class Meta2030RMdl {
    metaa2030Id: number;
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion: string;
    odsId: any;

    constructor(){

        this.metaa2030Id = null;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.activo = true;
        this.nombre = null;
        this.descripcion = null;
        this.odsId = '';
    }

}