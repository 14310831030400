export class IndicadorA2030RMdl{
    
    indicadora2030Id: number;
    creado:            Date;
    creadoPor:        number;
    actualizado:       Date;
    actualizadoPor:   number;
    activo:            boolean;
    nombre:            string;
    descripcion:       string;
    metaa2030Id:      any;

    constructor(){

        this.indicadora2030Id = null;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.activo = true;
        this.nombre = null;
        this.descripcion = null;
        this.metaa2030Id = '';

    
    }

}