import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { ObjetivoBloqueMdl } from '../../models/objetivoBloque.model';
import { URL_API } from '../../globals';
import { BloqueMdl } from '../../models/bloque.model';
import { MenuItem } from '../../models/MenuItem';
import { Menu } from '../../models/Menu';
import { iMenuMenuItem } from '../../models/imenumenuitem';


@Injectable()
export class MenuService {

    constructor(private http: HttpClient) { }

    getMenu() {
        return this.http.get(`${URL_API}/menu`).pipe(map(this.crearLista));
    }

    getMenuId(id: number) {
        return this.http.get(`${URL_API}/menu/${id}`)
    }

    getMenuItem() {
        return this.http.get(`${URL_API}/menuitem`).pipe(map(this.crearListaItem));
    }

    getMenuitemId(id: number) {
        return this.http.get(`${URL_API}/menuitem/${id}`)
    }

    getMenuItemRoot() {
        return this.http.get(`${URL_API}/menuitem/root`).pipe(map(this.crearListaItem));
    }

    updateMenuItem(id: number, menuitem: MenuItem) {
        return this.http.put(`${URL_API}/menuitem/${id}`,
            menuitem)
    }

    crearMenuItem(menuitem: MenuItem) {
        return this.http.post(`${URL_API}/menuitem`, menuitem)
    }

    crearMenu(menuitem: Menu) {
        return this.http.post(`${URL_API}/menu`, menuitem)
    }

    updateMenu(id: number, menu: Menu) {
        return this.http.put(`${URL_API}/menu/${id}`,
            menu)
    }

    getMenuItemAsignados(id: string) {
        return this.http.get(`${URL_API}/menuitem/asignados/${id}`).pipe(map(this.crearListaItem));
    }

    getiMenuMenuItem() {
        return this.http.get(`${URL_API}/imenumenuitem`).pipe(map(this.crearListaiMenuMenuItem));
    }

    saveiMenuMenuItem(imenumenuitem: iMenuMenuItem[]) { 
        return this.http.put(`${URL_API}/imenumenuitem`, imenumenuitem)
    }

    private crearListaiMenuMenuItem(imenumenuitemObj: Object) {
        const imenumenuitems: iMenuMenuItem[] = []
        if (imenumenuitemObj === null) { return [] }

        Object.keys(imenumenuitemObj).forEach(key => {
            const imenumenuitem: iMenuMenuItem = imenumenuitemObj[key]
            imenumenuitems.push(imenumenuitem)
        })

        return imenumenuitems
    }

    private crearListaItem(menuitemObj: Object) {
        const menuitems: MenuItem[] = []
        if (menuitemObj === null) { return [] }

        Object.keys(menuitemObj).forEach(key => {
            const menuitem: MenuItem = menuitemObj[key]
            menuitems.push(menuitem)
        })

        return menuitems
    }

    private crearLista(menuObj: Object) {
        const menus: Menu[] = []
        if (menuObj === null) { return [] }

        Object.keys(menuObj).forEach(key => {
            const menu: Menu = menuObj[key]
            menus.push(menu)
        })

        return menus
    }
}
