import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms'

//servicios
import {
  SentidoService,
  UnidadMedidaService,
  IndicadorService,
  DesglosadosService,
  FuenteService,
  PerioricidadService,
  DesagregadoService,
  IndicadorPvdDesagregadoService,
  SidebarService,
  BloquesService,
  DependenciasService,
  UsuarioService
} from '../../../services/service.index';

//modelos
import { EjepvdMdl } from '../../../models/ejepvd.model';
import { IndicadorPvdMdl } from '../../../models/indicadorPvd.model';
import { SentidoMdl } from '../../../models/sentido.model';
import { PerioricidadMdl } from '../../../models/perioricidad.model';
import { UnidadMedidaMdl } from '../../../models/unidadMedida.model';
import { DesglosadosMdl } from '../../../models/desglosados.model';
import { FuenteMdl } from '../../../models/fuente.model';
import { EstadoMdl } from '../../../models/estado.model';
import { DesagregadoMdl } from '../../../models/desagregado.model';
import { IndicadorPvdDesagregadoMdl } from '../../../models/indicadorPvdDesagregado.model';
import { BloqueMdl } from '../../../models/bloque.model';
import { DependenciaMdl } from '../../../models/dependencia.model';
import { UsuarioMdl } from '../../../models/Usuario';

@Component({
  selector: 'app-indicadorPvd',
  templateUrl: './indicadorPvd.component.html',
  styleUrls: ['./indicadorPvd.component.css']
})
export class IndicadorPvdComponent implements OnInit {

  // arreglos que se utilizan dentro del indicador
  ejepvd: EjepvdMdl = new EjepvdMdl()

  sentido: SentidoMdl = new SentidoMdl();
  sentidos: SentidoMdl[] = [];

  perioricidad: PerioricidadMdl = new PerioricidadMdl();
  perioricidades: PerioricidadMdl[] = [];

  unidadMedida: UnidadMedidaMdl = new UnidadMedidaMdl();
  unidadadesMedida: UnidadMedidaMdl[] = [];

  desglosado: DesglosadosMdl = new DesglosadosMdl();
  desglosados: DesglosadosMdl[] = [];

  fuente: FuenteMdl = new FuenteMdl();
  fuentes: FuenteMdl[] = [];

  estado: EstadoMdl = new EstadoMdl();
  estados: EstadoMdl[] = [];

  desagregado: DesagregadoMdl = new DesagregadoMdl();
  desagregados: DesagregadoMdl[] = [];

  bloque: BloqueMdl = new BloqueMdl();
  bloques: BloqueMdl[] = [];


  dependencia: DependenciaMdl = new DependenciaMdl();
  dependencias: DependenciaMdl[] = [];


  indicador: IndicadorPvdMdl = new IndicadorPvdMdl();

  // arreglos que se utilizan para los desagregados seleccionados o disponibles
  indicadorPvdDesagregadosDisponibles: IndicadorPvdDesagregadoMdl[] = [];
  indicadorPvdDesagregadosSeleccionados: IndicadorPvdDesagregadoMdl[] = [];

  formGroup: FormGroup;
  TabIndex: number = 0; // se usa para pasar entre desagregados y indicador
  TipoFormulario: any = "";

  get f() { return this.formGroup.controls; } // para usar a las propiedades del formulario

  isSave: boolean = true
  user: any = [];
  usuario: UsuarioMdl;
  dependenciaBool: boolean = false;

  calculo:number;
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private sentidoService: SentidoService,
    private unidaMedidaService: UnidadMedidaService,
    private perioricidadService: PerioricidadService,
    private indicadorService: IndicadorService,
    private desglosadosService: DesglosadosService,
    private fuenteService: FuenteService,
    private desagregadoService: DesagregadoService,
    private indicadorPvdDesagregadoService: IndicadorPvdDesagregadoService,
    private sideBarService: SidebarService,
    private bloqueService: BloquesService,
    private dependenciaService: DependenciasService,
    private usuarioService: UsuarioService,



  ) {
    // crea el formulario apartir del desagregado
    this.formGroup = this._formbuilder.group(this.indicador);
    // se asigna la validacion del año del 1900 al 2100
    this.formGroup.setControl('aniolineabase', new FormControl("", [Validators.required, Validators.min(1900), Validators.max(2100)]));
  }

  ngOnInit() {

    this.user = this.sideBarService.getCurrentUser();

    this.usuarioService.getUsuario(Number(this.user.usuario_id))
      .subscribe((resp: UsuarioMdl) => {
        this.usuario = resp;
      })

    // se hace una cascada para que antes de realizar un cambio se carguen todos los datos donde interviene el indicador
    this.desagregadoService.getDesagregados()
      .subscribe((res => {
        this.desagregados = res

        this.sentidoService.getSentidos()
          .subscribe((res => {
            this.sentidos = res

            this.perioricidadService.getPerioricidades()
              .subscribe((res => {
                this.perioricidades = res

                this.unidaMedidaService.getUnidadesMedida()
                  .subscribe((res => {
                    this.unidadadesMedida = res

                    this.desglosadosService.getDesglosados()
                      .subscribe((res => {
                        this.desglosados = res

                        this.fuenteService.getFuentes()
                          .subscribe((res => {
                            this.fuentes = res

                            this.bloqueService.getBloques()
                              .subscribe((res => {
                                this.bloques = res

                                this.dependenciaService.getDependencias()
                                  .subscribe((res => {
                                    this.dependencias = res


                                    //obtengo el parametro en la ruta GET
                                    const id = this.route.snapshot.paramMap.get('id')

                                    // evaluá  si es edicion o nuevo indicador
                                    this.TipoFormulario = id;
                                    if (id !== 'new') {
                                      this.isSave = false
                                      //Editar

                                      // busca los datos del indicador
                                      this.indicadorService.getIndicador(id)
                                        .subscribe(resp => { console.log(resp)
                                          this.indicador = resp;  
                                          this.indicador.fechaActualizacion = new Date(resp.fechaActualizacion);

                                          // se asigna la dependencia como solo ID para evitar conflictos con el servicio de guardado
                                          //  this.indicador.dependenciaId = resp.dependenciaId.dependenciaId;

                                          // desde el servicio existe una propiedad llamada desagregadoOut la cual guarda los desagregados y sus hijos
                                          // solo se reasigna para que los muestre desde el desagregadoId
                                          this.indicador.indicadordesagregados.forEach(element => {
                                            element.desagregadoId = element.desagregadoOut;
                                          });

                                          // método que evaluar que desagregados tiene el indicador asignados
                                          this.ListaIndicadorDesagregado();

                                          // se asignan los Ids de cada parámetro para que sea mas sencillo enviar y guardar el desagregado
                                          try {
                                            this.indicador.periodicidadId = resp.periodicidadId.periodicidadId;
                                          }
                                          catch{
                                          }

                                          try {
                                            this.indicador.unidadmedidaId = resp.unidadmedidaId.unidadmedidaId;
                                          }
                                          catch{
                                          }

                                          try {
                                            this.indicador.sentidoId = resp.sentidoId.sentidoId;
                                          }
                                          catch{
                                          }

                                          try {
                                            this.indicador.desglosadosId = resp.desglosadosId.desglosadosId;
                                          }
                                          catch{
                                          }

                                          try {
                                            this.indicador.fuenteId = resp.fuenteId.fuenteId;
                                          }
                                          catch{
                                          }

                                          try {
                                            this.indicador.bloqueId = resp.bloqueId.bloqueId;
                                          }
                                          catch{
                                            this.indicador.bloqueId = null;
                                          }

                                          try {
                                            this.indicador.dependenciaId = resp.dependenciaId.dependenciaId;
                                          }
                                          catch{
                                            this.indicador.dependenciaId = null;
                                          }
                                          // una vez terminado el indicador se crea el formgroup



                                          this.formGroup = this._formbuilder.group(this.indicador);
                                          // se vuelve a asignar la validacion por que se pierde con la asignacion anterior
                                          this.formGroup.setControl("aniolineabase", new FormControl(this.indicador.aniolineabase, [Validators.required, Validators.min(1900), Validators.max(2100)]));

                                          this.calculo= (Number (this.indicador.proyeccion2024) - Number(this.indicador.lineabase) ) /( Number(this.indicador.ultimoValor)- Number(this.indicador.lineabase) ) * 100
                                          this.formGroup.controls.avance.setValue(this.calculo.toFixed(2))

                                          if (this.sideBarService.isAdmin()) {
                                            // console.log("admin")
                                          }
                                          else {
                                            // console.log("user")
                                            // debugger
                                            this.dependenciaBool = true;
                                            // this.formGroup.controls.dependenciaId.disable();
                                            this.asignaDependencia(Number.parseInt(this.indicador.dependenciaId));

                                          }
                                        })
                                    }
                                    else {
                                      // se asigna la lista de desagregados disponibles con este metodo
                                      this.ListaIndicadorDesagregado();

                                      if (this.sideBarService.isAdmin()) {
                                        console.log("admin")
                                        this.indicador.dependenciaId = Number.parseInt(this.user.dependencia_id);
                                        this.formGroup.controls.dependenciaId.setValue(this.indicador.dependenciaId);
                                      }
                                      else {
                                        console.log("user")
                                        // debugger
                                        this.indicador.dependenciaId = Number.parseInt(this.user.dependencia_id);
                                        this.formGroup.controls.dependenciaId.setValue(this.indicador.dependenciaId);
                                        this.asignaDependencia(Number.parseInt(this.user.dependencia_id));
                                        //   this.formGroup.controls.dependenciaId.disable();
                                      }

                                    }



                                  }))
                              }))
                          }))
                      }))
                  }))
              }))
          }))
      }))

  }

  calculaAvance(){
    // this.calculo= (Number (this.indicador.proyeccion2024) - Number(this.indicador.lineabase) ) /( Number(this.indicador.ultimoValor)- Number(this.indicador.lineabase) ) * 100
    this.calculo=(Number (this.formGroup.value.proyeccion2024) - Number(this.formGroup.value.lineabase) ) /( Number(this.formGroup.value.ultimoValor)- Number(this.formGroup.value.lineabase) ) * 100
    this.formGroup.controls.avance.setValue(this.calculo.toFixed(2))
    
  }

  asignaDependencia(id: number) {
    // debugger
    let lista: DependenciaMdl[] = [];
    this.dependencias.forEach(element => {
      if (element.dependenciaId == id) {
        lista.push(element);
      }
    })

    this.dependencias = lista;
  }

  ListaIndicadorDesagregado() { // se recorre los desagregados disponibles en la base de datos
    this.desagregados.forEach(desagregado => {
      // por cada desagregado se crea un IndicadorPvdDesagregado para su guardado en el servicio
      var indicadorDesagregado: IndicadorPvdDesagregadoMdl = new IndicadorPvdDesagregadoMdl();
      indicadorDesagregado.desagregadoId = desagregado;
      indicadorDesagregado.actualizadoPor = 1;
      indicadorDesagregado.creadoPor = 1;
      indicadorDesagregado.creado = new Date();

      // busca si el desagregado existe dentro de los desagregados que tenga el indcador asignado
      var index = this.indicador.indicadordesagregados.find(desagregado => desagregado.desagregadoOut.desagregadoId == indicadorDesagregado.desagregadoId.desagregadoId)

      if (index) {
        // si existe se le asigna el desagregado
        index.desagregadoId.desagregados = desagregado.desagregados;

        // si existe y esta activo se agrega a los seleccinados
        if (index.activo) {
          this.indicadorPvdDesagregadosSeleccionados.push(index);
        } else {
          // si existe pero no esta activo se agrega a los disponibles
          this.indicadorPvdDesagregadosDisponibles.push(index);
        }

      }
      else {
        // si el desagregado no esta asignado al indicador, se agrega a los disponibles
        this.indicadorPvdDesagregadosDisponibles.push(indicadorDesagregado);
      }

    });

  }

  MoverSeleccionados(evento: any) {
    // cambia de la lista de disponibles a seleccinados
    var lista = evento.items;
    lista.forEach(element => {
      element.activo = true;
    });

  }

  MoverDisponibles(evento: any) {
    // cambia de la lista de seleccinados  a disponibles
    var lista = evento.items;
    lista.forEach(element => {
      element.activo = false;
    });

  }

  MoverTodosSeleccionados(evento: any) {
    // cambia de la lista de disponibles a seleccinados a todos
    var lista = evento.items;
    lista.forEach(element => {
      element.activo = true;
    });
  }

  MoverTodosDisponibles(evento: any) {
    // cambia de la lista de seleccinados  a disponibles a todos
    var lista = evento.items;
    lista.forEach(element => {
      element.activo = false;
    });

  }

  TipoValor(value) {
    // sirve para devolver el tipo de valor del los desagregados
    if (value == "Number") {
      return "Números";
    }
    else if (value == "String") {
      return "Letras y Números";
    }
    else if (value == "Date") {
      return "Fecha";
    }
    else {
      return "Vació";
    }
  }


  guardarIndicador() {

    if (this.formGroup.invalid) {
      //validaciones de la forma 
      this.TabIndex = 0;
      Swal.fire({
        title: "Errores en el formulario",
        text: 'Por favor valide la información ',
        type: 'error',
      })
      return
    }

    if (this.indicadorPvdDesagregadosSeleccionados.length == 0) {
      // al menos debe tener 1 desagregado seleccionado
      this.TabIndex = 1;
      Swal.fire({
        title: "Sin desagregados seleccionados",
        text: 'por favor selecciona al menos 1 desagregado para el indicador',
        type: 'error',
      })
      return
    }

    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    // debugger
    // se asignan valores al indicador
    this.indicador = this.formGroup.value;
    this.indicador.activo = true;
    this.indicador.creadoPor = 1;
    this.indicador.actualizadoPor = 1;
    this.indicador.actualizado = new Date();
    //  this.indicador.dependenciaId = 3;  // dependencia estática para inserción
    //  this.indicador.fechaActualizacion = new Date(this.indicador.fechaActualizacion);

    Swal.showLoading()
    // debugger
    if (this.indicador.indicadorpvdId) {

      // si el indicador ya existe

      var opcionesIndicadorDesagregadoTemp: IndicadorPvdDesagregadoMdl[] = [];
      // se crea un arreglo, se asigna el indicador a cada una de los IndicadorPvdDesagregados
      this.indicadorPvdDesagregadosSeleccionados.forEach(key => {
        key.indicadorpvdId = this.indicador.indicadorpvdId;
        opcionesIndicadorDesagregadoTemp.push(key);
      })

      // se agregan tambien los IndicadorPvdDesagregados que existan pero que esten desactivados
      this.indicadorPvdDesagregadosDisponibles.forEach(key => {
        if (key.iindicadorpvdDesagregadoId) {
          opcionesIndicadorDesagregadoTemp.push(key);
        }
      })

      // se crea un IndicadorPvdDesagregados que sirve para actualizar los campos del desagregado
      var temp: IndicadorPvdDesagregadoMdl = new IndicadorPvdDesagregadoMdl();
      temp.iindicadorpvdDesagregadoId = 0;
      temp.indicadorpvdId = this.indicador;
      temp.indicadorpvdId.indicadordesagregados = null; // se hace null para evitar conflictos con el servicio
      temp.creado = new Date();
      temp.creadoPor = 1;
      temp.actualizadoPor = 1;
      opcionesIndicadorDesagregadoTemp.push(temp);

      peticion = this.indicadorPvdDesagregadoService.EditIndicadorPvdDesagregado(opcionesIndicadorDesagregadoTemp)
    }
    else {
      // si el indicador es nuevo, se asignan

      this.indicador.creado = new Date();
      this.indicador.actualizado = new Date();
      this.indicador.creadoPor = 1;
      this.indicador.actualizadoPor = 1;

      // por cada seleccionado se asigna el indicador
      this.indicadorPvdDesagregadosSeleccionados.forEach(indicadorDesagregado => {
        indicadorDesagregado.indicadorpvdId = this.indicador;
      });

      peticion = this.indicadorPvdDesagregadoService.NuevosIndicadorPvdDesagregado(this.indicadorPvdDesagregadosSeleccionados);

    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.indicador.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.indicador.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }

    )
  }
}

