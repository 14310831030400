import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { FuenteMdl } from '../../models/fuente.model';
import { URL_API } from '../../globals';

@Injectable()
export class FuenteService {

  constructor(private http: HttpClient) { }

  getFuentes(){
    return this.http.get(`${URL_API}/fuente`)
      .pipe(map(this.crearLista))
  }

  eliminarFuente(id:number, fuentes: FuenteMdl){
    return this.http.delete(`${URL_API}/fuente/${id}`)
  }

  crearFuente(fuentes: FuenteMdl){
    return this.http.post(`${URL_API}/fuente`, fuentes)
    .pipe(map((resp) => {
      return resp
    }))
  }

  getFuente(id:string){
    return this.http.get<any>(`${URL_API}/fuente/${id}`)
  }

  actualizarFuente(fuentes: FuenteMdl){
    const fuentesTemp = {
      ...fuentes
    }
    delete fuentesTemp.fuenteId
    return this.http.put(`${URL_API}/fuente/${fuentes.fuenteId}`, 
    fuentes)
  }

  private crearLista(fuentesObj: Object){
    const Fuentes: FuenteMdl[] = []
    if(fuentesObj === null){ return [] }

    Object.keys(fuentesObj).forEach(key => {
      const desglosado: FuenteMdl = fuentesObj[key]
      Fuentes.push(desglosado)
    })

    return Fuentes
  }

}