import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { InversionService, SidebarService,ImagenInversionService } from '../../../services/service.index';

import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ProyectoInversionMdl } from '../../../models/proyectoinv.model';
import { ImgProyInvMdl } from '../../../models/ImgProy.model';
import { RegionMdl } from '../../../models/region.model';
import { MunicipioMdl } from '../../../models/municipio.model';
import { LocalidadMdl } from '../../../models/localidad.model';
import { FondoFinanciamientoMdl } from '../../../models/fondofinanciamiento.model';
import { EstatusProyectoMdl } from '../../../models/estatusproyecto.model';
import { EjecutoraMdl } from '../../../models/ejecutora.model';
import { OdsMdl } from '../../../models/ods.model';
import { BloqueMdl } from '../../../models/bloque.model';


@Component({
  selector: 'app-inversion',
  templateUrl: './proinv.component.html',
  //styles: './proinv.component.css'
   styleUrls: ['./proinv.component.css']
})


export class InversionComponent implements OnInit {


  invers: ProyectoInversionMdl = new ProyectoInversionMdl()

  imagenes: ImgProyInvMdl[] = [];
  tipoImg = ['Antes', 'Despues']
  tipoImagen:any=''
  ComImg: ImgProyInvMdl = new ImgProyInvMdl()

  regi: RegionMdl = new RegionMdl()
  regis: RegionMdl[] = []
  regislctd: number = 0;

  muni: MunicipioMdl = new MunicipioMdl()
  munis: MunicipioMdl[] = []
  munislctd: number = 0;

  loca: LocalidadMdl = new LocalidadMdl()
  locas: LocalidadMdl[] = []
  locaslctd: number

  fondo: FondoFinanciamientoMdl = new FondoFinanciamientoMdl()
  fondos: FondoFinanciamientoMdl[] = []
  fondoslctd: number;

  estatu: EstatusProyectoMdl = new EstatusProyectoMdl()
  estatus: EstatusProyectoMdl[] = []
  estatuslctd: number

  ejecutora: EjecutoraMdl = new EjecutoraMdl()
  ejecutoras: EjecutoraMdl[] = []
  ejecutoraslctd: number

  Ods: OdsMdl = new OdsMdl()
  OdSS: OdsMdl[] = []
  Odslctd: number

  bloque: BloqueMdl = new BloqueMdl()
  bloques: BloqueMdl[] = []
  bloqueslctd: number

  dropdownODSettings: any = {}
  selectedODS: OdsMdl[] = [];
  odsRelacionadolist: OdsMdl[] = []

  dropdownBloqueSettings: any = {}
  selectedBloque: BloqueMdl[] = [];
  bloqueRelacionadolist: BloqueMdl[] = []

  get f() { return this.inversionGroupForm.controls; }
  isSave: boolean = true

  //*****************VALIDAR*******************************************
  createFormGroup() {
    return new FormGroup({
      localidadId: new FormControl('', Validators.required),
      odsId: new FormControl('', Validators.required),
      fondofinanciamientoId: new FormControl('', Validators.required),
      estatusproyectoId: new FormControl('', Validators.required),
      ejecutoraId: new FormControl('', Validators.required),
      nombre: new FormControl('', Validators.required),
      monto: new FormControl('', Validators.required),
      beneficiario: new FormControl('', Validators.required),
      numeroProyecto: new FormControl('', Validators.required),
      bloqueId: new FormControl('', Validators.required),
      fechaInicio: new FormControl('', Validators.required),
      fechaTermino: new FormControl('', Validators.required),
      fechaCorte: new FormControl('', Validators.required),
      // odsRelacionadoSlct: new FormControl(''),
      rezagoId: new FormControl('1'),
      aFiscal: new FormControl('', Validators.required),
      /* descripcion: new FormControl('', Validators.required) */


    });
  }
  //*****************VALIDAR*******************************************



  inversionGroupForm: FormGroup;
  inversionGroup: FormGroup;




  inversion: ProyectoInversionMdl[] = []
  region: RegionMdl[] = []
  municipio: MunicipioMdl[] = []
  localidad: LocalidadMdl[] = []

  constructor(private service: InversionService,
    private serviceImg: ImagenInversionService,
    private route: ActivatedRoute,
    private _formbuilder: FormBuilder,
    private sideBarService: SidebarService) {
    this.inversionGroupForm = _formbuilder.group(this.invers);
    this.inversionGroup = new FormGroup({
      bloqueId: new FormControl('', Validators.required),
      odsId: new FormControl('', Validators.required),

    });

    //*****************VALIDAR*******************************************
    // this.inversionGroupForm = this.createFormGroup();
    //*****************VALIDAR*******************************************
  }


  ngOnInit() {

    this.service.getRegion()
      .subscribe(res => {
        this.region = res
      })

    this.service.getFondo()
      .subscribe(res => {
        this.fondos = res
      })

    this.service.getEstatus()
      .subscribe(res => {
        this.estatus = res
      })

    this.service.getEjecutora()
      .subscribe(res => {
        this.ejecutoras = res
      })



    this.dropdownODSettings = {
      singleSelection: false,
      idField: 'odsId',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    this.dropdownBloqueSettings = {
      singleSelection: false,
      idField: 'bloqueId',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }


    //Obtengo el parametro de la ruta get
    const id = this.route.snapshot.paramMap.get('id')

    this.service.getOds()
      .subscribe(res => {
        this.OdSS = res

        this.service.getBloque()
          .subscribe(res => {
            this.bloques = res

            if (id !== 'new') {
              this.isSave = false

              //Editar
              this.service.getInv(Number(id)).subscribe((res: ProyectoInversionMdl) => {
                this.invers = res


                let odsidsTemp: string = res.odsId.toString();
                let bloqueTemp: string = res.bloqueId.toString();

                //this.invers.odsId = JSON.parse(res.odsId);
                this.regislctd = this.invers.localidadId.municipioId.regionId.regionId;
                this.regi = this.regis.find(r => r.regionId === this.regislctd)
                this.muni = this.munis.find(m => m.municipioId === this.munislctd)
                // this.loca = this.locas.find(l => l.localidadId === this.locaslctd)
                this.invers.fechaInicio = new Date(res.fechaInicio);
                this.invers.fechaCorte = new Date(res.fechaCorte);
                this.invers.fechaTermino = new Date(res.fechaTermino);

                this.fondoslctd = res.fondofinanciamientoId.fondofinanciamientoId
                this.fondo = this.fondos.find(f => f.fondofinanciamientoId === this.fondoslctd)
                this.invers.fondofinanciamientoId = this.invers.fondofinanciamientoId.fondofinanciamientoId;

                this.ejecutoraslctd = res.ejecutoraId.ejecutoraId
                this.ejecutora = this.ejecutoras.find(ej => ej.ejecutoraId === this.ejecutoraslctd)
                this.invers.ejecutoraId = this.invers.ejecutoraId.ejecutoraId;

                this.estatuslctd = res.estatusproyectoId.estatusproyectoId
                this.estatu = this.estatus.find(e => e.estatusproyectoId === this.estatuslctd)
                this.invers.estatusproyectoId = this.invers.estatusproyectoId.estatusproyectoId;

                this.invers.descripcion = res.descripcion

                this.invers.proyectoinversionId = res.proyectoinversionId

                // this.bloqueslctd = res.bloqueId.bloqueId
                //    this.bloque = this.bloques.find(b => b.bloqueId === this.bloqueslctd)
                //  this.invers.bloqueId = this.invers.bloqueId.bloqueId;

                //  this.Odslctd = res.odsId.odsId
                //  this.Ods = this.OdSS.find(o => o.odsId === this.Odslctd)
                //  this.invers.odsId = this.invers.odsId.odsId;


                this.service.getMunicipio(this.regislctd)
                  .subscribe(res => {
                    this.municipio = res
                    this.munislctd = this.invers.localidadId.municipioId.municipioId;


                    this.service.getLocalidad(this.munislctd)
                      .subscribe(res => {
                        this.localidad = res

                        this.locaslctd = this.invers.localidadId.localidadId;
                        this.loca = this.locas.find(l => l.localidadId == this.locaslctd)
                        this.invers.localidadId = this.invers.localidadId.localidadId;

                        this.serviceImg.getImagenes(this.invers.proyectoinversionId).
                        subscribe(resImg => {
                          this.imagenes = resImg

                          // console.log(this.imagenes)

                     //   let tempOds = odsidsTemp.split(",");
                     let tempOds = JSON.parse(odsidsTemp);

                        this.OdSS.forEach(ods => {
                          tempOds.forEach(ids => {
                            if (ods.odsId.toString() == ids) {
                              this.selectedODS.push(ods);
                            }
                          })
                        });

                       // let tempBloque = bloqueTemp.split(",");
                        let tempBloque = JSON.parse(bloqueTemp);

                        this.bloques.forEach(bloque => {
                          tempBloque.forEach(ids => {
                            if (bloque.bloqueId.toString() == ids) {
                              this.selectedBloque.push(bloque);
                            }
                          })
                        });

                        this.invers.bloqueId = this.selectedBloque;
                        this.invers.odsId = this.selectedODS;

                        // this.invers.bloqueId=[];
                        this.inversionGroupForm = this._formbuilder.group(this.invers);

                        //    this.inversionGroupForm.controls.odsId.setValue(this.selectedODS);
                        //   this.inversionGroupForm.controls.bloqueId.setValue(this.selectedBloque);

                        // this.onODSSelect("actualiza");

                      }) // imagenes

                      })
                  })

              })
            }


          })
      })




  }



  guardarInvers() {
    //debugger;

    if (this.inversionGroupForm.invalid) {
      console.log('Form no valido')

      return
    }


    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })


    let odsId = this.invers.odsId;
    let bloqueId = this.invers.bloqueId;


    this.invers = this.inversionGroupForm.value;

    this.invers.bloqueId= bloqueId;
    this.invers.odsId= odsId;

    this.invers.activo = true;
    this.invers.creadoPor = 1;
    this.invers.actualizado = new Date();
    this.invers.actualizadoPor = 1;
    this.invers.fechaInicio = new Date(this.invers.fechaInicio);
    this.invers.fechaCorte = new Date(this.invers.fechaCorte);
    this.invers.fechaTermino = new Date(this.invers.fechaTermino);


    this.invers.beneficiario = Number(this.invers.beneficiario);
    this.invers.fondofinanciamientoId = Number(this.invers.fondofinanciamientoId);
    this.invers.localidadId = Number(this.invers.localidadId);
    this.invers.monto = Number(this.invers.monto);
    this.invers.numeroProyecto = Number(this.invers.numeroProyecto);
    this.invers.ejecutoraId = Number(this.invers.ejecutoraId);
    this.invers.estatusproyectoId = Number(this.invers.estatusproyectoId);
    this.invers.rezagoId = Number(this.invers.rezagoId);
    this.invers.rezagoId = Number(1);
    //ejercicio fiscal
    this.invers.afiscal = Number(this.invers.afiscal);

     this.invers.descripcion = this.invers.descripcion;

    //this.invers.bloqueId = Number(this.invers.bloqueId);
    // this.invers.odsId = Number(this.invers.odsId);

    let proyectoinvTem:any=this.invers;

    let odsIds = Array();
    let bloqueIds = Array();


    this.invers.odsId.forEach(ods => {
      odsIds.push(ods.odsId.toString())
    })

    this.invers.bloqueId.forEach(bloque => {
      bloqueIds.push(bloque.bloqueId.toString())
    })

   // proyectoinvTem.odsId="["+odsIds.toString()+"]";
  //  proyectoinvTem.bloqueId="["+bloqueIds.toString()+"]";

    proyectoinvTem.odsId=JSON.stringify(odsIds);
    proyectoinvTem.bloqueId=JSON.stringify(bloqueIds);

    Swal.showLoading()

    if (this.invers.proyectoinversionId) {
      this.invers.creado = this.invers.creado
      peticion = this.service.actualizarInversion(proyectoinvTem)

      /* console.log(this.invers) */
    }

    else {
      this.invers.creado = new Date()
      // console.log(this.invers)
      peticion = this.service.crearInversion(proyectoinvTem)

    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.invers.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    },
      error => {

        if (error) {

        }

        Swal.fire({
          title: this.invers.nombre +' " '+this.invers.afiscal + '"',
          text: 'error',
          type: 'error',
        })
      },


    )
  }// guardarInvers

  //*****************VALIDAR*******************************************
  get localidadId() { return this.inversionGroupForm.get('localidadId'); }
  get fondofinanciamientoId() { return this.inversionGroupForm.get('fondofinanciamientoId'); }
  get estatusproyectoId() { return this.inversionGroupForm.get('estatusproyectoId'); }
  get ejecutoraId() { return this.inversionGroupForm.get('ejecutoraId'); }
  get nombre() { return this.inversionGroupForm.get('nombre'); }
  get monto() { return this.inversionGroupForm.get('monto'); }
  get beneficiario() { return this.inversionGroupForm.get('beneficiario'); }
  get numeroProyecto() { return this.inversionGroupForm.get('numeroProyecto'); }
  get bloqueId() { return this.inversionGroupForm.get('bloqueId'); }
  get odsId() { return this.inversionGroupForm.get('odsId'); }
  get fechaInicio() { return this.inversionGroupForm.get('fechaInicio'); }
  get fechaTermino() { return this.inversionGroupForm.get('fechaTermino'); }
  get fechaCorte() { return this.inversionGroupForm.get('fechaCorte'); }
get afiscal() { return this.inversionGroupForm.get('afiscal'); }
get descripcion() { return this.descripcion.get('afiscal'); }




  //*****************VALIDAR*******************************************




  onRegionSlctd(event: any) {
    this.regi = this.regis.find(r => r.regionId === event)
  }


  onMunicipioSlctd(event: any) {
    this.muni = this.munis.find(m => m.municipioId === event)
  }

  onLocalidadSlctd(event: any) {
    this.loca = this.locas.find(l => l.localidadId === event)
  }

  onFondoSlctd(event: any) {
    this.fondo = this.fondos.find(f => f.fondofinanciamientoId === event)
  }

  onEstatuSlctd(event: any) {
    this.estatu = this.estatus.find(e => e.estatusproyectoId === event)
  }

  onEjecutoraSlctd(event: any) {
    this.ejecutora = this.ejecutoras.find(e => e.ejecutoraId === event)
  }

  onBloqueSlctd(event: any) {
    this.bloque = this.bloques.find(b => b.bloqueId === event)

  }


  onSelectMun(idMun: number) {
    this.service.getMunicipio(idMun)
      .subscribe(res => {
        this.municipio = res
      })
  }

  onSelectLoc(idLoc: number) {
    this.service.getLocalidad(idLoc)
      .subscribe(res => {
        this.localidad = res
      })
  }

  onODSSelect(e: any) {
   // const idsOds = this.inversionGroupForm.controls.odsId.value.map(ods => ods.odsId);

    // this.inversionGroupForm.get('odsRelacionadoSlct').setValue([]);


    //this.odsRelacionadolist = this.OdSS.filter((item: OdsMdl) => {
    //   if (!idsOds.includes(item.odsId))
    //    return item
    //  });

   // this.inversionGroupForm.controls.odsId.setValue(this.selectedODS);


  }

  onBloqueSelect(e: any) {
   // const idbloque = this.inversionGroupForm.controls.bloqueId.value.map(b => b.bloqueId);


  }

  borrarImagen(imgen: ImgProyInvMdl,  i:number){

    // console.log(imgen)
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${imgen.nombreArchivoOriginal}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){

        this.imagenes = this.imagenes.filter( img => img.imagenesProyectoinversionId !== imgen.imagenesProyectoinversionId)
        imgen.activo = false;

        this.serviceImg.eliminarImagen(
          imgen.imagenesProyectoinversionId,
          ).subscribe()
      }
    })
  }

  onTipoFoto(event: any,imagen:ImgProyInvMdl){
    this.ComImg=imagen
    if(imagen.imagenesProyectoinversionId){
      this.ComImg.notaFoto=event.value;
   this.serviceImg.actualizarImagen(this.ComImg).subscribe()
    }
  }

  /* guardarInversion() {
    console.log('Guardar')
  } */
}
