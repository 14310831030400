import { AfterViewInit, Component, OnInit, ViewChild, OnDestroy, Renderer2, ElementRef } from '@angular/core';
import { FuenteService, SidebarService } from '../../../services/service.index';
import Swal from 'sweetalert2'
import { FuenteMdl } from '../../../models/fuente.model';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { Router } from '@angular/router';



@Component({
  selector: 'app-fuentes',
  templateUrl: './listado-fuente.component.html',
  styleUrls: ['./listado-fuente.component.css']
})
export class ListadoFuenteComponent implements OnDestroy, OnInit, AfterViewInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })

  datatableElement: DataTableDirective;
  fuentes: FuenteMdl[] = []
  p: number = 1
  constructor(private service: FuenteService,
    private sideBarService: SidebarService,
    private router: Router
  ) { }

  ngOnInit() {

    //----
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
      },
      dom: 'Blfrtip',
      processing: true,
      scrollX: true,
      responsive: true,
      orderCellsTop: true,
      stateSave: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.service.getFuentes()
          .subscribe(resp => {
            this.fuentes = resp
            // this.dtTrigger.next();
            callback({
              recordsTotal: resp.length,
              recordsFiltered: resp.length,
              data: resp
            });
          })
      },
      columns: [
        { title: 'Id', data: 'fuenteId' },
        { title: 'Descripcion', data: 'descripcion' },
        { title: 'Acciones', data: 'fuenteId' }
      ],
      rowCallback: (row: Node, data: FuenteMdl, index: number) => {
        const self = this;
        $('#editar', row).off('click');
        $('#editar', row).on('click', () => {
          this.router.navigate(["/fuente/" + data.fuenteId])
        });

        $('#borrar', row).off('click');
        $('#borrar', row).on('click', () => {
          self.borrarFuente(data, index);

        });
        return row;
      },
      createdRow: (row, data: FuenteMdl, dataIndex) => {
        if (this.sideBarService.BuscarPermiso('/webresources/fuente/{id}', 'Administrador', 'put') == true) {
          $('td', row).eq(2).html("" +
            "<button id='editar' type='button' class='btn btn-success btn-default btn-sm'>" +
            "<i class='fa fa-edit icon-white'></i></button>"
          )
        }
        if (this.sideBarService.BuscarPermiso('/webresources/fuente/{id}', 'Administrador', 'delete') == true) {
          $('td', row).eq(2).append("" +
            "<button id='borrar' type='button' class='btn btn-danger btn-default btn-sm'>" +
            "<i class='fa fa-trash icon-white'></i></button>"
          )
        }
      }
    };
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();

    // this.dtTrigger.subscribe(() => {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input').on('keyup change', function () {
          if (dtInstance.column(this['id']).search() !== this['value']) {
            dtInstance
              .column(this['id'])
              .search(this['value'])
              .draw();
          }

        });
      });
    });
    // });

  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next()
      // this.dtTrigger.next(0);
      // console.log(this.datatableElement)
    });
  }

  borrarFuente(fuente: FuenteMdl, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${fuente.descripcion}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {
        this.fuentes = this.fuentes.filter(l => l.fuenteId !== fuente.fuenteId)
        fuente.activo = false;
        console.log(fuente)
        this.service.eliminarFuente(
          fuente.fuenteId,
          fuente
        ).subscribe()
        this.rerender();
      }
    })
  }

}
