import { ComiteMdl } from "./comite.model";

export class DependenciaMdl {
    
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    dependenciaId: number;
    comiteId: any;

    
    constructor(){
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.dependenciaId = null;
        this.nombre = null;
        this.comiteId = '';

      
    }
}