import { EstrategiaPvdMdl } from './estrategiaPvd.model';

export class LineaMdl {
    
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion:string;
    lineaaccionpvdId: number
    estrategiapvdId: any

    constructor(){
        this.activo = true;
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.lineaaccionpvdId = null;
        this.nombre = null;
        this.descripcion = null;
        this.estrategiapvdId = null;
    }
}
