import { Component, OnInit } from '@angular/core';
import { Menu } from '../../../models/Menu';
import { MenuService } from '../../../services/shared/menu.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-menu-listado',
  templateUrl: './menu-listado.component.html',
  styleUrls: ['./menu-listado.component.css']
})
export class MenuListadoComponent implements OnInit {
  menus: Menu[] = []
  p: number = 1
  constructor(private service: MenuService) { }

  ngOnInit() {
    this.service.getMenu()
      .subscribe(resp => {
        this.menus = resp;
      })
  }

  borrarMenu(menu: Menu, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${menu.nombre}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {       
        this.menus = this.menus.filter(m => m.menuId !== menu.menuId)
        menu.activo = false;
        console.log(menu)
        
        this.service.updateMenu(
          menu.menuId,
          menu
        ).subscribe()
      }
    })
  }
 

}
