import { NgModule } from "@angular/core";
import { CommonModule, CurrencyPipe,DecimalPipe } from "@angular/common";
//Modules
import { SharedModule } from "../shared/shared.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPaginationModule } from 'ngx-pagination'

import { DashboardComponent } from "./dashboard/dashboard.component";
import { PagesComponent } from "./pages.component";

//Multiselect https://nileshpatel17.github.io/ng-multiselect-dropdown/
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

//rutas
import { PAGES_ROUTES } from "./pages.routes";
import { ListadoComponent } from './dependencias/listado-dependencia/listado.component';
import { DependenciaComponent } from './dependencias/dependencia/dependencia.component';
import { ComiteComponent } from './comite/comite.component';
import { ListadoSentidoComponent } from './sentidos/listado-sentido/listado-sentido.component';
import { ListadoBloqueComponent } from './bloques/listado-bloque/listado-bloque.component';
import { EjepvdComponent } from './ejepvd/ejepvd.component';
import { BloqueComponent } from './bloques/bloque/bloque.component';
import { FichaTecnicaComponent } from './fichetecnica/fichatecnica.component';
import { ListadoEstrategiaComponent } from './EstrategiaPvd/listado-estrategiaPvd/listadoestrategia.component';
import { EstrategiaComponent } from './EstrategiaPvd/estrategiasPvd/estrategia.component';

import { ListadoOdsComponent } from './A-2030/listado-ods/listado-ods.component';
import { OdsComponent } from './A-2030/ods/ods.component';

import { FondoComponent } from './fondofinanciamieno/fondo/fondo.component';
import { ListadoFondoComponent } from './fondofinanciamieno/listado-fondo/listado-fondo.component';


import { EjecutoraComponent } from './ejecutoras/ejecutora/ejecutora.component';
import { ListadoEjecutoraComponent } from './ejecutoras/listado-ejecutora/listado-ejecutora.component';

import { MacrobjetivoComponent } from './macrobjetivo/macro/macro.component';
import { ListadoMacrobjetivoComponent } from './macrobjetivo/listado-macro/listado-macro.component';

import { MetaComponent } from './metaA2030/meta/meta.component';
import { ListadoMetaComponent } from './metaA2030/listado-meta/listado-meta.component';

import { ObjetivoComponent } from './objetivoBloque/objetivo/objetivo.component';
import { ListadoObjetivoComponent } from './objetivoBloque/listado-objetivo/listado-objetivo.component';

import { VideoComponent } from './Video/video/video.component';
import { ListadoVideoComponent } from './Video/listado-video/listado-video.component';

import { LineaAccionComponent } from './LineaAccion/linea/linea.component';
import { ListadoLineaComponent } from './LineaAccion/listado-linea/listado-linea.component';

import {ListadoInversionComponent} from './Inversion/ListadoInversion/listadoInv.component';
import { MenuItemListadoComponent } from './menuItem/menu-item-listado/menu-item-listado.component';
import { MenuItemComponent } from './menuItem/menu-item/menu-item.component';
import { MenuComponent } from './menu/menu/menu.component';
import { MenuListadoComponent } from './menu/menu-listado/menu-listado.component';
import { MenuAsignacionComponent } from './menu/menu-asignacion/menu-asignacion.component';
import { DerechoItemComponent } from './derechoItem/derecho-item/derecho-item.component';
import { DerechoItemListadoComponent } from './derechoItem/derecho-item-listado/derecho-item-listado.component';
import { DerechoListadoComponent } from './derecho/derecho-listado/derecho-listado.component';
import { DerechoAsigncionComponent } from './derecho/derecho-asigncion/derecho-asigncion.component';
import { DerechoComponent } from './derecho/derecho/derecho.component';
import { PerfilComponent } from './perfil/perfil/perfil.component';
import { PerfilListadoComponent } from './perfil/perfil-listado/perfil-listado.component';
import { UsuarioComponent } from './usuario/usuario/usuario.component';
import { UsuarioListadoComponent } from './usuario/usuario-listado/usuario-listado.component';
//import { UsuarioAsignacionComponent } from './usuario/usuario-asignacion/usuario-asignacion.component';
// import { IndicadorComponent } from './indicador/indicador.component';

import { HistoricoControlComponent } from '../components/historico-control/historico-control.component';

import { InversionComponent } from './Inversion/ProInv/proinv.component';
import { IndicadorPvdComponent } from './indicadoresPvd/indicadorPvd/indicadorPvd.component';
import { MaterialModule } from './material.module';
import { ListadoIndicadorPvdComponent } from './indicadoresPvd/listado-IndicadorPvd/listado-indicadorpvd.component';
import { ListadoDesglosadoComponent } from './desglosados/listado-desglosado/listado-desglosado.component';
import { DesglosadosComponent } from './desglosados/desglosado/desglosado.component';
import { FuenteComponent } from './fuentes/fuente/fuente.component';
import { ListadoFuenteComponent } from './fuentes/listado-fuente/listado-fuente.component';
import { PrimeNgModule } from './primeNg.module';
import { MAT_DATE_LOCALE } from '@angular/material';
import { DesagregadoComponent } from './desagregados/desagregado/desagregado.component';
import { ListadoDesagregadoComponent } from './desagregados/listado-desagregado/listado-desagregado.component';
import { BloquePorcentajeComponent } from './bloquePorcentaje/bloquePorcentaje/bloquePorcentaje.component';
import { ListadoBloquePorcentajeComponent } from './bloquePorcentaje/listado-bloquePorcentaje/listado-bloquePorcentaje.component';
import { tablaDinamicaComponent } from './fichetecnica/tablaDinamica/tablaDinamica.component';
import { FichaTecnicaEdicionComponent } from './fichetecnica/fichaTecnicaEdicion/fichatecnicaEdicion.component';
import { listaDinamicaComponent } from './fichetecnica/ListaDinamica/listaDinamica.component';
import { ListadofichaComponent } from './fichetecnica/listado-ficha/listadoficha.component';
import { ListaDinamicaEditComponent } from './fichetecnica/ListaDinamicaEdit/ListaDinamicaEdit.component';
import {IndA2030Component} from './IndicadorA2030/IndA2030/IndA2030.component';
import {ListadoIndicadorA2030Component} from './IndicadorA2030/ListadoIndA2030/listadoIndA2030.component';
import {UnidadMedidaComponent} from './UnidadMedida/UniMedida/UniMed.component';
import {ListadoUniMedidaComponent} from './UnidadMedida/listadoUnidadMedida/listadoUniMed.component';
import {EjePndRComponent} from './EjePnd/ejePnd/ejepnd.component';
import {ListadoEjePndRComponent} from './EjePnd/listadoEjePnd/listadoEjepnd.component';
import {ImagenInversionComponent} from './ImgProyInv/ImagenInv/imgProyInv.component';
import {ListadoImagenComponent} from './ImgProyInv/listadoImgProy/listImagen.component';
import {ListadoPeriodicidadComponent} from './Periodicidad/listado-periodicidad/listado-periodicidad.component';
import {PeriodicidadComponent} from './Periodicidad/periodicidad/periodicidad.component';
import {ComplementoImagenComponent} from './ImgProyInv/ComplementoImg/comImg.component';
import { DerechoAsignacionComponent } from './seguridad/derecho-asignacion/derecho-asignacion.component';
import {IntermediaComponent} from './usuario/usuario-asignacion/usuario-asignacion.component';
import {UsuarioAsignacionListadoComponent} from './usuario/UsuarioAsignacion-Listado/UsuarioAsignacion-Listado.component';
import {IBloqueDepComponent} from './bloques/Iblo-dep/iblo-dep.component';
import {ListadoIbloDepComponent} from './bloques/listado-iblodep/listado-iblodep.component';

import { ListadoRegionComponent } from "./region/listado-region/listado-region.component";
import { RegionComponent } from "./region/region/region.component";

import { DataTablesModule } from "angular-datatables";

@NgModule({
    declarations: [
        PagesComponent,
        DashboardComponent,
        ListadoComponent,
        DependenciaComponent,
        ComiteComponent,
        ListadoSentidoComponent,
        ListadoBloqueComponent,
        EjepvdComponent,
        BloqueComponent,
        ListadoEstrategiaComponent,
        EstrategiaComponent,
        ListadoOdsComponent,
        OdsComponent,
        EjecutoraComponent,
        ListadoEjecutoraComponent,
        FondoComponent,
        ListadoFondoComponent,
        MacrobjetivoComponent,
        ListadoMacrobjetivoComponent,

        MetaComponent,
        ListadoMetaComponent,

        ListadoObjetivoComponent,
        ObjetivoComponent,

        ListadoVideoComponent,
        VideoComponent,

        LineaAccionComponent,
        ListadoLineaComponent,
        
        InversionComponent,
        ListadoInversionComponent,

        IndicadorPvdComponent,
        ListadoIndicadorPvdComponent,
        
        DesglosadosComponent,
        ListadoDesglosadoComponent,

        FuenteComponent,
        ListadoFuenteComponent,

        DesagregadoComponent,
        ListadoDesagregadoComponent,

        BloquePorcentajeComponent,
        ListadoBloquePorcentajeComponent,

        MenuItemListadoComponent,
        MenuItemComponent,
        MenuComponent,
        MenuListadoComponent,
        MenuAsignacionComponent,
        DerechoItemComponent,
        DerechoItemListadoComponent,
        DerechoListadoComponent,
        DerechoAsigncionComponent,
        DerechoComponent,
        PerfilComponent,
        PerfilListadoComponent,
        UsuarioComponent,
        UsuarioListadoComponent,
       // UsuarioAsignacionComponent,

        DerechoAsignacionComponent,
       
        // IndicadorComponent
        HistoricoControlComponent,

        tablaDinamicaComponent,
        FichaTecnicaComponent,
        FichaTecnicaEdicionComponent,
        listaDinamicaComponent,
        ListadofichaComponent,
        ListaDinamicaEditComponent,

        //Modulos nuevos
        IndA2030Component,
        ListadoIndicadorA2030Component,
        UnidadMedidaComponent,
        ListadoUniMedidaComponent,
        EjePndRComponent,
        ListadoEjePndRComponent,
        ImagenInversionComponent,
        ListadoImagenComponent,

        ListadoPeriodicidadComponent,
        PeriodicidadComponent,
        ComplementoImagenComponent,
        IntermediaComponent,
        UsuarioAsignacionListadoComponent,
        IBloqueDepComponent,
        ListadoIbloDepComponent,

        ListadoRegionComponent,
        RegionComponent

    ], 
    exports:[

        IndicadorPvdComponent,
        ListadoIndicadorPvdComponent,

        ListadoDesglosadoComponent,
        DesglosadosComponent,

        FuenteComponent,
        ListadoFuenteComponent,

        DesagregadoComponent,
        ListadoDesagregadoComponent,
        DashboardComponent,

    ],
  
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        NgxPaginationModule,
        NgMultiSelectDropDownModule.forRoot(),
        PAGES_ROUTES,
        MaterialModule,
        PrimeNgModule,
        DataTablesModule
    ],
    providers: [CurrencyPipe     ,DecimalPipe,
        { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    ],
})

export class PagesModule { }