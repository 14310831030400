import { Component, OnInit,OnDestroy } from '@angular/core';
import { EjePndRService, SidebarService } from '../../../services/service.index';
import { EjepndMdl } from '../../../models/ejePndReplica.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bloques',
  templateUrl: './listadoEjepnd.component.html',
  styleUrls: ['./listadoEjepnd.component.css']
})
export class ListadoEjePndRComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  ejes: EjepndMdl[] = []
  p: number = 1
  constructor(private service: EjePndRService, 
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
    },
      dom: 'Blfrtip',     
    };
    this.service.getEjePnds()
      .subscribe(resp => {
        
        this.ejes = resp
        this.ejes = this.ejes.filter( b => b.activo == true)
        this.dtTrigger.next();
      })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  borrarEjePnd(eje: EjepndMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${eje.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.ejes = this.ejes.filter( e => e.ejepndId !== eje.ejepndId)
        eje.activo = false;
        console.log(eje)
        this.service.eliminarEjePnd(
          eje.ejepndId,
          eje
          ).subscribe()
      }
    })
  }
  
}
