import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { BloquePorcentajeMdl } from '../../models/bloqueporcentaje.model';
import { URL_API } from '../../globals';

@Injectable()
export class BloquesPorcentajeService {

  constructor(private http: HttpClient) { }

  getBloques(){
    return this.http.get(`${URL_API}/bloqueporcentaje`)
      .pipe(map(this.crearLista))
      
  }

  eliminarBloque(id:number, bloque: BloquePorcentajeMdl){
    return this.http.delete(`${URL_API}/bloqueporcentaje/${id}`)
  }

  crearBloque(bloque: BloquePorcentajeMdl){
    return this.http.post(`${URL_API}/bloqueporcentaje`, bloque)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return bloque
    }))
  }

  getBloque(id:number){
    return this.http.get<any>(`${URL_API}/bloqueporcentaje/${id}`)
  }

  actualizarBloque(bloque: BloquePorcentajeMdl){
    const bloqueTemp = {
      ...bloque
    }
    delete bloqueTemp.bloqueId
    return this.http.put(`${URL_API}/bloqueporcentaje/${bloque.bloquePorcentajeId}`, 
      bloque)
  }

  private crearLista(bloqueObj: Object){
    const bloques: BloquePorcentajeMdl[] = []
    if(bloqueObj === null){ return [] }

    Object.keys(bloqueObj).forEach(key => {
      const bloque: BloquePorcentajeMdl = bloqueObj[key]
      bloques.push(bloque)
    })

    return bloques
  }

}