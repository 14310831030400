import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  SidebarService,
  DependenciasService,
  SentidoService,
  BloquesService,
  IndicadorService,
  EstrategiaPvdService,
  OdsService,
  EjecutorasService,
  FondosService,
  MacrosService,
  MetaA2030sService,
  ObjetivosService,
  VideoService,
  LineaAccionService,
  InversionService,
  UnidadMedidaService,
  DesglosadosService,
  FuenteService,
  DesagregadoService,
  PerioricidadService,
  OpcionDesagregadoService,
  IndicadorPvdDesagregadoService,
  BloquesPorcentajeService,
  AuthenticationService,
  MenuService,
  DerechoService,
  PerfilService,
  AuthGuard,
  UsuarioService,
  A2030Service, 
  FichaTecnicaService,
  IndA2030Service,
  UniMedidaService,
  EjePndRService,
  ImagenInversionService,
  PeriodicidadRService,
  IntUsuPerService,
  iBloqueDependenciaService,
  RegionService
  
  
} from './service.index';

import { HttpClientModule } from '@angular/common/http';



@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    SidebarService,
    DependenciasService,
    SentidoService,
    BloquesService,
    EstrategiaPvdService,
    OdsService,
    EjecutorasService,
    FondosService,
    MacrosService,
    MetaA2030sService,
    ObjetivosService,
    VideoService,
    LineaAccionService,
    IndicadorService,
    InversionService,
    PerioricidadService,
    UnidadMedidaService,
    DesglosadosService,
    FuenteService,
    DesagregadoService,
    OpcionDesagregadoService,
    IndicadorPvdDesagregadoService,
    BloquesPorcentajeService,
    AuthenticationService,
    AuthGuard,
    MenuService,
    DerechoService,
    PerfilService,
    UsuarioService,
    A2030Service,
    FichaTecnicaService,
    IndA2030Service,
    UniMedidaService,
    EjePndRService,
    ImagenInversionService,
    PeriodicidadRService,
    IntUsuPerService,
    iBloqueDependenciaService,
    RegionService
  
    
  ],
  declarations: []
})
export class ServiceModule { }
