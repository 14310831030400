import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { MetaA2030sService, SidebarService } from '../../../services/service.index';

//modelos
import { MetaA2030Mdl } from '../../../models/metaa2030.model';
import { OdsMdl } from '../../../models/ods.model';

@Component({
  selector: 'app-meta',
  templateUrl: './meta.component.html',
  styleUrls: ['./meta.component.css']
})
export class MetaComponent implements OnInit {

  meta: MetaA2030Mdl = new MetaA2030Mdl()
  
 
  ods: OdsMdl = new OdsMdl()
  odsS: OdsMdl[] = []
  odslctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private metaService: MetaA2030sService, 
    private  sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.meta);
    }

  ngOnInit() {

    this.metaService.getOdsS()
      .subscribe(res => {
        
        this.odsS = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.metaService.getMeta(Number(id))
        .subscribe((resp:MetaA2030Mdl) => {
        
          this.meta = resp

          this.odslctd = resp.odsId.odsId;
          this.ods = this.odsS.find(o => o.odsId == this.odslctd)
          this.meta.odsId= this.meta.odsId.odsId;
          this.formGroup = this._formbuilder.group(this.meta);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarMeta(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.meta = this.formGroup.value;
    this.meta.activo = true;
    this.meta.creadoPor =1;
    this.meta.actualizado = new Date();
    this.meta.actualizadoPor = 1;
    this.meta.odsId = Number(this.meta.odsId);

    
  
    Swal.showLoading()

    if(this.meta.metaa2030Id){
      this.meta.creado = this.meta.creado
      peticion = this.metaService.actualizarMeta(this.meta)

      console.log(this.meta)
    }

    else {
      this.meta.creado = new Date()
      console.log(this.meta)
      peticion = this.metaService.crearMeta(this.meta)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.meta.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.meta.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  onComiteSlctd(event:any) {
    this.ods = this.odsS.find( o =>  o.odsId === event  )
  }
}

