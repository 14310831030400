import { Component, OnInit } from '@angular/core';
import { Derecho} from '../../../models/Derecho';
import { DerechoService } from '../../../services/shared/derecho.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-derecho-listado',
  templateUrl: './derecho-listado.component.html',
  styleUrls: ['./derecho-listado.component.css']
})
export class DerechoListadoComponent implements OnInit {
  derechos: Derecho[] = []
  p: number = 1
  constructor(private service: DerechoService) { }

  ngOnInit() {
    this.service.getDerecho()
      .subscribe(resp => {
        this.derechos = resp;
      })
  }

  borrarDerecho(derecho: Derecho, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${derecho.nombre}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {       
        this.derechos = this.derechos.filter(d => d.derechoId !== derecho.derechoId)
        derecho.activo = false;
        console.log(derecho)        
        this.service.actualizarDerecho(
          derecho.derechoId,
          derecho
        ).subscribe()
      }
    })
  }
}
