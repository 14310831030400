import { Component, OnInit } from '@angular/core';
import { PerfilMdl } from '../../../models/Perfil';
import { PerfilService } from '../../../services/shared/perfil.service';
import Swal from 'sweetalert2'
import { SidebarService } from '../../../services/shared/sidebar.service';

@Component({
  selector: 'app-perfil-listado',
  templateUrl: './perfil-listado.component.html',
  styleUrls: ['./perfil-listado.component.css']
})
export class PerfilListadoComponent implements OnInit {

  perfiles: PerfilMdl[] = []
  p: number = 1
  constructor(private service: PerfilService,
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    this.service.getPerfil()
      .subscribe(resp => {
        this.perfiles = resp;
      })
  }

  borrarPerfil(perfil: PerfilMdl, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${perfil.nombre}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {
        // this.dependencias.splice(i, 1)
        this.perfiles = this.perfiles.filter(prf => prf.perfilId !== perfil.perfilId)
        perfil.activo = false;
        console.log(perfil)
        this.service.updatePerfil(
          perfil.perfilId,
          perfil
        ).subscribe()
      }
    })
  }

}
