import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_API } from '../../globals';
import { map } from 'rxjs/operators';
import { Meta2030Mdl } from '../../models/meta2030.model';
import { Observable } from 'rxjs';
import { IndicadorA2030Mdl } from '../../models/indicadorA2030.model'
import { IndicadorPvdMdl } from '../../models/indicadorPvd.model'
import { DesagregadoMdl } from '../../models/desagregado.model';
import { OpcionesDesagregadoMdl } from '../../models/opcionesDesagregado.model';
import { FichaMdl, FichaPesistenciaMdl } from '../../models/FichaItem.model';
import { ComiteMdl } from '../../models/comite.model';


@Injectable()
export class FichaTecnicaService {

  constructor(private http: HttpClient) { }

  getMetasA2030(ids: []): Observable<Meta2030Mdl[]> {
    return this.http.post<Meta2030Mdl[]>(`${URL_API}/metaa2030/list`,
      ids, { observe: 'response' })
      .pipe(map(response => {
        response.headers.keys(); // all header names
        response.body // response content

        return response.body;
      }))
  }

  getIndicadoresOds(ids: []): Observable<IndicadorA2030Mdl[]> {
    return this.http.post<IndicadorA2030Mdl[]>(`${URL_API}/indicadora2030/list`,
      ids, { observe: 'response' })
      .pipe(map(res => {
        res.headers.keys(); // all header names
        res.body // response content

        return res.body
      }))
  }

  getIndicadoresFicha() {
    return this.http.get(`${URL_API}/indicadorpvd`)
      .pipe(map(this.getListaIndicadores))
  }

  actualizarFicha(ficha: FichaPesistenciaMdl) {
    return this.http.put(`${URL_API}/ficha/persistencia/${ficha.ficha_id}`, ficha)
  }

  validaFicha(id: number, ficha: FichaMdl) {
    return this.http.put(`${URL_API}/ficha/valida/${id}`, ficha)
  }

  getFicha(id) {
    return this.http.get<any>(`${URL_API}/ficha/` + id);
  }

  getFichas() {
    return this.http.get<any>(`${URL_API}/ficha/`);
  }

  eliminarFicha(id: number, ficha: FichaMdl) {
    return this.http.delete(`${URL_API}/ficha/${id}`)
  }

  private getListaIndicadores(indicadores: Object) {
    const indicadoresList: IndicadorPvdMdl[] = []
    if (indicadores === null) { return [] }

    Object.keys(indicadores).forEach(key => {
      const indicador: IndicadorPvdMdl = indicadores[key]
      indicadoresList.push(indicador)
    })
    return indicadoresList
  }

  getcomites() {
    return this.http.get(`${URL_API}/comite`)
      .pipe(map(this.getComiteList))
  }

  private getComiteList(comites: Object) {
    const comitesList: ComiteMdl[] = []
    if (comites === null) { return [] }

    Object.keys(comites).forEach(key => {
      const comite: ComiteMdl = comites[key]
      comitesList.push(comite)
    })
    return comitesList

  }

  getIndicadorDetail(id: Number) {
    return this.http.get(`${URL_API}/indicadorpvd/ficha/${id}`)
      .pipe(map(this.getIndicadorDetailObject))
  }

  getIndicadorDetalle(id:number){
    return this.http.get(`${URL_API}/indicadorpvd/ficha/${id}`)
    .pipe(map(this.getListaIndicadores));
  }

  private getDesagregados(desa: Object) {
    const desaList: DesagregadoMdl[] = []
    if (desa === null) { return [] }

    Object.keys(desa).forEach(key => {
      const comite: DesagregadoMdl = desa[key]
      desaList.push(comite)
    })
    return desaList

  }

  private getIndicadorDetailObject(indicador: Object) {
    const indi: any = indicador
    return indi
  }

  saveFichaDetail(ficha: any[]) {
    return this.http.post(`${URL_API}/ficha/persistencia`, ficha)
      .pipe(map((resp: any) => {
        console.log('Respuesta server: ', resp)
        return resp
      }))
  }
}
