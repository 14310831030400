export class EjepndMdl {
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: Boolean;
    nombre: string;
    descripcion: string;
    ejepndId: number;

        constructor(){
            this.creado = null;
            this.creadoPor = null;
            this.actualizado = null;
            this.actualizadoPor = null;
            this.activo = true;
            this.nombre = null;
            this.descripcion = null;
            this.ejepndId = null;
        }

}