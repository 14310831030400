import { ObjetivoBloqueMdl } from './objetivoBloque.model';

export class EstrategiaPvdMdl {
   
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion: string;
    estrategiapvdId: number;
    objetivobloqueId: any;
  
    constructor(){
      this.activo = true;
      this.creado = null;
      this.creadoPor = null;
      this.actualizado = null;
      this.actualizadoPor = null;
      this.estrategiapvdId = null;
      this.nombre = null;
      this.descripcion = null;
      this.objetivobloqueId = null;
      
      
    }
}