import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { EjecutorasService } from '../../../services/service.index';

//modelos
import { EjecutoraMdl } from '../../../models/ejecutora.model';

@Component({
  selector: 'app-ejecutora',
  templateUrl: './ejecutora.component.html',
  styleUrls: ['./ejecutora.component.css']
})
export class EjecutoraComponent implements OnInit {

  ejecutora: EjecutoraMdl = new EjecutoraMdl();
  ejecutoras: EjecutoraMdl[] = [];

  formGroup: FormGroup;

  get f() { return this.formGroup.controls; }
  isSave: boolean = true
  
  
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private ejecutoraService: EjecutorasService,
    ) {
    this.formGroup = this._formbuilder.group(this.ejecutora);
  }

  ngOnInit() {     
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.ejecutoraService.getEjecutora(Number(id))
      .subscribe(resp => {
        
        console.log(resp);
        this.ejecutora = resp;
        console.log(this.ejecutora);
        this.formGroup = this._formbuilder.group(this.ejecutora);
      })
    }
  

  }

  guardarEjecutora() {
    
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    this.ejecutora = this.formGroup.value;
   
    Swal.showLoading()

    if (this.ejecutora.ejecutoraId) {
      this.ejecutora.creado = new Date();
      this.ejecutora.actualizado = new Date();
      this.ejecutora.actualizadoPor = 1;

      peticion = this.ejecutoraService.actualizarEjecutora(this.ejecutora)
      console.log(this.ejecutora)
    }
    else {
      this.ejecutora.activo = true;
      this.ejecutora.creadoPor = 1;
      this.ejecutora.creado =new Date();
      this.ejecutora.actualizado =new Date();
      this.ejecutora.actualizadoPor =1;
      peticion = this.ejecutoraService.crearEjecutora(this.ejecutora)
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.ejecutora.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.ejecutora.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }
    
    )
  }

}


