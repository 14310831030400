import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from '../services/shared/auth.guard';

import { PagesComponent } from "./pages.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { ListadoComponent } from "./dependencias/listado-dependencia/listado.component";
import { DependenciaComponent } from "./dependencias/dependencia/dependencia.component";
import { ListadoBloqueComponent } from "./bloques/listado-bloque/listado-bloque.component";
import { BloqueComponent } from "./bloques/bloque/bloque.component";

import { EstrategiaComponent } from "./EstrategiaPvd/estrategiasPvd/estrategia.component";

import { ListadoSentidoComponent } from './sentidos/listado-sentido/listado-sentido.component';
import { FichaTecnicaComponent } from './fichetecnica/fichatecnica.component';

import { ListadoPeriodicidadComponent } from './Periodicidad/listado-periodicidad/listado-periodicidad.component';
import { PeriodicidadComponent } from './Periodicidad/periodicidad/periodicidad.component';

import { ListadoEstrategiaComponent } from './EstrategiaPvd/listado-estrategiaPvd/listadoestrategia.component';

import { ListadoOdsComponent } from './A-2030/listado-ods/listado-ods.component';
import { OdsComponent } from './A-2030/ods/ods.component';

import { FondoComponent } from './fondofinanciamieno/fondo/fondo.component';
import { ListadoFondoComponent } from './fondofinanciamieno/listado-fondo/listado-fondo.component';

import { EjecutoraComponent } from './ejecutoras/ejecutora/ejecutora.component';
import { ListadoEjecutoraComponent } from './ejecutoras/listado-ejecutora/listado-ejecutora.component';

import { MacrobjetivoComponent } from './macrobjetivo/macro/macro.component';
import { ListadoMacrobjetivoComponent } from './macrobjetivo/listado-macro/listado-macro.component';

import { MetaComponent } from './metaA2030/meta/meta.component';
import { ListadoMetaComponent } from './metaA2030/listado-meta/listado-meta.component';

import { ObjetivoComponent } from './objetivoBloque/objetivo/objetivo.component';
import { ListadoObjetivoComponent } from './objetivoBloque/listado-objetivo/listado-objetivo.component';

import { VideoComponent } from './Video/video/video.component';
import { ListadoVideoComponent } from './Video/listado-video/listado-video.component';

import { LineaAccionComponent } from './LineaAccion/linea/linea.component';
import { ListadoLineaComponent } from './LineaAccion/listado-linea/listado-linea.component';

import { InversionComponent } from './Inversion/ProInv/proinv.component';
import {ListadoInversionComponent} from './Inversion/ListadoInversion/listadoInv.component';

import { IndicadorPvdComponent } from './indicadoresPvd/indicadorPvd/indicadorPvd.component';
import { ListadoIndicadorPvdComponent } from './indicadoresPvd/listado-IndicadorPvd/listado-indicadorpvd.component';
import { ListadoDesglosadoComponent } from './desglosados/listado-desglosado/listado-desglosado.component';
import { DesglosadosComponent } from './desglosados/desglosado/desglosado.component';
import { ListadoFuenteComponent } from './fuentes/listado-fuente/listado-fuente.component';
import { FuenteComponent } from './fuentes/fuente/fuente.component';
import { ListadoDesagregadoComponent } from './desagregados/listado-desagregado/listado-desagregado.component';
import { DesagregadoComponent } from './desagregados/desagregado/desagregado.component';
import { ListadoBloquePorcentajeComponent } from './bloquePorcentaje/listado-bloquePorcentaje/listado-bloquePorcentaje.component';
import { BloquePorcentajeComponent } from './bloquePorcentaje/bloquePorcentaje/bloquePorcentaje.component';

import { MenuItemListadoComponent } from './menuItem/menu-item-listado/menu-item-listado.component';
import { MenuItemComponent } from './menuItem/menu-item/menu-item.component';
import { MenuComponent } from './menu/menu/menu.component';
import { MenuListadoComponent } from './menu/menu-listado/menu-listado.component';
import { DerechoItemComponent } from './derechoItem/derecho-item/derecho-item.component';
import { DerechoItemListadoComponent } from './derechoItem/derecho-item-listado/derecho-item-listado.component';
import { DerechoComponent } from './derecho/derecho/derecho.component';
import { DerechoListadoComponent } from './derecho/derecho-listado/derecho-listado.component';
import { PerfilComponent } from './perfil/perfil/perfil.component';
import { PerfilListadoComponent } from './perfil/perfil-listado/perfil-listado.component';
import { MenuAsignacionComponent } from './menu/menu-asignacion/menu-asignacion.component';
import { DerechoAsigncionComponent } from './derecho/derecho-asigncion/derecho-asigncion.component';
import { UsuarioComponent } from './usuario/usuario/usuario.component';
import { UsuarioListadoComponent } from './usuario/usuario-listado/usuario-listado.component';
//import { UsuarioAsignacionComponent } from './usuario/usuario-asignacion/usuario-asignacion.component';
import { FichaTecnicaEdicionComponent } from './fichetecnica/fichaTecnicaEdicion/fichatecnicaEdicion.component';
import { ListadofichaComponent } from './fichetecnica/listado-ficha/listadoficha.component';
import {IndA2030Component} from './IndicadorA2030/IndA2030/IndA2030.component';
import {ListadoIndicadorA2030Component} from './IndicadorA2030/ListadoIndA2030/listadoIndA2030.component';
import {UnidadMedidaComponent} from './UnidadMedida/UniMedida/UniMed.component';
import {ListadoUniMedidaComponent} from './UnidadMedida/listadoUnidadMedida/listadoUniMed.component';
import {EjePndRComponent} from './EjePnd/ejePnd/ejepnd.component';
import {ListadoEjePndRComponent} from './EjePnd/listadoEjePnd/listadoEjepnd.component';
import {ImagenInversionComponent} from './ImgProyInv/ImagenInv/imgProyInv.component';
import {ListadoImagenComponent} from './ImgProyInv/listadoImgProy/listImagen.component';
import {ComplementoImagenComponent} from './ImgProyInv/ComplementoImg/comImg.component';
import { DerechoAsignacionComponent } from './seguridad/derecho-asignacion/derecho-asignacion.component';
import {IntermediaComponent} from './usuario/usuario-asignacion/usuario-asignacion.component';
import {UsuarioAsignacionListadoComponent} from './usuario/UsuarioAsignacion-Listado/UsuarioAsignacion-Listado.component';

import {IBloqueDepComponent} from './bloques/Iblo-dep/iblo-dep.component';
import {ListadoIbloDepComponent} from './bloques/listado-iblodep/listado-iblodep.component';

import { ListadoRegionComponent } from "./region/listado-region/listado-region.component";
import { RegionComponent } from "./region/region/region.component";








const pagesRoutes: Routes = [
    {
        path: '',
        component: PagesComponent,
        children: [
            { path: 'dashboard', component: DashboardComponent, data:{ titulo:'Dashboard'} },
            { path: 'dependencias', component: ListadoComponent, canActivate: [AuthGuard], data:{ titulo:'Dependencias'}  },
            { path: 'dependencia/:id', component: DependenciaComponent,canActivate: [AuthGuard], data:{ titulo:'Dependencia'}  },
            { path: 'sentidos', component: ListadoSentidoComponent, canActivate: [AuthGuard],data:{ titulo:'Sentidos'}},
            
            { path: 'bloques', component: ListadoBloqueComponent, canActivate: [AuthGuard],data:{ titulo:'Bloques'} },
            { path: 'bloque/:id', component: BloqueComponent,canActivate: [AuthGuard], data:{ titulo:'Bloque'} },
            { path: 'IbloDep/:id', component: IBloqueDepComponent,canActivate: [AuthGuard], data:{ titulo:'Bloque-Dependencia'} },
            { path: 'IbloDep', component: ListadoIbloDepComponent,canActivate: [AuthGuard], data:{ titulo:'Listado Bloque-Dependencia'} },


            { path: 'bloquesporcentaje', component: ListadoBloquePorcentajeComponent,canActivate: [AuthGuard], data:{ titulo:'Bloques porcentaje'} },
            { path: 'bloqueporcentaje/:id', component: BloquePorcentajeComponent,canActivate: [AuthGuard], data:{ titulo:'Bloque porcentaje'} },
           
            { path: 'fichatecnica', component: FichaTecnicaComponent, canActivate: [AuthGuard],data:{ titulo:'Ficha Técnica'} },
            { path: 'fichatecnica/:id', component: FichaTecnicaEdicionComponent,canActivate: [AuthGuard], data:{ titulo:'Ficha'} },
            { path: 'fichas', component: ListadofichaComponent, canActivate: [AuthGuard],data:{ titulo:'Ficha'} },

            { path: 'estrategias', component: ListadoEstrategiaComponent,canActivate: [AuthGuard], data:{ titulo:'Estrategias'} },
            { path: 'estrategia/:id', component: EstrategiaComponent,canActivate: [AuthGuard], data:{ titulo:'Estrategia'} },
            
            { path: 'odsS', component: ListadoOdsComponent, canActivate: [AuthGuard], data:{ titulo:'ODS'} },
            { path: 'ods/:id', component: OdsComponent, canActivate: [AuthGuard], data:{ titulo:'Ods'} },

            { path: 'fondos', component: ListadoFondoComponent, canActivate: [AuthGuard], data:{ titulo:'Fondos'} },
            { path: 'fondo/:id', component: FondoComponent,canActivate: [AuthGuard], data:{ titulo:'Fondo'} },

            { path: 'ejecutoras', component: ListadoEjecutoraComponent,canActivate: [AuthGuard], data:{ titulo:'Ejecutoras'} },
            { path: 'ejecutora/:id', component: EjecutoraComponent, canActivate: [AuthGuard], data:{ titulo:'Ejecutora'} },

            { path: 'macros', component: ListadoMacrobjetivoComponent, canActivate: [AuthGuard], data:{ titulo:'Macros'} },
            { path: 'macro/:id', component: MacrobjetivoComponent, canActivate: [AuthGuard], data:{ titulo:'Macro'} },

            { path: 'metas', component: ListadoMetaComponent, canActivate: [AuthGuard], data:{ titulo:'Metas'} },
            { path: 'meta/:id', component: MetaComponent, canActivate: [AuthGuard], data:{ titulo:'Meta'} },
            
            { path: 'objetivos', component: ListadoObjetivoComponent, canActivate: [AuthGuard], data:{ titulo:'Objetivos'} },
            { path: 'objetivo/:id', component: ObjetivoComponent, canActivate: [AuthGuard], data:{ titulo:'Objetivo'} },

            { path: 'periodicidades', component: ListadoPeriodicidadComponent, canActivate: [AuthGuard], data:{ titulo:'Periodicidades'} },
            { path: 'periodicidad/:id', component: PeriodicidadComponent, canActivate: [AuthGuard], data:{ titulo:'Periodicidad'} },

            

            { path: 'videos', component: ListadoVideoComponent, canActivate: [AuthGuard], data:{ titulo:'Videos'} },
            { path: 'video/:id', component: VideoComponent, canActivate: [AuthGuard], data:{ titulo:'Video'} },
            
            { path: 'lineas', component: ListadoLineaComponent, canActivate: [AuthGuard], data:{ titulo:'Líneas'} },
            { path: 'linea/:id', component: LineaAccionComponent, canActivate: [AuthGuard], data:{ titulo:'Linea'} },

            { path: 'inversions', component: ListadoInversionComponent, canActivate: [AuthGuard], data:{ titulo:'Proyecto de Inversión'} },
            { path: 'inversion/:id', component: InversionComponent, canActivate: [AuthGuard], data:{ titulo:'Inversion'} },
            { path:  'imgInv/:id', component: ImagenInversionComponent,canActivate: [AuthGuard], data:{titulo:'Carga Imagen'}},
            { path:  'imgInv', component: ListadoImagenComponent, canActivate: [AuthGuard], data:{titulo:'Imagenes'}},
            { path:  'ComImg/:id', component: ComplementoImagenComponent,canActivate: [AuthGuard], data:{titulo:'Complemento'}},
            

            { path: 'indicadores', component: ListadoIndicadorPvdComponent, canActivate: [AuthGuard], data:{ titulo:'Indicadores'} },
            { path: 'indicador/:id', component: IndicadorPvdComponent, canActivate: [AuthGuard], data:{ titulo:'Indicador'} },
            
            { path: 'desglosados', component: ListadoDesglosadoComponent, canActivate: [AuthGuard], data:{ titulo:'Desglosados'} },
            { path: 'desglosado/:id', component: DesglosadosComponent, canActivate: [AuthGuard], data:{ titulo:'Desglosado'} },
            
            { path: 'fuentes', component: ListadoFuenteComponent, canActivate: [AuthGuard], data:{ titulo:'Fuentes'} },
            { path: 'fuente/:id', component: FuenteComponent, canActivate: [AuthGuard], data:{ titulo:'Fuentes'} },
            
            { path: 'desagregados', component: ListadoDesagregadoComponent, canActivate: [AuthGuard], data:{ titulo:'Desagregados'} },
            { path: 'desagregado/:id', component: DesagregadoComponent, canActivate: [AuthGuard], data:{ titulo:'Desagregado'} },

            { path: 'IndA2030', component: ListadoIndicadorA2030Component, canActivate: [AuthGuard], data:{ titulo:'Listado Indicador A2030'} },
            { path: 'IndA2030/:id', component: IndA2030Component, canActivate: [AuthGuard], data:{ titulo:'Indicador A2030'} },

            { path: 'UniMed', component: ListadoUniMedidaComponent, canActivate: [AuthGuard], data:{ titulo:'Listado Unidad de Medida'} },
            { path: 'UniMed/:id', component: UnidadMedidaComponent,canActivate: [AuthGuard], data:{ titulo:'Unidad de Medida'} },

            { path: 'EjePnd', component: ListadoEjePndRComponent,canActivate: [AuthGuard], data:{ titulo:'Listado Eje PND'} },
            { path: 'EjePnd/:id', component: EjePndRComponent,canActivate: [AuthGuard], data:{ titulo:'Eje Pnd'} },

            { path: 'region', component: ListadoRegionComponent, data:{ titulo:'Listado Región'} },
             { path: 'region/:id', component: RegionComponent,canActivate: [AuthGuard], data:{ titulo:'Región'} },
            
            
              //Routes para modulos de administracion de los recursos de seguridad  
            //MENU
            { path: 'menuItem/:id', component: MenuItemComponent, canActivate: [AuthGuard], data: { titulo: 'Menú ítem' } },
            { path: 'menuItemListado', component: MenuItemListadoComponent,canActivate: [AuthGuard], data: { titulo: 'Listado menú ítem' } },
            { path: 'menu/:id', component: MenuComponent, canActivate: [AuthGuard],data: { titulo: 'Menú' } },
            { path: 'menuListado', component: MenuListadoComponent, canActivate: [AuthGuard], data: { titulo: 'Listado menú' } },
            { path: 'menuAsigacion/:id/:nombre', component: MenuAsignacionComponent, canActivate: [AuthGuard], data: { titulo: 'Asignación de menú' } },
            //DERECHO
            { path: 'derechoItem/:id', component: DerechoItemComponent, canActivate: [AuthGuard], data: { titulo: 'Derecho ítem' } },
            { path: 'derechoItemListado', component: DerechoItemListadoComponent, canActivate: [AuthGuard], data: { titulo: 'Listado derecho ítem' } },
            { path: 'derecho/:id', component: DerechoComponent, canActivate: [AuthGuard], data: { titulo: 'Derecho' } },
            { path: 'derechoListado', component: DerechoListadoComponent,canActivate: [AuthGuard],  data: { titulo: 'Listado derecho' } },
            { path: 'derechoAsignacion/:id/:nombre', component: DerechoAsigncionComponent, canActivate: [AuthGuard], data: { titulo: 'Asignación de derecho' } },
            { path: 'derechoAsignacion', component: DerechoAsignacionComponent,   data: { titulo: 'Asignación de derecho' } },

            //PERFIL
            { path: 'perfil/:id', component: PerfilComponent, canActivate: [AuthGuard], data: { titulo: 'Perfil' } },
            { path: 'perfilListado', component: PerfilListadoComponent, canActivate: [AuthGuard], data: { titulo: 'Listado perfil' } },
            //USUARIO
            { path: 'usuario/:id', component: UsuarioComponent, canActivate: [AuthGuard], data: { titulo: 'Usuario' } },
            { path: 'usuarioListado', component: UsuarioListadoComponent, canActivate: [AuthGuard], data: { titulo: 'Usuario listado' } },
            { path: 'intermedia/:id', component: IntermediaComponent, canActivate: [AuthGuard], data: { titulo: 'Usuario-Perfil' } },
            { path: 'intermedia', component: UsuarioAsignacionListadoComponent, canActivate: [AuthGuard], data: { titulo: 'Listado Usuario-Perfil' } },
           
            //{ path: 'usuarioAsignacion/:id/:nombre', component: UsuarioAsignacionComponent, canActivate: [AuthGuard], data: { titulo: 'Asignación de perfiles a usuario' } },
           
            // { path: 'indicador/:id', component: IndicadorComponent, data:{ titulo:'Indicador'} },
            { path: '', redirectTo: '/dashboard', pathMatch: 'full' }
        ], canActivate: [AuthGuard] /*Enoc: 2019/09/06: La propiedad CanActivate hace referencia que si la ruta demarcada podrá ser visible o no,
                                                        AuthGuard verificará que exista un usuario en sesion para seguir la direccion, de lo contrario
                                                        redireccionará a la pantalla de inicio de sesión*/
    }
]

export const PAGES_ROUTES = RouterModule.forChild(pagesRoutes)