import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit  {

  constructor() { }

  ngOnInit() {
    /*2019/09/09 Enoc: Tras iniciar sesion, los elementos de TreeVies aun no están cargados, por eso se agrega la inicializacion de la vista
    del arbol, esto solo sucedia tras iniciar sesión */
    // $(new  Document).ready(() => {
    //   const trees: any = $(' [data-widget = "tree"] ');
    //   trees.tree();
    // });

  } 
}
