import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { FuenteService, SidebarService } from '../../../services/service.index';

//modelos
import { FuenteMdl } from '../../../models/fuente.model';

@Component({
  selector: 'app-fuente',
  templateUrl: './fuente.component.html',
  styleUrls: ['./fuente.component.css']
})
export class FuenteComponent implements OnInit {

  fuente: FuenteMdl = new FuenteMdl();
  fuentes: FuenteMdl[] = [];

  formGroup: FormGroup;

  TipoFormulario:any="";

  get f() { return this.formGroup.controls; }


  isSave: boolean = true
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private fuenteService: FuenteService,
    private sideBarService: SidebarService

    ) {
    this.formGroup = this._formbuilder.group(this.fuente);

  }

  ngOnInit() {
     
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id');
    this.TipoFormulario=id;

    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.fuenteService.getFuente(id)
      .subscribe(resp => {
        // console.log(resp);
        this.fuente = resp;
        // console.log(this.fuente);
        this.formGroup = this._formbuilder.group(this.fuente);
      })
    }
  

  }

  guardarDesglosado() {
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    this.fuente=this.formGroup.value;


    Swal.showLoading()

    if (this.fuente.fuenteId) {
      this.fuente.creado = new Date();
      this.fuente.actualizado = new Date();
      this.fuente.actualizadoPor = 1;

      peticion = this.fuenteService.actualizarFuente(this.fuente)
    } else {
      this.fuente.activo = true;
      this.fuente.creadoPor = 1;
      this.fuente.actualizadoPor = 1;
      this.fuente.creado = new Date();
      this.fuente.actualizado = new Date();
      peticion = this.fuenteService.crearFuente(this.fuente)
    }
    peticion.subscribe(resp => {
      Swal.fire({
          title: 'Fuente',
          text: 'Realizado correctamente',
          type: 'success',
        })
      }, 
      error => {
        Swal.fire({
          title: 'Fuente',
          text: 'Error en la peticion',
          type: 'error',
        })
      }
    )
  }


}

