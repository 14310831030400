import { NgModule } from '@angular/core';
import {
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatPaginatorIntl,
  MatPaginatorModule,
  MatTableModule,
  MatProgressSpinnerModule,
  MatSidenavModule,
  MatDialogModule,
  MatDatepickerModule,
  MatCheckboxModule,
  MatTabsModule,
  MatNativeDateModule,
  MatFormFieldModule,
  MatInputModule,
  MatOptionModule,
  MatSelectModule,
  MatBadgeModule,
  MatSliderModule,
  MatAutocompleteModule,
  MatTreeModule,
  MatSnackBarModule,
  MatSortModule,
  MAT_DATE_LOCALE,
  MatRadioModule,
  MatStepperModule,
  MatTooltipModule,
  MatChipsModule
} from '@angular/material';

import { MatExpansionModule, MatListModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';
/* Modulos externos */
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


@NgModule({
  imports: [
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatListModule,
    MatBadgeModule,
    MatSliderModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatTreeModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSortModule,
    MatRadioModule,
    ScrollDispatchModule,
    MatStepperModule,
    MatTooltipModule,
    BrowserAnimationsModule,
    MatChipsModule
    
  ],
  exports: [
    MatMenuModule,
    MatToolbarModule,
    MatIconModule,
    MatCardModule,
    MatTableModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSidenavModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatOptionModule,
    MatSelectModule,
    MatListModule,
    MatBadgeModule,
    MatSliderModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatTreeModule,
    MatTabsModule,
    MatSnackBarModule,
    MatSortModule,
    MatRadioModule,
    ScrollDispatchModule,
    MatStepperModule,
    MatTooltipModule,  
    BrowserAnimationsModule,
    MatChipsModule
  ],
  providers: [
  ]
})
export class MaterialModule { }
