import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { ImagenInversionService, SidebarService } from '../../../services/service.index';

//modelos
import { ImgProyInvMdl } from '../../../models/ImgProy.model';

@Component({
  selector: 'app-ComplementoImagen',
  templateUrl: './comImg.component.html',
  styleUrls: ['./comImg.component.css']
})
export class ComplementoImagenComponent implements OnInit {

  ComImg: ImgProyInvMdl = new ImgProyInvMdl()
  
 
  

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private Service: ImagenInversionService,
    private sideBarService: SidebarService 
    ) { 

      this.formGroup = this._formbuilder.group(this.ComImg);
    }

  ngOnInit() {

    
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.Service.getImg(Number(id))
        .subscribe((resp:ImgProyInvMdl) => {
        
          this.ComImg = resp
          this.ComImg.fechaFoto = new Date(resp.fechaFoto);
  
         this.formGroup = this._formbuilder.group(this.ComImg);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarComplemento(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.ComImg = this.formGroup.value;
    this.ComImg.activo = true;
    this.ComImg.creadoPor =1;
    this.ComImg.actualizado = new Date();
    this.ComImg.actualizadoPor = 1;
   

    
  
    Swal.showLoading()

    if(this.ComImg.imagenesProyectoinversionId){
      this.ComImg.creado = this.ComImg.creado
      peticion = this.Service.actualizarImagen(this.ComImg)

      console.log(this.ComImg)
    }

    else {
      this.ComImg.creado = new Date()
      console.log(this.ComImg)
      peticion = this.Service.crearImagen(this.ComImg)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.ComImg.nombreArchivoOriginal,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.ComImg.nombreArchivoOriginal,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  
}
