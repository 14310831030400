export class ImgProyInvMdl {

    imagenesProyectoinversionId : number;
    creado : Date;
    creadoPor : number;
    actualizado : Date;
    actualizadoPor : number;
    activo : boolean;
    idMinio : string;
    url : string;
    notaFoto : string;
    fechaFoto : Date; 
    nombreArchivoOriginal : string;
    proyectoinversionId : any;
    localidadId : any;
    municipioId : any;
    regionId : any;


    constructor(){

        this.imagenesProyectoinversionId = null;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.activo = true;
        this.idMinio = null;
        this.url = null;
        this.nombreArchivoOriginal = "";
        this.fechaFoto = null;
        this.notaFoto = null;
        this.proyectoinversionId = '';
        this.localidadId = '';
        this.municipioId = '';
        this.regionId = '';

    }
}