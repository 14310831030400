import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { DesglosadosService, SidebarService } from '../../../services/service.index';

//modelos

import { DesglosadosMdl } from '../../../models/desglosados.model';

@Component({
  selector: 'app-desglosado',
  templateUrl: './desglosado.component.html',
  styleUrls: ['./desglosado.component.css']
})
export class DesglosadosComponent implements OnInit {

  desglosado: DesglosadosMdl = new DesglosadosMdl();
  desglosados: DesglosadosMdl[] = [];

  formGroup: FormGroup;

  TipoFormulario:any="";

  get f() { return this.formGroup.controls; }

  isSave: boolean = true
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private desglosadosService: DesglosadosService,
    private sideBarService: SidebarService

    ) {
    this.formGroup = this._formbuilder.group(this.desglosado);
  }

  ngOnInit() {
     
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id');
    this.TipoFormulario=id;

    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.desglosadosService.getDesglosado(id)
      .subscribe(resp => {
        
        console.log(resp);
        this.desglosado = resp;
        console.log(this.desglosado);
        this.formGroup = this._formbuilder.group(this.desglosado);
      })
    }
  }

  guardarDesglosado() {
    
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    this.desglosado=this.formGroup.value;
    this.desglosado.activo = true;
    this.desglosado.creadoPor = 1
    this.desglosado.creado =new Date();
    this.desglosado.actualizado =new Date();
    this.desglosado.actualizadoPor =1;

    Swal.showLoading()

    if (this.desglosado.desglosadosId) {
      this.desglosado.creado = this.desglosado.creado
      peticion = this.desglosadosService.actualizarDesglosado(this.desglosado)
      console.log(this.desglosado)
    }
    else {
      this.desglosado.creado =new Date();
      //this.dependencia.actualizado = Date.now()
      console.log(this.desglosado)
      peticion = this.desglosadosService.crearDesglosado(this.desglosado)
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.desglosado.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.desglosado.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }
    
    )
  }


}

