import { OpcionesDesagregadoMdl } from './opcionesDesagregado.model';

export class DesagregadoMdl {

    desagregadoId: number;
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean
    padreId: any
    nombre: string;
    descripcion: string;
    cantidad: number;
    icono: string;
    tipoDato: string;
    unidadmedidaId: any
    desagregados : any[];
    i_indicadorpvd_desagregado_activo:boolean;
    opciones_desagregados: OpcionesDesagregadoMdl[]
    constructor() {
        this.desagregadoId = null;
        this.creado = new Date();
        this.creadoPor = null;
        this.actualizado = new Date();
        this.actualizadoPor = null;
        this.activo = true;
        this.padreId = null;
        this.nombre = null;
        this.descripcion = null;
        this.cantidad = null;
        this.icono = null;
        this.tipoDato = null;
        this.unidadmedidaId = null;
        this.desagregados=null;
    }

}