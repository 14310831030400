/*import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { VideoMdl } from '../../models/video.model';
import { URL_API } from '../../globals';

@Injectable()
export class VideoService {

  constructor(private http: HttpClient) { }

  getVideos(){
    

    return this.http.get(`${URL_API}/video`)
      .pipe(map(this.crearLista))
      //http://127.0.0.1:8181/sapi/bloque
  }

  eliminarVideo(id:number, video: VideoMdl){
    return this.http.put(`${URL_API}/video/${id}`, 
     video)
  }

  crearVideo(video: VideoMdl){
    
    return this.http.post(`${URL_API}/video`, video)
    .pipe(map((resp:any)=>{
      //video.videoId = resp.videoId
      return video
    }))
  }

  getVideo(id:number){
    return this.http.get(`${URL_API}/video/${id}`)
  }

  actualizarVideo(video: VideoMdl){
    const videoTemp = {
      ...video
    }
    delete videoTemp.videoId
    return this.http.put(`${URL_API}/video/${video.videoId}`, 
      video)
  }

  private crearLista(videoObj: Object){
    const videos: VideoMdl[] = []
    if(videoObj === null){ return [] }

    Object.keys(videoObj).forEach(key => {
      const video: VideoMdl = videoObj[key]
      videos.push(video)
    })

    return videos
  }

}*/


import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { VideoMdl } from '../../models/video.model';
import { URL_API } from '../../globals';

@Injectable()
export class VideoService {

  constructor(private http: HttpClient) { }

  getVideos(){
    return this.http.get(`${URL_API}/video`)
      .pipe(map(this.crearLista))
      
  }

  eliminarVideo(id:number, video: VideoMdl){
    return this.http.put(`${URL_API}/video/${id}`, 
    video)
  }

  crearVideo(video: VideoMdl){
    return this.http.post(`${URL_API}/video`, video)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return video
    }))
  }

  getVideo(id:number){
    return this.http.get<any>(`${URL_API}/video/${id}`)
  }

  actualizarVideo(video: VideoMdl){
    const videoTemp = {
      ...video
    }
    delete videoTemp.videoId
    return this.http.put(`${URL_API}/video/${video.videoId}`, 
      video)
  }

  private crearLista(vidObj: Object){
    const videos: VideoMdl[] = []
    if(vidObj === null){ return [] }

    Object.keys(vidObj).forEach(key => {
      const video: VideoMdl = vidObj[key]
      videos.push(video)
    })

    return videos
  }

}