
export { SidebarService } from "./shared/sidebar.service"
export { DependenciasService } from "./shared/dependencias.service"
export { BloquesService } from "./shared/bloques.service"
export { BloquesPorcentajeService } from "./shared/bloquesPorcentaje.service"
export { SentidoService } from "./shared/sentido.service"
export {EstrategiaPvdService} from "./shared/estrategiapvd.service"
export { IndicadorService } from "./shared/indicador.service"
export { OdsService } from "./shared/ods.service"
export {EjecutorasService} from "./shared/ejecutora.service"
export {FondosService} from "./shared/fondoFin.service"
export {MacrosService} from "./shared/macrobjetivo.service"
export {MetaA2030sService} from "./shared/metaa2030.service"
export {ObjetivosService} from "./shared/objetivoBloque.service"
export {VideoService} from "./shared/video.service"
export {LineaAccionService} from "./shared/lineaaccion.service"
export {InversionService} from "./shared/ProInv.service"
export {PerioricidadService} from "./shared/perioricidad.service"
export {UnidadMedidaService} from "./shared/unidadMedida.service"
export {DesglosadosService} from "./shared/desglosados.service"
export {FuenteService} from "./shared/fuente.service"
export {DesagregadoService} from "./shared/desagregado.service"
export {OpcionDesagregadoService} from "./shared/opcionDesagregado.service"
export {IndicadorPvdDesagregadoService} from "./shared/indicadorpvddesagregado.service"
export { AuthenticationService } from "./shared/authentication.service"; 
export { MenuService } from "./shared/menu.service"; 
export { AuthGuard } from './shared/auth.guard';
export { DerechoService } from './shared/derecho.service';
export { PerfilService } from './shared/perfil.service';
export { UsuarioService } from './shared/usuario.service';
export {A2030Service} from "./shared/a2030.service"
export {FichaTecnicaService} from "./shared/ficha-tecnica.service"
export {IndA2030Service} from "./shared/IndicadorA2030.service";
export {UniMedidaService} from "./shared/UniMedida.service";
export {EjePndRService} from './shared/ejepnd.service';
export {ImagenInversionService} from './shared/ImgProy.service';
export {PeriodicidadRService} from './shared/PeriodicidadR.service';
export {IntUsuPerService} from './shared/IntUsuPer.service';
export {iBloqueDependenciaService} from './shared/ibloquedependencia.service';
export {RegionService } from './shared/region.service';


