import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { ObjetivosService, SidebarService } from '../../../services/service.index';

//modelos
import { ObjetivoBloqueMdl } from '../../../models/objetivoBloque.model';
import { BloqueMdl } from '../../../models/bloque.model';

@Component({
  selector: 'app-objetivo',
  templateUrl: './objetivo.component.html',
  styleUrls: ['./objetivo.component.css']
})
export class ObjetivoComponent implements OnInit {

  objetivo: ObjetivoBloqueMdl = new ObjetivoBloqueMdl()
  
 
  bloque: BloqueMdl = new BloqueMdl()
  bloques: BloqueMdl[] = []
  bloquelctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private objetivoService: ObjetivosService, 
    private  sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.objetivo);
    }

  ngOnInit() {

    this.objetivoService.getBloques()
      .subscribe(res => {
        
        this.bloques = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.objetivoService.getObjetivo(Number(id))
        .subscribe((resp:ObjetivoBloqueMdl) => {
        
          this.objetivo = resp

          this.bloquelctd = resp.bloqueId.bloqueId;
          this.bloque = this.bloques.find(b => b.bloqueId == this.bloquelctd)
          this.objetivo.bloqueId= this.objetivo.bloqueId.bloqueId;
          this.formGroup = this._formbuilder.group(this.objetivo);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarObjetivo(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.objetivo = this.formGroup.value;
    this.objetivo.activo = true;
    this.objetivo.creadoPor =1;
    this.objetivo.actualizado = new Date();
    this.objetivo.actualizadoPor = 1;
    this.objetivo.bloqueId = Number(this.objetivo.bloqueId);

    
  
    Swal.showLoading()

    if(this.objetivo.objetivobloqueId){
      this.objetivo.creado = this.objetivo.creado
      peticion = this.objetivoService.actualizarObjetivo(this.objetivo)

      console.log(this.objetivo)
    }

    else {
      this.objetivo.creado = new Date()
      console.log(this.objetivo)
      peticion = this.objetivoService.crearObjetivo(this.objetivo)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.objetivo.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.objetivo.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  onComiteSlctd(event:any) {
    this.bloque = this.bloques.find( b =>  b.bloqueId === event  )
  }
}

