import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { EstrategiaPvdMdl } from '../../models/estrategiaPvd.model';
import { ObjetivoBloqueMdl } from '../../models/objetivoBloque.model';

@Injectable()
export class EstrategiaPvdService {

  constructor(private http: HttpClient) { }

  getEstrategias(){
    return this.http.get(`${URL_API}/estrategiapvd`)
      .pipe(map(this.ListaEstrategia))
  }

  getObjetivos(){
    return this.http.get(`${URL_API}/objetivobloque`)
      .pipe(map(this.ListaObjetivos))
  }

  eliminarEstrategia(id:number, estrategia: EstrategiaPvdMdl){
    return this.http.delete(`${URL_API}/estrategiapvd/${id}`)
  }

  crearEstrategia(estrategia: EstrategiaPvdMdl){
    return this.http.post(`${URL_API}/estrategiapvd`, estrategia)
    .pipe(map((resp:any)=>{
     // estrategia.estrategiapvdId = resp.estrategiapvdId
      console.info('Guardado: ', resp)
      return estrategia
    }))
  }

  getEstrategia(id:number){
    return this.http.get<any>(`${URL_API}/estrategiapvd/${id}`)
  }

  actualizarEstrategia(estrategia: EstrategiaPvdMdl){
    const estrategiaTemp = {
      ...estrategia
    }
    delete estrategiaTemp.estrategiapvdId
    return this.http.put(`${URL_API}/estrategiapvd/${estrategia.estrategiapvdId}`, 
      estrategia)
  }

  private ListaEstrategia(EstObj: Object){
    const estrategias: EstrategiaPvdMdl[] = []
    if(EstObj === null){ return [] }

    Object.keys(EstObj).forEach(key => {
      const estrategia: EstrategiaPvdMdl = EstObj[key]
      estrategias.push(estrategia)
    })

    return estrategias
  }

  private ListaObjetivos(ObjsObj: Object){
    const objetivos: ObjetivoBloqueMdl[] = []
    if(ObjsObj === null){return []}
   
    Object.keys(ObjsObj).forEach(key => {
      const objetivo: ObjetivoBloqueMdl = ObjsObj[key]
      objetivos.push(objetivo)
    })

    return objetivos
  }
}
