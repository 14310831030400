import { Component, OnInit } from '@angular/core';
import { DesagregadoService, SidebarService } from '../../../services/service.index';
import Swal from 'sweetalert2'
import { DesagregadoMdl } from '../../../models/desagregado.model';

@Component({
  selector: 'app-desagregados',
  templateUrl: './listado-desagregado.component.html',
  styleUrls: ['./listado-desagregado.component.css']
})
export class ListadoDesagregadoComponent implements OnInit {
  desagregados: DesagregadoMdl[] = []
  p: number = 1
  constructor(private service: DesagregadoService,
    private sideBarService: SidebarService) { }

  ngOnInit() {
    this.service.getDesagregados()
      .subscribe(resp => {
        this.desagregados = resp
      })
  }

  borrarDesagregado(desagregado: DesagregadoMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${desagregado.descripcion}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        this.desagregados = this.desagregados.filter( l => l.desagregadoId !== desagregado.desagregadoId)
        desagregado.activo = false;
        console.log(desagregado)
        this.service.eliminarDesagregado(
          desagregado.desagregadoId,
          desagregado
          ).subscribe()
      }
    })
  }
  
}
