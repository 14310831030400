import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { Derecho } from '../../models/Derecho';
import { DerechoItem } from '../../models/DerechoItem';
import { iDerechoDerechoItem } from '../../models/iDerechoDerechoitem';
import { SidebarService } from '../service.index';
import { TreeNode } from 'primeng/api';
import { Subject } from 'rxjs/internal/Subject';
import { Observable } from 'rxjs';

@Injectable()
export class DerechoService {

  selection: any = [];
  selection2: any = [];
  private menuEntidades: any = [];
  private menuEntidadesList = new Subject<TreeNode[]>();

  constructor(private http: HttpClient,
  ) { }

  getArmarLista(lista: any, entidades: any): Observable<TreeNode[]> { 

    this.menuEntidades = entidades; 
    let lista2: TreeNode[] = [];
    let listaFiltrada: TreeNode[] = [];
    console.log(lista2)
    let endPoint: any = [];
    let endPointNombre = "";

    Object.keys(lista).forEach(key => {

      let nombre: string = key.toString();
      let ruta: string[] = nombre.split("/");

      if (ruta[2].startsWith("public")) {

      }
      else {

        if (endPointNombre == ruta[2]) {

          let listaNodos: any[] = this.NodosHijos(lista[key], endPoint.label, key.toString().replace('/' + ruta[1] + '/' + ruta[2], ""), key);
          listaNodos.forEach(element => {
            let nodoNuevo: TreeNode = {};
            nodoNuevo.label = element.label;
            nodoNuevo.data = [];
            nodoNuevo.data.metodo = element.data.metodo;
            nodoNuevo.data.ruta = key;
            nodoNuevo.expandedIcon = "fa fa-edit";
            nodoNuevo.collapsedIcon = "fa fa-edit";
            nodoNuevo.children = [];

            endPoint.children.push(nodoNuevo);
          });

        }
        else {

          endPointNombre = ruta[2];

          if (endPoint.label != null) {
            lista2.push(endPoint);
          }

          let endPointTemp: TreeNode = {};
          endPointTemp.label = endPointNombre;
          endPointTemp.data = "raiz";
          endPointTemp.expandedIcon = "fa fa-folder-open";
          endPointTemp.collapsedIcon = "fa fa-folder";
          endPointTemp.children = [];
          endPoint = endPointTemp;

          let listaNodos: any[] = this.NodosHijos(lista[key], endPoint.label, key.toString().replace('/' + ruta[1] + '/' + ruta[2], ""), key);

          listaNodos.forEach(element => {
            let nodoNuevo: any = [];
            nodoNuevo.label = element.label;
            nodoNuevo.data = [];
            nodoNuevo.data.metodo = element.data.metodo;
            nodoNuevo.data.ruta = key;
            nodoNuevo.expandedIcon = "fa fa-edit";
            nodoNuevo.collapsedIcon = "fa fa-edit";
            nodoNuevo.children = [];
            endPoint.children.push(nodoNuevo);
          });

        }

      }
    });

    
    let NombreReal: string = "";
    lista2.forEach(element => {
      NombreReal = "";

      let valor = this.BuscarChildren(element); 
      try {
        NombreReal = this.BuscarNombre(lista[element.children[valor].data.ruta][element.children[valor].data.metodo]);
  
      }
      catch{
        NombreReal = "";
      }
      if (NombreReal != "") {  
        element.label = NombreReal;
        listaFiltrada.push(element);
      }

    })
    // 
    this.menuEntidadesList.next(listaFiltrada);
    // this.menuEntidadesList.next(lista2);
    return this.menuEntidadesList.asObservable();
  }

  BuscarChildren(element) {
    let index = 0;
    let valor = 1;

    element.children.forEach(child => {
      if (child.label=="Editar registro") {
        valor = index;
      }
      index = index + 1;
    })
    return valor;
  }

  getListaFiltrada(): Observable<TreeNode[]> {
    // this.menuEntidadesList.next([]);
    // return this.menuEntidadesList.asObservable();
    return this.menuEntidadesList.asObservable()
  }

  getDerechos() {
    return this.http.get<any>(`${URL_API}/openapi.json`).pipe(map(resp => {
      this.menuEntidades = resp.components.schemas;
      return resp;
    }));;

  }

  getDerechoItemId(id: number) {
    return this.http.get(`${URL_API}/derechoitem/${id}`);
  }

  getDerecho() {
    return this.http.get(`${URL_API}/derecho`).pipe(map(this.crearLista));
  }

  getDerechoId(id: number) {
    return this.http.get(`${URL_API}/derecho/${id}`);
  }

  actualizarDerecho(id: number, derecho: Derecho) {
    return this.http.put(`${URL_API}/derecho/${id}`, derecho);
  }

  actualizarDerechoItem(id: number, derechoitem: DerechoItem) {
    return this.http.put(`${URL_API}/derechoitem/${id}`, derechoitem);
  }

  crearDerechoItem(derechoitem: DerechoItem) {
    return this.http.post(`${URL_API}/derechoitem`, derechoitem)
  }

  crearDerecho(derecho: Derecho) {
    return this.http.post(`${URL_API}/derecho`, derecho)
  }

  getDerechoItemAsignados(id: string) {
    return this.http.get(`${URL_API}/derechoitem/asignados/${id}`).pipe(map(this.crearListaItem));
  }

  getiDerechoDerechoItem() {
    return this.http.get(`${URL_API}/iderechoderechoitem`).pipe(map(this.crearListaiDerechoDerechoItem));
  }

  saveiDerechoDerechoItem(iDerechoDerechoitem: iDerechoDerechoItem[]) {
    return this.http.put(`${URL_API}/iderechoderechoitem`, iDerechoDerechoitem)
  }

  private crearListaiDerechoDerechoItem(iderechoderechoitemObj: Object) {
    const iderechoderechoitems: iDerechoDerechoItem[] = []
    if (iderechoderechoitemObj === null) { return [] }

    Object.keys(iderechoderechoitemObj).forEach(key => {
      const iderechoderechoitem: iDerechoDerechoItem = iderechoderechoitemObj[key]
      iderechoderechoitems.push(iderechoderechoitem)
    })

    return iderechoderechoitems
  }
  private crearListaItem(derechoitemObj: Object) {
    const derechoitems: DerechoItem[] = []
    if (derechoitemObj === null) { return [] }

    Object.keys(derechoitemObj).forEach(key => {
      const derechoItem: DerechoItem = derechoitemObj[key]
      derechoitems.push(derechoItem)
    })

    return derechoitems
  }

  private crearLista(derechoObj: Object) {
    const derechos: Derecho[] = []
    if (derechoObj === null) { return [] }

    Object.keys(derechoObj).forEach(key => {
      const derecho: Derecho = derechoObj[key]
      derechos.push(derecho)
    })

    return derechos
  }

  NodosHijos(nodo: any, nombreEndPoint, ruta: string, rutaCompleta: string) {
    let listaNodos: TreeNode[] = [];

    Object.keys(nodo).forEach(key2 => {
      let Metodo: TreeNode = {}
      Metodo.label = this.equivalenciaDatos(ruta, key2, nombreEndPoint);
      Metodo.data = nodo[key2];
      Metodo.data.metodo = key2;
      Metodo.expandedIcon = "fa fa-edit";
      Metodo.collapsedIcon = "fa fa-edit";
      Metodo.children = [];
      listaNodos.push(Metodo);
    });

    return listaNodos;
  }


  equivalenciaDatos(nombre, metodo, nombreEndPoint) {

    if (nombre == "" && metodo == "get") {
      return "Listar"
    }
    else if (nombre == "/count" && metodo == "get") {
      return "Contar"
    }
    else if (nombre == "/{from}/{to}" && metodo == "get") {
      return "Buscar por rango"
    }
    else if (nombre == "/{id}" && metodo == "get") {
      return "Ver registro individualmente"
    }
    else if (nombre == "/{id}" && metodo == "delete") {
      return "Eliminar registro"
    }
    else if (nombre == "" && metodo == "post") {
      return "Crear nuevo registro"
    }
    else if (nombre == "/{id}" && metodo == "put") {
      return "Editar registro"
    }
    else {

      let MetodoNombre = "";

      if (metodo == "post") {
        MetodoNombre = "(metodo post) "
      }
      else if (metodo == "get") {
        MetodoNombre = "(metodo get) "
      }
      else if (metodo == "delete") {
        MetodoNombre = "(metodo delete) "
      }
      else if (metodo == "put") {
        MetodoNombre = "(metodo put) "
      }
      else {
        MetodoNombre = metodo
      }

      return nombre + " " + MetodoNombre
    }

  }

  BuscarNombre(nodo) {
    let NombreClase: string = "";
    try {
      NombreClase = nodo.requestBody.content["application/json"].schema.$ref;
      let ruta: string[] = NombreClase.split("/");
      NombreClase = ruta[3];
      NombreClase = this.menuEntidades[NombreClase].xml.name;
    }
    catch{

      try {
        NombreClase = nodo.responses.default.content["application/json"].schema.items.$ref
        let ruta: string[] = NombreClase.split("/");
        NombreClase = ruta[3];
        NombreClase = this.menuEntidades[NombreClase].xml.name;
      }
      catch{
        NombreClase = "";
      }


    }
    return NombreClase;
  }
}
