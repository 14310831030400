import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { DesglosadosMdl } from '../../models/desglosados.model';
import { URL_API } from '../../globals';

@Injectable()
export class DesglosadosService {

  constructor(private http: HttpClient) { }

  getDesglosados(){
    return this.http.get(`${URL_API}/desglosados`)
      .pipe(map(this.crearLista))
  }

  eliminarDesglosado(id:number, desglosados: DesglosadosMdl){
    return this.http.delete(`${URL_API}/desglosados/${id}`)
  }

  crearDesglosado(desglosados: DesglosadosMdl){
    return this.http.post(`${URL_API}/desglosados`, desglosados)
    .pipe(map((resp)=>{
      return resp
    }))
  }

  getDesglosado(id:string){
    return this.http.get<any>(`${URL_API}/desglosados/${id}`)
  }

  actualizarDesglosado(desglosados: DesglosadosMdl){
    const desglosadosTemp = {
      ...desglosados
    }
    delete desglosadosTemp.desglosadosId
    return this.http.put(`${URL_API}/desglosados/${desglosados.desglosadosId}`, desglosados)
  }

  private crearLista(desglosadosObj: Object){
    const desglosados: DesglosadosMdl[] = []
    if(desglosadosObj === null){ return [] }

    Object.keys(desglosadosObj).forEach(key => {
      const desglosado: DesglosadosMdl = desglosadosObj[key]
      desglosados.push(desglosado)
    })

    return desglosados
  }

}