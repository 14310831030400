export class UnidadMedidaMdl {
    activo: number;
    actualizado: number;
    actualizadoPor: number;
    creado: number;
    creadoPor: number;
    descripcion?: any;
    nombre: string;
    unidadmedidaId: number;

    constructor(){
        this.activo=1;
    }

}