export class OdsMdl {
    
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion: string;
    odsId: number;
    

    constructor(){
        this.creado= null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.activo = true;
        this.nombre = "";
        this.descripcion = null;
        this.odsId = null;
    }
}