import { Component, OnInit,OnDestroy } from '@angular/core';
import { OdsService, SidebarService } from '../../../services/service.index';
import Swal from 'sweetalert2'
import { OdsMdl } from '../../../models/ods.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-odsS',
  templateUrl: './listado-ods.component.html',
  styleUrls: ['./listado-ods.component.css']
})
export class ListadoOdsComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  OdsS: OdsMdl[] = []
  p: number = 1
  constructor(private service: OdsService,
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    //----
this.dtOptions = {
  "language": {
    "lengthMenu": "Mostrar _MENU_ Registros por pagina",
    "zeroRecords": "Nothing found - sorry",
    "info": "Total de Registros _TOTAL_ ",
    "infoEmpty": "No Encontrado",
    "infoFiltered": " de un Total de _MAX_ Registros",
    "search": "Buscar",
},
dom: 'Blfrtip',
};
    this.service.getOdsS()
      .subscribe(resp => {
        this.OdsS = resp
        this.OdsS = this.OdsS.filter( b => b.activo == true)
        this.dtTrigger.next();
      })
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  borrarOds(ods: OdsMdl,  i:number){
    
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${ods.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.OdsS = this.OdsS.filter( o => o.odsId !== ods.odsId)
        ods.activo = false;
        console.log(ods)
        this.service.eliminarOds(
          ods.odsId,
          ods
          ).subscribe()
      }
      
    })
  }
  
}
