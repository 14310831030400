import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { AuthenticationService, SidebarService, PerfilService, IntUsuPerService } from '../services/service.index';
import { iUsuarioPerfilMdl } from '../models/iUsuarioPerfil';
import * as jwt_decode from "jwt-decode";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild("InputUsuario", { static: false }) private _inputElement: ElementRef;

  loginForm: FormGroup;
  submitted = false;
  loading = false;
  returnUrl: string;
  error = '';
  user: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private sidebarService: SidebarService,
    private perfilService: PerfilService,
    private iUsuarioPerfil: IntUsuPerService,


  ) { }

  ngAfterViewInit(): void {
    this._inputElement.nativeElement.focus();
  }

  ngOnInit() {

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.compose([ Validators.required , Validators.minLength(6)    ])   ]
    });

    // logout the person when he opens the app for the first time
    //this.authenticationService.logout();


    if (sessionStorage.getItem('currentUser')) {
      this.user = JSON.parse(sessionStorage.getItem('currentUser'));//Enoc: 2019/09/06: Extrae la informacion de seison del usuario del sessionStorage   
    }

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() { return this.loginForm.controls; }
  // on submit
  onSubmit() {
    this.submitted = true;
    // stop if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;

    this.authenticationService.login(this.f.username.value, this.f.password.value, this.authenticationService.nombrePerfil)
      .pipe(first())
      .subscribe(
        data => {

          this.router.navigate([this.returnUrl]);
          this.sidebarService.ActualizarMenu();
        },
        error => {
          if (error == "Unauthorized") {
            this.error = 'Error al iniciar sesión, usuario o contraseña incorrectos';
          }
          this.loading = false;
        }
      )
  }

}
