import { Component, OnInit } from '@angular/core';
import { SidebarService, DerechoService } from './services/service.index';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'app';

  constructor(
    private service: DerechoService,
    private sidebarService: SidebarService,
  ) { }

  ngOnInit() {

    this.service.getDerechos()
      .subscribe(resp => {
        this.sidebarService.menuPermisos.Administrador = resp.paths;
        this.sidebarService.ActualizarMenu();
      });


  }

}
