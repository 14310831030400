import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ejepvd',
  templateUrl: './ejepvd.component.html',
  styleUrls: ['./ejepvd.component.css']
})
export class EjepvdComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
