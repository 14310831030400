//import { Usuario } from './Usuario';

export class MenuItem {
    constructor() {
        this.menuitemId;
        this.creado = 0; 
        this.creadoPor;
        this.actualizado = 0; 
        this.actualizadoPor;
        this.activo = false;       
        this.nombre= '';
        this.descripcion = '';
        this.url = ""; 
        this.menuPadreId;
        this.icono = ""; 
    }
    menuitemId: number;
    creado: number;
    creadoPor: any;
    actualizado: number;
    actualizadoPor: any;
    activo: boolean;   
    nombre: string;
    descripcion: string;
    url: string;
    menuPadreId: MenuItem;
    icono: string;
}