import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { EjepndMdl } from '../../models/ejepnd.model';
import { IndicadorService, A2030Service, FichaTecnicaService, PerioricidadService, SidebarService } from '../../services/service.index';
import { FormControl, FormGroup, Validators, FormArray, FormBuilder } from '@angular/forms';
import { MacrobjetivoMdl } from '../../models/macrobjetivo.model';
import { BloqueMdl } from '../../models/bloque.model';
import { ObjetivoBloqueMdl } from '../../models/objetivoBloque.model';
import { EstrategiaPvdMdl } from '../../models/estrategiaPvd.model';
import { LineaMdl } from '../../models/lineaAccion.model';
import { Meta2030Mdl } from '../../models/meta2030.model';
import { OdsMdl } from '../../models/ods.model';
import { IndicadorPvdMdl } from '../../models/indicadorPvd.model'
import { IndicadorA2030Mdl } from '../../models/indicadorA2030.model'
import { DesagregadoMdl } from '../../models/desagregado.model'
import { ChangeDetectionStrategy } from '@angular/core';
import { FichaItemMdl } from '../../models/FichaItem.model';
import { DatoIndicadorMdl } from '../../models/datoIndicador.model';
import { ComiteMdl } from '../../models/comite.model';
import { RespuestaMdl } from '../../models/respuestaDesagregado.model';
import Swal from 'sweetalert2'
import { EjepvdMdl } from '../../models/ejepvd.model';
import { PerioricidadMdl } from '../../models/perioricidad.model';



@Component({
  selector: 'app-fichatecnica',
  templateUrl: './fichatecnica.component.html',
  styles: [],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FichaTecnicaComponent implements OnInit {

  indicadorGroupForm: FormGroup;
  historicosList: FormArray;

  ejesPnd: EjepndMdl[] = []
  ejesPvd: EjepvdMdl[] = []
  macroObjs: MacrobjetivoMdl[] = []
  // macroObjs: MacroObjtetivoMdl[] = []
  bloquesIndicador: BloqueMdl[] = []
  objetivosBloque: ObjetivoBloqueMdl[] = []
  estrategiasPvd: EstrategiaPvdMdl[] = []
  lineasAccion: LineaMdl[] = []
  metasa2030: Meta2030Mdl[] = []
  indicadora2030: IndicadorPvdMdl[] = []
  indicador2030Slctd: IndicadorPvdMdl
  odslist: OdsMdl[] = []
  comitesList: ComiteMdl[] = []
  odsRelacionadolist: OdsMdl[] = []
  indicadorA2030list: IndicadorA2030Mdl[] = []
  desagregadosList: DesagregadoMdl[] = []
  // tiposIndicador: Tipo[] = []
  dropdownMetaSettings: any = {}
  dropdownODSettings: any = {}
  dropdownIndicadorSettings: any = {}

  selectedODS: any[] = [];
  selectedMetaA2030: any[] = [];
  selectedIndicadorA2030: any[] = [];
  selectedODSrelacionado: any[] = [];

  historico: any = {};
  historicos: any[] = [];
  indicadorSlct: Number

  bloque: BloqueMdl = new BloqueMdl();
  meta: MacrobjetivoMdl = new MacrobjetivoMdl();

  listaDesagregados: FichaItemMdl[] = [];
  datos_desagregados: DatoIndicadorMdl[] = []
  periodicidad_lista: PerioricidadMdl[] = []

  constructor(private service: IndicadorService,
    private a2030Service: A2030Service,
    private fichaService: FichaTecnicaService,
    private periodicidadService: PerioricidadService,
    private _fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private  sideBarService: SidebarService) { 

      this.historico.valor={};
      this.historico.valor.dato="";
      this.historico.observacion={};
      this.historico.observacion.dato="";
      this.historico.valor_opcional={};
      this.historico.valor_opcional.dato="";

     
     
    }

  ngOnInit() {

    this.indicadorGroupForm = this._fb.group({
      ods_id:            new FormControl('', [Validators.required]),
      metaa2030_id:      new FormControl('', [Validators.required]),
      indicadora2030_id: new FormControl('', [Validators.required]),
      ejepnd_id:           new FormControl('', [Validators.required]),
      macroobjetivo_id:           new FormControl('', [Validators.required]),
      ejepvd_id:           new FormControl('', [Validators.required]),
      bloque_id:    new FormControl('', [Validators.required]),
      comite_id:    new FormControl('', [Validators.required]),
      objetivobloque_id:     new FormControl('', [Validators.required]),
      estrategiapvd_id:   new FormControl('', [Validators.required]),
      lineaaccionpvd_id:        new FormControl('', [Validators.required]),
      indicadorpvd_id:   new FormControl('', [Validators.required]),
      
      // tipoIndicador:      new FormControl(''),
      odsRelacionadoSlct: new FormControl(''),
      observaciones: new FormControl('')
    });

    this.periodicidadService.getPerioricidades()
      .subscribe(res => {
        this.periodicidad_lista = res
      })

    this.service.getEjespndList()
      .subscribe(res => {
        this.ejesPnd = res
      })

      this.service.getEjespvdList()
      .subscribe(res => {
        this.ejesPvd = res
      })

    this.service.getBloquesIndicadorList()
      .subscribe(res => {
        this.bloquesIndicador = res
      })

    this.indicador2030Slctd = new IndicadorPvdMdl()
    this.desagregadosList = []

    this.a2030Service.getODSs()
      .subscribe(res => {
        this.odslist = res
      })

    this.fichaService.getIndicadoresFicha()
      .subscribe(res => {
        this.indicadora2030 = res
      })

    this.fichaService.getcomites()
      .subscribe(res => {
          this.comitesList = res
      })

    this.dropdownMetaSettings = {
      singleSelection: false,
      idField: 'metaa2030_id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    this.dropdownODSettings = {
      singleSelection: false,
      idField: 'odsId',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

    this.dropdownIndicadorSettings = {
      singleSelection: false,
      idField: 'indicadora2030_id',
      textField: 'nombre',
      selectAllText: 'Seleccionar todo',
      unSelectAllText: 'Quitar todo',
      itemsShowLimit: 5,
      allowSearchFilter: true
    }

  } 

  ActualizaDesagregados(arreglo, indice) {
    // this.desagregados = arreglo
    this.listaDesagregados[indice].lista = arreglo;
    
  }

  ActualizaHistoricos(arreglo, indice) {
    this.historicos = arreglo;
     console.log(this.historicos)
    // console.log(indice)
  }

  onSelectEje(idEje: number) {
    this.service.getMocaroObj(idEje)
      .subscribe(res => {
        this.macroObjs = res
      })
  }

  onSelectBloque(idBloque: number) {
    this.service.getObjetivoBloquesIndicadorList(idBloque)
      .subscribe(res => {
        this.objetivosBloque = res
      })
  }

  onSelectObjBloque(idObjBlock: number) {
    this.service.getEstrategiaPvdList(idObjBlock)
      .subscribe(res => {
        this.estrategiasPvd = res
      })
  }

  onSelectEstrategiaBloque(idEstrategia: number) {
    this.service.getLineaAccionList(idEstrategia)
      .subscribe(res => {
        this.lineasAccion = res
      })
  }

  onMetaSelect(e: any) {
    const idsMetas = this.indicadorGroupForm.controls.metaa2030_id.value.map(meta => meta.metaa2030_id)
    this.fichaService.getIndicadoresOds(idsMetas).subscribe(res => {
      this.indicadorA2030list = res
    })
  }

  onSelectIndicador(e) {
    // console.log('INDICADOR: ', e)
    this.indicadorSlct = e
    this.fichaService.getIndicadorDetail(e)
      .subscribe(res => {
        // console.info(res)
        this.indicador2030Slctd = res
        this.desagregadosList = this.indicador2030Slctd.desagregados.filter(i => i.i_indicadorpvd_desagregado_activo == true)

        this.listaDesagregados = [];

        this.desagregadosList.forEach(element => {
          let tempDesagregado = new FichaItemMdl();
          let tempCampos: any = {};
          tempDesagregado.desagregado = element.opciones_desagregados[0];

          element.opciones_desagregados.forEach(campo => {
            if(campo.activo === true){
              tempCampos[campo.nombre] = {};
              tempCampos[campo.nombre].valor = "";
              tempCampos[campo.nombre].opcionDesagregadoId = campo.opcion_desagregado_id;
              tempCampos[campo.nombre].nombre = campo.nombre;
              tempCampos[campo.nombre].desagregadoId = campo.desagregado_id;
            }
            // console.log(campo)
          })
          // 
          tempDesagregado.desagregado = element;
          tempDesagregado.OpcionesDesagregado = tempCampos;
          this.listaDesagregados.push(tempDesagregado);
        })
        // 
        // console.info('Desagregados: ', this.desagregadosList)
      })

      
  }

  onIndicadorSelect(e: any) {
    // console.log(e)
  }

  onODSDeseelct(e: any) {
    let odslistNew: Meta2030Mdl[] = Array()
    if (this.indicadorGroupForm.controls.metaa2030_id.value) {
      this.indicadorGroupForm.controls.metaa2030_id.value.map(meta => {
        const meta2030 = this.metasa2030.find(m => m.metaa2030_id === meta.metaa2030_id)
        if (meta2030.ods_id !== e.odsId) {
          odslistNew.push(meta2030)
        }
      })
      this.indicadorGroupForm.controls.metaa2030_id.setValue(odslistNew)
    }
  }

  onMetaDeseelct(e: any) {
    let indicadorListNew: IndicadorA2030Mdl[] = Array()
    if (this.indicadorGroupForm.controls.indicadora2030_id.value) {
      this.indicadorGroupForm.controls.indicadora2030_id.value.map(indi => {
        const indicador = this.indicadorA2030list.find(m => m.indicadora2030_id === indi.indicadora2030_id)
        if (indicador.metaa2030_id !== e.metaa2030_id) {
          indicadorListNew.push(indicador)
        }
      })
      this.indicadorGroupForm.controls.indicadora2030_id.setValue(indicadorListNew)
    }
  }

  onODSSelect(e: any) {
    const idsOds = this.indicadorGroupForm.controls.ods_id.value.map(ods => ods.odsId);

    this.indicadorGroupForm.get('odsRelacionadoSlct').setValue([]);
    this.odsRelacionadolist = this.odslist.filter((item: OdsMdl) => {
      if (!idsOds.includes(item.odsId))
        return item
    })

    this.fichaService.getMetasA2030(idsOds).subscribe(res => {
      // console.log('respuesta: ', res) 
      this.metasa2030 = res;
    })
  }

  onODSRelacionadoSelect(e: any) {
    // console.log(e)
  }

  guardarIndicador() {
    console.log('Guardar')
  }

    parseToSave(fichaObj:any, listaDesagregados: RespuestaMdl[]) {
     // debugger
    let toSave = Object()
    let odsIds = Array()
    let metasIds = Array()
    let indicadoreids = Array()
    let historicosList = Array()
    let odsIdsRel = Array()

    fichaObj.ods_id.map(item => {
      odsIds.push(item.odsId.toString())
    })
    toSave.ods_id = odsIds
    fichaObj.metaa2030_id.map(item => {
      metasIds.push(item.metaa2030_id.toString())
    })
    toSave.metaa2030_id = metasIds

    fichaObj.indicadora2030_id.map(item => {
      indicadoreids.push(item.indicadora2030_id.toString())
    })
    toSave.indicadora2030_id = indicadoreids

    let index = 1
    this.historicos.forEach(element=>{
      let hist:any={};
      
      hist.año=element.valor.anio;
      hist.valor=element.valor.dato;            
      hist.observacion=element.observacion.dato;
      hist.valor_opcional=element.valor_opcional.dato;
      hist.index=index;
      index ++
      historicosList.push(hist);
    })
     console.info('Historicos: ', historicosList)
    fichaObj.odsRelacionadoSlct.forEach(odsRel => {
      odsIdsRel.push(odsRel.odsId.toString())
    })

    // console.log(fichaObj)
    toSave.ejepnd_id = Number(fichaObj.ejepnd_id)
    // toSave.estrategiapvd_id = fichaObj.estrategiapvd_id
    toSave.macroobjetivo_id = Number(fichaObj.macroobjetivo_id)
    toSave.ejepvd_id = Number(fichaObj.ejepvd_id)
    toSave.bloque_id = Number(fichaObj.bloque_id)
    toSave.comite_id = Number(fichaObj.comite_id)
    toSave.objetivobloque_id = Number(fichaObj.objetivobloque_id)
    toSave.estrategiapvd_id = Number(fichaObj.estrategiapvd_id)
    toSave.lineaaccionpvd_id = Number(fichaObj.lineaaccionpvd_id)
    toSave.datos_desagregados = {}
    toSave.historicos = historicosList;
    toSave.observaciones = fichaObj.observaciones
    toSave.indicadorpvd_id = Number(fichaObj.indicadorpvd_id)
    toSave.datos_desagregados = listaDesagregados
    toSave.ods_id_relacionados = odsIdsRel
   
    return toSave
  }

  onSubmitFicha() {
   // debugger
    let desagregados = []
    let indicadroId = Number(this.indicadorSlct)
        // console.log(this.historicos)
    this.listaDesagregados.forEach(desagregado => {
      if(desagregado.lista){
        let index = 1
        for(let i = 0; desagregado.lista.length > i; i++){
          Object.keys(desagregado.lista[i]).forEach(key => {
            if(desagregado.lista[i][key].desagregadoId){ 
              let opcion = new RespuestaMdl()
              opcion.indicadorpvd_id = indicadroId
              opcion.desagregado_id = desagregado.lista[i][key].desagregadoId
              opcion.opcion_desagregado_id = desagregado.lista[i][key].opcionDesagregadoId
              opcion.ficha_id = null;
              opcion.lineabase = "";
              opcion.anio = desagregado.lista[i][key].anio
              opcion.observacion = desagregado.lista[i][key].observacion
              opcion.dato = desagregado.lista[i][key].dato ? desagregado.lista[i][key].dato : '';
              opcion.periodicidad_id = desagregado.lista[i][key].periodicidad_id
              opcion.index = index
              index ++
              desagregados.push(opcion)
            }
          })
        }
      }
    });

    if(this.indicadorGroupForm.valid){  
      const fichaToSave = this.parseToSave(this.indicadorGroupForm.value, desagregados)
      console.info(JSON.stringify(fichaToSave) )
  
      this.fichaService.saveFichaDetail(fichaToSave).subscribe(res => {
         console.log(res)
        if(res.exitoso){
          Swal.fire({
            title:'Ficha Agregada correctamente',
            text:'Realizado correctamente',
            type:'success',
          })
        }else{
          Swal.fire({
            title:'No se pudo agregar la ficha',
            text:'error: Intenta Nuevamente',
            type:'error',
          })
        }
       
      }), error => {
        console.log(error)
        Swal.fire({
          title:'No pudimos Guardar la ficha',
          text:'error',
          type:'error',
        })
      }
    }else{
      Swal.fire({
        title:'Todos los campos son requeriods.',
        text:'error',
        type:'error',
      })
    }
  }

}
