import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-tablaDinamica',
  templateUrl: './tablaDinamica.component.html',
  styleUrls: ['./tablaDinamica.component.css'],

})
export class tablaDinamicaComponent implements OnInit {

  @Input('ObjetoBase') base: any = {};
  @Input('nombre') nombre: string;
  @Input('ObjetoPadre') padre: any = {};
  @Output() public listaDesagregado = new EventEmitter<any>();
  @Output() public showEvent = new EventEmitter<boolean>();

  public show = false;
  listaObjetos: any[] = [];
  // responseList: any[] = [];
  arreglo: any = {};

  constructor(
    private _fb: FormBuilder,
  ) {}

  ngOnInit() {
    
    this.base.Año = "";
  }


  EventoEmiter(item, campo, event) {
    this.listaObjetos[campo].base[item.key] = event;
    // this.responseList[campo] = this.base[campo]
    // console.log(this.listaObjetos[campo], 'el campo: ', campo)   
    // 
    // this.base[campo]
    // const tempo : {}
    let listatemporal: any[] = []
    this.listaObjetos.forEach(element => {
      listatemporal.push(element.base);
    })

    // this.responseList.forEach(item => {
    //   console.log('Item ', item)
    // })

    this.listaDesagregado.emit(listatemporal);
  }

  DeleteForma(index) {
    this.listaObjetos.splice(index, 1)
  }

  AddCampo() {
    this.show = true;
    this.showEvent.emit(this.show);
    let basetemp: any = {};
    let baseobjeto: any = {};
    Object.keys(this.base).forEach(key => {
      baseobjeto[key] = this.base[key];
    })


    basetemp.base = baseobjeto;
    basetemp.forma = this._fb.group(baseobjeto);

    this.listaObjetos.push(basetemp);

  }


}
