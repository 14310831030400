import { Component, OnInit, ViewChild } from '@angular/core';
import { SidebarService, BloquesService } from '../../../services/service.index';
import { DerechoService } from '../../../services/service.index';
import { PerfilService } from '../../../services/service.index';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { MenuPerfil } from '../../../models/Perfil';
import { TreeNode, SelectItem } from 'primeng/api';
import { Tree } from 'primeng/tree';
import { BloqueMdl } from '../../../models/bloque.model';
import { Menu } from '../../../models/Menu';
import { PerfilMdl } from '../../../models/Perfil';
import { Derecho } from '../../../models/Derecho';
import { IPerfilBloqueMdl } from '../../../models/iPerfilBloque';
import { element } from 'protractor';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {
  isSave: boolean = true;
  perfil: PerfilMdl = new PerfilMdl();
  bloques: SelectItem[] = [];
  bloquesSeleccinados: any[] = [];
  listaIPerfilBloqueMdl: IPerfilBloqueMdl[] = [];

  ListaTemporalPerfilBloquesSeleccionado: IPerfilBloqueMdl[] = [];
  ListaTemporalPerfilBloques: IPerfilBloqueMdl[] = []; // lista temporal donde se almacena los permisos guardados en el perfil
  listaDerechoObs: Observable<TreeNode[]>; // lista completa de todos los derechos existentes en el servicio

  listaDerecho: TreeNode[] = []; // lista completa de todos los derechos existentes en el servicio
  listaDerechoSelecionados: TreeNode[] = []; // lista donde se guardan nodos tanto padres como hijos
  listaDerechoSelecionadosHijos: any[] = []; // lista donde solo se guardan los nodos hijos que tienen ruta y metodo
  ListaTemporalPermisos: any[] = []; // lista temporal donde se almacena los permisos guardados en el perfil

  loading: boolean = true;  // sirve para mostrar un icono de carga en el arbol

  menuPerfil: MenuPerfil[] = []; // clase para almacenar una lista de ruta y metodos

  openApiPermisos: any = [];

  id = "";

  @ViewChild('expandingTree', { static: false }) expandingTree: Tree;

  constructor(
    private perfilService: PerfilService,
    private route: ActivatedRoute,
    private service: DerechoService,
    private sideBarService: SidebarService,
    private bloquesService: BloquesService,
    private _router: Router,

  ) {

  }



  ngOnInit() { 

    //////// define si es edicion o perfil nuevo
    this.id = this.route.snapshot.paramMap.get('id');

    if (this.id !== 'new') {
      this.isSave = false
      //Editar
      this.perfilService.getPerfilBloque(Number.parseInt(this.id)).subscribe((res) => {

        this.ListaTemporalPerfilBloques = res;

      

        this.perfilService.getPerfilId(Number(this.id))
          .subscribe((res: PerfilMdl) => {
            this.perfil = res
            this.ListaTemporalPermisos = JSON.parse(this.perfil.derechos);

            this.BuscarDerechos();

            //// carga los bloque que podra ver el perfil
            this.bloquesService.getBloquesSinFiltro().subscribe((resp) => {

              resp.forEach(element => {
                var iPerfilBloque: IPerfilBloqueMdl = new IPerfilBloqueMdl();
                iPerfilBloque.perfilId = Number(this.id);
                iPerfilBloque.bloqueId = element;
                this.listaIPerfilBloqueMdl.push(iPerfilBloque);
              })
              this.BuscarBloquesSeleccionado();
            })
            //// carga los bloque que podra ver el perfil
          })
      })
    }
    else {

      this.bloquesService.getBloques().subscribe((res) => {
        var nodoLista: SelectItem[] = [];

        res.forEach(element => {

          var iPerfilBloque: IPerfilBloqueMdl = new IPerfilBloqueMdl();
          iPerfilBloque.bloqueId = element.bloqueId;
          iPerfilBloque.creadoPor = 1;
          iPerfilBloque.actualizadoPor = 1;

          let nodoTree: any = [];
          nodoTree.label = element.nombre;
          nodoTree.value = iPerfilBloque;
          nodoLista.push(nodoTree);
        })
        this.bloques = nodoLista;
      })

      this.BuscarDerechos();
      this.BuscarBloquesSeleccionado();
    }
    ////// define si es edicion o perfil nuevo
  }

  BuscarDescripcionEndPoint(ruta, metodo) {
    let descipcion: string = "";
    try {
      descipcion = this.openApiPermisos[ruta][metodo].description;
    } catch{
      descipcion = "";
    }
    return descipcion;
  }

  BuscarDerechos() {
    //// se encarga de buscar la lista de endpoints del servicio, y armar una lista para el arbol
    this.service.getDerechos().subscribe((res) => {
      this.openApiPermisos = res.paths;
      this.listaDerechoObs = this.service.getListaFiltrada(); 
    
      this.listaDerechoObs.subscribe(rest1 => {
       
        this.listaDerecho = rest1;
        try {
          this.selectAllRecursive(this.listaDerecho);
        } catch{

        }
        this.loading = false;
      });
      this.service.getArmarLista(res.paths, res.components.schemas).subscribe(rest => {
      });
    })
    //// se encarga de buscar la lista de endpoints del servicio, y armar una lista para el arbol
  }



  BuscarBloquesSeleccionado() {

    this.listaIPerfilBloqueMdl.forEach(element => {
      var tempIperfil: IPerfilBloqueMdl = null;
      this.ListaTemporalPerfilBloques.forEach(bloque => {
        if (bloque.bloqueId.bloqueId == element.bloqueId.bloqueId) {
          tempIperfil = bloque;
        }
      })
      if (tempIperfil) {

        this.ListaTemporalPerfilBloquesSeleccionado.push(tempIperfil);
        var nodoTree: any = [];
        nodoTree.label = tempIperfil.bloqueId.nombre;
        nodoTree.value = tempIperfil;

        this.bloques.push(nodoTree);
        if (tempIperfil.activo == true) {
          this.bloquesSeleccinados.push(tempIperfil);
        }
      }
      else {
        this.ListaTemporalPerfilBloquesSeleccionado.push(element);
        var nodoTree: any = [];
        nodoTree.label = element.bloqueId.nombre;
        nodoTree.value = element;
        this.bloques.push(nodoTree);
      }
    })

    this.actualizaActivoBloques();
  }

  actualizaActivoBloques() {
    this.bloques.forEach(element => {
      element.value.activo = false;
    })

    this.bloquesSeleccinados.forEach(element => {
      element.activo = true;
    })
    // console.log(this.bloques);
    // console.log(this.bloquesSeleccinados);
  }

  guardarPerfil(form: NgForm) {
    if (form.invalid) {
      //Aquí va la validación del form
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    this.perfil.activo = true
    this.perfil.creadoPor = 1;
    this.perfil.actualizado = new Date();
    this.perfil.menuId = 1;
    this.menuPerfil = [];

    var nodoTemp: MenuPerfil = new MenuPerfil();

    ///// arma una lista de métodos y rutas de los endpoints, para asignarlos como permisos
    this.listaDerechoSelecionadosHijos.forEach(element => {
      try { 
        nodoTemp = new MenuPerfil();
        var ruta: string = element.data.ruta;
        var metodo: string = element.data.metodo;
        nodoTemp.ruta = ruta;
        nodoTemp.metodo = metodo;
        this.menuPerfil.push(nodoTemp);
      } catch{
      }
    })
    this.perfil.derechos = JSON.stringify(this.menuPerfil);

    ///// arma una lista de métodos y rutas de los endpoints, para asignarlos como permisos
    this.perfil.bloques = "[]";

    let listaPerfiles: IPerfilBloqueMdl[] = [];
    this.bloques.forEach(element => {

      element.value.perfilId = this.perfil;
      listaPerfiles.push(element.value);
    })

    Swal.showLoading()

    if (this.perfil.perfilId) {
      this.perfil.creado = this.perfil.creado
      this.perfil.actualizado = new Date();
      this.perfil.actualizadoPor = 1;
      peticion = this.perfilService.updatePerfilBloque(listaPerfiles);
      console.log(this.perfilService)
    }
    else {
      this.perfil.creado = new Date();
      this.perfil.actualizado = new Date();
      peticion = this.perfilService.updatePerfilBloque(listaPerfiles);
      console.log(this.perfil)

    }

    peticion.subscribe(resp => {


      this.perfilService.getPerfilId(resp[0].perfilId.perfilId).subscribe((res) => {
        
       this.sideBarService.ActualizarMenu();

        Swal.fire({
          title: this.perfil.nombre,
          text: 'Realizado correctamente',
          type: 'success',
        })

        this._router.navigateByUrl("/").then(success => {
          this._router.navigateByUrl("perfil/" + resp[0].perfilId.perfilId);
        });

      })


    },
      error => {

        Swal.fire({
          title: this.perfil.nombre,
          text: 'error',
          type: 'error',
        })


      });
  }

  derechoChange() {  // cuando cambiar algun permiso, se debe ejecutar para actualizar la lista de nodos hijos
    try {
      this.listaDerechoSelecionadosHijos = [];

      this.listaDerechoSelecionados.forEach(element => {

        false
        if (element.collapsedIcon == "fa fa-edit") {
          let Metodo: any = []
          Metodo.label = element.label;
          Metodo.data = element.data;
          Metodo.expandedIcon = "fa fa-edit";
          Metodo.collapsedIcon = "fa fa-edit";
          Metodo.children = [];
          if (element != []) {
            let temp: TreeNode = element;
            this.listaDerechoSelecionadosHijos.push(temp);
          }
        }
      });
    }
    catch{

    }
  }

  private selectAllRecursive(tree: TreeNode[]) {
    tree.forEach(NodoPadre => {
      var contadorHijos: number = 0;
      NodoPadre.children.forEach(Nodohijos => {

        if (this.BuscarEnArbol(Nodohijos)) {
          contadorHijos = contadorHijos + 1;
          Nodohijos.partialSelected = false;
          this.listaDerechoSelecionados.push(Nodohijos);
        }
      });

      if (NodoPadre.children.length == contadorHijos) {
        NodoPadre.partialSelected = false;
        this.listaDerechoSelecionados.push(NodoPadre);
      }
      else if (contadorHijos > 0) {
        NodoPadre.partialSelected = true;
        this.listaDerechoSelecionados.push(NodoPadre);
      }
    })
    this.derechoChange();
  }

  selectAll() {
    this.listaDerecho.forEach(NodoPadre => {
      this.listaDerechoSelecionados.push(NodoPadre);
      NodoPadre.children.forEach(Nodohijos => {
        this.listaDerechoSelecionados.push(Nodohijos);
      });
    })
    this.derechoChange();
  }

  UncheckTree() {
    this.listaDerechoSelecionados = [];
    this.derechoChange();
  }

  BuscarEnArbol(nombreNodo) {
    var valor = false;
    this.ListaTemporalPermisos.forEach(element => {
      try {
        if (element.ruta == nombreNodo.data.ruta && element.metodo == nombreNodo.data.metodo) {
          valor = true;
        }
      } catch{
        valor = false;
        console.log("error")
      }
    });
    return valor;
  }

}
