import { Component, OnInit } from '@angular/core';
import { IntUsuPerService, SidebarService } from '../../../services/service.index';
import { iUsuarioPerfilMdl } from '../../../models/iUsuarioPerfil';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-usuario',
  templateUrl: './UsuarioAsignacion-Listado.component.html',
  styleUrls: ['./UsuarioAsignacion-Listado.component.css']
})
export class UsuarioAsignacionListadoComponent implements OnInit {
  Intermedias: iUsuarioPerfilMdl[] = []
  p: number = 1
  constructor(private service: IntUsuPerService,
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    this.service.getIntUsuPers()
      .subscribe(resp => {
        this.Intermedias = resp
        this.Intermedias = this.Intermedias.filter( b => b.activo == true)
      })
  }

  borrarIntermedia(int: iUsuarioPerfilMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${int.usuarioId.usuario}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.Intermedias = this.Intermedias.filter( i => i.iusuarioPerfilId !== int.iusuarioPerfilId)
        int.activo = false;
        
        this.service.eliminarIntUsuPer(int.iusuarioPerfilId,int).subscribe()
      }
    })
  }
  
}

