import { IndicadorPvdDesagregadoMdl } from './indicadorPvdDesagregado.model';
import { DesagregadoMdl } from './desagregado.model';

export class IndicadorPvdMdl {

    activo:boolean
    actualizado:Date;
    actualizadoPor: number;
    creado: Date;
    creadoPor: number;
    dependenciaId: any;
    descripcion:string;
    ejepvdId: number;
    estadoId: number;
    estatusindicadorId: number;
    estrategiapvdId: number;
    fechaActualizacion:Date;
    indicadorpvdId: number;
    nombre :string;
  //  indicadorpvdJson:any;
    referentenacional:string;
    objetivonacionalId: number;
    proyeccion2024 :string;
    proyeccion2030 :string;
    lineabase :string;
    aniolineabase :string;
    ultimoValor:string;
    sentidoId: any;
    periodicidadId: any;
    desglosadosId: any;
    unidadmedidaId: any;
    fuenteId: any;
    metodologia:string;
    formula:string;
    variables:string;
    fuentes:string;
    observaciones:string;
    avance:string;
    indicadordesagregados : any[];
    desagregados:DesagregadoMdl[]
    bloqueId: any;


    constructor(){
        this.activo=true;
        this.actualizado=new Date();
        this.actualizadoPor=null;
        this.creado=null;
        this.creadoPor=null;
        this.dependenciaId=null;
        this.descripcion=null;
        this.ejepvdId=null;
        this.estadoId=null;
        this.estatusindicadorId=null;
        this.estrategiapvdId=null;
        this.fechaActualizacion=new Date();
        this.indicadorpvdId=null;
    //    this.indicadorpvdJson=null;
        this.nombre=null;
        this.objetivonacionalId=null;
        this.referentenacional=null;
        this.proyeccion2024="";
        this.proyeccion2030 ="";
        this.lineabase ="";
        this.aniolineabase ="";
        this.ultimoValor="";

        this.sentidoId="";
        this.periodicidadId="";
        this.desglosadosId="";
        this.unidadmedidaId="";
        this.fuenteId="";

        this.indicadordesagregados=[];

        this.metodologia="";
        this.formula="";
        this.variables="";
        this.fuentes="";
        this.observaciones="";
        this.avance="";
        this.bloqueId=null;

    }
    
}