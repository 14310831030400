import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { UnidadMedidaMdl } from '../../models/unidadMedida.model';

@Injectable()
export class UnidadMedidaService {

  constructor(private http: HttpClient) { }

  getUnidadesMedida(){
      return this.http.get(`${URL_API}/unidadmedida`)
      .pipe(map(this.crearLista))
  }

  eliminarUnidadMedida(id:number, unidadMedida: UnidadMedidaMdl){
    return this.http.put(`${URL_API}/unidadmedida/${id}`, 
    unidadMedida)
  }

  crearUnidadMedida(unidadMedida: UnidadMedidaMdl){
    
    return this.http.post(`${URL_API}/unidadmedida`, unidadMedida)
    .pipe(map((resp:any)=>{
      unidadMedida.unidadmedidaId = resp.unidadmedidaId
      return unidadMedida
    }))
  }

  getUnidadMedida(id:number){
    return this.http.get(`${URL_API}/unidadmedida/${id}`)
  }

  actualizarUnidadMedida(unidadMedida: UnidadMedidaMdl){
    const unidadMedidaTemp = {
      unidadMedida
    }
    delete unidadMedidaTemp.unidadMedida.unidadmedidaId
    return this.http.put(`${URL_API}/unidadmedida/${unidadMedida.unidadmedidaId}`, 
    unidadMedida)
  }

  private crearLista(unidadMedidaObj: Object){
    const unidadMedidas: UnidadMedidaMdl[] = []
    if(unidadMedidaObj === null){ return [] }

    Object.keys(unidadMedidaObj).forEach(key => {
      const unidadMedida: UnidadMedidaMdl = unidadMedidaObj[key]
      unidadMedidas.push(unidadMedida)
    })

    return unidadMedidas
  }

}