import { OdsMdl } from './ods.model';

export class MetaA2030Mdl {
    
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    metaa2030Id: number;
    odsId: any;

    constructor(){
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.nombre = null;
        this.metaa2030Id = null;
        this.odsId = null;
    }
}