import { Component, OnInit,OnDestroy } from '@angular/core';
import {  DesglosadosService, SidebarService } from '../../../services/service.index';
import { IndicadorPvdMdl } from '../../../models/indicadorPvd.model';
import Swal from 'sweetalert2'
import { DesglosadosMdl } from '../../../models/desglosados.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-desglosados',
  templateUrl: './listado-desglosado.component.html',
  styleUrls: ['./listado-desglosado.component.css']
})
export class ListadoDesglosadoComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  desglosados: DesglosadosMdl[] = []
  p: number = 1
  constructor(private service: DesglosadosService,
    private sideBarService: SidebarService
    ) { }

  ngOnInit() {
     //----
this.dtOptions = {
 // pagingType: 'full_numbers',
  // pageLength: 10,
  dom: 'Bfrtip',
  // buttons: [    
  //   'excel',    
  // ]    
};
    this.service.getDesglosados()
      .subscribe(data => {
        this.desglosados =  data
        this.dtTrigger.next();
      })
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  borrarDesglosado(desglosado: DesglosadosMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${desglosado.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        this.desglosados = this.desglosados.filter( l => l.desglosadosId !== desglosado.desglosadosId)
        desglosado.activo = false;
        console.log(desglosado)
        this.service.eliminarDesglosado(
          desglosado.desglosadosId,
          desglosado
          ).subscribe()
      }
    })
  }
  
}
