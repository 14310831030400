//import { Usuario } from './Usuario';

export class DerechoItem {
  array: any;
    constructor() {
        this.derechoitemId;
        this.creado = 0; 
        this.creadoPor;
        this.actualizado = 0; 
        this.actualizadoPor;
        this.activo = false;       
        this.nombre= '';
        this.descripcion= '';
    }
    derechoitemId: number;
    creado: number;
    creadoPor: any;
    actualizado: number;
    actualizadoPor: any;
    activo: boolean;   
    nombre: string;
    descripcion: string;
}