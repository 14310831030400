import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { DependenciaMdl } from '../../models/dependencia.model';
import { URL_API } from '../../globals';
import { ComiteMdl } from '../../models/comite.model';

@Injectable()
export class DependenciasService {

  constructor(private http: HttpClient) { }

  getDependencias(){
    return this.http.get(`${URL_API}/dependencia`)
      .pipe(map(this.crearLista))
  }

  getComites(){
    return this.http.get(`${URL_API}/comite`)
      .pipe(map(this.listaComites))
  }

  eliminarDependencia(id:number, dependencia: DependenciaMdl){
    return this.http.delete(`${URL_API}/dependencia/${id}`)
  }

  crearDependencia(dependencia: DependenciaMdl){
    return this.http.post(`${URL_API}/dependencia`, dependencia)
    .pipe(map((resp:any)=>{
     // dependencia.dependenciaId = resp.dependenciaId
      //console.info('Guardado: ', resp)
      return dependencia
    }))
  }

  getDependencia(id:number){
    return this.http.get<any>(`${URL_API}/dependencia/${id}`)
  }

  actualizarDependencia(dependencia: DependenciaMdl){
    const dependenciaTemp = {
      ...dependencia
    }
    delete dependenciaTemp.dependenciaId
    return this.http.put(`${URL_API}/dependencia/${dependencia.dependenciaId}`, 
      dependencia)
  }

  private crearLista(dependenciaObj: Object){
    const dependencias: DependenciaMdl[] = []
    if(dependenciaObj === null){ return [] }

    Object.keys(dependenciaObj).forEach(key => {
      const dependencia: DependenciaMdl = dependenciaObj[key]
      dependencias.push(dependencia)
    })

    return dependencias
  }

  private listaComites(objsComites: Object){
    const comites: ComiteMdl[] = []
    if(objsComites === null){return []}
   
    Object.keys(objsComites).forEach(key => {
      const comite: ComiteMdl = objsComites[key]
      comites.push(comite)
    })

    return comites
  }
}
