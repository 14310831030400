import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';

//rutas
import { APP_ROUTES } from './app.routes';

import { AppComponent } from './app.component';


//Modulos
import { PagesModule } from './pages/pages.module';

//servicios 
import { ServiceModule } from './services/service.module';

//Login y complementos para su uso 
import { LoginComponent } from './login/login.component';
import { ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    FormsModule,
    APP_ROUTES,
    PagesModule,
    ServiceModule,
  ],
  providers: [
     { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },//2019/09/09/ Enoc: Se añade interceptor de peticiones
     { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }//2019/09/09/ Enoc: se añade interceptor de errores
],
  bootstrap: [AppComponent]
})
export class AppModule { }
