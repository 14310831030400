import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { IndicadorPvdDesagregadoMdl } from '../../models/indicadorPvdDesagregado.model';
import { URL_API } from '../../globals';

@Injectable()
export class IndicadorPvdDesagregadoService {

  constructor(private http: HttpClient) { }

  getIndicadorPvdDesagregados(){
    return this.http.get(`${URL_API}/iindicadorpvddesagregado`)
      .pipe(map(this.crearLista))
  }
  
  getIndicadorPvdDesagregadoByIndicadorPvd(idIndicadorPvd){
    return this.http.get(`${URL_API}/iindicadorpvddesagregado/list/`+idIndicadorPvd)
      .pipe(map(this.crearLista))
  }

  eliminarIndicadorPvdDesagregado(id:number, indicadorPvdDesagregado: IndicadorPvdDesagregadoMdl){
    return this.http.delete(`${URL_API}/iindicadorpvddesagregado/${id}`)
  }

  crearIndicadorPvdDesagregado(indicadorPvdDesagregado: IndicadorPvdDesagregadoMdl){
    return this.http.post(`${URL_API}/iindicadorpvddesagregado`, indicadorPvdDesagregado)
    .pipe(map((resp:any)=>{
      indicadorPvdDesagregado.iindicadorpvdDesagregadoId = resp.iindicadorpvdDesagregadoId
      return indicadorPvdDesagregado
    }))
  }

  NuevosIndicadorPvdDesagregado(indicadorPvdDesagregado: IndicadorPvdDesagregadoMdl[]){
    return this.http.post(`${URL_API}/iindicadorpvddesagregado/AgregarOpcionesNuevo`, indicadorPvdDesagregado)
    .pipe(map((resp:any)=>{
      

      return indicadorPvdDesagregado
    }))
  }

  EditIndicadorPvdDesagregado(indicadorPvdDesagregado: IndicadorPvdDesagregadoMdl[]){
    
    return this.http.post(`${URL_API}/iindicadorpvddesagregado/AgregarOpciones`, indicadorPvdDesagregado)
    .pipe(map((resp:any)=>{
      
      return indicadorPvdDesagregado
    }))
  }


  getIndicadorPvdDesagregado(id:string){
    return this.http.get<any>(`${URL_API}/iindicadorpvddesagregado/${id}`)
  }

  actualizarIndicadorPvdDesagregado(indicadorPvdDesagregado: IndicadorPvdDesagregadoMdl){
    const indicadorPvdDesagregadoTemp = {
      ...indicadorPvdDesagregado
    }
    delete indicadorPvdDesagregadoTemp.desagregadoId
    return this.http.put(`${URL_API}/iindicadorpvddesagregado/${indicadorPvdDesagregado.iindicadorpvdDesagregadoId}`, indicadorPvdDesagregado)
  }

  private crearLista(indicadorPvdDesagregadoObj: Object){
    const opcionesindicadorPvdDesagregado: IndicadorPvdDesagregadoMdl[] = []
    if(indicadorPvdDesagregadoObj === null){ return [] }

    Object.keys(indicadorPvdDesagregadoObj).forEach(key => {
      const indicadorPvdDesagregado: IndicadorPvdDesagregadoMdl = indicadorPvdDesagregadoObj[key]
      opcionesindicadorPvdDesagregado.push(indicadorPvdDesagregado)
    })

    return opcionesindicadorPvdDesagregado
  }

}