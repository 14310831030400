export class EstadoMdl {
    creado: number
    creadoPor: number
    actualizado: number
    actualizadoPor: number
    activo: number
    claveentidad:number
    nombre: string
    abreviatura:string
    latitud:any
    longitud:any
    latDecimal:any
    longDecimal:any
    altitud:any
    mapa:any
    estadoId: number
    

    constructor(){
        this.activo = 1
    }
}