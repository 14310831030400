import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { PeriodicidadRMdl } from '../../models/periodicidadReplica.model';
import { URL_API } from '../../globals';

@Injectable()
export class PeriodicidadRService {

  constructor(private http: HttpClient) { }

  getPeriodicidades(){
    return this.http.get(`${URL_API}/periodicidad`)
      .pipe(map(this.ListaPeriodicidad))
      
  }

  eliminarPeriodicidad(id:number, periodicidad: PeriodicidadRMdl){
    return this.http.delete(`${URL_API}/periodicidad/${id}`)
  }

  crearPeriodicidad(periodicidad: PeriodicidadRMdl){
    
    return this.http.post(`${URL_API}/periodicidad`, periodicidad)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return periodicidad
    }))
  }

  getUPeriodicidad(id:number){
    return this.http.get<any>(`${URL_API}/periodicidad/${id}`)
  }

  actualizarPeriodicidad(periodicidad: PeriodicidadRMdl){
    const perioTemp = {
      ...periodicidad
    }
    delete perioTemp.periodicidadId
    return this.http.put(`${URL_API}/periodicidad/${periodicidad.periodicidadId}`, 
    periodicidad)
  }

  private ListaPeriodicidad(perObj: Object){
    const periodicidades: PeriodicidadRMdl[] = []
    if(perObj === null){ return [] }

    Object.keys(perObj).forEach(key => {
      const uni: PeriodicidadRMdl = perObj[key]
      periodicidades.push(uni)
    })

    return periodicidades
  }

}