import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { MacrosService, SidebarService } from '../../../services/service.index';

//modelos
import { MacrobjetivoMdl } from '../../../models/macrobjetivo.model';
import { EjepndMdl } from '../../../models/ejepnd.model';

@Component({
  selector: 'app-macro',
  templateUrl: './macro.component.html',
  styleUrls: ['./macro.component.css']
})
export class MacrobjetivoComponent implements OnInit {

  macroobjetivo: MacrobjetivoMdl = new MacrobjetivoMdl()
  
 
  ejepnd: EjepndMdl = new EjepndMdl()
  ejepnds: EjepndMdl[] = []
  ejepndslctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private macroService: MacrosService,
    private  sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.macroobjetivo);
    }

  ngOnInit() {

    this.macroService.getEjepnds()
      .subscribe(res => {
        
        this.ejepnds = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.macroService.getMacrobjetivo(Number(id))
        .subscribe((resp:MacrobjetivoMdl) => {
        
          this.macroobjetivo = resp

          this.ejepndslctd = resp.ejepndId.ejepndId;
          this.ejepnd = this.ejepnds.find(e => e.ejepndId == this.ejepndslctd)
          this.macroobjetivo.ejepndId= this.macroobjetivo.ejepndId.ejepndId;
          this.formGroup = this._formbuilder.group(this.macroobjetivo);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarMacrobjetivo(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.macroobjetivo = this.formGroup.value;
    this.macroobjetivo.activo = true;
    this.macroobjetivo.creadoPor =1;
    this.macroobjetivo.actualizado = new Date();
    this.macroobjetivo.actualizadoPor = 1;
    this.macroobjetivo.ejepndId = Number(this.macroobjetivo.ejepndId);

    debugger
  
    Swal.showLoading()

    if(this.macroobjetivo.macroobjetivoId){
      this.macroobjetivo.creado = this.macroobjetivo.creado
      peticion = this.macroService.actualizarMacrobjetivo(this.macroobjetivo)

      console.log(this.macroobjetivo)
    }

    else {
      this.macroobjetivo.creado = new Date()
      console.log(this.macroobjetivo)
      peticion = this.macroService.crearMacrobjetivo(this.macroobjetivo)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.macroobjetivo.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.macroobjetivo.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  onComiteSlctd(event:any) {
    this.ejepnd = this.ejepnds.find( eje =>  eje.ejepndId === event  )
  }
}

