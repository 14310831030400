import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { iBloqueDependenciaService, SidebarService } from '../../../services/service.index';

//modelos
import { ibloquedependenciaMdl } from '../../../models/ibloquedependencia.model';
import { DependenciaMdl } from '../../../models/dependencia.model';
import { BloqueMdl } from '../../../models/bloque.model';

@Component({
  selector: 'app-bloque',
  templateUrl: './iblo-dep.component.html',
  styleUrls: ['./iblo-dep.component.css']
})
export class IBloqueDepComponent implements OnInit {

  iblodep: ibloquedependenciaMdl = new ibloquedependenciaMdl()
  
  blo: BloqueMdl = new BloqueMdl()
  blos: BloqueMdl[] = []
  bloslctd: number=0;

  dep: DependenciaMdl = new DependenciaMdl()
  deps: DependenciaMdl[] = []
  depslctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private Service: iBloqueDependenciaService,
    private  sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.iblodep);
    }

  ngOnInit() {

    this.Service.getBloques()
      .subscribe(res => {
        
        this.blos = res
      })

      this.Service.getDependencias()
      .subscribe(res => {
        
        this.deps = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.Service.getIBloDep(Number(id))
        .subscribe((resp:ibloquedependenciaMdl) => {
        
          this.iblodep = resp

          this.bloslctd = resp.bloqueId.bloqueId;
          this.blo = this.blos.find(b => b.bloqueId == this.bloslctd)

          this.depslctd=resp.dependenciaId.dependenciaId
          this.dep = this.deps.find(d => d.dependenciaId == this.depslctd )

          this.iblodep.bloqueId = this.iblodep.bloqueId.bloqueId;
          this.iblodep.dependenciaId = this.iblodep.dependenciaId.dependenciaId
          
          this.formGroup = this._formbuilder.group(this.iblodep);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarIBloDep(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.iblodep = this.formGroup.value;
    this.iblodep.activo = true;
    this.iblodep.creadoPor =1;
    this.iblodep.actualizado = new Date();
    this.iblodep.actualizadoPor = 1;
    this.iblodep.bloqueId = Number(this.iblodep.bloqueId);
    this.iblodep.dependenciaId = Number(this.iblodep.dependenciaId);

    
  
    Swal.showLoading()

    if(this.iblodep.ibloqueDependenciaId){
      this.iblodep.creado = this.iblodep.creado
      peticion = this.Service.actualizarIBloDep(this.iblodep)

      console.log(this.iblodep)
    }

    else {
      this.iblodep.creado = new Date()
      console.log(this.iblodep)
      peticion = this.Service.crearIBloDep(this.iblodep)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.iblodep.bloqueId,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.iblodep.bloqueId,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

}

