export class RespuestaMdl{
    indicadorpvd_id: number;
    desagregado_id: number;
    opcion_desagregado_id: number;
    ficha_id: number;
    periodicidad_id: number;
    lineabase: string;
    anio: string;
    dato: string;
    observacion: string;
    valor_opcional: string;
    index:number
}