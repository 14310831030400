import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {URL_API} from '../../globals';
import {LineaMdl} from '../../models/lineaAccion.model';
import {EstrategiaPvdMdl} from '../../models/estrategiaPvd.model';

@Injectable()

    export class LineaAccionService{
        constructor (private http: HttpClient) { }

        getLineas(){
            return this.http.get(`${URL_API}/lineaaccionpvd`)
            .pipe(map(this.ListaLinea))
        }

        getEstrategias(){
            return this.http.get(`${URL_API}/estrategiapvd`)
            .pipe(map(this.ListaEstrategia))
        }

        eliminarLinea(id:number, linea: LineaMdl){
            return this.http.delete(`${URL_API}/lineaaccionpvd/${id}`)

        }

        crearLinea(linea:LineaMdl){
            return this.http.post(`${URL_API}/lineaaccionpvd`,linea)
            .pipe(map((resp:any) =>{
                console.info('Guardado: ',resp)
                return linea
            }))
        }


        getLinea(id:number){
            return this.http.get<any>(`${URL_API}/lineaaccionpvd/${id}`)

        }

        actualizarLinea(linea:LineaMdl){
            const lineaTemp ={
                ...linea
            }

            delete lineaTemp.lineaaccionpvdId
            return this.http.put(`${URL_API}/lineaaccionpvd/${linea.lineaaccionpvdId}`,
            linea)
        }

        private ListaLinea(linObj: object){
            const lineas : LineaMdl [] = []
            if(linObj ===null){return []}

            Object.keys(linObj).forEach(key =>{
                const linea:LineaMdl = linObj[key]
                lineas.push(linea)
            })
            return lineas
        }

        private ListaEstrategia(EstObj: object){
            const estrategias : EstrategiaPvdMdl [] = []
            if(EstObj ===null){return []}

            Object.keys(EstObj).forEach(key =>{
                const estrategia:EstrategiaPvdMdl = EstObj[key]
                estrategias.push(estrategia)
            })
            return estrategias
        }

    }
