export class PerioricidadMdl {

    activo: number;
    actualizado: number;
    actualizadoPor: number;
    creado: number;
    creadoPor: number;
    descripcion?: any;
    nombre: string;
    perioricidad_id: number;


    constructor(){
        this.activo=1;
    }

}