import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { SidebarService } from './sidebar.service';

@Injectable()
//2019/9/9 Enoc: authGuard ara verificacion de usuario autentificado
export class AuthGuard implements CanActivate {
    constructor(private router: Router,
        private side: SidebarService
    ) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (sessionStorage.getItem('currentUser')) {
            //Si existe el usuario en sesion, devuelve true
            this.side.ActualizarMenu();
           // return true;
            if (state.url=="/login" || state.url=="/" || state.url=="/dashboard") {
                return true;
            } 
            
            let urlTemporal="/"+route.routeConfig.path;

            if(state.url.endsWith("new")){
                urlTemporal=state.url;
            }
            else{
                urlTemporal="/"+route.routeConfig.path;
            }

            if (this.side.BuscarPermisos(urlTemporal)) {
                return true;

            } else {
                this.router.navigate(['/login']);
                return false;
            }

        }
        //de lo contrario redireccionará a login         
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}

