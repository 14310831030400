import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { ibloquedependenciaMdl } from '../../models/ibloquedependencia.model';
import { DependenciaMdl } from '../../models/dependencia.model';
import {BloqueMdl} from '../../models/bloque.model';
import { URL_API } from '../../globals';


@Injectable()
export class iBloqueDependenciaService {

  constructor(private http: HttpClient) { }

  getIBloDeps(){
    return this.http.get(`${URL_API}/ibloquedependencia`)
      .pipe(map(this.ListaIBloDep))
  }

  getBloques(){
    return this.http.get(`${URL_API}/bloque`)
      .pipe(map(this.listabloque))
  }

  getDependencias(){
    return this.http.get(`${URL_API}/dependencia`)
      .pipe(map(this.listadependencia))
  }

  eliminarIBloDep(id:number, ibd: ibloquedependenciaMdl){
    return this.http.delete(`${URL_API}/ibloquedependencia/${id}`)
  }

  crearIBloDep(ibd: ibloquedependenciaMdl){
    return this.http.post(`${URL_API}/ibloquedependencia`, ibd)
    .pipe(map((resp:any)=>{
     // dependencia.dependenciaId = resp.dependenciaId
      //console.info('Guardado: ', resp)
      return ibd
    }))
  }

  getIBloDep(id:number){
    return this.http.get<any>(`${URL_API}/ibloquedependencia/${id}`)
  }

  actualizarIBloDep(ibd: ibloquedependenciaMdl){
    const ibdTemp = {
      ...ibd
    }
    delete ibdTemp.ibloqueDependenciaId
    return this.http.put(`${URL_API}/ibloquedependencia/${ibd.ibloqueDependenciaId}`, 
      ibd)
  }

  private ListaIBloDep(IbldeObj: Object){
    const ibds: ibloquedependenciaMdl[] = []
    if(IbldeObj === null){ return [] }

    Object.keys(IbldeObj).forEach(key => {
      const ibd: ibloquedependenciaMdl = IbldeObj[key]
      ibds.push(ibd)
    })

    return ibds
  }

  private listabloque(Blobjs: Object){
    const bloques: BloqueMdl[] = []
    if(Blobjs === null){return []}
   
    Object.keys(Blobjs).forEach(key => {
      const bloque: BloqueMdl = Blobjs[key]
      bloques.push(bloque)
    })

    return bloques
  }

  private listadependencia(depObj: Object){
    const dependencias:DependenciaMdl [] = []
    if(depObj === null){ return [] }

    Object.keys(depObj).forEach(key => {
      const dependencia: DependenciaMdl = depObj[key]
      dependencias.push(dependencia)
    })

    return dependencias
  }
}

