import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
// import { EjePndMdl } from '../../models/ejepnd.model';
// import { MacroObjtetivoMdl } from '../../models/macroobjetivo.model';
import { BloqueMdl } from '../../models/bloque.model';
import { ObjetivoBloqueMdl } from '../../models/objetivoBloque.model';
import { EstrategiaPvdMdl } from '../../models/estrategiaPvd.model';
import { LineaMdl } from '../../models/lineaAccion.model';
import { Meta2030Mdl } from '../../models/meta2030.model';
import { EjepndMdl } from '../../models/ejepnd.model';
import { MacrobjetivoMdl } from '../../models/macrobjetivo.model';
import { IndicadorPvdMdl } from '../../models/indicadorPvd.model';
import { Observable } from 'rxjs';
import { EjepvdMdl } from '../../models/ejepvd.model';

@Injectable()
export class IndicadorService {

  constructor(private http:HttpClient) { }

  getEjespndList(){
    return this.http.get(`${URL_API}/ejepnd`)
      .pipe(map(this.getListEjespnd))
  }

  getEjespvdList(){
    return this.http.get(`${URL_API}/ejepvd`)
      .pipe(map(this.getListEjespvd))
  }

  getMocaroObj(idEje: number){
    return this.http.get(`${URL_API}/macroobjetivo/list/${idEje}`)
      .pipe(map(this.getMacroObjetivosLst))
  }

  getBloquesIndicadorList(){
    return this.http.get(`${URL_API}/bloque`)
      .pipe(map(this.getListBloques))
  }
  // /webresources/objetivobloque/list/{id}
  getObjetivoBloquesIndicadorList(idbloque: number){
    return this.http.get(`${URL_API}/objetivobloque/list/${idbloque}`)
      .pipe(map(this.getListObjetivosBloques))
  }

  getEstrategiaPvdList(idbloque: number){
    return this.http.get(`${URL_API}/estrategiapvd/list/${idbloque}`)
      .pipe(map(this.getListEstrategiasBloques))
  }
  ///webresources/lineaaccionpvd/list/{id}
  getLineaAccionList(idbloque: number){
    return this.http.get(`${URL_API}/lineaaccionpvd/list/${idbloque}`)
      .pipe(map(this.getListLineaAccion))
  }

  getIndicadores(){
    return this.http.get(`${URL_API}/indicadorpvd`)
      .pipe(map(this.crearLista))
  }

  actualizarIndicador(indicador: IndicadorPvdMdl){
    const indicadorTemp = {
      ...indicador
    }
    delete indicadorTemp.indicadorpvdId
    return this.http.put(`${URL_API}/indicadorpvd/${indicador.indicadorpvdId}`, 
    indicador)
  }

  crearIndicador(indicador: IndicadorPvdMdl){
    return this.http.post(`${URL_API}/indicadorpvd`, indicador)
    .pipe(map((resp:any)=>{
    //  indicador.indicadorpvdId = resp.indicadorpvdId
      console.info('Guardado: ', resp)
      return indicador
    }))
  }
  
  getIndicador(idindicador: any): Observable<any> {
    return this.http.get<any>(`${URL_API}/indicadorpvd/${idindicador}`)

 }

  eliminarIndicador(id:number, indicador: IndicadorPvdMdl){
    return this.http.delete(`${URL_API}/indicadorpvd/${id}`)
  }
  
  getMetas2030(){
    return this.http.get(`${URL_API}/metaa2030`)
      .pipe(map(this.getListMetas))
  }

  private getListMetas(metas: Object) {
    const list: Meta2030Mdl[] = [];
    Object.keys(metas).forEach(key => {
      const pnd: Meta2030Mdl = metas[key]
      list.push(pnd)
    })

    return list;
  }

  private crearLista(indicadorObj: Object){
    const indicadores: IndicadorPvdMdl[] = []
    if(indicadorObj === null){ return [] }

    Object.keys(indicadorObj).forEach(key => {
      const indicador: IndicadorPvdMdl = indicadorObj[key]
      indicadores.push(indicador)
    })

    return indicadores
  }

  private getListLineaAccion(lineas: Object) {
    const list: LineaMdl[] = [];
    Object.keys(lineas).forEach(key => {
      const pnd: LineaMdl = lineas[key]
      list.push(pnd)
    })

    return list;
  }

  private getListEstrategiasBloques(estrategias: Object){
    const list: EstrategiaPvdMdl[] = [];
    Object.keys(estrategias).forEach(key => {
      const pnd: EstrategiaPvdMdl = estrategias[key]
      list.push(pnd)
    })

    return list;
  }

  private getListEjespnd(ejesObj: Object) {
    const list: EjepndMdl[] = []
    if(ejesObj === null){ return [] }
    Object.keys(ejesObj).forEach(key => {
      const pnd: EjepndMdl = ejesObj[key]
      list.push(pnd)
    })

    return list;
  }

  private getListEjespvd(ejesObj: Object) {
    const list: EjepvdMdl[] = []
    if(ejesObj === null){ return [] }
    Object.keys(ejesObj).forEach(key => {
      const pnd: EjepvdMdl = ejesObj[key]
      list.push(pnd)
    })

    return list;
  }

  private getMacroObjetivosLst(macroObj: Object){
    const list: MacrobjetivoMdl[] = []
    if(macroObj === null){ return [] }

    Object.keys(macroObj).forEach(key => {
      const pnd: MacrobjetivoMdl = macroObj[key]
      list.push(pnd)
    })

    return list;
  }

  private getListBloques(bloqueObj: Object){
    const bloques: BloqueMdl[] = []
    if(bloqueObj === null){ return [] }

    Object.keys(bloqueObj).forEach(key => {
      const bloque: BloqueMdl = bloqueObj[key]
      bloques.push(bloque)
    })

    return bloques
  }

  private getListObjetivosBloques(bloqueObj: Object){
    const bloques: ObjetivoBloqueMdl[] = []
    if(bloqueObj === null){ return [] }

    Object.keys(bloqueObj).forEach(key => {
      const bloque: ObjetivoBloqueMdl = bloqueObj[key]
      bloques.push(bloque)
    })

    return bloques
  }
}
