import { NgModule } from "@angular/core";
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { BreadcrumbsComponent } from "./breadcrumbs/breadcrumbs.component";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { NopagefoundComponent } from "./nopagefound/nopagefound.component";



@NgModule({
    imports: [
        RouterModule,
        CommonModule
    ],
    declarations:[
        HeaderComponent,
        FooterComponent,
        BreadcrumbsComponent,
        SidebarComponent,
        NopagefoundComponent       
    ],
    exports:[
        HeaderComponent,
        FooterComponent,
        BreadcrumbsComponent,
        SidebarComponent,
        NopagefoundComponent
    ]
})

export class SharedModule { }