import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { DesagregadoMdl } from '../../models/desagregado.model';
import { URL_API } from '../../globals';

@Injectable()
export class DesagregadoService {

  constructor(private http: HttpClient) { }

  getDesagregados(){
    return this.http.get(`${URL_API}/desagregado`)
      .pipe(map(this.crearLista))
  }

  eliminarDesagregado(id:number, desglosados: DesagregadoMdl){
    return this.http.delete(`${URL_API}/desagregado/${id}`)
  }

  crearDesagregado(desglosados: DesagregadoMdl){
    return this.http.post(`${URL_API}/desagregado`, desglosados)
    .pipe(map((resp:any)=>{
      desglosados.desagregadoId = resp.desglosadosId
      return desglosados
    }))
  }

  getDesagregado(id:string){
    return this.http.get<any>(`${URL_API}/desagregado/${id}`)
  }

  actualizarDesagregado(desglosados: DesagregadoMdl){
    const desglosadosTemp = {
      ...desglosados
    }
    delete desglosadosTemp.desagregadoId
    return this.http.put(`${URL_API}/desagregado/${desglosados.desagregadoId}`, desglosados)
  }

  private crearLista(desglosadosObj: Object){
    const desglosados: DesagregadoMdl[] = []
    if(desglosadosObj === null){ return [] }

    Object.keys(desglosadosObj).forEach(key => {
      const desglosado: DesagregadoMdl = desglosadosObj[key]
      desglosados.push(desglosado)
    })

    return desglosados
  }

}