import { AfterViewInit, Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import { IndicadorService, SidebarService } from '../../../services/service.index';
import { IndicadorPvdMdl } from '../../../models/indicadorPvd.model';
import Swal from 'sweetalert2'
// import { Table } from 'primeng/table';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-indicadorespvd',
  templateUrl: './listado-indicadorpvd.component.html',
  styleUrls: ['./listado-indicadorpvd.component.css']
})
export class ListadoIndicadorPvdComponent implements OnDestroy,OnInit, AfterViewInit {
  dtTrigger: Subject<any> = new Subject<any>();
  indicadores: IndicadorPvdMdl[] = []
  data: any[] = [];
  
  @ViewChild(DataTableDirective, {static: false})
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};

  constructor(private service: IndicadorService,
    private sideBarService: SidebarService,
    private router: Router
  ) { }


  ngOnInit() {

    // this.dtOptions = {
  // dom: 'Blfrtip',
  // processing:true,
  // scrollX:true,
  // responsive:true      
  //   };
  //   this.service.getIndicadores().subscribe(resp=>{
  //     this.indicadores=resp
  //     this.data = resp;

  //       this.data.forEach(element => {
  //         try {
  //           element.NombreDependencia = element.dependenciaId.nombre

  //         } catch {
  //           element.NombreDependencia = "";

  //         }
  //         try {
  //           element.NombreBloque = element.bloqueId.nombre

  //         } catch {
  //           element.NombreBloque = "";
  //         }

  //       })
  //     this.dtTrigger.next();
  //   })


  this.dtOptions = {
    "language": {
      "lengthMenu": "Mostrar _MENU_ Registros por pagina",
      "zeroRecords": "Nothing found - sorry",
      "info": "Total de Registros _TOTAL_ ",
      "infoEmpty": "No Encontrado",
      "infoFiltered": " de un Total de _MAX_ Registros",
      "search": "Buscar",
  },
    dom: 'Blfrtip',
    processing: true,
    scrollX: true,
    responsive: true,
    orderCellsTop: true,
    stateSave: true,
    ajax: (dataTablesParameters: any, callback) => {
  this.service.getIndicadores().subscribe(resp=>{
      this.indicadores=resp
      this.data = resp;

        this.data.forEach(element => {
          try {
            element.NombreDependencia = element.dependenciaId.nombre

          } catch {
            element.NombreDependencia = "";

          }
          try {
            element.NombreBloque = element.bloqueId.nombre

          } catch {
            element.NombreBloque = "";
          }

        })
      // this.dtTrigger.next();
      callback({
        recordsTotal: resp.length,
        recordsFiltered: resp.length,
        data: resp
      });
    });
  },
  columns: [
    { title: 'Id', data: 'indicadorpvdId' },
    { title: 'Nombre', data: 'nombre' },
    { title: 'Descripción', data: 'descripcion' },
    { title: 'Dependencia', data: 'NombreDependencia' },
    { title: 'Bloque', data: 'NombreBloque' },    
    { title: 'Acciones', data: 'indicadorpvdId' }
  ],
  rowCallback: (row: Node, data: IndicadorPvdMdl, index: number) => {
    const self = this;   

    $('#editar', row).off('click');
    $('#editar', row).on('click', () => {
      this.router.navigate(["/indicador/" + data.indicadorpvdId])
    });

    $('#borrar', row).off('click');
    $('#borrar', row).on('click', () => {
      self.borrarLinea(data, index);

    });
    return row;


  },
  createdRow: (row, data: IndicadorPvdMdl, dataIndex) => {
    
    if(this.sideBarService.BuscarPermiso('/webresources/indicadorpvd/{id}','Administrador', 'put')==true){
    $('td', row).eq(5).html("" +      
      "<button id='editar' type='button' class='btn btn-success btn-default btn-sm'>" +
      "<i class='fa fa-edit icon-white'></i>" +
      "</button>")
    }
    if(this.sideBarService.BuscarPermiso('/webresources/indicadorpvd/{id}','Administrador', 'delete')==true){
      $('td', row).eq(5).append("<button id='borrar' type='button' class='btn btn-danger btn-default btn-sm'>" +
      "<i class='fa fa-trash icon-white'></i>" +
      "</button>"
    )
    }
  }
    }//dtOptions
  }//ngOnInit

  ngAfterViewInit(): void { 
    this.dtTrigger.next();

    // this.dtTrigger.subscribe(() => {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {       
        const that = this;
        $('input').on('keyup change', function () {          
          if (dtInstance.column(this['id']).search() !== this['value']) {
            dtInstance
              .columns(this['id'])
              .search(this['value'])
              .draw();
          }
        });
      });
    });
    // });
  }

  rerender(): void {

    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next()
      // this.dtTrigger.next(0);
      console.log(this.datatableElement)
    });
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  borrarLinea(indicador: IndicadorPvdMdl, i: number) {
    Swal.fire({
      title: 'Eliminar',
      text: `Eliminará  ${indicador.nombre}`,
      type: 'question',
      showConfirmButton: true,
      showCancelButton: true
    }).then(resp => {
      if (resp.value) {
        this.indicadores = this.indicadores.filter(l => l.indicadorpvdId !== indicador.indicadorpvdId)
        this.data = this.indicadores.filter(l => l.indicadorpvdId !== indicador.indicadorpvdId)

        indicador.activo = false;
        console.log(indicador)
        this.service.eliminarIndicador(
          indicador.indicadorpvdId,
          indicador
        ).subscribe()
      }
    })
  }

}
