import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { EstrategiaPvdService, SidebarService } from '../../../services/service.index';

//modelos
import { EstrategiaPvdMdl } from '../../../models/estrategiaPvd.model';
import { ObjetivoBloqueMdl } from '../../../models/objetivoBloque.model';

@Component({
  selector: 'app-estrategia',
  templateUrl: './estrategia.component.html',
  styleUrls: ['./estrategia.component.css']
})
export class EstrategiaComponent implements OnInit {

  estrategia: EstrategiaPvdMdl = new EstrategiaPvdMdl()
  
 
  objetivo: ObjetivoBloqueMdl = new ObjetivoBloqueMdl()
  objetivos: ObjetivoBloqueMdl[] = []
  objetivolctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private estrategiaService: EstrategiaPvdService ,
    private sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.estrategia);
    }

  ngOnInit() {

    this.estrategiaService.getObjetivos()
      .subscribe(res => {
        
        this.objetivos = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.estrategiaService.getEstrategia(Number(id))
        .subscribe((resp:EstrategiaPvdMdl) => {
        
          this.estrategia = resp

          this.objetivolctd = resp.objetivobloqueId.objetivobloqueId;
          this.objetivo = this.objetivos.find(o => o.objetivobloqueId == this.objetivolctd)
          this.estrategia.objetivobloqueId= this.estrategia.objetivobloqueId.objetivobloqueId;
          this.formGroup = this._formbuilder.group(this.estrategia);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarEstrategia(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.estrategia = this.formGroup.value;
    this.estrategia.activo = true;
    this.estrategia.creadoPor =1;
    this.estrategia.actualizado = new Date();
    this.estrategia.actualizadoPor = 1;
    this.estrategia.objetivobloqueId = Number(this.estrategia.objetivobloqueId);

    
  
    Swal.showLoading()

    if(this.estrategia.estrategiapvdId){
      this.estrategia.creado = this.estrategia.creado
      peticion = this.estrategiaService.actualizarEstrategia(this.estrategia)

      console.log(this.estrategia)
    }

    else {
      this.estrategia.creado = new Date()
      console.log(this.estrategia)
      peticion = this.estrategiaService.crearEstrategia(this.estrategia)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.estrategia.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.estrategia.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  onComiteSlctd(event:any) {
    this.objetivo = this.objetivos.find( o =>  o.objetivobloqueId === event  )
  }
}

