import { Component, OnInit } from '@angular/core';
import { DerechoService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms'
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { DerechoItem } from '../../../models/DerechoItem';

@Component({
  selector: 'app-derecho-item',
  templateUrl: './derecho-item.component.html',
  styleUrls: ['./derecho-item.component.css']
})
export class DerechoItemComponent implements OnInit {
  derechoitem: DerechoItem = new DerechoItem();
 

  isSave: boolean = true
  constructor(private service: DerechoService,
    private route: ActivatedRoute) { }

  ngOnInit() {
         //obtengo el parametro en la ruta GET    
         const id = this.route.snapshot.paramMap.get('id')    
         if (id !== 'new') {    
           this.isSave = false
           //Editar
           this.service.getDerechoItemId(Number(id))
             .subscribe((res: DerechoItem) => {
               this.derechoitem = res          
             })
         }
  }

  guardaDerechoItem(form: NgForm) {
    if (form.invalid) {
      //Aquí va la validación del form
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    this.derechoitem.activo = true
    this.derechoitem.creadoPor = 1
    this.derechoitem.actualizado = Date.now()    
    Swal.showLoading()
    if (this.derechoitem.derechoitemId) {
      this.derechoitem.creado = this.derechoitem.creado
      this.derechoitem.actualizado = Date.now();
      this.derechoitem.actualizadoPor = 1; 
      peticion = this.service.actualizarDerechoItem(this.derechoitem.derechoitemId, this.derechoitem)
      console.log(this.derechoitem)
    }
    else {
      this.derechoitem.creado = Date.now()
      //this.dependencia.actualizado = Date.now()
      console.log(this.derechoitem)
      peticion = this.service.crearDerechoItem(this.derechoitem)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.derechoitem.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    })
  }
}
