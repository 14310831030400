import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { FondosService, SidebarService } from '../../../services/service.index';

//modelos
import { FondoFinanciamientoMdl } from '../../../models/fondofinanciamiento.model';

@Component({
  selector: 'app-fondo',
  templateUrl: './fondo.component.html',
  styleUrls: ['./fondo.component.css']
})
export class FondoComponent implements OnInit {

  fondo: FondoFinanciamientoMdl = new FondoFinanciamientoMdl();
  fondos: FondoFinanciamientoMdl[] = [];

  formGroup: FormGroup;

  get f() { return this.formGroup.controls; }
  isSave: boolean = true
  
  
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private fondoService: FondosService,
    private  sideBarService: SidebarService
    ) {
    this.formGroup = this._formbuilder.group(this.fondo);
    
  }

  ngOnInit() {

    
     
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.fondoService.getFondo(Number(id))
      .subscribe(resp => {
        
        console.log(resp);
        this.fondo = resp;
        console.log(this.fondo);
        this.formGroup = this._formbuilder.group(this.fondo);
      })
    }
  

  }

  guardarFondo() {
    
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    this.fondo = this.formGroup.value;
   
    Swal.showLoading()

    if (this.fondo.fondofinanciamientoId) {
      this.fondo.creado = new Date();
      this.fondo.actualizado = new Date();
      this.fondo.actualizadoPor = 1;

      peticion = this.fondoService.actualizarFondo(this.fondo)
      console.log(this.fondo)
    }
    else {
      this.fondo.activo = true;
      this.fondo.creadoPor = 1;
      this.fondo.creado =new Date();
      this.fondo.actualizado =new Date();
      this.fondo.actualizadoPor =1;
      peticion = this.fondoService.crearFondo(this.fondo)
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.fondo.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.fondo.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }
    
    )
  }

}


