import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { OpcionDesagregadoMdl } from '../../models/opciondesagregado.model';
import { URL_API } from '../../globals';

@Injectable()
export class OpcionDesagregadoService {

  constructor(private http: HttpClient) { }

  getOpcionesDesagregados(){
    return this.http.get(`${URL_API}/opciondesagregado`)
      .pipe(map(this.crearLista))
  }
  
  getOpcionesDesagregadosByDesagregado(idDesagregado){
    return this.http.get(`${URL_API}/opciondesagregado/list/`+idDesagregado)
      .pipe(map(this.crearLista))
  }

  eliminaropcionDesagregado(id:number, opcionDesagregado: OpcionDesagregadoMdl){
    return this.http.delete(`${URL_API}/opciondesagregado/${id}`)
  }

  crearOpcionDesagregado(opcionDesagregado: OpcionDesagregadoMdl){
    return this.http.post(`${URL_API}/opciondesagregado`, opcionDesagregado)
    .pipe(map((resp:any)=>{
      opcionDesagregado.desagregadoId = resp.desglosadosId
      return opcionDesagregado
    }))
  }

  NuevosOpcionesDesagregado(opcionDesagregado: OpcionDesagregadoMdl[]){
    return this.http.post(`${URL_API}/opciondesagregado/AgregarOpcionesNuevo`, opcionDesagregado)
    .pipe(map((resp:any)=>{
      return opcionDesagregado
    }))
  }

  EditOpcionesDesagregado(opcionDesagregado: OpcionDesagregadoMdl[]){
    return this.http.post(`${URL_API}/opciondesagregado/AgregarOpciones`, opcionDesagregado)
    .pipe(map((resp:any)=>{
      return opcionDesagregado
    }))
  }


  getOpcionDesagregado(id:string){
    return this.http.get<any>(`${URL_API}/opciondesagregado/${id}`)
  }

  actualizarOpcionDesagregado(opcionDesagregado: OpcionDesagregadoMdl){
    const opcionDesagregadoTemp = {
      ...opcionDesagregado
    }
    delete opcionDesagregadoTemp.desagregadoId
    return this.http.put(`${URL_API}/opciondesagregado/${opcionDesagregado.desagregadoId}`, opcionDesagregado)
  }

  private crearLista(opcionDesagregadoObj: Object){
    const opcionesDesagregado: OpcionDesagregadoMdl[] = []
    if(opcionDesagregadoObj === null){ return [] }

    Object.keys(opcionDesagregadoObj).forEach(key => {
      const desglosado: OpcionDesagregadoMdl = opcionDesagregadoObj[key]
      opcionesDesagregado.push(desglosado)
    })

    return opcionesDesagregado
  }

}