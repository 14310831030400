import { EstadoMdl } from './estado.model';
import { RegionMdl } from './region.model';
import { MunicipioMdl } from './municipio.model';

export class LocalidadMdl {
    creado: number
    creadoPor: number
    actualizado: number
    actualizadoPor: number
    activo: number
    clavemunicipio:number
    nombre: string
    ambito:string
    claveCarta:string
    clavelocalidad:number
    pobTotal:number
    pobMasculina:number
    pobFemenina:number
    viviendas:number
    abreviatura:string
    latitud:any
    longitud:any
    latDecimal:any
    longDecimal:any
    altitud:any
    mapa:any
    localidadId: number
    municipioId:MunicipioMdl
    estadoId:EstadoMdl
    regionId:RegionMdl
    

    constructor(){
        this.activo = 1
    }
}




