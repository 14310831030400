import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { URL_API } from '../../globals';
import { Router } from '@angular/router';
import * as jwt_decode from "jwt-decode";
// authentication service is used to LOGIN and LOGOUT of the application
// it posts the creds (username and password) to the backend and check for the response if it has JWT token
// if the response from the backend has jwt token, then the authentication was succesful
// on successful authentication, the user details are stored in the local storage + jwt token
@Injectable({ providedIn: 'root' })
export class AuthenticationService {

    public nombrePerfil: string = "";

    constructor(
        private http: HttpClient,
        private router: Router,
    ) {
        this.nombrePerfil = "";
    }
    // login
    login(email: string, password: string, perfil: string) {//Metodo para el llamado al servicio de login 
        let grantType: string = "password";
        return this.http.post<any>(`${URL_API}/auth/login`, { email, password, grantType })
            .pipe(
                // the backend service sends an instance of the user
                // user: any (because .post<any>)
                map(user => {
                    // login successful if the response has jwt token
                    this.nombrePerfil = perfil;

                    if (user && user.token) {
                        // store user details and jwt token in the local storage to keep the user logged in between page refreshes
                        sessionStorage.setItem('currentUser', JSON.stringify(user));
                        const tokk = jwt_decode(user.token);/*DECODIFICACIÓN DE TOKEN DE AUTH. EN CUANTO SE ENVIE EN EL TOKEN LA INFORMACION
                                                              DE LOS PRIVILEIOS DEL USUAIR OEN SESIÓN, AQUI SERAN TOMADOS Y CARGADOS AL SESION STORAGE POR INDIVIDUAL*/
                        sessionStorage.setItem('perfil', "0");
                    }
                    return user;
                })
            );
    }
    // logout
    logout() { //Enoc: 2019/09/06: Al dar logout elimina la informacion del usuario del sessionStorage y redirecciona a la pagina de Login               
        sessionStorage.removeItem('currentUser'); //Agregar codigo para redireccionar a la pagina de inicio de sesion 
        this.router.navigate(['/login']);
    }
}
