import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../../models/MenuItem';
import { MenuService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { iMenuMenuItem } from '../../../models/imenumenuitem';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-menu-asignacion',
  templateUrl: './menu-asignacion.component.html',
  styleUrls: ['./menu-asignacion.component.css']
})
export class MenuAsignacionComponent implements OnInit {
  menuItems: any;  //Almacena todos los menú items existentes, se agrega como un any para agregar la columna correspondiente al checkbox
  menuItemsAsignados: MenuItem[];
  menuNombre: string;
  menuId: string;
  Save: boolean = false; //Bandera para activar el boton de guardado de cambios
  imenumenuitem: iMenuMenuItem[] = [];
  TodosMenumenuItem: any;

  constructor(private serviceMenu: MenuService, private route: ActivatedRoute) {
    this.menuId = this.route.snapshot.paramMap.get('id');
    this.menuNombre = this.route.snapshot.paramMap.get('nombre');
    this.poblarDatos();


  }

  poblarDatos() { //Funcion encargada de consultar los todos los items, asi como los que ya tiene asignado el menu para realizar las marcas previas
    this.serviceMenu.getMenuItem()
      .subscribe(resp => {
        this.menuItems = resp;
        if (this.menuId) {
          this.serviceMenu.getMenuItemAsignados(this.menuId)
            .subscribe(resp => {
              this.menuItemsAsignados = resp;
              this.menuItems.forEach(e => { //añade campo de seleccion a los datos a desplegar en la tabla          
                if (typeof e === "object") {
                  if (this.menuItemsAsignados.find(mAsig => mAsig.menuitemId === e.menuitemId)) {
                    e["isChecked"] = true
                  }
                  else {
                    e["isChecked"] = false
                  }
                }
              });
            })
        }
        this.serviceMenu.getiMenuMenuItem()
          .subscribe(resp => {
            this.TodosMenumenuItem = resp;
          });
      });
  }

  ngOnInit() {
  }

  clickCheckbox(row: any) { //Click al check para marcar en false su respectivo registro en n
    if (this.menuItems.find(m => (m.menuitemId === row.menuitemId) && (m.menuId === row.menuId)).isChecked)
      this.menuItems.find(m => (m.menuitemId === row.menuitemId) && (m.menuId === row.menuId)).isChecked = false;
    else
      this.menuItems.find(m => (m.menuitemId === row.menuitemId) && (m.menuId === row.menuId)).isChecked = true;
    if (!this.Save)
      this.Save = true;
  }

  guardaCambios() {
    Swal.fire({
      title: 'Espere',
      text: 'Revisando y guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    Swal.showLoading()
    this.menuItems.forEach(m => {
      if (m.isChecked) {//Si el registro está seleccionado en el checkbox de la tabla
        if (!this.menuItemsAsignados.find(mAsig => mAsig.menuitemId === m.menuitemId)) { //pero no existe en el listado de los asignados
          this.addItem(m.menuitemId); //Se agrega el registro a lisado de los que se enviarán a server como un registro nuevo
        }
      } else {//Si el registro no esta seleccionado en el checkbox de la tabla
        if (this.menuItemsAsignados.find(mAsig => mAsig.menuitemId === m.menuitemId)) { //pero existe en el listado de los asignados
          this.addItem(m.menuitemId, 'E');//Se agrega el registro a lisado de los que se enviarán a server como un registro eliminado
        }
      }
    });
    if (this.imenumenuitem.length > 0) { //Si existem registros a grabarse (ya sea eliminación o nuevo) 
      let peticion: Observable<any> = this.serviceMenu.saveiMenuMenuItem(this.imenumenuitem)
      peticion.subscribe(resp => {
        Swal.fire({
          title: this.menuNombre,
          text: 'Realizado correctamente',
          type: 'success',
        })
      });
    } else { //si no hay nada que guardar/actualizar
      Swal.fire({
        title: this.menuNombre,
        text: 'No existen cambios para guardar',
        type: 'warning',
      });
    }
    this.poblarDatos();//Actualiza los datos de la pantalla
    this.Save = false; // Desactiva la bandera para deshabilitar el boton de guardar cambios
  }

  addItem(menuitemId: number, imenumenuitem?: string) {
    if (imenumenuitem == "E") {  // si es un registro de eliminacion       
      var menuMenuItem = this.TodosMenumenuItem.find(mnuItm => mnuItm.menuitemId.menuitemId === menuitemId && String(mnuItm.menuId.menuId) === this.menuId);
      
      menuMenuItem.activo = 0;
      menuMenuItem.actualizado = Date.now();
      menuMenuItem.actualizadoPor = 1; // Actualmente se pasa como ide de actualizado un 1, esto debe cambiarse al id del usuario en sesion cuando se tenga el manejo de la misma
      this.imenumenuitem.push(menuMenuItem);
    } else { // si es un registro nuevo para el usuario determinado  
      var menuMenuItem: any;
      menuMenuItem = {};
      menuMenuItem.activo = 1;
      menuMenuItem.actualizado = Date.now();
      menuMenuItem.actualizadoPor = 1;// Actualmente se pasa como ide de actualizado un 1, esto debe cambiarse al id del usuario en sesion cuando se tenga el manejo de la misma
      menuMenuItem.creado = Date.now();
      menuMenuItem.creadoPor = 1; // Actualmente se pasa como ide de actualizado un 1, esto debe cambiarse al id del usuario en sesion cuando se tenga el manejo de la misma      
      menuMenuItem.menuitemId = menuitemId;
      menuMenuItem.menuId = +this.menuId;
      this.imenumenuitem.push(menuMenuItem);
    }
  }



}
