import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { LineaAccionService, SidebarService } from '../../../services/service.index';

//modelos
import { LineaMdl } from '../../../models/lineaAccion.model';
import { EstrategiaPvdMdl } from '../../../models/estrategiaPvd.model';

@Component({
  selector: 'app-linea',
  templateUrl: './linea.component.html',
  styleUrls: ['./linea.component.css']
})
export class LineaAccionComponent implements OnInit {

  linea: LineaMdl = new LineaMdl()
  
 
  estrategia: EstrategiaPvdMdl = new EstrategiaPvdMdl()
  estrategias: EstrategiaPvdMdl[] = []
  estrategialctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private lineaService: LineaAccionService , 
    private  sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.linea);
    }

  ngOnInit() {

    this.lineaService.getEstrategias()
      .subscribe(res => {
        
        this.estrategias = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.lineaService.getLinea(Number(id))
        .subscribe((resp:LineaMdl) => {
        
          this.linea = resp

          this.estrategialctd = resp.estrategiapvdId.estrategiapvdId;
          this.estrategia = this.estrategias.find(e => e.estrategiapvdId == this.estrategialctd)
          this.linea.estrategiapvdId= this.linea.estrategiapvdId.estrategiapvdId;
          this.formGroup = this._formbuilder.group(this.linea);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarLinea(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.linea = this.formGroup.value;
    this.linea.activo = true;
    this.linea.creadoPor =1;
    this.linea.actualizado = new Date();
    this.linea.actualizadoPor = 1;
    this.linea.estrategiapvdId = Number(this.linea.estrategiapvdId);

    
  
    Swal.showLoading()

    if(this.linea.lineaaccionpvdId){
      this.linea.creado = this.linea.creado
      peticion = this.lineaService.actualizarLinea(this.linea)

      console.log(this.linea)
    }

    else {
      this.linea.creado = new Date()
      console.log(this.linea)
      peticion = this.lineaService.crearLinea(this.linea)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.linea.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.linea.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  onComiteSlctd(event:any) {
    this.estrategia = this.estrategias.find( e =>  e.estrategiapvdId === event  )
  }
}


