/*import { Usuario} from './Usuario';
import { Perfil } from './Perfil';

export class iUsuarioPerfil {
    constructor() {
        this.iUsuarioPerfilId;
        this.creado = 0; 
        this.creadoPor;
        this.actualizado = 0; 
        this.actualizadoPor;
        this.activo = false;       
     
    }
    iUsuarioPerfilId: number;
    creado: number;
    creadoPor: any;
    actualizado: number;
    actualizadoPor: any;
    activo: boolean;   
    usuarioId: Usuario;
    perfilId: Perfil;
    
}*/

import {PerfilMdl} from './Perfil';

export class iUsuarioPerfilMdl{

    iusuarioPerfilId:number;
    creado:Date;
    creadoPor:number;
    actualizado:Date;
    actualizadoPor:number;
    activo:boolean;
    perfilId:any;
    usuarioId:any;

        constructor(){
            this.iusuarioPerfilId = null;
            this.creado = null;
            this.creadoPor = null;
            this.actualizado = null;
            this.actualizadoPor = null;
            this.activo = true;
            this.perfilId = null;
            this.usuarioId = '';
        }
}