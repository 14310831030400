import { RespuestaMdl } from './respuestaDesagregado.model';
import { OdsMdl } from './ods.model';
import { Meta2030Mdl } from './meta2030.model';
import { IndicadorA2030Mdl } from './indicadorA2030.model';

export class desa {
nombre:string;
desagregado_id:number;
constructor(){
    this.nombre=null
    this.desagregado_id=0
}
}

export class FichaItemMdl {
    desagregado: any;
    OpcionesDesagregado: any;
    lista:any[]=[];
    listaDatosDesagregado:any[]=[];
    desagregado2:desa
    
    constructor(){
        this.desagregado = null;
        this.lista = null;
    }
}

export class FichaMdl {

    fichaId: any;
    creado: any;
    creadoPor: any;
    actualizado: any;
    actualizadoPor: any;
    activo: boolean;
    odsId: any;
    metaa2030Id: any;
    indicadora2030Id:any;
    datosDesagregados:any[];
    observaciones:"";
    historicos:any;
    bloqueId:any;
    comiteId:any;
    ejepndId:any;
    ejepvdId:any;
    estrategiapvdId:any;
    indicadorpvdId:any;
    lineaaccionpvdId:any;
    macroobjetivoId:any;
    objetivobloqueId:any;
    odsIdRelacionados:any;

    constructor(){
        
    }
}

export class FichaPesistenciaMdl {

    ficha_id: any={};   
    ods_id: OdsMdl[]=[];
    metaa2030_id: Meta2030Mdl[]=[];
    indicadora2030_id: IndicadorA2030Mdl[]=[];
    ods_id_relacionados: OdsMdl[]=[];
    ejepnd_id: any={};
    macroobjetivo_id: any={};
    ejepvd_id: any={};
    bloque_id: any={};   
    comite_id: any={};
    objetivobloque_id:any={};
    estrategiapvd_id:any={};
    lineaaccionpvd_id:any={};
    indicadorpvd_id:any={};
    datos_desagregados: RespuestaMdl[]=[];
    observaciones:string="";
    historicos: any[]=[];
   
    constructor(){
        
    }
}