import { Component, OnInit,OnDestroy } from '@angular/core';
import { iBloqueDependenciaService, SidebarService } from '../../../services/service.index';
import { ibloquedependenciaMdl } from '../../../models/ibloquedependencia.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-macros',
  templateUrl: './listado-iblodep.component.html',
  styleUrls: ['./listado-iblodep.component.css']
})
export class ListadoIbloDepComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  iblodeps: ibloquedependenciaMdl[] = []
  p: number = 1
  constructor(private service: iBloqueDependenciaService, 
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    this.dtOptions = {
      "language": {
        "lengthMenu": "Mostrar _MENU_ Registros por pagina",
        "zeroRecords": "Nothing found - sorry",
        "info": "Total de Registros _TOTAL_ ",
        "infoEmpty": "No Encontrado",
        "infoFiltered": " de un Total de _MAX_ Registros",
        "search": "Buscar",
    },
      dom: 'Bfrtip',
    };
    this.service.getIBloDeps()
      .subscribe(resp => {
        this.iblodeps = resp
        this.iblodeps = this.iblodeps.filter( b => b.activo == true)
        this.dtTrigger.next();
      })
     
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  borrarIbloDep(bd: ibloquedependenciaMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${bd.ibloqueDependenciaId}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.iblodeps = this.iblodeps.filter( ibd => ibd.ibloqueDependenciaId !== bd.ibloqueDependenciaId)
        bd.activo = false;
        console.log(bd)
        this.service.eliminarIBloDep(
          bd.ibloqueDependenciaId,
          bd
          ).subscribe()
      }
    })
  }
  
}
