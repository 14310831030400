
import { Component, OnInit } from '@angular/core';
import { EjecutorasService } from '../../../services/service.index';
import { EjecutoraMdl } from '../../../models/ejecutora.model';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-ejecutoras',
  templateUrl: './listado-ejecutora.component.html',
  styleUrls: ['./listado-ejecutora.component.css']
})
export class ListadoEjecutoraComponent implements OnInit {
  ejecutoras: EjecutoraMdl[] = []
  p: number = 1
  constructor(private service: EjecutorasService) { }

  ngOnInit() {
    this.service.getEjecutoras()
      .subscribe(resp => {
        this.ejecutoras = resp
        this.ejecutoras = this.ejecutoras.filter( b => b.activo == true)
      })
  }

  borrarEjecutora(ejecutora: EjecutoraMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${ejecutora.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.ejecutoras = this.ejecutoras.filter( e => e.ejecutoraId !== ejecutora.ejecutoraId)
        ejecutora.activo = false;
        console.log(ejecutora)
        this.service.eliminarEjecutora(
          ejecutora.ejecutoraId,
          ejecutora
          ).subscribe()
      }
    })
  }
  
}
