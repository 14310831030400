import { Component, OnInit,OnDestroy,AfterViewInit,ViewChild } from '@angular/core';
import { IndA2030Service, SidebarService } from '../../../services/service.index';
import { IndicadorA2030RMdl } from '../../../models/IndicadorA2030Replica.model';
import Swal from 'sweetalert2'
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-indicadores',
  templateUrl: './listadoIndA2030.component.html',
  styleUrls: ['./listadoIndA2030.component.css']
})
export class ListadoIndicadorA2030Component implements OnDestroy,OnInit,AfterViewInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;

  indicadores: IndicadorA2030RMdl[] = []
  p: number = 1
  constructor(private service: IndA2030Service, 
    private  sideBarService: SidebarService,private router: Router) { }

  ngOnInit() {    
      this.dtOptions = {
        "language": {
          "lengthMenu": "Mostrar _MENU_ Registros por pagina",
          "zeroRecords": "Nothing found - sorry",
          "info": "Total de Registros _TOTAL_ ",
          "infoEmpty": "No Encontrado",
          "infoFiltered": " de un Total de _MAX_ Registros",
          "search": "Buscar",
        },
      dom: 'Blfrtip',
      processing: true,
      scrollX: true,
      responsive: true,
      orderCellsTop: true,
      stateSave: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.service.getIndicadoresA2030()
        .subscribe(resp => { console.log(resp)
          this.indicadores = resp
          this.indicadores = this.indicadores.filter( b => b.activo == true)
          // this.dtTrigger.next();
          callback({
            recordsTotal: resp.length,
            recordsFiltered: resp.length,
            data: resp
          });
        })
      },
      columns: [
        { title: 'Id', data: 'indicadora2030Id' },
        { title: 'Nombre ', data: 'nombre' },
        { title: 'Meta A2030', data: 'metaa2030Id.nombre' },     
        { title: 'Acciones', data: 'indicadora2030Id' }
      ],
      rowCallback: (row: Node, data: IndicadorA2030RMdl, index: number) => {
        const self = this;
        $('#editar', row).off('click');
        $('#editar', row).on('click', () => {
          this.router.navigate(["/IndA2030/" + data.indicadora2030Id])
        });
  
        $('#borrar', row).off('click');
        $('#borrar', row).on('click', () => {
          self.borrarIndicador(data, index);
  
        });
        return row;
      },
      createdRow: (row, data: IndicadorA2030RMdl, dataIndex) => {  
  
        if(this.sideBarService.BuscarPermiso('/webresources/indicadora2030/{id}', 'Administrador', 'put')==true ){
          $('td', row).eq(3).html(""+
          "<button id='editar' type='button' class='btn btn-success btn-default btn-sm'>" +
            "<i class='fa fa-edit icon-white'></i></button>"
          )        
        }
  
        if(this.sideBarService.BuscarPermiso('/webresources/indicadora2030/{id}', 'Administrador', 'delete')==true ){
          $('td', row).eq(3).append(""+
          "<button id='borrar' type='button' class='btn btn-danger btn-default btn-sm'>" +
          "<i class='fa fa-trash icon-white'></i></button>" 
          )          
        }
      }  
    };    
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
    // this.dtTrigger.subscribe(() => {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.columns().every(function () {
        const that = this;
        $('input').on('keyup change', function () {
          if (dtInstance.column(this['id']).search() !== this['value']) {
            dtInstance
              .column(this['id'])
              .search(this['value'])
              .draw();
          }

        });
      });
    });
    // });

  }
  
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  rerender(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next()   
    });
  }

  borrarIndicador(indicador: IndicadorA2030RMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${indicador.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
      this.indicadores = this.indicadores.filter( i => i.indicadora2030Id !== indicador.indicadora2030Id)
        indicador.activo = false;
        console.log(indicador)
        this.service.eliminarIndicadorA2030(
          indicador.indicadora2030Id,
          indicador
          ).subscribe()
          this.rerender();
      }
    })
  }
  
}
