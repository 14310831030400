import { Component, OnInit } from '@angular/core';
import {  RegionMdl } from '../../../models/region.model';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'
import { RegionService,SidebarService } from '../../../services/service.index';

@Component({
    selector: 'app-region',
    templateUrl: './region.component.html',
    styles: []
  })

  export class RegionComponent implements OnInit {

    region: RegionMdl = new RegionMdl();
    regiones: RegionMdl[] = [];
    
    formGroup: FormGroup;

  TipoFormulario:any="";

  get f() { return this.formGroup.controls; }
  isSave: boolean = true

  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private regionService: RegionService,
    private sideBarService: SidebarService

    ) {
    this.formGroup = this._formbuilder.group(this.region);

  }
    ngOnInit() {
     
      const id = this.route.snapshot.paramMap.get('id');
      this.TipoFormulario=id;
      if (id !== 'new') {
        this.isSave = false
        //Editar
        this.regionService.getReg(Number(id))
        .subscribe(resp => {
          this.region = resp;
          this.formGroup = this._formbuilder.group(this.region);
        })
      }
     
    }

    guardarRegion() {
      if (this.formGroup.invalid) {
        //Aquí va la validación del form
        return
      }
      let peticion: Observable<any>
      Swal.fire({
        title: 'Espere',
        text: 'Guardando información',
        type: 'info',
        allowOutsideClick: false
      })
      this.region=this.formGroup.value;
      Swal.showLoading();
      console.log(this.region)

      if (this.region.regionId) {
        this.region.creado = new Date();
        this.region.actualizado = new Date();
        this.region.actualizadoPor = 48;  
        peticion = this.regionService.actualizarRegion(this.region)
      }else {
        this.region.activo = true;
        this.region.creadoPor = 1;
        this.region.actualizadoPor = 1;
        this.region.creado = new Date();
        this.region.actualizado = new Date();
        peticion = this.regionService.crearRegion(this.region)
      }
      peticion.subscribe(resp => {
        Swal.fire({
            title: 'Región',
            text: 'Realizado correctamente',
            type: 'success',
          })
        },
        error => {
          Swal.fire({
            title: 'Región',
            text: 'Error en la peticion',
            type: 'error',
          })
        } 
      )

    }//guardar


  }