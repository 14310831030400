import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { A2030Mdl } from '../../models/a2030.model';
import { OdsMdl } from '../../models/ods.model';
import { URL_API } from '../../globals';
import { ComiteMdl } from '../../models/comite.model';

@Injectable()
export class A2030Service {

  constructor(private http: HttpClient) { }

  getODSs(){
    // return this.http.get(`${URL_API}/api/a2030/comite`)
    return this.http.get(`${URL_API}/ods`)
      .pipe(map(this.crearLista))
  }

  getComites(){
    return this.http.get(`${URL_API}/comite`)
      .pipe(map(this.listaComites))
  }

  eliminarA2030(id:number, a2030: A2030Mdl){
    return this.http.put(`${URL_API}/a2030/put/${id}`, 
     a2030)
  }

  crearA2030(a2030: A2030Mdl){
    return this.http.post(`${URL_API}/a2030/add`, a2030)
    .pipe(map((resp:any)=>{
      a2030.a2030Id = resp.a2030Id
      // console.info('Guardado: ', resp)
      return a2030
    }))
  }

  getA2030(id:number){
    return this.http.get(`${URL_API}/a2030/${id}`)
  }

  actualizarA2030(a2030: A2030Mdl){
    const a2030Temp = {
      ...a2030
    }
    delete a2030Temp.a2030Id
    return this.http.put(`${URL_API}/a2030/put/${a2030.a2030Id}`, 
      a2030)
  }

  private crearLista(odssObj: Object){
    const odsList: OdsMdl[] = []
    if(odssObj === null){ return [] }

    Object.keys(odssObj).forEach(key => {
      const a2030: OdsMdl = odssObj[key]
      odsList.push(a2030)
    })
    // console.log(odsList)
    return odsList
  }

  private listaComites(objsComites: Object){
    const comites: ComiteMdl[] = []
    if(objsComites === null){return []}
    Object.keys(objsComites).forEach(key => {
      const comite: ComiteMdl = objsComites[key]
      comites.push(comite)
    })

    return comites
  }
}
