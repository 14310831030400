/*import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder } from '@angular/forms'

//servicios
import { UsuarioService, SidebarService } from '../../../services/service.index';

//modelos
import { UsuarioMdl } from '../../../models/Usuario';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  usuario: UsuarioMdl = new UsuarioMdl();
  usuarios: UsuarioMdl[] = [];

  formGroup: FormGroup;

  get f() { return this.formGroup.controls; }
  isSave: boolean = true
  
  
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    private sideBarService: SidebarService
    ) {
    this.formGroup = this._formbuilder.group(this.usuario);
    
  }

  ngOnInit() {

    
     
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if (id !== 'new') {
      this.isSave = false
      //Editar
      this.usuarioService.getUsuario(Number(id))
      .subscribe(resp => {
        console.log(resp);
        this.usuario = resp;
        this.usuario.contrasenha=null;
        console.log(this.usuario);
        this.formGroup = this._formbuilder.group(this.usuario);
      })
    }
  

  }

  guardarUsuario() {
    if (this.formGroup.invalid) {
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })

    this.usuario = this.formGroup.value;
    debugger
   
    Swal.showLoading()

    if (this.usuario.usuarioId) {
      this.usuario.creado = new Date();
      this.usuario.actualizado = new Date();
      this.usuario.actualizadoPor = 1;

      peticion = this.usuarioService.actualizarUsuario(this.usuario)
      console.log(this.usuario)
    }
    else {
      this.usuario.activo = true;
      this.usuario.creadoPor = 1;
      this.usuario.creado =new Date();
      this.usuario.actualizado =new Date();
      this.usuario.actualizadoPor =1;
      peticion = this.usuarioService.crearUsuario(this.usuario)
    }
    peticion.subscribe(resp => {
      Swal.fire({
        title: this.usuario.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    }, error => {
      Swal.fire({
        title: this.usuario.nombre,
        text: 'Error en la peticion',
        type: 'error',
      })
    }
    
    )
  }

}*/

import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { FormGroup,FormBuilder } from '@angular/forms'

//servicios
import { UsuarioService, SidebarService } from '../../../services/service.index';

//modelos
import { UsuarioMdl } from '../../../models/Usuario';
import { DependenciaMdl } from '../../../models/dependencia.model';

@Component({
  selector: 'app-macro',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent implements OnInit {

  usuario: UsuarioMdl = new UsuarioMdl()
  
 
  dependencia: DependenciaMdl = new DependenciaMdl()
  dependencias: DependenciaMdl[] = []
  dependenciaslctd: number=0;

  formGroup: FormGroup;
  
  get f() { return this.formGroup.controls; }
  isSave: boolean = true
 
  constructor(
    private _formbuilder: FormBuilder,
    private route: ActivatedRoute,
    private usuarioService: UsuarioService,
    private  sideBarService: SidebarService
    ) { 

      this.formGroup = this._formbuilder.group(this.usuario);
    }

  ngOnInit() {

    this.usuarioService.getDependencia()
      .subscribe(res => {
        
        this.dependencias = res
      })
      
    //obtengo el parametro en la ruta GET
    const id = this.route.snapshot.paramMap.get('id')
    if(id !== 'new'){
      this.isSave = false
      //Editar
      this.usuarioService.getUsuario(Number(id))
        .subscribe((resp:UsuarioMdl) => {
        
          this.usuario = resp

          this.dependenciaslctd = resp.dependenciaId.dependenciaId;
          this.dependencia = this.dependencias.find(d => d.dependenciaId == this.dependenciaslctd)
          this.usuario.dependenciaId= this.usuario.dependenciaId.dependenciaId;
          this.formGroup = this._formbuilder.group(this.usuario);
          
        })
    }
  }

  //guardarDependencia(form: NgForm){
    guardarUsuario(){
    
      if(this.formGroup.invalid){
      //Aquí va la validación del form
      console.log(this.formGroup)
      console.log('Form no valido')
      return
    }
    let peticion : Observable<any>
    Swal.fire({
      title:'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick:false
    })

    this.usuario = this.formGroup.value;
    this.usuario.activo = true;
    this.usuario.creadoPor =1;
    this.usuario.actualizado = new Date();
    this.usuario.actualizadoPor = 1;
    this.usuario.dependenciaId = Number(this.usuario.dependenciaId);
    debugger
    
  
    Swal.showLoading()

    if(this.usuario.usuarioId){
      this.usuario.creado = this.usuario.creado
      peticion = this.usuarioService.actualizarUsuario(this.usuario)

      console.log(this.usuario)
    }

    else {
      this.usuario.creado = new Date()
      console.log(this.usuario)
      peticion = this.usuarioService.crearUsuario(this.usuario)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title:this.usuario.nombre,
        text:'Realizado correctamente',
        type:'success',
      })
    },

    error => {
      if(error){

      }

      Swal.fire({
        title:this.usuario.nombre,
        text:'error',
        type:'error',
      })
    },
    
    )

 
  }

  
}




