import { Component, OnInit } from '@angular/core';
import {  RegionMdl } from '../../../models/region.model';
import { RegionService,SidebarService } from '../../../services/service.index';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-listado-region',
  templateUrl: './listado-region.component.html',
  styles: []
})
export class ListadoRegionComponent implements OnInit {
 regiones: RegionMdl[] = []
 p: number = 1
  constructor(
    private service: RegionService,
    private  sideBarService: SidebarService
    ) { }

  ngOnInit() {
    this.service.getRegion()
    .subscribe(resp => {      
      this.regiones = resp     
      this.regiones = this.regiones.filter( b => b.activo == true)

    })

  }


  borrarRegion(per: RegionMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${per.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.regiones = this.regiones.filter
        ( e => e.regionId !== per.regionId)
        per.activo = false;
        console.log(per)
        this.service.eliminarRegion(
          per.regionId,
          per
          ).subscribe()
      }
    })
  }

}
