import { AfterViewInit, Component, OnInit, ViewChild,OnDestroy } from '@angular/core';
import {InversionService, SidebarService} from '../../../services/service.index';
import {ProyectoInversionMdl} from '../../../models/proyectoinv.model';
import {ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import { Table } from 'primeng/table';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { DecimalPipe, CurrencyPipe } from '@angular/common';
import { ADTSettings } from 'angular-datatables/src/models/settings';


import { saveAs } from 'file-saver';
/* import { MatSort, MatPaginator, MatDialog, MatSnackBar, MatTableDataSource } from '@angular/material';
 */
@Component({
    selector: 'app-inversion',
    templateUrl:'./listadoInv.component.html',
    styleUrls:['./listadoInv.component.css']

})

export class ListadoInversionComponent implements OnDestroy,OnInit, AfterViewInit{

/*     ProyectoInversionMdl: any;
 */    //cols: any[];
/*     dataSource;
    displayedColumns: string [] = ['regionId', 'municipioId', 'localidadId', 'fondofinanciamientoId',
    'estatusproyectoId', 'ejecutoraId', 'nombre', 'monto','beneficiario','numeroProyecto','eliminar'];
     */
    // @ViewChild('dt', { static: true }) dt: Table;

/*
    @ViewChild(MatSort, {static:false}) sort: MatSort;
    @ViewChild(MatPaginator , {static:false}) paginator: MatPaginator;
 */
    dtTrigger: Subject<any> = new Subject<any>();
    inversions: ProyectoInversionMdl [] = []


    @ViewChild(DataTableDirective, {static: false})
    datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings | ADTSettings   = {};
    // dtOptions: ADTSettings ={};




    filtro: any = {
      vin: "",
    };
    data: any[] = [];
    p:number = 1
    // cols: any[] = [
    //   { field: "Region", header: "Región", width: '150px' },
    //   { field: 'Municipio', header: 'Municipio', width: '150px' },
    //   { field: 'Localidad', header: 'Localidad', width: '150px' },
    //   { field: 'Fondo', header: 'Fondo Financiamiento', width: '150px' },
    //   { field: 'Estatus', header: 'Estatus', width: '150px' },
    //   { field: 'Ejecutora', header: 'Ejecutora', width: '250px' },
    //   { field: 'Nombre', header: 'Nombre', width: '350px' },
    //   { field: 'Monto', header: 'Monto', width: '150px' },
    //   { field: 'Beneficiario', header: 'Beneficiario', width: '150px' },
    //   { field: 'NProyectos', header: 'Número de Proyecto', width: '150px' },
    //    {field: 'AFiscal', header: 'Año Fiscal', width: '100px' },
    //   { field: 'Imagenes', header: 'Imágenes', width: '150px' },
    //   { field: 'Acciones', header: 'Acciones', width: '150px' }
    // ];
    constructor(
        private service: InversionService,
        private route: ActivatedRoute,
        private sideBarService: SidebarService,
        private router:Router   ,
        private pipeCurrencyInstance: CurrencyPipe, // inject the Pipe
        private pipeDecimal: DecimalPipe // inject the Pipe
        ) {

        /*  this.cols = [
            { field: 'vin', header: 'Región', width: '150px'},
            { field: 'year', header: 'Municipio', width: '150px' },
            { field: 'brand', header: 'Localidad', width: '150px' },
            { field: 'color', header: 'Fondo Financiamiento', width: '150px' },
            { field: 'color', header: 'Estatus', width: '150px' },
            { field: 'color', header: 'Ejecutora', width: '250px' },
            { field: 'color', header: 'Nombre', width: '350px' },
            { field: 'color', header: 'Monto', width: '150px' },
            { field: 'color', header: 'Beneficiario', width: '150px' },
            { field: 'color', header: 'Número de Proyecto', width: '150px' },
            { field: 'color', header: 'Imágenes', width: '150px' },
            { field: 'color', header: 'Acciones', width: '150px' }

        ];*/

/*         this.dataSource = new MatTableDataSource <ProyectoInversionMdl>();

        this.getInversion();*/
     }

     ngAfterViewInit(): void {

      // try {
      //   var temp = JSON.parse(sessionStorage.getItem('filtroficha'))
      //   if (temp) {
      //     this.filtro.Region = temp.Region
      //     this.filtro.Municipio = temp.Municipio
      //     this.filtro.Localidad = temp.Localidad
      //     this.filtro.Fondo = temp.Fondo
      //     this.filtro.Estatus = temp.Estatus
      //     this.filtro.Ejecutora = temp.Ejecutora
      //     this.filtro.NProyectos = temp.NProyectos
      //     this.filtro.AFiscal = temp.AFiscal

      //     this.dt.filter(temp.Region, 'Region', 'contains');
      //     this.dt.filter(temp.Municipio, 'Municipio', 'contains');
      //     this.dt.filter(temp.Localidad, 'Localidad', 'contains');
      //     this.dt.filter(temp.Fondo, 'Fondo', 'contains');
      //     this.dt.filter(temp.Estatus, 'Estatus', 'contains');
      //     this.dt.filter(temp.Ejecutora, 'Ejecutora', 'contains');
      //     this.dt.filter(temp.NProyectos, 'NProyectos', 'contains');
      //     this.dt.filter(temp.AFiscal, 'AFiscal', 'contains');


      //   }
      //   else {
      //     this.filtro = {
      //       Region: "",
      //       Municipio: "",
      //       Localidad: "",
      //       Fondo: "",
      //       Estatus: "",
      //       Ejecutora: "",
      //       NProyectos: "",
      //       AFiscal: ""

      //     };
      //   }
      // } catch (error) {
      //   this.filtro = {
      //     Region: "",
      //     Municipio: "",
      //     Localidad: "",
      //     Fondo: "",
      //     Estatus: "",
      //     Ejecutora: "",
      //     NProyectos: "",
      //     AFiscal: ""

      //   };
      // }
      this.dtTrigger.next();

      // this.dtTrigger.subscribe(() => {
      this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.columns().every(function () {
          const that = this;
          $('input').on('keyup change', function () {
            if (dtInstance.column(this['id']).search() !== this['value']) {
              dtInstance
                .columns(this['id'])
                .search(this['value'])
                .draw();
            }
          });
        });
      });
      // });

    }

    // changeFiltro(columna, dato) {
    //   //debugger

    //   this.filtro[columna] = dato;
    //   sessionStorage.removeItem('filtroficha')
    //   let filt = JSON.stringify(this.filtro)
    //   sessionStorage.setItem('filtroficha', filt)

    // }
    // limpiarFiltro() {

    //   this.filtro = {
    //     Region: "",
    //     Municipio: "",
    //     Localidad: "",
    //     Fondo: "",
    //     Estatus: "",
    //     Ejecutora: "",
    //     NProyectos: "",
    //     AFiscal: ""

    //   };

    //   sessionStorage.setItem('filtroficha', JSON.stringify(this.filtro))


    //   this.ngAfterViewInit()
    // }


    ngOnInit(){
      this.dtOptions = {
        "language": {
          "lengthMenu": "Mostrar _MENU_ Registros por pagina",
          "zeroRecords": "Nothing found - sorry",
          "info": "Total de Registros _TOTAL_ ",
          "infoEmpty": "No Encontrado",
          "loadingRecords": "Cargando Datos...",
          "infoFiltered": " de un Total de _MAX_ Registros",
          "search": "Buscar",
      },
        dom: 'Blfrtip',
        processing: true,
        // serverSide: true,
        // paging: true,
        // pagingType: 'full_numbers',
        destroy:true,
        scrollX: true,
        responsive: true,
        orderCellsTop: true,
        pageLength: 100,
        "lengthMenu": [ [100, 500, 1000, -1], [100, 500, 1000, "All"] ],
        // stateSave: true,
        ajax: (dataTablesParameters: any, callback) => {
        this.service.getInversion().subscribe(resp => {
          // this.dtTrigger.next();
            this.inversions = resp
            // console.log(resp)
            this.inversions = this.inversions.filter( b => b.activo == true)

            this.data = this.inversions;
            this.data.forEach(element => {

              try {
                element.Region = element.localidadId.municipioId.regionId.nombre
              } catch {
                element.Region = "";
              }

              try {
                element.Municipio = element.localidadId.municipioId.nombre
              } catch {
                element.Municipio = "";
              }

              try {
                element.Localidad = element.localidadId.nombre
              } catch {
                element.Localidad = "";
              }

              try {
                element.Fondo = element.fondofinanciamientoId.nombre
              } catch {
                element.Fondo = "";
              }

              try {
                element.Estatus = element.estatusproyectoId.nombre
              } catch {
                element.Estatus = "";
              }

              try {
                element.Ejecutora = element.ejecutoraId.nombre
              } catch {
                element.Ejecutora = "";
              }

              try {
                element.NProyectos = element.numeroProyecto
              } catch {
                element.NProyectos = "";
              }

              try {
                element.AFiscal = element.afiscal
              } catch {
                element.AFiscal = "";
              }

            })
            // this.dtTrigger.next();
            callback({
              recordsTotal: resp.length,
              recordsFiltered: resp.length,
              data: this.inversions
            });
        })
      },
      columns: [
        {title:'Id',data:'proyectoinversionId'},
        { title: 'Region', data: 'localidadId.municipioId.regionId.nombre' },
        { title: 'Municipio', data: 'localidadId.municipioId.nombre' },
        { title: 'Localidad', data: 'localidadId.nombre' },
        { title: 'Fondo', data: 'fondofinanciamientoId.nombre' },
        { title: 'Estatus', data: 'estatusproyectoId.nombre' },
        { title: 'Ejecutora', data: 'ejecutoraId.nombre' },
        { title: 'NProyecto', data: 'numeroProyecto' },
        { title: 'AFiscal', data: 'afiscal' },
        { title: 'Nombre', data: 'nombre'  },
        { title: 'Monto', data: 'monto',  render: $.fn.dataTable.render.number(',', '.', 2, '$') },
        { title: 'Beneficiario', data: 'beneficiario',render: $.fn.dataTable.render.number(',', '.', 0, '') },
        { title: 'Imagenes', data: 'proyectoinversionId' },
        { title: 'Acciones', data: 'proyectoinversionId' },
      ],
      rowCallback: (row: Node, data: ProyectoInversionMdl, index: number) => {
        const self = this;
        $('#img', row).off('click');
        $('#img', row).on('click', () => {
          this.router.navigate(["/imgInv/" + data.proyectoinversionId])
        });

        $('#editar', row).off('click');
        $('#editar', row).on('click', () => {
          this.router.navigate(["/inversion/" + data.proyectoinversionId])
        });

        $('#borrar', row).off('click');
        $('#borrar', row).on('click', () => {
          self.borrarInversion(data, index);

        });

        $('#reporte', row).off('click');
        $('#reporte', row).on('click', () => {
          // this.router.navigate(["/inversion/" + data.proyectoinversionId])
          this.imprimirReporte(data.proyectoinversionId)
        });
        return row;

      },
      createdRow: (row, data: ProyectoInversionMdl, dataIndex) => {
        if(this.sideBarService.BuscarPermiso('/webresources/proyectoinversion/{id}', 'Administrador', 'put')==true){
          $('td', row).eq(12).html("" +
          "<button id='img' type='button' class='btn btn-success btn-default btn-sm'>" +
          "<i class='fa fa-upload icon-white'></i>" +
          "</button>")
        }

        if(this.sideBarService.BuscarPermiso('/webresources/proyectoinversion/{id}', 'Administrador', 'put')==true){
          $('td', row).eq(13).html("" +
          "<button id='editar' type='button' class='btn btn-success btn-default btn-sm'>" +
          "<i class='fa fa-edit icon-white'></i>" +
          "</button>")
        }
        if(this.sideBarService.BuscarPermiso('/webresources/proyectoinversion/{id}', 'Administrador', 'delete')==true){
          $('td', row).eq(13).append("" +
          "<button id='borrar' type='button' class='btn btn-danger btn-default btn-sm'>" +
          "<i class='fa fa-trash icon-white'></i>" +
          "</button>")
        }

        if(this.sideBarService.BuscarPermiso('/webresources/proyectoinversion/reporte/{id}', 'Administrador', 'post')==true){
          $('td', row).eq(13).append("" +
          "<button id='reporte' type='button' class='btn btn-primary btn-default btn-sm'>" +
          "<i class='fa fa-print icon-white'></i>" +
          "</button>")
        }
      }

      }
    }



    /*
    getInversion() {


        this.service.getInversion().subscribe(data => {
          if (data) {
            ;
             this.ProyectoInversionMdl = data;
    this.dataSource = new MatTableDataSource(this.ProyectoInversionMdl);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
            //this.spinner.hide();
          } else {
          // this.service.openSnackBar(this.snackBar, 'Ocurrió un problema al cargar el listado de derechos ítem', 'Ok', 2000);
            //this.route.navigate(['/home']);

          }
        },
          error => {
            //this.service.openSnackBar(this.snackBar, "Error al consultar el listado de derechos ítem: " + error.message, 'Ok', 2000);
          });
      }

     */

      rerender(): void {

        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next()
          // this.dtTrigger.next(0);
          console.log(this.datatableElement)
        });
      }

      ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
      }

    borrarInversion(inversion: ProyectoInversionMdl, i:number){
        Swal.fire({
         title:'Eliminar',
         text:`Eliminará ${inversion.nombre}`,
         type:'question',
         showConfirmButton:true,
         showCancelButton:true
        }).then(resp =>{
            if(resp.value){
                this.inversions = this.inversions.filter( inv => inv.proyectoinversionId !== inversion.proyectoinversionId)
                inversion.activo = false;
                console.log(inversion)
                this.service.eliminarInversion(
                    inversion.proyectoinversionId,
                    inversion
                ).subscribe()

            }
        })

    }

    imprimirReporte(row :any) {
      let filename = "ReporteInversion.pdf";
      this.service.imprimirReporte(filename,row).subscribe(

        data => {
        saveAs(data, filename);
       // this._spinner.hide();
      },

        err => {
        alert("Problema al descargar el archivo.");
         console.error(err);
        }

          );
    }


}

