/*import { DependenciaMdl } from './dependencia.model';

export class Usuario {
    constructor() {
        this.usuarioId 
        this.creado = 0; 
        this.creadoPor = 0 ;
        this.actualizado = 0; 
        this.actualizadoPor = 0 ;
        this.activo = false;
        this.nombre = '';        
        this.usuario = '';    
        this.dependenciaId = 0; 
    }
    usuarioId: number;
    creado: number;
    creadoPor: number;
    actualizado: number;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;    
    usuario: string;  
    dependenciaId: any;
}*/

export class UsuarioMdl{

   
    creado : Date;
    creadoPor : number;
    actualizado : Date;
    actualizadoPor : number;
    activo : boolean;
    usuario : string;
    contrasenha : string;
    nombre : string; 
    apellidoPaterno : string;
    apellidoMaterno : string;
    usuarioId : number;
    dependenciaId: any;
  
  

        constructor(){

            this.usuarioId = null;
            this.creado = null;
            this.creadoPor = null;
            this.actualizado = null;
            this.actualizadoPor = null;
            this.activo = true;
            this.usuario = null;
            this.contrasenha = null;
            this.nombre = null;
            this.apellidoPaterno = null;
            this.apellidoMaterno = null;
            this.dependenciaId = '';

        
        }
}
