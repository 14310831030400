import { DesagregadoMdl } from './desagregado.model';

export class OpcionDesagregadoMdl {

    opcionDesagregadoId: number;
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean
    desagregadoId:any
    nombre: string;
    descripcion: string;
  
    constructor() {
        this.opcionDesagregadoId= null;
        this.desagregadoId =new DesagregadoMdl();
        this.creado = new Date();
        this.creadoPor = null;
        this.actualizado = new Date();
        this.actualizadoPor = null;
        this.activo = true;
        this.nombre = null;
        this.descripcion = null;
    }

}