import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

/*
The JWT interceptor intercepts the incoming requests from the application/user and adds JWT token to the request's Authorization header, only if the user is logged in.
This JWT token in the request header is required to access the SECURE END API POINTS on the server 
*/

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    constructor() {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        // check if the current user is logged in
        // if the user making the request is logged in, he will have JWT token in it's local storage, which is set by Authorization Service during login process
        let currentUser = JSON.parse(sessionStorage.getItem('currentUser'));//Enoc: 2019/09/06: Extrae la informacion de seison del usuario del sessionStorage

        if (currentUser && currentUser.token) {//Enoc: 2019/09/06: Si en el sessionStorage existe un usuario en log y contine el token 

            let headers = new HttpHeaders({ //Enoc: 2019/09/06: Inserta en la cabecera de la peticion el token de autorizacion 
                'Authorization': `Bearer ${currentUser.token}`
            });

            // Clone the request and replace the original headers with
            // cloned headers, updated with the authorization.
            const authReq = request.clone({ headers: headers });

            // send cloned request with header to the next handler.
            return next.handle(authReq);

        } else { //Enoc: 2019/09/06: Si no existe usuario en sesion, retorna el siguiente manejador de la peticion 
            return next.handle(request);
        }

    }
}