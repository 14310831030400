/*import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { FondoFinanciamientoMdl } from '../../models/fondofinanciamiento.model';
import { URL_API } from '../../globals';

@Injectable()
export class FondosService {

  constructor(private http: HttpClient) { }

  getFondos(){
    

    return this.http.get(`${URL_API}/fondofinanciamiento`)
      .pipe(map(this.crearLista))
      
  }

  eliminarFondo(id:number, fondo: FondoFinanciamientoMdl){
    return this.http.put(`${URL_API}/fondofinanciamiento/${id}`, 
    fondo)
  }

  crearFondo(fondo: FondoFinanciamientoMdl){
    
    return this.http.post(`${URL_API}/fondofinanciamiento`, fondo)
    .pipe(map((resp:any)=>{
     // fondo.fondofinanciamientoId = resp.fondofinanciamientoId
      return fondo
    }))
  }

  getFondo(id:number){
    return this.http.get(`${URL_API}/fondofinanciamiento/${id}`)
  }

  actualizarFondo(fondo: FondoFinanciamientoMdl){
    const fondoTemp = {
      ...fondo
    }
    delete fondoTemp.fondofinanciamientoId
    return this.http.put(`${URL_API}/fondofinanciamiento/${fondo.fondofinanciamientoId}`, 
    fondo)
  }

  private crearLista(fondoObj: Object){
    const fondos: FondoFinanciamientoMdl[] = []
    if(fondoObj === null){ return [] }

    Object.keys(fondoObj).forEach(key => {
      const fondo: FondoFinanciamientoMdl = fondoObj[key]
      fondos.push(fondo)
    })

    return fondos
  }

}*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { FondoFinanciamientoMdl } from '../../models/fondofinanciamiento.model';
import { URL_API } from '../../globals';

@Injectable()
export class FondosService {

  constructor(private http: HttpClient) { }

  getFondos(){
    return this.http.get(`${URL_API}/fondofinanciamiento`)
      .pipe(map(this.crearLista))
      
  }

  eliminarFondo(id:number, fondo: FondoFinanciamientoMdl){
    return this.http.delete(`${URL_API}/fondofinanciamiento/${id}`)
  }

  crearFondo(fondo: FondoFinanciamientoMdl){
    return this.http.post(`${URL_API}/fondofinanciamiento`, fondo)
    .pipe(map((resp:any)=>{
      //bloque.bloqueId = resp.bloqueId
      return fondo
    }))
  }

  getFondo(id:number){
    return this.http.get<any>(`${URL_API}/fondofinanciamiento/${id}`)
  }

  actualizarFondo(fondo: FondoFinanciamientoMdl){
    const fondoTemp = {
      ...fondo
    }
    delete fondoTemp.fondofinanciamientoId
    return this.http.put(`${URL_API}/fondofinanciamiento/${fondo.fondofinanciamientoId}`, 
      fondo)
  }

  private crearLista(fondoObj: Object){
    const fondos: FondoFinanciamientoMdl[] = []
    if(fondoObj === null){ return [] }

    Object.keys(fondoObj).forEach(key => {
      const fondo: FondoFinanciamientoMdl = fondoObj[key]
      fondos.push(fondo)
    })

    return fondos
  }

}