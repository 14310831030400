import { Component, OnInit } from '@angular/core';
import { DerechoItem } from '../../../models/DerechoItem';
import { DerechoService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { iDerechoDerechoItem } from '../../../models/iDerechoDerechoitem';
import Swal from 'sweetalert2';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-derecho-asigncion',
  templateUrl: './derecho-asigncion.component.html',
  styleUrls: ['./derecho-asigncion.component.css']
})
export class DerechoAsigncionComponent implements OnInit {
  derechoItems: any;  //Almacena todos los menú items existentes, se agrega como un any para agregar la columna correspondiente al checkbox
  derechoItemsAsignados: DerechoItem[];
  derechoNombre: string;
  derechoId: string;
  Save: boolean = false; //Bandera para activar el boton de guardado de cambios
  iderechoderechoitem: iDerechoDerechoItem[] = [];
  TodosderechoderechoItem: any;

  constructor(private serviceDerecho: DerechoService, private route: ActivatedRoute) {
    this.derechoId = this.route.snapshot.paramMap.get('id');
    this.derechoNombre = this.route.snapshot.paramMap.get('nombre');
    this.poblarDatos();


  }
  poblarDatos() { //Funcion encargada de consultar los todos los items, asi como los que ya tiene asignado el menu para realizar las marcas previas

  }

  clickCheckbox(row: any) { //Click al check para marcar en false su respectivo registro en n
    if (this.derechoItems.find(m => (m.derechoitemId === row.derechoitemId) && (m.derechoId === row.derechoId)).isChecked)
      this.derechoItems.find(m => (m.derechoitemId === row.derechoitemId) && (m.derechoId === row.derechoId)).isChecked = false;
    else
      this.derechoItems.find(m => (m.derechoitemId === row.derechoitemId) && (m.derechoId === row.derechoId)).isChecked = true;
    if (!this.Save)
      this.Save = true;
  }

  ngOnInit() {
  }

  guardaCambios() {
    Swal.fire({
      title: 'Espere',
      text: 'Revisando y guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    Swal.showLoading()
    this.derechoItems.forEach(m => {
      if (m.isChecked) {//Si el registro está seleccionado en el checkbox de la tabla
        if (!this.derechoItemsAsignados.find(mAsig => mAsig.derechoitemId === m.derechoitemId)) { //pero no existe en el listado de los asignados
          this.addItem(m.derechoitemId); //Se agrega el registro a lisado de los que se enviarán a server como un registro nuevo
        }
      } else {//Si el registro no esta seleccionado en el checkbox de la tabla
        if (this.derechoItemsAsignados.find(mAsig => mAsig.derechoitemId === m.derechoitemId)) { //pero existe en el listado de los asignados
          this.addItem(m.derechoitemId, 'E');//Se agrega el registro a lisado de los que se enviarán a server como un registro eliminado
        }
      }
    });
    if (this.iderechoderechoitem.length > 0) { //Si existem registros a grabarse (ya sea eliminación o nuevo) 
      let peticion: Observable<any> = this.serviceDerecho.saveiDerechoDerechoItem(this.iderechoderechoitem)
      peticion.subscribe(resp => {
        Swal.fire({
          title: this.derechoNombre,
          text: 'Realizado correctamente',
          type: 'success',
        })
      });
    } else { //si no hay nada que guardar/actualizar
      Swal.fire({
        title: this.derechoNombre,
        text: 'No existen cambios para guardar',
        type: 'warning',
      });
    }
    this.poblarDatos();//Actualiza los datos de la pantalla
    this.Save = false; // Desactiva la bandera para deshabilitar el boton de guardar cambios
  }


  addItem(derechoitemId: number, iderechoderechoitem?: string) {
    if (iderechoderechoitem == "E") {  // si es un registro de eliminacion       
      var derechoderechoItem = this.TodosderechoderechoItem.find(mnuItm => mnuItm.derechoitemId.derechoitemId === derechoitemId && String(mnuItm.derechoId.derechoId) === this.derechoId);

      derechoderechoItem.activo = 0;
      derechoderechoItem.actualizado = Date.now();
      derechoderechoItem.actualizadoPor = 1; // Actualmente se pasa como ide de actualizado un 1, esto debe cambiarse al id del usuario en sesion cuando se tenga el manejo de la misma
      this.iderechoderechoitem.push(derechoderechoItem);
    } else { // si es un registro nuevo para el usuario determinado  
      var derechoderechoItem: any;
      derechoderechoItem = {};
      derechoderechoItem.activo = 1;
      derechoderechoItem.actualizado = Date.now();
      derechoderechoItem.actualizadoPor = 1;// Actualmente se pasa como ide de actualizado un 1, esto debe cambiarse al id del usuario en sesion cuando se tenga el manejo de la misma
      derechoderechoItem.creado = Date.now();
      derechoderechoItem.creadoPor = 1; // Actualmente se pasa como ide de actualizado un 1, esto debe cambiarse al id del usuario en sesion cuando se tenga el manejo de la misma      
      derechoderechoItem.derechoitemId = derechoitemId;
      derechoderechoItem.derechoId = +this.derechoId;
      this.iderechoderechoitem.push(derechoderechoItem);
    }
  }
}
