
export class PerfilMdl{

    perfilId:number;
    creado:Date;
    creadoPor:number;
    actualizado:Date;
    actualizadoPor:number;
    activo:boolean;
    nombre:string;
    descripcion:string;
    derechos:string;
    bloques:string;
    menuId:any;

        constructor(){
            this.perfilId = null;
            this.creado = null;
            this.creadoPor = null;
            this.actualizado = null;
            this.actualizadoPor = null;
            this.activo = true;
            this.nombre = null;
            this.descripcion = null;
            this.derechos = null;
            this.bloques = '';
            this.menuId = 1;

        }
}

export class MenuPerfil {
    constructor() {
        this.metodo =[];
        this.ruta  =[];     
    }
    metodo: any;
    ruta: any;
}
