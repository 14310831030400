import { Component, OnInit,OnDestroy } from '@angular/core';
import { DependenciasService, SidebarService } from '../../../services/service.index';
import { DependenciaMdl } from '../../../models/dependencia.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-dependencias',
  templateUrl: './listado.component.html',
  styleUrls: ['./listado.component.css']
})
export class ListadoComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  dependencias: DependenciaMdl[] = []
  selectedDependencias: DependenciaMdl[];  
  p: number = 1
  constructor(private service: DependenciasService,
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    //----
this.dtOptions = {
  "language": {
    "lengthMenu": "Mostrar _MENU_ Registros por pagina",
    "zeroRecords": "Nothing found - sorry",
    "info": "Total de Registros _TOTAL_ ",
    "infoEmpty": "No Encontrado",
    "infoFiltered": " de un Total de _MAX_ Registros",
    "search": "Buscar",
},
dom: 'Blfrtip',
};

    this.service.getDependencias()
      .subscribe(data => {
        this.dependencias =  data
        this.dtTrigger.next();
        
        this.dependencias = this.dependencias.filter( b => b.activo == true)

      })

    
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  borrarDependencia(dependencia: DependenciaMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${dependencia.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
      this.dependencias = this.dependencias.filter( de => de.dependenciaId !== dependencia.dependenciaId)
        dependencia.activo = false;
        // console.log(dependencia)
        this.service.eliminarDependencia(
          dependencia.dependenciaId,
          dependencia
          ).subscribe()
      }
    })
  }

}
