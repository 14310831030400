export class ibloquedependenciaMdl {

    ibloqueDependenciaId:number;
    creado:Date;
    creadoPor:number;
    actualizado:Date;
    actualizadoPor:number;
    activo:boolean;
    bloqueId:any;
    dependenciaId:any;

    constructor(){

        this.ibloqueDependenciaId = null;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.activo = true;
        this.bloqueId = '';
        this.dependenciaId = '';

    }
}