import { Component, OnInit } from '@angular/core';
import { DerechoService } from '../../../services/service.index';
import { ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms'
import Swal from 'sweetalert2'
import { Observable } from 'rxjs';
import { Derecho } from '../../../models/Derecho';

@Component({
  selector: 'app-derecho',
  templateUrl: './derecho.component.html',
  styleUrls: ['./derecho.component.css']
})
export class DerechoComponent implements OnInit {
  derecho: Derecho = new Derecho();
 

  isSave: boolean = true
  constructor(private service: DerechoService,
    private route: ActivatedRoute) { }

  ngOnInit() {
         //obtengo el parametro en la ruta GET    
         const id = this.route.snapshot.paramMap.get('id')    
         if (id !== 'new') {    
           this.isSave = false
           //Editar
           this.service.getDerechoId(Number(id))
             .subscribe((res: Derecho) => {
               this.derecho = res          
             })
         }
  }

  guardaDerecho(form: NgForm) {
    if (form.invalid) {
      //Aquí va la validación del form
      console.log('Form no valido')
      return
    }
    let peticion: Observable<any>
    Swal.fire({
      title: 'Espere',
      text: 'Guardando información',
      type: 'info',
      allowOutsideClick: false
    })
    this.derecho.activo = true
    this.derecho.creadoPor = 1
    this.derecho.actualizado = Date.now()    
    Swal.showLoading()
    if (this.derecho.derechoId) {
      this.derecho.creado = this.derecho.creado
      this.derecho.actualizado = Date.now();
      this.derecho.actualizadoPor = 1; 
      peticion = this.service.actualizarDerecho(this.derecho.derechoId, this.derecho)
      console.log(this.derecho)
    }
    else {
      this.derecho.creado = Date.now()
      //this.dependencia.actualizado = Date.now()
      console.log(this.derecho)
      peticion = this.service.crearDerecho(this.derecho)
    }

    peticion.subscribe(resp => {
      Swal.fire({
        title: this.derecho.nombre,
        text: 'Realizado correctamente',
        type: 'success',
      })
    })
  }
}
