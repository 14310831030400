/*import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { MacrobjetivoMdl } from '../../models/macrobjetivo.model';
import { URL_API } from '../../globals';
import { EjepndMdl } from '../../models/ejepnd.model';

@Injectable()
export class MacrosService {

  constructor(private http: HttpClient) { }

  getMacrobjetivos(){
    return this.http.get(`${URL_API}/macroobjetivo`)
      .pipe(map(this.crearLista))
  }

  getEjepnds(){
    return this.http.get(`${URL_API}/ejepnd`)
      .pipe(map(this.listaEjepnds))
  }

  eliminarMacrobjetivo(id:number, macroobjetivo: MacrobjetivoMdl){
    return this.http.put(`${URL_API}/macroobjetivo/${id}`, 
    macroobjetivo)
  }

  crearMacrobjetivo(macroobjetivo: MacrobjetivoMdl){
    return this.http.post(`${URL_API}/macroobjetivo`, macroobjetivo)
    .pipe(map((resp:any)=>{
       // macrobjetivo.macroobjetivoId = resp.macroobjetivoId
      //console.info('Guardado: ', resp)
      return macroobjetivo
    }))
  }

  getMacrobjetivo(id:number){
    return this.http.get<any>(`${URL_API}/macroobjetivo/${id}`)
  }

  actualizarMacrobjetivo(macroobjetivo: MacrobjetivoMdl){
    const macroobjetivoTemp = {
      ...macroobjetivo
    }
    delete macroobjetivoTemp.macroobjetivoId
    return this.http.put(`${URL_API}/macroobjetivo/${macroobjetivo.macroobjetivoId}`, 
    macroobjetivo)
  }

  private crearLista(macrobjetivoObj: Object){
    const macrobjetivos: MacrobjetivoMdl[] = []
    if(macrobjetivoObj === null){ return [] }

    Object.keys(macrobjetivoObj).forEach(key => {
      const macrobjetivo: MacrobjetivoMdl = macrobjetivoObj[key]
      macrobjetivos.push(macrobjetivo)
    })

    return macrobjetivos
  }

  private listaEjepnds(objsEjepnds: Object){
    const ejepnds: EjepndMdl[] = []
    if(objsEjepnds === null){return []}
    Object.keys(objsEjepnds).forEach(key => {
      const ejepnd: EjepndMdl = objsEjepnds[key]
      ejepnds.push(ejepnd)
    })

    return ejepnds
  }
}*/

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { MacrobjetivoMdl } from '../../models/macrobjetivo.model';
import { URL_API } from '../../globals';
import { EjepndMdl } from '../../models/ejepnd.model';

@Injectable()
export class MacrosService {

  constructor(private http: HttpClient) { }

  getMacrobjetivos(){
    return this.http.get(`${URL_API}/macroobjetivo`)
      .pipe(map(this.crearLista))
  }

  getEjepnds(){
    return this.http.get(`${URL_API}/ejepnd`)
      .pipe(map(this.listaEjepnd))
  }

  eliminarMacrobjetivo(id:number, macrobjetivo: MacrobjetivoMdl){
    return this.http.delete(`${URL_API}/macroobjetivo/${id}`)
  }

  crearMacrobjetivo(macrobjetivo: MacrobjetivoMdl){
    return this.http.post(`${URL_API}/macroobjetivo`, macrobjetivo)
    .pipe(map((resp:any)=>{
     // dependencia.dependenciaId = resp.dependenciaId
      //console.info('Guardado: ', resp)
      return macrobjetivo
    }))
  }

  getMacrobjetivo(id:number){
    return this.http.get<any>(`${URL_API}/macroobjetivo/${id}`)
  }

  actualizarMacrobjetivo(macrobjetivo: MacrobjetivoMdl){
    const macrobjetivoTemp = {
      ...macrobjetivo
    }
    delete macrobjetivoTemp.macroobjetivoId
    return this.http.put(`${URL_API}/macroobjetivo/${macrobjetivo.macroobjetivoId}`, 
      macrobjetivo)
  }

  private crearLista(macroObj: Object){
    const macrobjetivos: MacrobjetivoMdl[] = []
    if(macroObj === null){ return [] }

    Object.keys(macroObj).forEach(key => {
      const macrobjetivo: MacrobjetivoMdl = macroObj[key]
      macrobjetivos.push(macrobjetivo)
    })

    return macrobjetivos
  }

  private listaEjepnd(Ejeobjs: Object){
    const ejepnds: EjepndMdl[] = []
    if(Ejeobjs === null){return []}
   
    Object.keys(Ejeobjs).forEach(key => {
      const ejepnd: EjepndMdl = Ejeobjs[key]
      ejepnds.push(ejepnd)
    })

    return ejepnds
  }
}

