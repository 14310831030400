
import { Component, OnInit,OnDestroy } from '@angular/core';
import { FondosService, SidebarService } from '../../../services/service.index';
import { FondoFinanciamientoMdl } from '../../../models/fondofinanciamiento.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-fondos',
  templateUrl: './listado-fondo.component.html',
  styleUrls: ['./listado-fondo.component.css']
})
export class ListadoFondoComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  fondos: FondoFinanciamientoMdl[] = []
  p: number = 1
  constructor(private service: FondosService,
    private  sideBarService: SidebarService) { }

  ngOnInit() {
    //----
this.dtOptions = {
  "language": {
    "lengthMenu": "Mostrar _MENU_ Registros por pagina",
    "zeroRecords": "Nothing found - sorry",
    "info": "Total de Registros _TOTAL_ ",
    "infoEmpty": "No Encontrado",
    "infoFiltered": " de un Total de _MAX_ Registros",
    "search": "Buscar",
},
  dom: 'Blfrtip',
};
    this.service.getFondos()
      .subscribe(data => {
        this.fondos = data
        
        this.fondos = this.fondos.filter( b => b.activo == true)

        this.dtTrigger.next();
      })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  borrarFondo(fondo: FondoFinanciamientoMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${fondo.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.fondos = this.fondos.filter( f => f.fondofinanciamientoId !== fondo.fondofinanciamientoId)
        fondo.activo = false;
        console.log(fondo)
        this.service.eliminarFondo(
          fondo.fondofinanciamientoId,
          fondo
          ).subscribe()
      }
    })
  }
  
}
