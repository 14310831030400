import { Component, OnInit,OnDestroy } from '@angular/core';
import { UniMedidaService, SidebarService } from '../../../services/service.index';
import { UnidadMedidaRMdl } from '../../../models/UniMedidaReplica.model';
import Swal from 'sweetalert2'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-bloques',
  templateUrl: './listadoUniMed.component.html',
  styleUrls: ['./listadoUniMed.component.css']
})
export class ListadoUniMedidaComponent implements OnDestroy,OnInit {
  dtTrigger: Subject<any> = new Subject<any>();
  dtOptions: DataTables.Settings = {};

  medidas: UnidadMedidaRMdl[] = []
  p: number = 1
  constructor(private service: UniMedidaService,
    private  sideBarService: SidebarService
    ) { }

  ngOnInit() {
    //----
this.dtOptions = {
  "language": {
    "lengthMenu": "Mostrar _MENU_ Registros por pagina",
    "zeroRecords": "Nothing found - sorry",
    "info": "Total de Registros _TOTAL_ ",
    "infoEmpty": "No Encontrado",
    "infoFiltered": " de un Total de _MAX_ Registros",
    "search": "Buscar",
},
  dom: 'Blfrtip',
};
    this.service.getUniMedidas()
      .subscribe(resp => {
        
        this.medidas = resp
        this.medidas = this.medidas.filter( b => b.activo == true)
        this.dtTrigger.next();
      })
  }

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }
  borrarUniMed(unidad: UnidadMedidaRMdl,  i:number){
    Swal.fire({
      title:'Eliminar',
      text:`Eliminará  ${unidad.nombre}`,
      type: 'question',
      showConfirmButton:true,
      showCancelButton:true
    }).then(resp => {
      if(resp.value){
        // this.dependencias.splice(i, 1)
        this.medidas = this.medidas.filter( m => m.unidadmedidaId !== unidad.unidadmedidaId)
        unidad.activo = false;
        console.log(unidad)
        this.service.eliminarUnidad(
          unidad.unidadmedidaId,
          unidad
          ).subscribe()
      }
    })
  }
  
}
