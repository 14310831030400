export class VideoMdl {
    
    creado: Date;
    creadoPor: number;
    actualizado: Date;
    actualizadoPor: number;
    activo: boolean;
    nombre: string;
    descripcion: string;
    link:string;
    idioma:string;
    videoId: number;


    constructor(){
        this.activo = true;
        this.creado = null;
        this.creadoPor = null;
        this.actualizado = null;
        this.actualizadoPor = null;
        this.descripcion = null;
        this.videoId = null;
        this.nombre = null;
        this.link = null;
        this.idioma = null;

      
    }
}