import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators'
import { URL_API } from '../../globals';
import { SentidoMdl } from '../../models/sentido.model';

@Injectable()
export class SentidoService {

  constructor(private http: HttpClient) { }

  getSentidos(){
    return this.http.get(`${URL_API}/sentido`)
      .pipe(map(this.crearLista))
  }

  private crearLista(sentidoObj: Object){
    const sentidos: SentidoMdl[] = []
    if(sentidoObj === null){ return [] }

    Object.keys(sentidoObj).forEach(key => {
      const sentido: SentidoMdl = sentidoObj[key]
      sentidos.push(sentido)
    })

    return sentidos
  }
}
